import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import ReactGA from 'react-ga4';
import * as actions from './actions';
import {chaseOrder} from '../OrderDetails/actions';
import {loadCustomerContacts} from '../App/actions';
import {
    selectOrders,
    selectTotalOrdersCount,
    selectOrderStatuses,
    selectActiveTab,
    selectTotalOpenOrdersCount,
    selectOrderGridRowState,
    selectIsLoading,
    selectFilterInfo,
    selectCurrentPage
} from './selectors';
import {selectShowFilters,selectCustomerContacts} from '../App/selectors';
import {createStructuredSelector} from 'reselect';
import PageTitle from './PageTitle';
import Filters from '../../components/Filters';
import GridOrdersDashboard from '../../components/GridOrders/GridOrdersDashboard';
import GridOrdersClosed from '../../components/GridOrders/GridOrdersClosed';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import homeSaga from './sagas';
import homeReducer from './reducer';
import { compose } from '@reduxjs/toolkit';
//import { RESTART_ON_REMOUNT } from '../../utils/constants';

export class Orders extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    constructor(props) {
        super(props);
        this.handleOrdersGridPageChange = this.handleOrdersGridPageChange.bind(this);
        this.handleOpenCloseTabChanged = this.handleOpenCloseTabChanged.bind(this);
        this.handleChase = this.handleChase.bind(this);
        
        this.filtersRef = React.createRef();
    }

    componentDidMount() {
        this.props.loadPagedOrders({startIndex: 0, pageSize: 10, loadOpenOrders: this.props.activeTab == 'open'});
        this.props.loadCustomerContacts();
        this.props.loadOrderStatuses({loadOpenOrders: this.props.activeTab == 'open'});
        this.props.loadTotalOpenOrdersCount();
        //ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.send({ hitType: "pageview", page: "/orders" });
    }

    executeSearch = (filterInfo) => {
        this.props.storeOrderFilters(filterInfo);
        this.props.loadPagedOrders({
            dateCreated: filterInfo.dateCreated,
            dateCompleted: filterInfo.dateCompleted,
            filterText: filterInfo.filterText,
            customerContactId: filterInfo.createdBy,
            orderStatus: filterInfo.orderStatus,
            startIndex: 0,
            pageSize: 10,
            loadOpenOrders: this.props.activeTab == 'open'
        });
    }

    clearSearch = ()=>{
        this.props.storeOrderFilters({});
        this.props.loadPagedOrders({startIndex: 0, pageSize: 10, loadOpenOrders: this.props.activeTab == 'open'});
    }

    handleOrdersGridPageChange(startIndex, pageSize, gridName, currentPage) {
        this.props.loadPagedOrders({
            startIndex: startIndex,
            pageSize: pageSize,
            dateCreated: this.props.filterInfo.dateCreated,
            dateCompleted: this.props.filterInfo.dateCompleted,
            filterText: this.props.filterInfo.filterText,
            customerContactId: this.props.filterInfo.createdBy,
            orderStatus: this.props.filterInfo.orderStatus,
            loadOpenOrders: this.props.activeTab == 'open',
            currentPage
        });
    }

    handleOpenCloseTabChanged(newValue) {
        this.props.switchOpenCloseQuery(newValue);
        this.props.storeOrderFilters({});
        this.filtersRef.current.clear();
        this.props.loadPagedOrders({
            startIndex: 0,
            pageSize: 10,
            loadOpenOrders: newValue == 'open'
        });

        this.props.loadOrderStatuses({loadOpenOrders: newValue == 'open'});
    }

    getCustomerContactsFilterData() {
        const modifiedCustContacts = [];

        for (let i = 0; i < this.props.customerContacts.length; i++) {
            modifiedCustContacts.push({
                id: this.props.customerContacts[i].id,
                text: this.props.customerContacts[i].name
            });
        }

        return modifiedCustContacts;
    }

    handleChase(order) {
        const info = {
            orderId: order.orderId,
            dueDate: order.dueDate
        };

        this.props.chaseOrder(info);
    }

    render() {
        return (
        <React.Fragment>
            <PageTitle activeTab={this.props.activeTab}
                    switchOpenClose={this.handleOpenCloseTabChanged}
                    totalOpenOrdersCount={this.props.totalOpenOrdersCount}/>
            <Filters dropdownCreatedByDataSource={this.getCustomerContactsFilterData()}
                    executeSearchAction = {this.executeSearch}
                    filterClearedAction = {this.clearSearch}
                    dropdownOrderStatusDataSource={this.props.orderStatuses}
                    showFilterProp={this.props.showFilterProp}
                    showOrderStatusDropdown={true}
                    showDateCompletedDatePicker = {this.props.activeTab == 'open' ? false : true}
                    ref={this.filtersRef}/>
            {this.props.activeTab == 'open' 
                ?<GridOrdersDashboard onPageChange={this.handleOrdersGridPageChange}
                                            totalItemsCount={this.props.totalOrdersCount}
                                            datasource={this.props.orders}
                                            showPager={true}
                                            handleChase={this.handleChase}
                                            orderGridRowState={this.props.orderGridRowState}
                                            isLoading={this.props.isLoaded}
                                            currentPage={this.props.currentPage}
                                            showTitle = {false}/>
                :<GridOrdersClosed onPageChange={this.handleOrdersGridPageChange}
                                totalItemsCount={this.props.totalOrdersCount}
                                datasource={this.props.orders}
                                showPager={true}
                                currentPage={this.props.currentPage}
                                isLoading={this.props.isLoaded}/>
            }
        </React.Fragment>
        );
    }
}

Orders.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool
    ]),
};

const mapStateToProps = createStructuredSelector({
    activeTab: selectActiveTab(),
    orders: selectOrders(),
    totalOrdersCount: selectTotalOrdersCount(),
    customerContacts: selectCustomerContacts(),
    orderStatuses: selectOrderStatuses(),
    totalOpenOrdersCount: selectTotalOpenOrdersCount(),
    orderGridRowState: selectOrderGridRowState(),
    showFilterProp: selectShowFilters(),
    isLoaded: selectIsLoading(),
    filterInfo: selectFilterInfo(),
    currentPage: selectCurrentPage()
})
const mapDispatchToProps = ({
    ...actions,
    chaseOrder,
    loadCustomerContacts
})
// Wrap the component to inject dispatch and state into it
//export default connect(mapStateToProps, mapDispatchToProps)(Quotes);
var withSaga = injectSaga({ key: 'orders', saga: homeSaga/*, mode: RESTART_ON_REMOUNT*/ });
var withReducer = injectReducer({ key: 'orders', reducer: homeReducer });

// Wrap the component to inject dispatch and state into it
export default compose(
  withSaga,
  withReducer,
  connect(mapStateToProps, mapDispatchToProps)
)(Orders);
