import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectSelectedPackages, selectDiscountPackageCore } from './selectors';
import { showBasketOnMobile } from './actions';
import keys from 'lodash/keys';
import Cart from '../../svg-icons/cart';

class PageTitle extends React.PureComponent{

    showBasketOnMobile (){
        document.body.classList.add('no-scroll');
        this.props.showBasketOnMobile();
    }

    getPackageCount(){
        var count = keys(this.props.selectedPackageIds).length;
        return count;
    }
    render(){
        return(
            <div className="page-title"> {/* Add className "fixed" when users scroll down and this reaches the top of the screen */}
                    <div className="content-wrapper">
                        {/*<h1>New Order</h1>*/}
                        {this.props.children}
                        {/* Button to show basket on mobile */}
                        <div className="button button-icon button-icon-small button-grey cart-toggle" onClick={()=>this.showBasketOnMobile()}>
                            {/* When clicked, add className 'no-scroll' to <body> and className 'open' L32 */}
                            <Cart className="icon icon-cart" />
                            <span className="badge">{this.getPackageCount()}</span>
                        </div>
                    </div>
                </div>
        );
    }
}
const mapStateToProps = createStructuredSelector({
    selectedPackageIds: selectSelectedPackages(),
    selectedDiscountPackageCore : selectDiscountPackageCore(),
})

const mapDispatchToProps ={
    showBasketOnMobile
}
// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);
