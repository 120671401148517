import React from 'react';
import EmptyView from './empty';
import ProductList from './productList';
import PriceLines from './priceLines';
import CompletionDate from './completionDate';
import ActionLine from './actionLine';
import keys from 'lodash/keys';

export default class BasketContent extends React.PureComponent {
    static defaultProps = {
        showSubmit : true
    }

    isEmpty(){
        var res = keys(this.props.selectedPackageIds).length == 0 &&
                  keys(this.props.selectedDiscountPackageCore).length == 0;

            return res;
    }

    calculateMaxPackageSLA() {
        var maxSLA = -1;

        keys(this.props.selectedPackageIds).forEach(p => {
            if (this.props.allPackages[p].packageSLA > maxSLA) {
                maxSLA = this.props.allPackages[p].packageSLA;
            }
        });

        return maxSLA;
    }

    render() {
        return (
            <div className="details-aside-content">
                {this.isEmpty() ? <EmptyView /> : null}
                {this.isEmpty() == false ? <ProductList selectedPackageIds={this.props.selectedPackageIds}
                                                        selectedPackagesInOrder={this.props.selectedPackagesInOrder}
                                                        allPackages={this.props.allPackages}
                                                        selectedDiscountPackageCore= {this.props.selectedDiscountPackageCore}
                                                        deletePackage = {this.props.deletePackage}
                                                        deleteCoreDiscountPackage= {this.props.deleteCoreDiscountPackage}
                                                        deleteEnquiryPackage={this.props.deleteEnquiryPackage}
                                                        localAuthoritySearchPackageId={this.props.localAuthoritySearchPackageId}
                                                        warningPackages={this.props.warningPackages}
                                                        /> : null}

                {this.isEmpty() == false ? <PriceLines  selectedPackageIds={this.props.selectedPackageIds} vatRate={this.props.vatRate}
                                                        selectedDiscountPackageCore= {this.props.selectedDiscountPackageCore}
                                                        allPackages={this.props.allPackages} /> : null}
                {this.isEmpty() == false ? <CompletionDate selectedPackageIds={this.props.selectedPackageIds} allPackages={this.props.allPackages} /> : null}

                {this.isEmpty() == false ? <ActionLine
                                                        isError={this.props.isCreating}
                                                        showSubmit={this.props.showSubmit}
                                                        showAddToOrder={this.props.showAddToOrder}
                                                        validateForm={this.props.validateForm}
                                                        handleSaveQuote = {this.props.handleSaveQuote}
                                                        handleSubmit = {this.props.handleSubmit}
                                                        handleAddPackages = {this.props.handleAddPackages}
                                                        isBusy={this.props.isBusy}
                                                        showQuote={this.props.showQuote}/> : null}
            </div>
        );
    }
}
