import React from 'react';
import Attachment from '../../svg-icons/attachment';
import DownloadLink from '../../components/DownloadLink';
import { teal100 } from '../../assets/less/base/_variables.js';
import moment from 'moment';
import Form from '../Form';
import UploadFile from '../../components/UploadFile';
import config from '../../utils/config';

export default class QueryDetailsConversationTemplate extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            replyText: '',
            reopenStatus: ''
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        var dataContext = nextProps.dataContext;
        if (dataContext == undefined) {
            return null;
        }
        var queryDetailsRowState = this.getRowState(nextProps.queryGridNavigationColumnState, dataContext.queryId);
        this.setState({ replyText: queryDetailsRowState.text });
    }

    componentDidMount() {
        var dataContext = this.props.dataContext;
        if (dataContext == undefined) {
            return null;
        }

        var queryDetailsRowState = this.getRowState(this.props.queryGridNavigationColumnState, dataContext.queryId);
        this.setState({ replyText: queryDetailsRowState.text });
    }

    getRowState(state, queryId) {
        return state[queryId];
    }

    handleFileUploadChanged = (files) => {
        /*var files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }*/
        console.debug("handleFileUploadChanged");
        Array.from(files).forEach(function (file) {
            this.props.addAttachmentToQuery(file, this.props.dataContext.queryId, 'query', this.props.gridName); // uploadRequest
            //this.props.addAttachmentToQuery(this.props.gridName, this.props.dataContext.queryId, file);//, this.props.dataContext.queryId, 'query');
        }, this);

        /*if(this.props.addAttachmentToQuery){
            this.props.addAttachmentToQuery(this.props.gridName, this.props.dataContext.queryId, files, this.props.dataContext.queryId, 'query');
        }*/
    }

    handleTextReplyChanged = (a, b) => {
        this.setState({ replyText: b });
    }

    handleTextReplyLostFocus = (e) => {
        var text = e.target.value;
        if (this.props.changeReplyText) {
            this.props.changeReplyText(this.props.gridName, this.props.dataContext.queryId, text);
        }
    }

    // creates new query
    handleSubmitClick = () => {
        if (this.props.submitQueryRequest) {
            var dc = this.props.dataContext;
            var queryDetailsRowState = this.getRowState(this.props.queryGridNavigationColumnState, dc.queryId);
            this.props.submitQueryRequest(this.props.gridName, { queryId: dc.queryId, orderId: dc.orderId, description: queryDetailsRowState.text, originator: 'Atlas', attachments: queryDetailsRowState.attachments });
        }
    }

    handleCancelClick = () => {
        if (this.props.cancelQueryAction) {
            var dc = this.props.dataContext;
            var queryDetailsRowState = this.getRowState(this.props.queryGridNavigationColumnState, dc.queryId);
            this.props.cancelQueryAction(this.props.gridName, { queryId: dc.queryId, orderId: dc.orderId, description: queryDetailsRowState.text, originator: 'Atlas', attachments: queryDetailsRowState.attachments });
        }
    }

    handleReplyClick = () => {
        if (this.props.customerReplyToQuery) {
            var dc = this.props.dataContext;
            var queryDetailsRowState = this.getRowState(this.props.queryGridNavigationColumnState, dc.queryId);
            this.props.customerReplyToQuery(this.props.gridName, { queryId: dc.queryId, orderId: dc.orderId, description: queryDetailsRowState.text, originator: 'Atlas', attachments: queryDetailsRowState.attachments });
        }
    }

    handleFileDelete = (index) => {
        if (this.props.deleteQueryFile) {
            this.props.deleteQueryFile(this.props.gridName, this.props.dataContext.queryId, index);
        }
    }

    handleShowEarlierMsgs = () => {
        if (this.props.showEarlierMsgs) {
            this.props.showEarlierMsgs(this.props.gridName, this.props.dataContext.queryId);
        }
    }
    handleReopen=()=>{
        if (this.props.customerReopenQuery) {
            var dc = this.props.dataContext;
            var queryDetailsRowState = this.getRowState(this.props.queryGridNavigationColumnState, dc.queryId);
            this.props.customerReopenQuery(this.props.gridName, { queryId: dc.queryId, orderId: dc.orderId, description: queryDetailsRowState.text, originator: 'Atlas', attachments: queryDetailsRowState.attachments });
        }
    }
    _renderActions(queryDetailsRowState) {
        if (queryDetailsRowState.isNew) {
            return (
                <div className="actions">
                    <button type="submit" onClick={this.handleSubmitClick} className="button button-regular button-green">Submit</button>
                    <button type="submit" onClick={this.handleCancelClick} className="button button-regular button-white color-red">Cancel</button>
                </div>
            );
        }

        if (queryDetailsRowState.state == 'busy') {
            return <div className="loader"></div>
        }

        return (
            <div className="actions">
                <button type="submit" onClick={this.handleReplyClick} className="button button-regular button-green">Reply</button>
            </div>);
    }

    _renderForm() {
        var queryDetailsRowState = this.getRowState(this.props.queryGridNavigationColumnState, this.props.dataContext.queryId);
        return <Form id="query-form" className="query-form">
            <div className="entry">
                <label htmlFor="reply-text">Reply</label>
                <textarea id="reply-text" rows="3" className="form-control-white" value={this.state.replyText}
                    onBlur={this.handleTextReplyLostFocus}
                    onChange={this.handleTextReplyChanged}></textarea>
            </div>

            <UploadFile fileAdded={this.handleFileUploadChanged} fileDeleted={this.handleFileDelete} attachments={queryDetailsRowState.attachments} />

            {this._renderActions(queryDetailsRowState)}
        </Form>
    }
    render() {
        var dataContext = this.props.dataContext;
        if (dataContext == undefined) {
            return null;
        }

        // state as js object d
        var queryDetailsRowState = this.getRowState(this.props.queryGridNavigationColumnState, this.props.dataContext.queryId);
        var conversation = dataContext.queryDetails.map((item, index) => {
            var files = null;
            if (item.attachments) {
                files = item.attachments.map((file, index) => {
                    //var url = `${config.webApiUrl}/file/download?filePath=${file.path}`;
                    var url = `${config.webApiUrl}/file/download?filePath=`;
                    return (
                        <DownloadLink key={index} url={url} file={file.path} className="button button-small button-text-icon button-white color-teal">
                            <Attachment className="icon icon-attachment" stroke={teal100} />
                            <div className="file-label" data-type="jpg">{file.fileName}</div>
                        </DownloadLink>
                    );
                });
            }

            var isCustomerReply = item.originator == 'Atlas';

            if (queryDetailsRowState.showLatestMsgs == false && index > 2) {
                return null;
            }

            return (
                <div className={isCustomerReply ? 'query query-customer' : 'query query-sts'} key={index}>
                    <div className="query-text">
                        <strong className="query-person">{`${item.user.firstName} ${item.user.lastName}`}</strong>
                        {isCustomerReply ?
                            <p>{item.description}</p> :
                            <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                        }
                        {files}
                    </div>
                    <span className="query-date">{moment(item.dateTime).format('DD/MM/YYYY hh:mm A')}</span>
                </div>);
        })
        conversation = conversation.reverse();
        var queryStatus = dataContext.status ;
        if(this.state.reopenStatus=='New'){
            queryStatus='New';
        }
        return (
            <div className="query-details">
                <div className="query-conversation">
                    <h1 className="show-mobile">QUERY TITLE</h1>
                    {queryDetailsRowState.showLatestMsgs == false && dataContext.queryDetails.length > 3
                        ? <div className="button button-small button-grey color-pink" onClick={this.handleShowEarlierMsgs}>Show earlier messages</div>
                        : null}
                    {conversation}
                </div>
                {queryStatus != 'Close'
                    ? this._renderForm()
                    : <div className="query-action">
                        <button type="button" className="button button-regular button-teal" onClick={this.handleReopen}>Reopen Query</button>
                    </div>}
            </div>);
    };
}
