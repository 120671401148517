import React from 'react';
import AlertWarning from '../../svg-icons/alertWarning';
import UploadFile from '../../components/UploadFile';
import {uploadRequest, uploadDeleteTempFileRequest} from '../../components/UploadFile/actions';
import {createStructuredSelector} from 'reselect';
import {selectUploadedFiles, selectDeveloperName, selectSiteName, selectAdditionalInfo} from './selectors';
import {selectDeveloperNameAction, selectSiteNameAction, selectAdditionalInfoAction, deleteMap} from './actions';
import {connect} from 'react-redux';
import ExpandableSection from '../../components/ExpandableSection';
import TextField from '../../components/TextField';
import TextArea from '../../components/TextArea';

class NewOrderAdditionalInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      devName: '',
      siteName: '',
      addInfo: ''
    };
    this.handleFileAdded = this.handleFileAdded.bind(this);
    this.handleFileDeleted = this.handleFileDeleted.bind(this);
    this.uploadChannel = 'create-order-upload-channel';
  }

  componentDidMount() {
    this.setState({
      devName: this.props.developerName,
      siteName: this.props.siteName,
      addInfo: this.props.additionalInfo
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      devName: nextProps.developerName,
      siteName: nextProps.siteName,
      addInfo: nextProps.additionalInfo
    });
  }

  handleFileAdded(files) {
    // fire action
    //this.props.addFileToOrder(files, -1, 'order');
    Array.from(files).forEach(function (file) {
      this.props.uploadRequest(file, -1, 'order', this.uploadChannel);
    }, this);
  }

  handleFileDeleted(index, fileName, filePath) {
    if(fileName=='property-plan.png'){
      this.props.deleteMap(filePath, fileName, this.uploadChannel);
      return;
    }

    this.props.deleteFileFromOrder(filePath, fileName, this.uploadChannel);
  }

  handleTextChanged = (value, textBoxName) => {
    var newState = {};
    newState[textBoxName] = value;
    this.setState(newState);
  }

  render() {
    return (
      <ExpandableSection title='Additional Information' isExpandable={true} rootStyle={{zIndex:1000}}>
        <fieldset className="content-block-form">
          <div className="alert alert-block alert-warning">
            <AlertWarning className="icon icon-alert-warning"/>
            <div className="alert-content">
              <strong>Please provide any additional information that may assist us in completing the search – including
                details of the developer if the property is under 10 years old.</strong>
            </div>
          </div>
          <div className="additional-info-pos">
            <div className="additional-info-element">
              <TextField placeholder="" label='Developer Name: ' inputClassName='form-control-grey'
                         value={this.state.devName}
                         onChange={(event, value) => this.handleTextChanged(value, 'devName')}
                         onBlur={(event, value) => this.props.selectDeveloperNameAction(value)}/>
            </div>
            <div className="additional-info-element margin-left-10">
              <TextField placeholder="" label='Site Name: ' inputClassName='form-control-grey'
                         value={this.state.siteName}
                         onChange={(event, value) => this.handleTextChanged(value, 'siteName')}
                         onBlur={(event, value) => this.props.selectSiteNameAction(value)}/>
            </div>
          </div>
          <div className="additional-info-pos">
            <div className="additional-info-element">
                    <TextArea placeholder="" label='Additional information: ' inputClassName='form-control-grey'
                              rows={3}
                              value={this.state.addInfo}
                              onChange={(event, value) => this.handleTextChanged(value, 'addInfo')}
                              onBlur={(event, value) => this.props.selectAdditionalInfoAction(value)}/>
            </div>
            <div className="additional-info-element margin-left-10">
              <UploadFile fileAdded={this.handleFileAdded} fileDeleted={this.handleFileDeleted}
                          attachments={this.props.uploadedFiles} uploadChannel={this.uploadChannel}/>
            </div>
          </div>
        </fieldset>
      </ExpandableSection>
    );
  }
}


const mapStateToProps = createStructuredSelector({
  developerName: selectDeveloperName(),
  siteName: selectSiteName(),
  additionalInfo: selectAdditionalInfo(),
  uploadedFiles: selectUploadedFiles(),
})
const mapDispatchToProps = {
  deleteFileFromOrder: uploadDeleteTempFileRequest, // uploadDeleteSuccess,
  deleteMap: deleteMap,
  uploadRequest: uploadRequest,
  selectDeveloperNameAction: selectDeveloperNameAction,
  selectSiteNameAction: selectSiteNameAction,
  selectAdditionalInfoAction: selectAdditionalInfoAction
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(NewOrderAdditionalInfo);
