import React from 'react';
import Row from './Row';
import RowWithTemplate from './RowWithTemplate';
import find from 'lodash/find';

export default class Rows extends React.PureComponent {


    render(){
        if(!this.props.datasource){
            return null;
        }
        
        var rows = this.props.datasource.map(function(element, index) {
            // it should not be undefined but it could be wrong data so just skip this data
            if(element == undefined){
                return null;
            }

            // element has data and rowTemplateKey properties. They are defined in reducer
            if(element['rowTemplateKey']){
                var rowTemplateObj = find(this.props.rowTemplates, ['key', element['rowTemplateKey']]);
                if(rowTemplateObj){
                    return <RowWithTemplate key={index} rowTemplateObj={rowTemplateObj} columns={this.props.columns} row={element.data}/>
                }
            }

            var className = null;
            if(this.props.rowClassNameResolver){
                // get className from function which will resolve it
                className = this.props.rowClassNameResolver(element);
            }

            /*if(className==''){
                return <Row key={index} row={element} columns={this.props.columns}/>    
            }*/

            return <Row key={index} row={element} columns={this.props.columns} className={className}/>
        }, this);
        return (
            <tbody>
                {rows}
            </tbody>
        );
    }
}
