import React from 'react';
import { Grid } from '../Grid'
import StarColumnTemplate from './StarColumnTemplate';
import ProductColumnTemplate from './ProductColumnTemplate';
import DocumentColumnTemplate from './DocumentColumnTemplate';
import AdditionalInfoRowTemplate from './AdditionalInfoRowTemplate';
import AdditionalInfoWarningRowTemplate from './AdditionalInfoWarningRowTemplate';
import { formatPrice } from '../../utils/format';

export default class GridProducts extends React.Component {
    constructor(props) {
        super(props);
        this.rowTemplateResolver = this.rowTemplateResolver.bind(this);
    }
    checkForTerms(rowItem) {
        if (rowItem.products !== null) {
            rowItem.products[0]['termsRequiredChecked'] = true;
            this.forceUpdate();
        }
    }

    rowTemplateResolver(rowData) {
        /*<!-- Add class 'selected' when product is selected, remove it when it's unselected -->*/

        /*var cellState = this.props.queryGridNavigationColumnState[rowData.queryId];
        if(cellState && cellState.state == 'open'){
            return 'row-open';
        }*/
        var isSelected = this.props.selectedPackages[rowData['id']];
        if (isSelected) {
            return 'selected';
        }
        return '';
    }

    render() {
        return (
            <Grid {... this.props}
                datasource={this.props.datasource}
                showHeader={false}
                tableClassName={this.props.tableClassName}
                style={{ tableLayout: 'auto' }}
                columnsData={[
                    {
                        header: 'star',
                        className: 'favorites-cell',
                        style: { width: 40 },
                        dataCellTemplate: (props) => (<StarColumnTemplate {...props} favoritePackages={this.props.favoritePackages} toggleFavorite={this.props.toggleFavoriteAction} />)
                    },
                    {
                        header: 'name',
                        style: { width: '*', textAlign: 'left' },
                        dataCellTemplate: (props) => (<ProductColumnTemplate {...props} gridName={this.props.name}
                            activeCategoryTab={this.props.activeCategoryTab}
                            selectPackageAction={this.props.selectPackageAction}
                            selectedPackages={this.props.selectedPackages}
                            discountPackages={this.props.discountPackages}
                            selectedPackagesInOrder={this.props.selectedPackagesInOrder}
                            isAnyWaterPackageSelected={this.props.isAnyWaterPackageSelected}
                        />)
                    },
                    {
                        header: 'docs',
                        dataField: 'packageDocuments',
                        style: { width: 320, textAlign: 'left' },
                        className: 'show-desktop',
                        dataCellTemplate: (props) => (<DocumentColumnTemplate {...props} />)
                    },
                    {
                        header: 'days',
                        dataField: 'packageSLA',
                        style: { width: 50, textAlign: 'right' },
                        className: 'hide-mobile',
                        dataCellTemplate: (props) => (<div className="text-product"> {props.datacontext == undefined ? 'NaN' : props.datacontext}</div>)
                    },
                    {
                        header: 'price',
                        dataField: 'priceWithVAT',
                        style: { width: 100, textAlign: 'right' },
                        dataCellTemplate: (props) => (<div className="text-price">{formatPrice(props.datacontext)}</div>)
                    }
                ]}
                // if data contains key as prop value then the template will be applied
                rowTemplates={[
                    {
                        key: 'additionalInfoKey',
                        rowClassName: 'enquiry-row',
                        template: (props) => (<AdditionalInfoRowTemplate {...props} gridName={this.props.name}
                            selectTmIntegrationPackage={this.props.selectTmIntegrationPackage}
                            setAdditionalTextToProduct={this.props.setAdditionalTextToProduct}
                            setAgreeCheckBoxToProduct={this.props.setAgreeCheckBoxToProduct}
                            selectedPackages={this.props.selectedPackages} />)
                    },
                    {
                        key: 'additionalInfoWarningKey',
                        rowClassName: 'enquiry-row',
                        template: (props) => (<AdditionalInfoWarningRowTemplate {...props} gridName={this.props.name} />)
                    },
                    {
                        key: 'selectAllFavouriteKey',
                        template: <div className="cell-wrapper" style={{ margin: '5px 0px 0px 33px' }}>
                            <label htmlFor='favorite-select-all' className='form-checkbox'>
                                <input id='favorite-select-all' type="checkbox"
                                    onChange={(event) => this.props.toggleAllFavoriteAction(event.target.checked, this.props.name, this.props.selectedPackages, this.props.selectedPackagesInOrder)} />
                                <span className="indicator"></span>
                                <span className="label">SELECT ALL FAVOURITES</span>
                            </label>
                        </div>
                    }
                ]}

                // use this prop if you want to set class to <tr>
                rowClassNameResolver={this.rowTemplateResolver}>
            </Grid>
        );
    }
}
