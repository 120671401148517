import React from 'react';
import Clear from '../../svg-icons/clear';
import throttle from 'lodash/throttle';

export default class AsideWithCart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { fixed: '' };
    this.handleScroll = throttle(this.setPlacement.bind(this), 20);
    this.getRef = this.getRef.bind(this);
  }

  static defaultProps = {
    isOpen: '' // class name for open in mobile
  }

  componentDidMount() {
    var div = document.getElementsByClassName("content")[0];
    div.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    var div = document.getElementsByClassName("content")[0];
    div.removeEventListener('scroll', this.handleScroll);
  }

  setPlacement(event) {
    if (this.aside == undefined) return;
    var scrollTop = document.getElementsByClassName("content")[0].scrollTop;

    if (scrollTop > 70) {
      var menuOffset = document.getElementById('asideMenu');
      var marginLeft = menuOffset.offsetLeft + 'px';

      this.setState({
        fixed: 'fixed',
        left: marginLeft
      });

      return;
    }
    this.setState({
      fixed: ''
    });
  }

  getRef(el) {
    this.aside = el;
  }

  hideBasketOnMobile = () => {
    this.props.hideBasketOnMobile();
    document.body.classList.remove('no-scroll');
  }

  render() {
    var style = {};
    if (this.state.fixed == 'fixed') {
      style = {
        left: this.state.left,
        overflowY: 'auto',
      }
    }

    return (
      <aside id="asideMenu" style={style} className={`details-aside with-cart ${this.state.fixed} ${this.props.isOpen}`}
        ref={this.getRef}> {/* Add className "fixed" when users scroll down and this reaches the top of the screen */}
        {/* Button to close cart on mobile */}
        <div className="button button-icon button-icon-small button-grey cart-toggle"
          onClick={this.hideBasketOnMobile}> {/* When clicked remove className 'open' from L32 and className 'no-scroll' form <body> */}
          <Clear className="icon icon-clear" />
        </div>
        {this.props.children}
      </aside>
    );
  }
}
