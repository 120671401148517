/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Outlet } from "react-router-dom";
import ReactGA from 'react-ga4';
import * as actions from './actions';
import { showFilters } from '../App/actions';
//import injectTapEventPlugin from 'react-tap-event-plugin';
//import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import CustomizedSnackbar from '../../components/Snackbar';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Content from '../../components/Content';
import ModalNotifications from '../../components/ModalNotifications/index';
import { createStructuredSelector } from 'reselect';
import {
  selectIsUserLogged,
  selectActiveLink,
  selectSnackbarOpen,
  selectNotificationsOpen,
  selectSnackbarMsg,
  selectIsLoginPageVisible,
  selectCustomerName,
  selectUserNotifications,
  selectHideFilterIcon,
  selectReceiveWebNotification,

} from './selectors';
import IdleTimer from '../../components/IdleTimer';
import injectSaga from '../../utils/injectSaga';
import appSaga from './sagas';
import { compose } from '@reduxjs/toolkit';
import ErrorBoundary from './errorBoundary';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'


export class App extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    ReactGA.send({ hitType: "pageview", page: "/" });
  }

  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <ErrorBoundary fallback={<p>Something went wrong</p>}>
        <IdleTimer timeout={6000} idleAction={this.props.logout} isUserLogged={this.props.isUserLogged}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            {/* <MuiThemeProvider> */}
            <React.Fragment>
              <Header
                hideFilterIconProps={this.props.hideFilterIconProp}
                showFilter={this.props.showFilters}
                logout={this.props.logout}
                showNotificationAction={this.props.menuNotificationsClicked}
                isUserLogged={this.props.isUserLogged}
                activeLink={this.props.activeLink}
                receiveWebAlerts={this.props.receiveWebAlerts}
                notifications={this.props.notifications}
              />
              {this.props.showNotification == true ?
                <ModalNotifications
                  showModal={!!this.props.notificationsOpen}
                  title="Notifications"
                  notifications={this.props.notifications} />
                : null}
              <Content isLoginPageVisible={this.props.isLoginPageVisible} isUserLogged={this.props.isUserLogged} customerName={this.props.customerName}>
                {/*React.Children.toArray(this.props.children)*/}
                <Outlet />
                {/* <Footer isUserLogged={this.props.isUserLogged} customerName={this.props.customerName} /> */}
              </Content>

              <CustomizedSnackbar
                open={this.props.snackBarOpen}
                message={this.props.snackBarMsg}
                handleClose={this.props.hideSnackBar}
              />
            </React.Fragment>
            {/* </MuiThemeProvider> */}
          </LocalizationProvider>
        </IdleTimer>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isUserLogged: selectIsUserLogged(),
  activeLink: selectActiveLink(),
  snackBarOpen: selectSnackbarOpen(),
  snackBarMsg: selectSnackbarMsg(),
  showNotification: selectNotificationsOpen(),
  hideFilterIconProp: selectHideFilterIcon(),
  isLoginPageVisible: selectIsLoginPageVisible(),
  customerName: selectCustomerName(),
  notifications: selectUserNotifications(),
  receiveWebAlerts: selectReceiveWebNotification()
  //error: selectError(),
})
const mapDispatchToProps = ({
  ...actions,
  showFilters
});

var withSaga = injectSaga({ key: 'app', saga: appSaga });

// Wrap the component to inject dispatch and state into it
export default compose(
  withSaga,
  connect(mapStateToProps, mapDispatchToProps)
)(App);