import React from 'react';

export default class Columns extends React.PureComponent {
    render(){
        return (
            <thead>
                <tr>
                    {this.props.children}
                </tr>
            </thead>
        );
    }
}