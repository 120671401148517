import React from 'react';
import Trash from '../../../svg-icons/trash';
import keys from 'lodash/keys';
import findIndex from 'lodash/findIndex';
import { formatPrice } from '../../../utils/format';
import { tmIntegration, requiresAdditionalInfo } from '../../../utils/packageEx';

export default class ProductList extends React.PureComponent {

    isPackageInDiscountBundle(packageId) {
        if (this.props.selectedDiscountPackageCore.length == 0)
            return false;

        var flag = false;
        Object.keys(this.props.selectedDiscountPackageCore).forEach(id => {
            var pack = this.props.allPackages[id];
            var packIndex = findIndex(pack.subPackages, (el) => el.subPackageId == packageId);
            if (packIndex != -1) {
                flag = true;
                return;
            }
        });

        return flag;
    }

    deletePackage(packageId) {
        if (this.props.selectedPackageIds[packageId] && this.props.selectedPackageIds[packageId].value == true) {
            this.props.deletePackage(packageId, false);
            return;
        }

        if (this.props.selectedDiscountPackageCore[packageId] == true) {
            this.props.deleteCoreDiscountPackage(packageId, false);
            return;
        }
    }

    deleteEnquiryPackage(packageId, productId, priceDetailsId) {
        this.props.deleteEnquiryPackage(parseInt(packageId), parseInt(productId), { priceDetailsId: priceDetailsId }, false);
    }

    renderAdditionalRow(packageId, productId, item, index, classEx) {
        return (
            <li key={index}>
                <div className="product" style={{ paddingLeft: 35 }}>
                    <strong className={`product-name color-${classEx}`}>{item.productComponentType}</strong>
                    <span className="product-price">{`${formatPrice(item.costVatable)} + ${formatPrice(item.costVat)} (VAT)`}</span>
                </div>
                <div className="button button-icon button-icon-small button-white" onClick={() => this.deleteEnquiryPackage(packageId, productId, item.priceDetailsId)}>
                    <Trash className="icon icon-trash" />
                </div>
            </li>
        )
    }

    renderRow(item, index, classEx, showPrice = true, showDelete = true, ident = false) {
        var style = {};
        if (ident == true) {
            style = { paddingLeft: 35 };
        }
        if (tmIntegration(item)) {
            showPrice = false;
        }
        let recommendedComponent = null;
        let borderedClass = '';

        var isPackContained = this.props.warningPackages.includes(item.id);
        if (isPackContained == true) {
            recommendedComponent = <span>This product will be ordered but based on the address it is not recommended.</span>
            borderedClass = 'bordered';
        }
        return (
            <li key={index} className={`${borderedClass}`}>
                <div className="product" style={style}>
                    <strong className={`product-name color-${classEx}`}>{item.name}</strong>
                    {showPrice == true ?
                        item.priceLines.map((price, index) => {
                            return <span key={index} className="product-price">{formatPrice(price.defaultPrice)}{price.vatRate != 0 ? ' + ' + formatPrice(price.vatRate * price.defaultPrice / 100) + '(VAT)' : null}</span>;
                        })
                        : null}
                </div>
                {showDelete == true ?
                    <div className="button button-icon button-icon-small button-white" onClick={() => this.deletePackage(item.id)}>
                        <Trash className="icon icon-trash" />
                    </div>
                    : null}
                {recommendedComponent}
            </li>
        )
    }



    render() {
        if (this.props.allPackages == undefined || keys(this.props.allPackages).length == 0) return null;
        var list = keys(this.props.selectedPackageIds).map((packageId, index) => {
            var item = this.props.allPackages[packageId];
            var selectedPackageMetadata = this.props.selectedPackageIds[packageId];
            if (this.isPackageInDiscountBundle(packageId) || selectedPackageMetadata.isLocalAuthorityPackageInDiscountBundle) {
                return null;
            }

            var classEx = 'residential';
            if (item.category === 'Commercial' || item.category === 'CommercialPopular') {
                classEx = 'commercial';
            }
            else if (item.classification === "Discount Packages") {
                classEx = 'discount-package';
            }

            var discountInfo = this.props.selectedPackageIds[packageId].discount;
            if (discountInfo && discountInfo.isDiscountPackage == true) {
                classEx = 'discount-package';
            }

            var additionalRows = [];
            if (this.props.selectedPackageIds[packageId].additionalProductInfo) {
                var additionalRows = keys(this.props.selectedPackageIds[packageId].additionalProductInfo).map((productId) => {
                    var productInfo = this.props.selectedPackageIds[packageId].additionalProductInfo[productId];
                    if (productInfo.tm)
                        return productInfo.tm.map((additionalItem, index) => {
                            return this.renderAdditionalRow(packageId, productId, additionalItem, index + 100, classEx);
                        })
                });
            }

            var row = this.renderRow(item, index, classEx);

            additionalRows.splice(0, 0, row);
            return additionalRows;
        });

        var discountCore = Object.keys(this.props.selectedDiscountPackageCore).map((packageId, index) => {
            var item = this.props.allPackages[packageId];
            var classEx = 'discount-package';
            return this.renderRow(item, index, classEx, false);
        });

        var discountSub = Object.keys(this.props.selectedDiscountPackageCore).map((packageId, index) => {
            var item = this.props.allPackages[packageId];

            var classEx = 'discount-package';
            var sub = item.subPackages.map((subPackage, index) => {
                var subPackageItem = this.props.allPackages[subPackage.subPackageId];
                if (subPackage.isLocalAuthoritySearch && this.props.localAuthoritySearchPackageId > -1) {
                    subPackageItem = this.props.allPackages[this.props.localAuthoritySearchPackageId];
                }

                return this.renderRow(subPackageItem, index, classEx, true, false, true);
            })

            return sub;
        });

        return (
            <ul className="details-aside-cart">
                {/*listDisabled*/}
                {list}
                {/*discounts*/}
                {discountCore}
                {discountSub}
            </ul>
        );
    }
}
