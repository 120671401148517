
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Info = (props) => (
  <SvgIconEx  {...props}>
     <circle fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="11.5" cy="12.5" r="11"/>
     <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M8.5 19.5h6M9.5 10.5h2V19"/>
     <path fill="none" strokeLinejoin="round" strokeMiterlimit="10" d="M11 6c-.277 0-.5.225-.5.5 0 .277.223.5.5.5.275 0 .5-.223.5-.5 0-.275-.225-.5-.5-.5z"/>
  </SvgIconEx>
);
Info.displayName = 'Info';
Info.muiName = 'SvgIcon';

export default Info;