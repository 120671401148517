import { createSelector } from 'reselect';
const selectRoot = () => (state) => state['order-confirmation'];
const selectOrderData = () => createSelector(
    selectRoot(),
    (homeState) => homeState?.order
);

const selectShowError = () => createSelector(
    selectRoot(),
    (homeState) => homeState?.showError
);

export{
    selectOrderData,
    selectShowError
}