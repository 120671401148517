import React from 'react';
import keys from 'lodash/keys';
import {formatPrice} from '../../../utils/format';
import {getFormattedPrice} from './priceFunctions';

export default class PriceLines extends React.PureComponent {
    static defaultProps= {
        className : 'details-aside-totals'
    }
    
    render(){
        if(this.props.allPackages == undefined || keys(this.props.allPackages).length == 0) return null;
        
        var selectedDiscountPackages = [];
        keys(this.props.selectedPackageIds).forEach(id => {
            var item = this.props.selectedPackageIds[id];
            if(item.discount && item.discount.isDiscountPackage){
                selectedDiscountPackages.push({name: this.props.allPackages[id].name, discountAmount: item.discount.discountAmount})
            }
        });

        var price = getFormattedPrice(this.props.selectedPackageIds, this.props.selectedDiscountPackageCore, this.props.allPackages, this.props.vatRate);
        return (
            <ul className= {this.props.className}>
                <li className="total">
                    <span className="label">Total Exc. VAT</span>
                    <span className="value">{price.totalNoVat}</span>
                </li>
                {
                   selectedDiscountPackages.map((item, index)=>{
                        return  <li key={index}>
                                    <span className="label">Package Discount on {item.name}</span>
                                    <span className="value">{'-' + formatPrice(item.discountAmount)}</span>
                                </li>
                    })
                }
                {price.charges.length != 0 ?
                keys(price.charges).map((itemId, index)=>{
                    var item = price.charges[itemId];
                    return (
                        <li key={index}>
                            <span className="label">{item.discountType}</span>
                            <span className="value">{formatPrice(item.chargeAmount)}</span>
                        </li>);
                })
                :null}

                <li className="total">
                    <span className="label">Subtotal</span>
                    <span className="value">{price.subTotal}</span>
                </li>
                <li>
                    <span className="label">VAT {price.vatRate}%</span>
                    <span className="value">{price.totalVat}</span>
                </li>
                <li className="total">
                    <span className="label">Total Inc. VAT</span>
                    <span className="value">{price.totalPrice}</span>
                </li>
            </ul>);
    }
}
