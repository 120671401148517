import {
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR
} from './constants';

export function registerUser(customerName, userName, password, navigate) {
    return {
        type: REGISTER_USER,
        customerName,
        userName,
        password,
        navigate
    };
}

export function registerUserSucceed(user) {
    return {
        type: REGISTER_USER_SUCCESS,
        user
    };
}

export function registerUserFail(error) {
    return {
        type: REGISTER_USER_ERROR,
        error
    };
}