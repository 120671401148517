import {
  LOGIN_USER, LOGIN_USER_SUCCESS, LOGIN_USER_ERROR, INIT_LOGIN_PAGE,
  REMIND_ME, REMIND_ME_SUCCESS, REMIND_ME_ERROR, CHANGE_VIEW, STORE_CUSTOMER_OPTIONS,
  LOGIN_PAGE_SHOW_NOTIFICATION, LOGIN_PAGE_SHOW_NOTIFICATION_REQUEST
} from './constants';

/**
 * Load the repositories, this action starts the request saga
 *
 * @return {object} An action object with a type of LOAD_REPOS
 */
export function loginUser(userName, password, returnUrl, navigate) {
  return {
    type: LOGIN_USER,
    userName,
    password,
    returnUrl,
    navigate
  };
}

/**
 * Dispatched when the repositories are loaded by the request saga
 *
 * @param  {string} jws The current username
 *
 * @return {object}      An action object with a type of LOAD_REPOS_SUCCESS passing the repos
 */
export function loginUserSucceed(jwt) {
  return {
    type: LOGIN_USER_SUCCESS,
    jwt
  };
}

/**
 * Dispatched when loading the repositories fails
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
 */
export function loginUserFail(error) {
  return {
    type: LOGIN_USER_ERROR,
    error,
  };
}


export function remindMe(userName, email) {
  return {
    type: REMIND_ME,
    userName, email
  }
}

export function remindMeSuccess() {
  return {
    type: REMIND_ME_SUCCESS
  }
}

export function remindMeError(err) {
  return {
    type: REMIND_ME_ERROR,
    err
  }
}

export function changeView(view) {
  return {
    type: CHANGE_VIEW,
    view
  }
}

export function initPage() {
  return {
    type: INIT_LOGIN_PAGE
  }
}

export function storeCustomerOptions(options) {
  return {
    type: STORE_CUSTOMER_OPTIONS,
    options
  }
}

export function showNotificationOnLogin(payload) {
  return {
    type: LOGIN_PAGE_SHOW_NOTIFICATION,
    payload
  }
}