import React from 'react';

 class StatusColumnTemplate extends React.Component{
    render(){
        var product = this.props.row;
        
        if(product == undefined){
            return null;
        }

        var statusClassEx = 'received'; //{'received', 'in-progress' or 'completed'}
        if(product.status == 2){
            statusClassEx= 'in-progress';
        }
        else if(product.status == 4){
            statusClassEx= 'completed';
        }

        return (
            <span className={`status-label ${statusClassEx}`}>{product["statusString"]}</span>
        );
    }
};

export default StatusColumnTemplate;