import React from 'react';
import AlertSuccess from '../../svg-icons/alertSuccess';
import AlertWarning from '../../svg-icons/alertWarning';

export default class AddressValid extends React.PureComponent {
    handleEditAddress = () => {
        if (this.props.editAddress)
            this.props.editAddress();
    }

    static defaultProps = {
        itemStyles: null
    }

    render() {
        var classEx = 'warning';
        if (this.props.isValid) {
            classEx = 'success';
        }

        return (
            <div className={`address-details alert alert-block alert-${classEx}`} style={this.props.itemStyles}>
                {this.props.isValid == true ? <AlertSuccess className="icon icon-alert-success" /> : <AlertWarning className="icon icon-alert-warning" />}
                <div className="alert-content">
                    {this.props.isValid == true
                        ? <strong id="validated-title">Validated Address</strong>
                        : <strong id="validated-title">We can't recommend products for addresses which are entered manually</strong>}

                    <ul className="vcard">
                        {this.props.address.organisation ?
                            <li>
                                <span className="label">Organisation</span>
                                <span className="value">{this.props.address.organisation}</span>
                            </li>
                            : null}
                        {this.props.address.subbuilding ?
                            <li>
                                <span className="label">Flat/Unit Number</span>
                                <span className="value">{this.props.address.subbuilding}</span>
                            </li>
                            : null}
                        {this.props.address.buildingNumber ?
                            <li>
                                <span className="label">House Number</span>
                                <span className="value">{this.props.address.buildingNumber}</span>
                            </li>
                            : null}
                        {this.props.address.buildingName ?
                            <li>
                                <span className="label">House Name</span>
                                <span className="value">{this.props.address.buildingName}</span>
                            </li>
                            : null}
                        {this.props.address.thoroughfare ?
                            <li>
                                <span className="label">Street</span>
                                <span className="value">{this.props.address.thoroughfare}</span>
                            </li> :
                            null}
                        {this.props.address.locality ?
                            <li>
                                <span className="label">Locality</span>
                                <span className="value">{this.props.address.locality}</span>
                            </li>
                            : null}
                        {this.props.address.town ?
                            <li>
                                <span className="label">City</span>
                                <span className="value">{this.props.address.town}</span>
                            </li>
                            : null}
                        {/* <li>
                <span className="label">County</span>
                <span className="value">{this.props.address.county}</span>
            </li>*/}
                        {this.props.address.postcode ?
                            <li>
                                <span className="label">Postcode</span>
                                <span className="value">{this.props.address.postcode}</span>
                            </li>
                            : null}

                        {/* {this.props.isValid == true ? null : VSTS 1497 edit valid address too*/}
                            <li className="vcard-edit">
                                <a onClick={this.handleEditAddress} className="text-link">Edit this address</a>
                            </li>
                        {/* } */}
                    </ul>
                </div>
            </div>
        );
    }
}
