import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../containers/App/actions';
import Clear from '../../svg-icons/clear';
import Trash from '../../svg-icons/trash';
import { alertRed } from '../../assets/less/base/_variables.js';
import moment from 'moment';
import { Link } from 'react-router-dom';

export class ModalNotifications extends React.Component {
    constructor(props) {
        super(props);
        this.styleOuter = "desktop-notification";
        this.styleInner = "";
    };

    componentDidMount() {
        // if small then 900 and 1300 then mobile
        if (window.outerHeigh <= 800 && window.outerWidth <= 1200) {
            this.styleOuter = "mobile-notification small";
            this.styleInner = "mobile-content";
        }

    }
    handleCloseMenu = () => {
        if (this.props.menuNotificationsClicked) {
            this.props.menuNotificationsClicked(false);
        }
    }

    handleRemoveItem = (item) => {
        if (this.props.removeNotificationItem) {
            this.props.removeNotificationItem(item);
        }
    }

    handleRemoveAllItems() {
        if (this.props.notifications &&
            this.props.notifications.length > 0 &&
            this.props.removeAllNotificationItems) {
            this.props.removeAllNotificationItems(this.props.notifications[0].customerId);
        }
    }

    render() {
        return (
            <div id='slide-out-wrapper' style={{ position: 'fixed', zIndex: 10000 }}>
                <div id='slide-out-content' style={{
                    position: 'fixed',
                    top: 50,
                    right: 0,
                    height: '100%',
                    background: 'aliceblue',
                    boxShadow: '0 6.4px 14.4px 0 rgba(0,0,0,.132),0 1.2px 3.6px 0',
                    maxWidth: 500,
                    minWidth: 500
                }}>
                    {/* <span onClick={(e) => this.handleCloseMenu(e)} className="close-custom"
                        style={{ marginBottom: "10px" }}>
                        <img height="30px" width="30px" src="/close-icon.webp" alt="" />
                    </span> */}
                    <button onClick={this.handleCloseMenu} className="modal-close-slideout button">
                        <Clear className="icon icon-clear" role="img" />
                    </button>
                    <div style={{height:'calc(100% - 50px'}}> {/* need this for scrolling */}
                        <h1 className='modal-title'>Notifications</h1>
                        <div style={{ textAlign: 'right', marginRight: 20 }}>
                            <button title='Delete quote' onClick={() => this.handleRemoveAllItems()} className="button button-transparent">
                                Dismiss all
                            </button>
                        </div>
                        <hr style={{ border: '0 none', height: 1, background: 'gray' }}></hr>

                        <div style={{height:'calc(100% - 90px', overflowY:'auto'}}>
                            {this.props.notifications.map((note, index) => {
                                return (
                                    <div style={{ position: 'relative' }} key={index}>
                                        <h2 style={{ textAlign: "center" }}>
                                            <Link className="text-link" style={{ fontSize: 14 }} to={`/order/${note.orderId}`}> Order Id - {note.orderId}</Link>
                                        </h2>
                                        <p style={{ padding: 10 }}>{note.message}</p>
                                        <div style={{ textAlign: 'right', margin: 10, fontSize: 10 }}>{`${moment(note.dateTime).format('DD/MM/YYYY')}`}</div>
                                        <button style={{ position: 'absolute', top: 5, right: 20 }} title='Dismiss notification' onClick={() => this.handleRemoveItem(note)} className="button button-icon button-icon-small button-transparent">
                                            <Trash className='icon' stroke={alertRed} />
                                        </button>
                                        <hr></hr>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
const mapDispatchToProps = ({
    ...actions
})

// Wrap the component to inject dispatch and state into it
export default connect(null, mapDispatchToProps)(ModalNotifications);