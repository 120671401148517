import React from 'react';
import isFunction from 'lodash/isFunction';

export default class Cell extends React.PureComponent {
    static defaultProps ={
        datacontext: {}
    }

    render(){
        var content = this.props.datacontext;
        var column = this.props.column;
        if(isFunction(column.dataCellTemplate)){
            content = column.dataCellTemplate({ datacontext: this.props.datacontext, row: this.props.row });
        }
        
        var style = Object.assign({
            textAlign: 'left'
        }, column.style);

        var cellClass="";
        if(column.className != undefined){
            cellClass = column.className;
        }
        return (
            <td style={style} className={cellClass}>
                {content}
            </td>
        );
    }
}