import { createSelector } from 'reselect';
const selectOrderDetails = () => (state) => state['order-details'] || {
  upload: {},
  ui: {},
  queryGrid: { dataSource: { items: [] } },
  queryGridClosed: { dataSource: { items: [] }, ui: { ui: {} } }
};
const selectOrder = () => createSelector(
  selectOrderDetails(), (state) => {
    if(state.order){
      return state.order.order;
    }
    
    return{}
  }
);

const selectOrderGridReference = () => createSelector(
  selectOrder(), (state) => state.gridReference
);

const selectUI = () => createSelector(
  selectOrderDetails(), (state) => state.ui
);

const selectPackagesInOrder = () => createSelector(
  selectOrder(), (order) => order.packages
);

const selectPackagesCount = () => createSelector(
  selectPackagesInOrder(), (packages) => {
    if (packages) {
      return packages.length;
    }

    return 0;
  }
);

const selectAttachments = () => createSelector(
  selectOrderDetails(), (order) => order.upload.uploadedFiles
);

const selectGridData = () => createSelector(
  selectOrderDetails(),
  (homeState) => homeState.queryGrid
);

const selectQueryGridDataSource = () => createSelector(
  selectGridData(),
  (data) => data.dataSource.items
);

const selectOpenQueryCount = () => createSelector(
  selectQueryGridDataSource(),
  (data) => data.length
);


const selectGridUI = () => createSelector(
  selectGridData(),
  (ui) => ui.ui
)

const selectQueryGridNavigationColumnState = () => createSelector(
  selectGridUI(),
  (ui) => ui.queryGridNavigationColumnState
)


//
// queries.queryGridClosed
const selectGridDataClosed = () => createSelector(
  selectOrderDetails(),
  (homeState) => homeState.queryGridClosed
);

//
// queries.queryGridClosed.dataSource.items
const selectClosedQueryGridDataSource = () => createSelector(
  selectGridDataClosed(),
  (data) => data.dataSource.items
);


// queries.queryGrid.totalQueriesCount
const selectClosedQueryCount = () => createSelector(
  selectClosedQueryGridDataSource(),
  (data) => data.length
);

//
// queries.queryGridClosed.ui.queryGridNavigationColumnState
const selectClosedQueryGridNavigationColumnState = () => createSelector(
  selectGridDataClosed(),
  (ui) => ui.ui.queryGridNavigationColumnState
);

const selectActiveQueryTab = () => createSelector(
  selectUI(),
  (ui) => ui.activeQueryTab
);

const selectIsQueryLoading = () => createSelector(
  selectUI(), (ui) => ui.isLoading
);

const selectAlerts = () => createSelector(
  selectUI(), (order) => order.alerts
);

const selectSelectedAlerts = () => createSelector(
  selectUI(), (order) => order.selectedAlerts
);

const selectIsSaving = () => createSelector(
  selectUI(), (order) => order.isSaving
);

const selectOrderChaseButtonState = () => createSelector(
  selectUI(),
  (ui) => ui.orderChaseButtonState
);

const selectOrderCancelButtonState = () => createSelector(
  selectUI(),
  (ui) => ui.orderCancelButtonState
);
const selectProductChaseButtonState = () => createSelector(
  selectUI(),
  (ui) => ui.productChaseButtonState
);

const selectProductCancelButtonState = () => createSelector(
  selectUI(),
  (ui) => ui.productCancelButtonState
);

const selectIsReportDialogOpen = () => createSelector(
  selectUI(),
  (ui) => ui.isReportDialogOpen || false
);

const selectReports = () => createSelector(
  selectUI(),
  (ui) => ui.reports
);
const selectRaiseButtonVisibility = () => createSelector(
  selectUI(),
  (ui) => ui.showRaiseQueryButton
)

const selectIsAnyWaterPackageSelected = () => createSelector(
  selectPackagesInOrder(),
  (packages) => {
    for (let index = 0; index < packages.length; index++) {
      const pack = packages[index];
      if(pack.packageType == 'water' || pack.packageType == 'water-ex'){
        return true;
      }
    }

    return false;
  }
);


export {
  selectSelectedAlerts,
  selectOrderDetails,
  selectOrder,
  selectPackagesInOrder,
  selectAttachments,
  selectQueryGridDataSource,
  selectQueryGridNavigationColumnState,
  selectClosedQueryGridDataSource,
  selectClosedQueryGridNavigationColumnState,
  selectActiveQueryTab,
  selectPackagesCount,
  selectIsQueryLoading,
  selectAlerts,
  selectOpenQueryCount,
  selectClosedQueryCount,
  selectOrderChaseButtonState,
  selectOrderCancelButtonState,
  selectProductChaseButtonState,
  selectProductCancelButtonState,
  selectIsReportDialogOpen,
  selectReports,
  selectIsSaving,
  selectRaiseButtonVisibility,
  selectOrderGridReference,
  selectIsAnyWaterPackageSelected
}
