import React from 'react';
import {formatPrice} from '../../utils/format';
import UploadFile from '../../components/UploadFile';
import Form from '../../components/Form';
import {OrderDetailsUploadChannel} from './constants';
import DownloadLink from '../../components/DownloadLink';
import config from '../../utils/config';

class Aside extends React.PureComponent{
    handleFileUploadChanged=(files)=>{
        Array.from(files).forEach(function(file) {
            this.props.addAttachmentToOrder(file, this.props.order.orderId, 'order', OrderDetailsUploadChannel); // uploadRequest
        }, this);
    }

    handleFileDelete = (index, fileName) => {
        if(this.props.deleteAttachmentFromOrder){
            this.props.deleteAttachmentFromOrder(fileName, this.props.order.orderId, OrderDetailsUploadChannel);
        }
    }

    render(){
        var customerContact= this.props.order.customerContact;
        var orderStatus = this.props.order.status;
        //var receiptUrl = `${config.webApiUrl}/file/download?filePath=${this.props.order.receiptFileUrl}`;
        var receiptUrl = `${config.webApiUrl}/file/download?filePath=`;
        return(
            <aside className="details-aside">
                <div className="details-aside-column">
                    {/*Order Details */}
                    <div className="details-aside-title">
                        <h4>Order Details</h4>
                    </div>
                    <div className="details-aside-content">
                        <table className="sidebar-grid">
                            <tbody>
                                <tr>
                                    <th className="cell-80" scope="row">Address</th>
                                    <td>{this.props.order.address}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Your Reference</th>
                                    <td>{this.props.order.customerRef}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Your Contact</th>
                                    <td>
                                        <strong>{customerContact ? customerContact.firstName : null} {customerContact ? customerContact.lastName : null}</strong>
                                        <br/>
                                        <a href={`mailto:${customerContact ? customerContact.email : null}`} className="text-link">{customerContact ? customerContact.email : null}</a>
                                        <br/>
                                        <a href={`tel:${customerContact ? customerContact.phone : null}`} className="text-link">{customerContact ? customerContact.phone : null}</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/*Order Price */}
                    <div className="details-aside-title hide-mobile">
                        <h4>Total Price</h4>
                    </div>
                    <div className="details-aside-content details-aside-price hide-mobile">
                        <strong className="price-value">{formatPrice(this.props.order.totalPrice)}</strong>
                        { orderStatus == 'Completed' ? 
                        <DownloadLink url = {receiptUrl} file ={this.props.order.receiptFileUrl} className="button button-small button-blue">
                            Download Receipt
                        </DownloadLink>
                        : null }
                    </div>
                </div>
                <div className="details-aside-column">
                    {/*Order Preferences */}
                    <div className="details-aside-title hide-mobile">
                        <h4>Order Preferences</h4>
                    </div>
                    <div className="details-aside-content hide-mobile">
                        <table className="sidebar-grid">
                            <tbody>
                                <tr>
                                    <th className="cell-180" scope="row">Mailing Preference</th>
                                    <td>{this.props.order.mailingPreference}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Order Acknowledgment</th>
                                    <td>{this.props.order.orderAck}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Order Receipt</th>
                                    <td>{this.props.order.recieptSuppresison}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Customer Type</th>
                                    <td>{this.props.order.customerType}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/*Order Attachments */}
                    <div className="details-aside-title hide-mobile">
                        <h4>Attachments</h4>
                    </div>
                    <Form className="details-aside-content hide-mobile">
                        <UploadFile fileAdded = {this.handleFileUploadChanged} fileDeleted={this.handleFileDelete} attachments={this.props.attachments}/>
                    </Form>
                </div>
            </aside>
            );
    }
}

export default Aside;
