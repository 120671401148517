import React from 'react';
import { createStructuredSelector } from 'reselect';
import {
  selectAllPackages,
  selectActiveCategoryTab,
  selectActiveClassificationTab,
  selectLaAuthorityDatasource,
  selectProductDatasource,
  selectFavoriteDatasource,
  selectActiveLALetterTab,
  selectSelectedPackages,
  selectLaAuthorityOtherDatasource,
  selectSelectedPackagesInOrder,
  selectSelectedAddress,
  selectRecommendedDatasource
} from './selectors';
import * as actions from './actions';
import { connect } from 'react-redux';
import Star from '../../svg-icons/star';
import AZrow from '../../components/AZrow';
import ProductTabs from './ProductTabs';
import GridProductContainer from './gridProductContainer';
import RecommendedPackages from './recommendedPackages';
import ExpandableSection from '../../components/ExpandableSection';

class NewOrderProductsResidential extends React.PureComponent {
  constructor(props) {
    super(props);
    this.labels = {
      con29: 'CON29DW DRAINAGE AND WATER SEARCHES',
      comCon29: 'COMMERCIAL CON29DW DRAINAGE AND WATER SEARCHES',
      authority: 'LOCAL AUTHORITY SEARCHES(A-Z)',
      land: 'REGULATED LA SEARCHES',
      env: 'ENVIRONMENTAL SEARCHES',
      mining: 'MINING SEARCHES',
      spe: 'MISCELLANEOUS SEARCHES',
      ins: 'INSURANCE',
      utility: 'UTILITY',
      chancel: 'CHANCEL REPAIR LIABILITY'
    }
  }
 
  render() {
    var style = 'residential';
    if (this.props.activeCategoryTab === 'com') {
      style = 'commercial'
    }
    return (
      <section className={`products ${style}-products`}>
        <table className="content-grid products-grid">
          <thead>
            <tr>
              <th className="cell-40 favorites-cell">
                <Star className='icon icon-star' />
              </th>
              <th>Product</th>
              <th className="cell-320 show-desktop">Documents</th>
              <th className="cell-50 hide-mobile align-right">Days</th>
              <th className="cell-100 align-right">Price <span>(Inc VAT)</span>
              </th>
            </tr>
          </thead>
        </table>

        <ExpandableSection isExpandable={true} title='Alerts' rootStyle={{ margin: 0, marginTop: 20 }}>
          {/* Recommended */}
          <RecommendedPackages />
        </ExpandableSection>

        {/* Favorites */}
        <ExpandableSection isExpandable={true} title='Favourites'
          rootStyle={{ margin: 0, marginTop: 20, marginBottom: 20 }}>
          <div className="products-group favorites-group">
            <h3 className="products-grid-title favorites-title">Your Favourites</h3>
            <div className="products-grid-container">
              <GridProductContainer name='gridResFavorite' productStyle={style}
                datasource={this.props.favoritesDatasource} />
            </div>
          </div>
        </ExpandableSection>

        {/* Product Group Tabs */}
        <ProductTabs changeTab={this.props.changeProductClassificationTab}
          activeCategoryTab={this.props.activeCategoryTab} activeTab={this.props.activeClassificationTab} />

        {/* Product Group Tab Content (replace content L84-L106 when tab is changes) */}
        <div className={`products-group ${style}-products expanded`}>
          <h3 className="products-grid-title">
            {this.labels[this.props.activeClassificationTab]}
          </h3>

          {/* LA Searches one search and other */}
          {this.props.activeClassificationTab == 'authority' ?
            <GridProductContainer name='grid-authority-other' productStyle={style}
              datasource={this.props.authorityOtherPackages} /> : null}

          {/* IF LA Searches, show A-Z options */}
          {this.props.activeClassificationTab == 'authority' ?
            <AZrow data={this.props.authorityPackages} changeTab={this.props.changeLALetterTab}
              activeTab={this.props.activeLALetterTab} productType={this.props.activeCategoryTab} /> : null}

          <GridProductContainer name='gridResProducts' productStyle={style} datasource={this.props.productDatasource} />

        </div>
      </section>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  productDatasource: selectProductDatasource(),
  favoritesDatasource: selectFavoriteDatasource(),
  activeCategoryTab: selectActiveCategoryTab(),
  activeClassificationTab: selectActiveClassificationTab(),
  activeLALetterTab: selectActiveLALetterTab(),
  authorityPackages: selectLaAuthorityDatasource(),
  authorityOtherPackages: selectLaAuthorityOtherDatasource(),
  selectedPackages: selectSelectedPackages(),
  selectedPackagesInOrder: selectSelectedPackagesInOrder(),
  selectedAddress: selectSelectedAddress(),
  allPackages: selectAllPackages(),
  recommendedDatasource: selectRecommendedDatasource()
})
const mapDispatchToProps = ({
  ...actions
})
// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(NewOrderProductsResidential);
