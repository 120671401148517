import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '../../components/Grid';
import DownloadLink from '../../components/DownloadLink';
import config from '../../utils/config';

export default class ReportDialog extends React.Component {
    handleClose = () => {
        this.props.closeDialog();
    }

    render() {
        return (
            <Dialog open={this.props.open}>
                <DialogTitle>
                    <h1>Reports</h1>
                </DialogTitle>
                <div className="content-block-grid" style={{ margin: 15 }}>
                    <Grid
                        gridName='reportsGrid'
                        isLoading={this.props.isLoading}
                        datasource={this.props.reports}
                        columnsData={[
                            {
                                header: 'Product',
                                style: { width: "70%" },
                                dataField: 'productName',
                                dataCellTemplate: (props) => (<span>{props.datacontext}</span>)
                            },
                            {
                                header: 'Report',
                                dataField: 'reportLink',
                                style: { width: '*' }, // 
                                dataCellTemplate: (props) => {
                                    //var url = `${config.webApiUrl}/file/download?filePath=${props.datacontext}`;
                                    var url = `${config.webApiUrl}/file/download?filePath=`;
                                    return (<DownloadLink url={url} file={props.datacontext} />)
                                    /*return <a href={url}  className="button button-small button-text-icon button-white color-teal" target="_blank">
                                                <DownloadLink url = {url} />
                                            </a>;
                                            */
                                }
                            },
                        ]}
                    >
                    </Grid>
                </div>
                <div style={{ textAlign: 'right', margin: 10 }}>
                    <button onClick={this.handleClose} className="button button-regular button-blue">Close</button>
                </div>
            </Dialog>
        );
    }
}
