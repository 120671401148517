import React from 'react';
import Dialog from '@mui/material/Dialog';
import Clear from '../../svg-icons/clear';
import AlertWarning from '../../svg-icons/alertWarning';

// https://my-account.stsearches.co.uk/login?returnUrl=%2F
// MK46 4AU = {Anglien} vs SW12 8JE {Themes} vs GL3 2LU = {None}
// NG5 9QE is within Coal area PE7 1RE is not within Coal area
// MK46 4AU --> testing other packages plus out of area VS GL3 2LU
// NG5 9QE is within Coal area (easting - 456483, northing - 346588) 
// and PE7 1RE is not within Coal area (easting - 526867, northing - 297606)
// Area	Supplier                              Package Code     Res  Com	Address to test
// 1	Severn Trent Searches                RES_CON29_STW      2   255 --53 Golden Vale Churchdown GLOUCESTER GL3 2LU
// 2	Geodesys (Anglian)                   RES_CON29_ANGLIAN  15  40 --26 Freemans Gardens OLNEY MK46 4AU
// 3	Thames Property Searches             RES_CON29_THAMES   22  46 --40 Linnet Mews LONDON SW12 8JE
// 4	Southern Water                       RES_CON29_SOUTH    20  44 --7 Brokes Way Southborough TUNBRIDGE WELLS TN4 0AR
// 5	Wessex Searches	                     RES_CON29_WESSEX   23  47 --6 Westgate Park 28 Alumhurst Road BOURNEMOUTH BH4 8ER
// 6	Source for Searches (South West)     RES_CON29_SWEST    21  45 --13 Riverside Embankment Road KINGSBRIDGE TQ7 1LJ
// 7	Welsh Water                          RES_CON29_HYDER    17  41 --The Moorings 11 Penlan Llanrhos LLANDUDNO LL30 1PE
// 8	United Utilities                     RES_CON29_NWEST    19  43 --65 Empress Road Kensington LIVERPOOL L7 8SE
// 9	SafeMove (Yorkshire)                 RES_CON29_YORK     24  48 --8 Elm Park PONTEFRACT WF8 4LG
// 10	NWG Property Solutions (Northumbria) RES_CON29_NHUMB    18	42 --35 Fenton Terrace HOUGHTON LE SPRING DH4 7AZ
export default class SwitchProductDlg extends React.Component {
    handleUserClick = (action, data, sectionIndex) => {
        if (action == "Switch") {
            this.props.switchToSuggestedPackage(data.delete, data.switchTo, sectionIndex);
            return;
        }

        this.props.closeSwitchProductDlg(data.delete, sectionIndex);
    }

    render() {
        return (
            <Dialog open={this.props.open}>
                <div style={{ padding: 10 }}>
                    <button onClick={() => this.props.closeSwitchProductDlg()} type="button" className="modal-close" data-demo="close-modal">
                        <Clear className="icon icon-clear" role="img" />
                    </button>

                    {this.props.switchDlgSource.map((section, sectionIndex) => {
                        return (<React.Fragment key={sectionIndex}>
                            <div className="alert alert-block alert-warning">
                                <AlertWarning className="icon icon-alert-warning" />
                                <div className="alert-content">
                                    <strong>{section.dlgTitle}</strong>
                                </div>
                            </div>
                            {section.dataSource.map((suggestion, index) => {
                                let buttonStyle = 'green';
                                let recommendedComponent = null;
                                let recommendedComponent2 = null;
                                let spacing = <br style={{ lineHeight: '3px' }} />;
                                let borderedClass = null;
                                if (suggestion.action === 'Select') {
                                    buttonStyle = 'red';
                                    recommendedComponent = <span style={{ color: 'red' }}><strong>Not recommended</strong></span>
                                    recommendedComponent2 = <span style={{ color: '#ad7700' }}><strong>Alternatively, please remove the product from the basket.</strong></span>
                                    borderedClass = 'bordered'
                                }

                                return (
                                    <div key={index}>
                                        {recommendedComponent}
                                        <div className={`${borderedClass}`}>
                                            <div key={index} className="alert alert-warning">
                                                <div className="alert-content">
                                                    <strong>{(index + 1) + ". " + suggestion.message}</strong>
                                                    <button className={`button-small button-${buttonStyle} button-right`}
                                                        onClick={() => this.handleUserClick(suggestion.action, suggestion.actionData, sectionIndex)}>
                                                        {suggestion.action}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div>{recommendedComponent2}</div>
                                        <div>{spacing}</div>
                                    </div>);
                            })
                            }
                        </React.Fragment>);
                    })}
                </div>
            </Dialog>
        );
    }
}
