import { createSelector } from 'reselect';

const selectHome = () => (state) => state.preferences;
const selectContacts = () => createSelector(
    selectHome(),
    (homeState) => homeState.contacts
);
const selectPrimaryContact = () => createSelector(
    selectHome(),
    (homeState) => homeState.primaryContact
);

const selectContactAddress = () => createSelector(
    selectHome(),
    (homeState) => homeState.contactAddress
);

const selectPreferenceSettings = () => createSelector(
    selectHome(),
    (homeState) => homeState.preferenceSettings
);

const selectJobTitles = () => createSelector(
    selectHome(),
    (homeState) => homeState.titles
);

const selectCustomerName = () => createSelector(
    selectHome(),
    (homeState) => homeState.userInfo
);

export {
    selectContacts,
    selectPrimaryContact,
    selectContactAddress,
    selectPreferenceSettings,
    selectJobTitles,
    selectCustomerName
};
