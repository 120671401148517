
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let EyeOpen = (props) => (
  <SvgIconEx {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
            <path d="M23.5 12s-5.148 6.5-11.5 6.5S.5 12 .5 12 5.648 5.5 12 5.5 23.5 12 23.5 12z"/>
            <circle cx="12" cy="12" r="4"/>
            <path d="M12 10c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2"/>
      </g>
  </SvgIconEx>
);
EyeOpen.displayName = 'EyeOpen';
EyeOpen.muiName = 'SvgIcon';

export default EyeOpen;