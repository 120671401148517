import {
    LOAD_QUERY_PAGED_LIST_REQUEST,
    LOAD_QUERY_PAGED_LIST_SUCCESS,
    LOAD_QUERY_DETAILS,
    SHOW_QUERY_REPLAY,
    HIDE_QUERY_REPLAY,
    CUSTOMER_REPLY_TO_QUERY,
    CHANGE_REPLY_TEXT_IN_QUERY,
    DELETE_QUERY_FILE,
    QUERY_SWITCH_OPEN_CLOSE,
    SET_QUERY_GRID_DATA_SOURCE,
    QUERY_DETAILS_SHOW_EARLIER_MSGS,
    QUERY_STORE_FILTER_INFO,
    CUSTOMER_REOPEN_QUERY,
    CUSTOMER_REOPEN_QUERY_SUCCESS
} from './constants';

export function switchOpenCloseQuery(activeTab) {
  return {
    type: QUERY_SWITCH_OPEN_CLOSE,
    activeTab
  };
}

export function setQueryDataSource(gridName, queryList) {
  return {
    type: SET_QUERY_GRID_DATA_SOURCE,
    gridName,
    queryList
  };
}

export function loadQuery(rowTemplateKey, gridName, queryId) {
  return {
    type: LOAD_QUERY_DETAILS,
    rowTemplateKey,
    gridName,
    queryId
  };
}


export function loadPagedQueryList(values) {
  return {
    type: LOAD_QUERY_PAGED_LIST_REQUEST,
    // open or closed queries to load
    values
  };
}

export function loadQueryListSuccess(queries) {
  return {
    type: LOAD_QUERY_PAGED_LIST_SUCCESS,
    queries
  };
}

export function showQueryReplyRow(rowTemplateKey, gridName, queryId, data) {
  return {
    type: SHOW_QUERY_REPLAY,
    rowTemplateKey,  // merge this key into new data so the grid knows it has to apply row template with that key
    gridName,
    queryId,
    data
  }
}

export function hideQueryReplyRow(gridName, queryId, queryDto) {
  return {
    type: HIDE_QUERY_REPLAY,
    gridName,
    queryId, 
    queryDto
  }
}

export function customerReplyToQuery(gridName, queryDto){
  return{
    type: CUSTOMER_REPLY_TO_QUERY,
    gridName,
    queryDto
  }
}
export function customerReopenQuery(gridName, queryDto){
  return{
    type: CUSTOMER_REOPEN_QUERY,
    gridName,
    queryDto
  }
}

export function customerReopenQuerySuccess(gridName, queryId, queryDto) {
  return {
    type: CUSTOMER_REOPEN_QUERY_SUCCESS,
    gridName,
    queryId, 
    queryDto
  }
}
export function changeReplyText(gridName, queryId, text) {
  return {
    type: CHANGE_REPLY_TEXT_IN_QUERY,
    gridName,
    queryId,
    text
  }
}


export function deleteQueryFile(gridName, queryId, index) {
  return {
    type: DELETE_QUERY_FILE,
    gridName,
    queryId,
    index
  }
}

export function showEarlierMsgs(gridName, queryId) {
  return {
    type: QUERY_DETAILS_SHOW_EARLIER_MSGS,
    gridName,
    queryId
  }
}

export function storeQueryFilters(filterInfo){
    return {
        type: QUERY_STORE_FILTER_INFO,
        filterInfo
    }
}