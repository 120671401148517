import React from 'react';
import {uniqueId} from 'lodash';

export default class TextField extends React.Component {
  static defaultProps = {
    type: 'text',
    placeholder: 'Enter text here.',
    label: 'TextFiled label',
    className: 'entry',
    description: '',
    isRequired: false,
    maxCharacterWarningMsg:'You are about to exceed the maximum characters length'
  };

  constructor(props) {
    super(props);
    this.state = {
      maxCharacterWarning: false,
      currentLength: 0
    };
  }

  componentDidMount() {
    this.unqId = uniqueId("id_");
  }

  handleValueChanged = (event) => {
    if (this.props.onChange) {
      var value = event.target.value;
      this.props.onChange(event, value);
      const state = value.length >= (+this.props.maxLength - 2)
      this.setState({maxCharacterWarning: state, currentLength: value.length});
    }
  };

  handleLostFocus = (event) => {
    if (this.props.onBlur) {
      var value = event.target.value;
      this.props.onBlur(event, value);
    }
  }

  // this.props.customerRef ?
  //Customer Reference is limited  -
  render() {
    return (
      <div className={this.props.className}>
        <label htmlFor={this.unqId}>
          {this.props.label}
          {this.props.isRequired ? <strong className="label-required"> *</strong> : null}
        </label>
        <input
          pattern={this.props.pattern}
          title={this.props.title}
          id={this.unqId}
          autoComplete="off"
          type={this.props.type}
          placeholder={this.props.placeholder}
          className={this.props.inputClassName}
          value={this.props.value}
          onChange={this.handleValueChanged}
          onBlur={this.handleLostFocus}
          required={this.props.isRequired ? true : false}
          maxLength={this.props.maxLength}/>
        <span className="form-control-description">{this.props.description}</span>
        {
          this.state.maxCharacterWarning ?
                    <span className="form-control-error-msg pos-absolute">{this.props.maxCharacterWarningMsg} ({this.state.currentLength} of {this.props.maxLength}) </span>
                    :  null
        }
      </div>
    );
  }
}
