import React from 'react';

let StatusColumnTemplate = (props) => {
    if(props.datacontext ==  'InProgress' || props.datacontext ==  'In Progress'){
        return (
            <span className="status-label in-progress">in progress</span>
        );
    }

    if(props.datacontext ==  'Completed'){
        return (
            <span className="status-label completed">Completed</span>
        );
    }

    if(props.datacontext ==  'Cancelled'){
        return (
            <span className="status-label cancelled">Cancelled</span>
        );
    }

    return (
        <span className="status-label recieved">received</span>
    );
}

export default StatusColumnTemplate;
