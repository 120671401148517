import React from 'react';
import Trash from '../../svg-icons/trash';
import Attachment from '../../svg-icons/attachment';
import Download from '../../svg-icons/download';
import Clear from '../../svg-icons/clear';
import { alertRed, teal100 } from '../../assets/less/base/_variables.js';
import config from '../../utils/config';
import Dropzone from 'react-dropzone';
import DownloadLink from '../../components/DownloadLink';

export default class UploadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCommonError: false,
            commonErrorMsg: ''
        };
    }

    _isFileAlreadyUploaded(fileName) {
        var index = this.props.attachments.findIndex(item => {
            return name == item.fileName;
        });

        if (index == -1) {
            return false;
        }
        return true;
    }

    handleFileUploadChanged = (files) => {
        if (this._isFileAlreadyUploaded(files[0].name)) {
            this.setState({ showCommonError: true, commonErrorMsg: `File '${files[0].name}' already exists.` });
            return;
        }

        var fileType = files[0].name.slice(files[0].name.lastIndexOf('.') + 1).toLowerCase();
        if (config.fileTypeAllowed.indexOf(fileType) === -1) {
            this.setState({ showCommonError: true, commonErrorMsg: `File format ('${fileType}') is not allowed !` });
            return;
        }
        this.setState({ showCommonError: false, commonErrorMsg: '' });
        if (this.props.fileAdded) {
             this.props.fileAdded(files);
        }
    }

    handleFileDelete(index, fileName, filePath) {
        // clear error if any
        this.setState({ showCommonError: false, commonErrorMsg: '' });
        if (this.props.fileDeleted) {
            this.props.fileDeleted(index, fileName, filePath);
        }
    }

    render() {
        if (this.state.showCommonError === true) {

        }
        var attachments = null;
        if (this.props.attachments != undefined) {
            attachments = this.props.attachments.map((mapItem, index) => {
                var item = mapItem;

                // _var url = `api/file/download/query/${this.props.dataContext.queryId}/?filePath=${item.path}`;
                // _var url = `${config.webApiUrl}/file/download?filePath=${item.path}`;
                var url = `${config.webApiUrl}/file/download?filePath=`;

                if (item.progress == 1) {
                    return (
                        <li key={index}>
                            <DownloadLink url={url} file={item.path} className="button button-small button-text-icon button-white color-teal" showFileInDialog={item.isNotDownloadable === true}>
                                <Attachment className="icon icon-attachment" stroke={teal100} />
                                <div className="file-label">{item.fileName}</div>
                            </DownloadLink>
                            <div className="button button-icon button-icon-small button-grey" onClick={() => this.handleFileDelete(index, item.fileName, item.path)}>
                                <Trash className="icon icon-trash" stroke={alertRed} />
                            </div>
                        </li>);
                }

                return (
                    <li key={index}>
                        <Attachment className="icon icon-attachment" />
                        <div className="file-label">{item.fileName}</div>
                        <div className="button button-icon button-icon-small button-grey" onClick={() => this.handleFileDelete(index, item.fileName, item.path)}>
                            <Clear className="icon icon-clear" />
                        </div>
                        {item.failed == true
                            ? <div className="progress-label" style={{ color: alertRed }}>{item.errMsg}</div>
                            : <div className="progress-label">Uploading: <span className="progress-value" >{(item.progress * 100).toFixed(0)} %</span></div>
                        }
                    </li>);
            });
        }

        return (
            <div className="entry" style={this.props.style}>
                <label htmlFor="file-upload" className="">Upload File(s)</label>
                <Dropzone onDropAccepted={this.handleFileUploadChanged} style={{ cursor: 'pointer' }}>
                    {({ getRootProps, getInputProps }) => (
                        <div className="file-upload" {...getRootProps()} title="Click to choose file(s) or drop them here.">
                            <input {...getInputProps()} />
                            <Download className="icon icon-download" />
                            <label className="file-upload-label">Click to choose file(s) or drop them here.</label>
                        </div>
                    )}

                </Dropzone>

                {this.state.showCommonError
                    ? <div style={{ color: alertRed, marginTop: 10 }}>{this.state.commonErrorMsg}</div>
                    : null
                }
                <ul className="file-upload-list">
                    {attachments}
                </ul>
            </div>
        );
    }
}