import * as Actions from './constants';
import findIndex from 'lodash/findIndex';
import {produce} from "immer";

const initialState = {
  quotes: [],
  totalQuotesCount: 0,
  currentPage: 1,
  isLoading: true
}

export default function quotesReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.LOAD_QUOTES_SUCCESS:
          draft.quotes=action.values.quotes;
          draft.totalQuotesCount=action.values.totalQuotesCount;
          draft.isLoading=false;
          break;

      case Actions.LOAD_QUOTES:
        draft.currentPage=action.values.currentPage;
          draft.isLoading=true;
          break;

      case Actions.DELETE_QUOTE:
        var index = findIndex(draft.quotes, item => { return item.id == action.payload; });
         draft.quotes.splice(index, 1)
         break;
    }
  });
}
