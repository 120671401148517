import { createSelector } from 'reselect';
const selectRegister = () => (state) => state.register || {};
const selectErrorMgs = () => createSelector(
    selectRegister(),
    (homeState) => homeState.showErrorMsg
);

const selectRegisterUser = () => createSelector(
    selectRegister(),
    function (state) {
        if (state == undefined) {
            return undefined;
        }

        return state.registedUserInfo;
    }
);

const selectErrorMsgText = () => createSelector(
    selectRegister(),
    (homeState) => homeState.errorText
);

export {
    selectErrorMgs,
    selectErrorMsgText,
    selectRegisterUser
}