import PropTypes from 'prop-types';
import React from 'react';
import AutoComplete from '../AutoComplete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dropdown from '../Dropdown';
import Form from '../Form';
import Clear from '../../svg-icons/clear';
import { encodeValueChars } from '../../utils/format';

export default class Filters extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

    static defaultProps = {
        showDateCompletedDatePicker: false,
        showOrderStatusDropdown: false,
        showWaitingFor: false
    }

    constructor(props) {
        super(props);
        this.handleDatePickerChange = this.handleDatePickerChange.bind(this);
        this.handleDateCompletedDatePickerChange = this.handleDateCompletedDatePickerChange.bind(this);
        this.handleCreatedByDropDownItemSelected = this.handleCreatedByDropDownItemSelected.bind(this);
        this.handleClearButtonClick = this.handleClearButtonClick.bind(this);
        this.handleOrderStatusDropDownItemSelected = this.handleOrderStatusDropDownItemSelected.bind(this);
        this.doneTyping = this.doneTyping.bind(this);
        this.state = {
            dateCreated: null,
            dateCompleted: null,
            filterText: '',
            createdBy: -1,
            showClearButton: false,
            orderStatus: 0,
            waitForSelected: 0
        }

        this.filterData = {};
        this.autoCompleteRef = React.createRef();
        this.initFilterData();
    }

    initFilterData() {
        this.filterData = {
            dateCreated: null,
            dateCompleted: null,
            filterText: '',
            createdBy: -1,
            orderStatus: 0,
            waitFor: 0
        }
    }

    getWaiForList() {
        return [
            {
                id: 0,
                text: 'All'
            },
            {
                id: 1,
                text: 'Me'
            },
            {
                id: 2,
                text: 'Severn Trent'
            }
        ];
    }

    raiseSearchAction() {
        //React does not guarantee that the state changes are applied immediately.
        //so we cannot use state here as source of truth 
        if (this.props.executeSearchAction) {
            var filterInfo = { ...this.filterData };
            if (filterInfo.filterText) {
                filterInfo.filterText = encodeValueChars(filterInfo.filterText);
            }

            this.props.executeSearchAction(filterInfo);
        }
    }

    doneTyping(text) {
        this.setState({ filterText: text, showClearButton: true });
        this.filterData.filterText = text;
        this.raiseSearchAction();
    }


    handleCreatedByDropDownItemSelected(value) {
        this.setState({ createdBy: value, showClearButton: true });
        this.filterData.createdBy = value;
        this.raiseSearchAction();
    }

    handleWaitingForDropDownItemSelected = (value) => {
        this.setState({ waitForSelected: value, showClearButton: true });
        this.filterData.waitFor = value;
        this.raiseSearchAction();
    }

    handleDatePickerChange(value) {
        this.setState({ dateCreated: value, showClearButton: true });
        this.filterData.dateCreated = value.toISOString();
        this.raiseSearchAction();
    }

    handleDateCompletedDatePickerChange(value) {
        this.setState({ dateCompleted: value, showClearButton: true });
        this.filterData.dateCompleted = value.toISOString();;
        this.raiseSearchAction();
    }

    handleOrderStatusDropDownItemSelected(value) {
        this.setState({ orderStatus: value, showClearButton: true });
        this.filterData.orderStatus = value;
        this.raiseSearchAction();
    }

    handleClearButtonClick() {
        // this.setState({
        //     dateCreated: null,
        //     dateCompleted: null,
        //     filterText: '',
        //     createdBy: -1,
        //     showClearButton: false,
        //     orderStatus: 0,
        //     waitForSelected: 0
        // });

        // this.autoCompleteRef.current.setInitalText('');
        // if(this.props.filterClearedAction){
        //     this.props.filterClearedAction();
        // }

        // this.initFilterData();

        this.clear();
        if (this.props.filterClearedAction) {
            this.props.filterClearedAction();
        }
    }

    // allows api call
    clear() {
        this.setState({
            dateCreated: null,
            dateCompleted: null,
            filterText: '',
            createdBy: -1,
            showClearButton: false,
            orderStatus: 0,
            waitForSelected: 0
        });

        this.autoCompleteRef.current.setInitalText('');
        this.initFilterData();
    }

    render() {
        var clearButton;

        if (this.state.showClearButton) {
            clearButton = (
                <div className="button button-icon button-icon-display button-white"
                    data-demo="clear-filters"
                    onClick={this.handleClearButtonClick}>
                    <span className="tooltip-custom">
                        <span className="tooltiptext-custom">Clear All</span>
                        <Clear className="icon icon-clear" role="img" />
                    </span>
                </div>
            );
        }

        return (
            <Form id="filters-form" className={this.props.showFilterProp === true ? 'filters-form open' : 'filters-form'}>
                <div className="content-wrapper">
                    <AutoComplete dataSource={[]}
                        onUpdateInput={this.doneTyping}
                        hintText='Search'
                        placeholder='Enter order number, your reference or the property address/postcode'
                        ref={this.autoCompleteRef}
                    />
                    {this.props.showWaitingFor ?
                        <Dropdown dataSource={this.getWaiForList()}
                            onItemSelected={this.handleWaitingForDropDownItemSelected}
                            selectedItem={this.state.waitForSelected}
                            hintText='Waiting For'
                            style={{ verticalAlign: 'bottom' }} />
                        : null}
                    {this.props.showOrderStatusDropdown ?
                        <Dropdown dataSource={this.props.dropdownOrderStatusDataSource}
                            onItemSelected={this.handleOrderStatusDropDownItemSelected}
                            selectedItem={this.state.orderStatus}
                            hintText='Order Status'
                            style={{ verticalAlign: 'bottom' }} />
                        : null}
                    <DatePicker
                        label='Date Created'
                        onChange={this.handleDatePickerChange}
                        className='entry form-control-datepicker'
                        value={this.state.dateCreated}
                        // slotProps={{
                        //     textField: {
                        //         readOnly: true,
                        //     }
                        // }}
                    />
                    {this.props.showDateCompletedDatePicker ?
                        <DatePicker
                            label='Date Completed'
                            onChange={this.handleDateCompletedDatePickerChange}
                            className='entry form-control-datepicker'
                            value={this.state.dateCompleted}
                        />
                        : null}
                    <Dropdown dataSource={this.props.dropdownCreatedByDataSource}
                        onItemSelected={this.handleCreatedByDropDownItemSelected}
                        selectedItem={this.state.createdBy}
                        hintText='Created By'
                        style={{ verticalAlign: 'bottom' }} />
                    {clearButton}
                </div>
            </Form>
        );
    }
}

Filters.propTypes = {
    dropdownCreatedByDataSource: PropTypes.array,
    executeSearchAction: PropTypes.func,
    filterClearedAction: PropTypes.func,
    showFilterProp: PropTypes.bool,
    showOrderStatusDropdown: PropTypes.bool,
    showDateCompletedDatePicker: PropTypes.bool
};
