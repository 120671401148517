import React from 'react';
import Broken from '../../../svg-icons/Broken';

export default class EmptyView extends React.PureComponent {

    render() {
        return (
            <div className="no-content">
                <Broken  className="icon icon-broken"/>
                <span>Your basket is empty.</span> {/* First on is for new order, second one is when products are added into existing orders -->*/}
            </div>
        );
    }
}
