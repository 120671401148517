import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectDiscountPackagesDatasource, selectDiscountPackageCore, selectSelectedPackages, selectAllPackages, selectLocalAuthoritySearchPackage, selectSelectedDiscountPackagesInOrder } from './selectors';
import NewOrderDiscountPackagesDialog from './newOrderDiscountPackagesDialog';
import { selectDiscountPackage, openDiscountPackagesModal } from './actions';
import Info from '../../svg-icons/info';
import Pdf from '../../svg-icons/pdf';
import { formatPrice } from '../../utils/format';

class NewOrderProductsDiscount extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    handleDiscountPackageClick(packageId, isChecked) {
        this.props.selectDiscountPackageAction(packageId, isChecked);

        if (isChecked) {
            this.props.openDiscountPackagesModal(packageId);
        }
    }

    isDisabled() {
        if (this.props.selectedDiscountPackagesInOrder.length > 0) {
            return true;
        }

        return false;
    }

    isSelected(id) {
        var isSelected = this.props.selectedDiscountPackageCore[id] === true ? true : false;
        if (isSelected == false) {
            isSelected = this.props.selectedDiscountPackagesInOrder.id === id ? true : false;
        }

        return isSelected;
    }

    renderSubPackages(data) {
        if (data == undefined) {
            return null;
        }
        var sub = data.map((subPackage, index) => {
            var item = this.props.allPackages[subPackage.subPackageId];
            if (subPackage.isLocalAuthoritySearch && this.props.localAuthoritySearchPackageId > -1) {
                item = this.props.allPackages[this.props.localAuthoritySearchPackageId];
            }

            return <tr key={index}>
                <td>
                    <div className="cell-wrapper">
                        <div className="text-package">{item.name}</div>
                        <div className="popover-icon hide-mobile">
                            <div className="popover popover-description popover-package">
                                <p>{item.description}</p>
                            </div>
                            <Info className="icon icon-attention icon-attention-package" />
                        </div>
                    </div>
                </td>
                <td className="cell-240 show-desktop">
                    {
                        item.packageDocuments.map((doc, index) => {
                            return (
                                <a href={doc.documentURL} target="_blank" className="text-link cell-wrapper" key={index}>
                                    <Pdf className="icon icon-document icon-pdf" />
                                    <div className="text-reference">{doc.documentToolTipDescription}</div>
                                </a>);
                        })
                    }
                </td>
                <td className="cell-50 hide-mobile align-right">
                    <div className="text-product">{item.packageSLA}</div>
                </td>
                <td className="cell-100 align-right">
                    <div className="text-price">{`${formatPrice(item.priceWithVAT)}`}</div>
                </td>
            </tr>
        });

        return sub;
    }

    renderDiscountPackages() {
        if (this.props.productDatasource == undefined)
            return null;

        var packages = this.props.productDatasource.map((item, index) => {
            var isDisabled = this.isDisabled();
            var isSelected = this.isSelected(item.id);
            return <tr className="discount-row" key={index}>
                <td colSpan="4">
                    <div className="cell-wrapper">
                        <label htmlFor={`package-${item.id}`} className={`form-checkbox ${isDisabled ? 'disabled' : null}`}>
                            <input id={`package-${item.id}`} type="checkbox" checked={isSelected} disabled={isDisabled} onChange={(event) => this.handleDiscountPackageClick(item.id, event.target.checked)} />
                            <span className="indicator"></span>
                            <span className="label">{item.name}</span>
                        </label>
                        <div className="popover-icon hide-mobile">
                            <div className="popover popover-description popover-package">
                                <p>{item.description}</p>
                            </div>
                            <Info className="icon icon-attention icon-attention-package" />
                        </div>
                        <a onClick={() => this.props.openDiscountPackagesModal(item.id)} className="text-link" style={isSelected ? { display: 'inline-block' } : null}>More products</a>
                    </div>
                    <table className="content-grid products-grid" key={index}>
                        <tbody>
                            {this.renderSubPackages(item.subPackages)}
                        </tbody>
                    </table>
                </td>
            </tr>
        });

        return packages;
    }

    // row 29 {/* Add className 'selected' when product is selected, remove it when it's unselected */}
    render() {
        return (
            <section className="products discount-packages">
                <div className="products-group discount-packages expanded">
                    <table className="content-grid products-grid discount-grid">
                        <thead>
                            <tr>
                                <th>Products</th>
                                <th className="cell-180 show-desktop">Documents</th>
                                <th className="cell-50 hide-mobile align-right">Days</th>
                                <th className="cell-100 align-right">Price <span>(Inc VAT)</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderDiscountPackages()}
                        </tbody>
                    </table>
                </div>
                <NewOrderDiscountPackagesDialog selectedPackages={this.props.selectedPackages} />
            </section>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    allPackages: selectAllPackages(),
    productDatasource: selectDiscountPackagesDatasource(),
    selectedDiscountPackageCore: selectDiscountPackageCore(),
    selectedPackages: selectSelectedPackages(),
    localAuthoritySearchPackageId: selectLocalAuthoritySearchPackage(),
    selectedDiscountPackagesInOrder: selectSelectedDiscountPackagesInOrder()
})
const mapDispatchToProps = ({
    selectDiscountPackageAction: selectDiscountPackage,
    openDiscountPackagesModal: openDiscountPackagesModal
})

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(NewOrderProductsDiscount);
