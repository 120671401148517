import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let EyeClosed = (props) => (
  <SvgIconEx {...props}>
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
      <path d="M19.518 8.288C21.958 10.052 23.5 12 23.5 12s-5.148 6.5-11.5 6.5c-1.04 0-2.045-.173-3.002-.464M4.468 15.7C2.038 13.94.5 12 .5 12S5.648 5.5 12 5.5c.86 0 1.698.12 2.504.325M8 12c0-2.21 1.79-4 4-4M16 12c0 2.21-1.79 4-4 4M21.75 2.25l-19.5 19.5" />
    </g>
  </SvgIconEx>
);
EyeClosed.displayName = 'EyeOpen';
EyeClosed.muiName = 'SvgIcon';

export default EyeClosed;
