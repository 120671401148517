import { take, call, put, get, select, fork, takeLatest } from 'redux-saga/effects';
import config from 'utils/config';
import request from 'utils/request';
import {
    loadContactsSuccess,
    loadContactsFail,
    loadPrimaryContactSuccess,
    loadPrimaryContactFail,
    loadCustomerAddressSuccess,
    loadCustomerAddressFail,
    loadPreferenceSettingsSuccess,
    loadPreferenceSettingsFail,
    updatePreferenceSettingsSuccess,
    updatePreferenceSettingsFail,
    addNewCustomerContactSuccess,
    addNewCustomerContactFail,
    updateContactSuccess,
    updateContactFail,
    updateCustomerAddressSuccess,
    updateCustomerAddressFail,
    loadJobTitlesSuccess,
    loadJobTitlesFail,
    loadCustomerNameSuccess,
    loadCustomerNameFail
} from './actions';
import { showAppError } from 'containers/App/actions';
import {
    LOAD_USER_CONTACTS,
    LOAD_PRIMARY_CONTACT,
    LOAD_CUSTOMER_ADDRESS,
    LOAD_PREFERENCE_SETTINGS,
    UPDATE_PREFERENCE_SETTINGS,
    UPDATE_CUSTOMER_CONTACT,
    UPDATE_CUSTOMER_ADDRESS,
    ADD_NEW_CUSTOMER_CONTACT,
    LOAD_JOB_TITLES,
    LOAD_CUSTOMER_NAME
} from './constants';

export function* loadContactsSaga() {
    yield takeLatest(LOAD_USER_CONTACTS, getLoadContacts);
}

export function* getLoadContacts() {
    const requestURL = config.webApiUrl + "/preferences/contactsList";
    try {
        const contacts = yield call(request.getAuth, requestURL);
        yield put(loadContactsSuccess(contacts));
    }
    catch (error) {
        yield put(loadContactsFail(error));
    }
}

export function* loadPrimaryContactSaga() {
    yield takeLatest(LOAD_PRIMARY_CONTACT, getLoadPrimaryContact);
}

export function* getLoadPrimaryContact() {
    const requestURL = config.webApiUrl + "/preferences/contact";
    try {
        const primaryContact = yield call(request.getAuth, requestURL);
        yield put(loadPrimaryContactSuccess(primaryContact));
    }
    catch (error) {
        yield put(loadPrimaryContactFail(error));
    }
}

export function* loadContactAddressSaga() {
    yield takeLatest(LOAD_CUSTOMER_ADDRESS, getLoadContactAddress);
}

export function* getLoadContactAddress() {
    const requestURL = config.webApiUrl + "/preferences/address";
    try {
        const contactAddress = yield call(request.getAuth, requestURL);
        yield put(loadCustomerAddressSuccess(contactAddress));
    }
    catch (error) {
        yield put(loadCustomerAddressFail(error));
    }
}

export function* updateCustomerAddressSaga() {
    yield takeLatest(UPDATE_CUSTOMER_ADDRESS, updateAddress);
}

export function* updateAddress(action) {
    const requestURL = config.webApiUrl + "/preferences/updateAddress";
    try {
        yield call(request.postAuth, requestURL, action.customerAddress);
        yield put(updateCustomerAddressSuccess(action.customerAddress));
    }
    catch (error) {
        yield put(updateCustomerAddressFail(error));
        yield put(showAppError(error));
    }
}

export function* loadPreferenceSettingsSaga() {
    yield takeLatest(LOAD_PREFERENCE_SETTINGS, getLoadPreferenceSettings);
}

export function* getLoadPreferenceSettings() {
    const requestURL = config.webApiUrl + "/preferences/settings";
    try {
        const preferenceSettings = yield call(request.getAuth, requestURL);
        yield put(loadPreferenceSettingsSuccess(preferenceSettings));
    }
    catch (error) {
        yield put(loadPreferenceSettingsFail(error));
    }
}

export function* updatePreferenceSettingsSaga() {
    yield takeLatest(UPDATE_PREFERENCE_SETTINGS, updateSettings);
}

export function* updateSettings(action) {
    const requestURL = config.webApiUrl + "/preferences/settings";
    try {
        var savedSettings = yield call(request.postAuth, requestURL, action.preferenceSettings);
        yield put(updatePreferenceSettingsSuccess(savedSettings));
        action.navigate("/preferences");
    }
    catch (error) {
        yield put(updatePreferenceSettingsFail(error));
        yield put(showAppError(error));
    }
}

export function* updateCustomerContactSaga() {
    yield takeLatest(UPDATE_CUSTOMER_CONTACT, updateContact);
}

export function* updateContact(action) {
    const requestURL = config.webApiUrl + "/preferences/contact";
    try {
        const updatedContact = yield call(request.postAuth, requestURL, action.customerContact);
        yield put(updateContactSuccess(updatedContact));
    }
    catch (error) {
        console.error(error);
        //yield put(updateContactFail(error));
        yield put(showAppError(error));
    }
}

export function* addCustomerContactSaga() {
    yield takeLatest(ADD_NEW_CUSTOMER_CONTACT, addNewContact);
}

export function* addNewContact(action) {
    const requestURL = config.webApiUrl + "/preferences/newcontact";
    try {
        const createdContact = yield call(request.postAuth, requestURL, action.customerContact);
        yield put(addNewCustomerContactSuccess(createdContact));
    }
    catch (error) {
        yield put(addNewCustomerContactFail(error));
        yield put(showAppError(error));
    }
}

export function* loadJobTitlesSaga() {
    yield takeLatest(LOAD_JOB_TITLES, loadJobTitlesRequest);
}

export function* loadJobTitlesRequest(action) {
    const requestURL = config.webApiUrl + "/registration/jobtitles";
    try {
        const titles = yield call(request.getAuth, requestURL);
        yield put(loadJobTitlesSuccess(titles));
    }
    catch (error) {
        yield put(loadJobTitlesFail(error));
    }
}

export function* loadCustomerNameSaga() {
    yield takeLatest(LOAD_CUSTOMER_NAME, loadName);
}
export function* loadName() {
    const requestURL = config.webApiUrl + "/registration/customerInfo";
    try {
        const userInfo = yield call(request.getAuth, requestURL);
        yield put(loadCustomerNameSuccess(userInfo));
    }
    catch (error) {
        yield put(loadCustomerNameFail(error));
    }
}

export default function* rootSaga() {
    yield fork(loadContactsSaga);
    yield fork(loadPrimaryContactSaga);
    yield fork(loadPreferenceSettingsSaga);
    yield fork(loadContactAddressSaga);
    yield fork(updateCustomerAddressSaga);
    yield fork(updatePreferenceSettingsSaga);
    yield fork(addCustomerContactSaga);
    yield fork(updateCustomerContactSaga);
    yield fork(loadJobTitlesSaga);
    yield fork(loadCustomerNameSaga);
};
