import { formatPrice } from '../../../utils/format';
import keys from 'lodash/keys';
import values from 'lodash/values';

export function getPriceCore(selectedPackageIds, selectedDiscountPackageCore, allPackages, vatRate) {
    var totalNoVat = 0;
    var subTotal = 0;
    var totalVat = 0;
    var totalPrice = 0;
    var charges = {};
    var totalCharges = 0;
    var discount = 0;

    keys(selectedPackageIds).forEach((packageId) => {
        var item = allPackages[packageId];
        totalNoVat += item.defaultPrice;
        totalPrice += item.priceWithVAT;

        var packageDiscount = selectedPackageIds[packageId].discount;
        if (packageDiscount && packageDiscount.isDiscountPackage == true) {
            discount += packageDiscount.discountAmount;
        }

        item.priceLines.forEach((line) => {
            if (packageDiscount && packageDiscount.isDiscountPackage == true) {
                totalVat += line.vatRate * (line.defaultPrice - packageDiscount.discountAmount) / 100
            }
            else {
                totalVat += line.vatRate * line.defaultPrice / 100; // it could be a packages with 0 vat so we need to estimate Vat for each line
            }
        });



        if (item.charges != undefined) {
            item.charges.forEach((p) => {
                var prev = charges[p.discountType];
                if (prev != undefined) {
                    prev.chargeAmount += p.discountAmount;
                }
                else {
                    var newP = { ...p };
                    newP.chargeAmount = p.discountAmount;
                    charges[p.discountType] = newP;
                }
                totalCharges += p.discountAmount;
                totalVat += vatRate * p.discountAmount / 100;
            })
        }

        var additionalPproductInfo = selectedPackageIds[packageId].additionalProductInfo;
        values(additionalPproductInfo).forEach(additionalInfo => {
            if (additionalInfo.tm)
                additionalInfo.tm.forEach(function (element) {
                    totalNoVat += element.costVatable;
                    totalPrice += element.costVatable + element.costVat;
                    totalVat += element.costVat;
                }, this);
        })
    });

    subTotal = totalNoVat - discount + totalCharges;
    totalPrice = subTotal + totalVat;

    return {
        totalNoVat,
        totalVat,
        totalPrice,
        subTotal,
        charges,
        vatRate,
        totalCharges
    };
}

export function getFormattedPrice(selectedPackageIds, selectedDiscountPackageCore, allPackages, vatRate) {
    var price = getPriceCore(selectedPackageIds, selectedDiscountPackageCore, allPackages, vatRate);
    return {
        totalNoVat: formatPrice(price.totalNoVat),
        totalVat: formatPrice(price.totalVat),
        totalPrice: formatPrice(price.totalPrice),
        vatRate: vatRate,
        subTotal: formatPrice(price.subTotal),
        charges: price.charges,
        totalCharges: formatPrice(price.totalCharges)
    };
}
