import PropTypes from 'prop-types';
import React from 'react';

export default class Pager extends React.Component {
    constructor(props) {
        super(props);
        // this.state = {
        //     currentPage: 1
        // };
    }

    static defaultProps = {
        initialPage: 1,
        pageSize: 10
    }

    // componentWillReceiveProps(nextProps) {
    //     this.setState({currentPage: nextProps.initialPage});

    // }

    setPage(page) {
        //this.setState({currentPage: page});

        if (page < 1 || page > this.props.totalItemsCount) {
            return;
        }

        var pagerInfo = this.getPager(this.props.totalItemsCount, page);

        // call change page function in parent component
        this.props.onChangePage(pagerInfo.startIndex, pagerInfo.pageSize, this.props.gridName, pagerInfo.currentPage);
    }

    getPager(totalItems, currentPage) {
        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        var pageSize = this.props.pageSize;

        // calculate total pages
        var totalPages = Math.ceil(totalItems / pageSize);

        var startPage, endPage;
        if (totalPages <= pageSize) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // calculate start and end item indexes
        var startItemIndex = (currentPage - 1) * pageSize;
        var endItemIndex = Math.min(startItemIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        var pages = _.range(startPage, endPage + 1);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startItemIndex,
            endIndex: endItemIndex,
            pages: pages
        };
    }

    render() {
        const pager = this.getPager(this.props.totalItemsCount, this.props.currentPage);

        if (pager.totalItems == 0){
            return null;
        }

        return (
            <div className="content-pagination">
                <a className={pager.currentPage === 1 ? 'hidden' : 'text-link'} onClick={() => this.setPage(1)}>First</a>
                <a className={pager.currentPage === 1 ? 'hidden' : 'text-link'} onClick={() => this.setPage(pager.currentPage - 1)}>&lt;&lt; Prev</a>

                {pager.pages.map((page, index) =>
                    <a className={pager.currentPage === page ? 'text-link active' : 'text-link'} key={index} onClick={() => this.setPage(page)}>{page}</a>
                )}

                <a className={pager.currentPage === pager.totalPages ? 'hidden' : 'text-link'} onClick={() => this.setPage(pager.currentPage + 1)}>Next &gt;&gt;</a>
                <a className={pager.currentPage === pager.totalPages ? 'hidden' : 'text-link'} onClick={() => this.setPage(pager.totalPages)}>Last</a>
            </div>
        );
    }
}

Pager.propTypes = {
    totalItemsCount: PropTypes.number,
    pageSize: PropTypes.number,
    onChangePage: PropTypes.func,
    initialPage: PropTypes.number,
    gridName: PropTypes.string
};
