import React from 'react';
import moment from 'moment';

let ExpDateCellTemplate = (props) => {
  if (props.row && props.row.statusString == 'Awaiting Response') {
    // dont show date if status is Awaiting response
    return (
      <span
        className={`date-badge color-grey`}> 
        <span className="month">DATE</span>
        <span className="day">TBC</span>
      </span>
    )
  }
  var date = moment(props.datacontext);//, 'DD-MMM-YYYY');
  var now = moment();

  var month = date.format('MMM');
  var day = date.format('DD');
  var classEx = '';
  var dueInDays = moment.duration(date - now).days();

  if (date.isBefore(now)) {
    classEx = 'color-red';
  }

  if (dueInDays >= 0) {
    classEx = 'color-green';
  }

  if (dueInDays === 0 && date.isBefore(now)) {
    classEx = 'color-orange';
  }

  if (props.objType === 'query') {
    classEx = 'color-grey';
  }

  if (props.orderStatus === 'Completed' || props.orderStatus === 'Canceled') {
    classEx = 'color-grey';
  }

  return (
    <span
      className={`date-badge ${classEx}`}> {/*<!-- Add class 'color-orange' if order is due in next 2 days. Add class 'color-red' if order is past due or due today -->*/}
      <span className="month">{month}</span>
      <span className="day">{day}</span>
    </span>
  );
  //  }
  //   else {
  //     return (
  //       <span
  //         className={`date-badge ${classEx}`}> {/*<!-- Add class 'color-orange' if order is due in next 2 days. Add class 'color-red' if order is past due or due today -->*/}
  //         <span className="month">DATE</span>
  //         <span className="day">{props.datacontext}</span>
  //       </span>
  //     );
  //   }
  // } else {
  //   return <span></span>
  // }
}

export default ExpDateCellTemplate;
