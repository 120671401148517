export const LOAD_ORDER_DETAILS = 'atlas/orderDetails/LOAD_ORDER_DETAILS';
export const LOAD_ORDER_DETAILS_SUCCESS = 'atlas/orderDetails/LOAD_ORDER_DETAILS_SUCCESS';
export const LOAD_ORDER_DETAILS_ERROR = 'atlas/orderDetails/LOAD_ORDER_DETAILS_ERROR';

export const ORDER_DETAILS_CHANGE_QUERY_TAB = 'atlas/orderDetails/ORDER_DETAILS_CHANGE_QUERY_TAB';
export const ORDER_DETAILS_SET_ALERT_DATASOURCE = 'atlas/OrderDetails/ORDER_DETAILS_SET_ALERT_DATASOURCE';
export const ORDER_DETAILS_SELECT_ALERT = 'atlas/OrderDetails/ORDER_DETAILS_SELECT_ALERT';
export const ORDER_DETAILS_ADD_SELECTED_PACKAGES_TO_ORDER = 'atlas/OrderDetails/ORDER_DETAILS_ADD_SELECTED_PACKAGES_TO_ORDER';
export const ORDER_DETAILS_ADD_SELECTED_PACKAGES_TO_ORDER_SUCCESS = 'atlas/OrderDetails/ORDER_DETAILS_ADD_SELECTED_PACKAGES_TO_ORDER_SUCCESS';

export const OpenQueryGridName = 'order-details-open-query-grid';
export const ClosedQueryGridName = 'order-details-closed-query-grid';
export const OrderDetailsUploadChannel = 'order-details-upload-channel';

export const ORDER_CHASE = 'atlas/orderDetails/ORDER_CHASE';
export const ORDER_CHASE_SUCCESS = 'atlas/orderDetails/ORDER_CHASE_SUCCESS';
export const ORDER_CHASE_FAIL = 'atlas/orderDetails/ORDER_CHASE_FAIL';
export const ORDER_CHASE_HIDE_LABEL = 'atlas/orderDetails/ORDER_CHASE_HIDE_LABEL';

export const PACKAGE_CHASE = 'atlas/orderDetails/PACKAGE_CHASE';
export const PACKAGE_CHASE_SUCCESS = 'atlas/orderDetails/PACKAGE_CHASE_SUCCESS';
export const PACKAGE_CHASE_FAIL = 'atlas/orderDetails/PACKAGE_CHASE_FAIL';
export const PACKAGE_CHASE_HIDE_LABEL = 'atlas/orderDetails/PACKAGE_CHASE_HIDE_LABEL';

export const LOAD_PACKAGE_REPORTS = 'atlas/orderDetails/LOAD_PACKAGE_REPORTS';
export const LOAD_PACKAGE_REPORTS_SUCCESS = 'atlas/orderDetails/LOAD_PACKAGE_REPORTS_SUCCESS';
export const LOAD_PACKAGE_REPORTS_FAIL = 'atlas/orderDetails/LOAD_PACKAGE_REPORTS_FAIL';
export const CLOSE_PACKAGE_REPORT_DIALOG = 'atlas/orderDetails/CLOSE_PACKAGE_REPORT_DIALOG';

export const PACKAGE_CANCEL = 'atlas/orderDetails/PACKAGE_CANCEL';
export const PACKAGE_CANCEL_SUCCESS = 'atlas/orderDetails/PACKAGE_CANCEL_SUCCESS';
export const PACKAGE_CANCEL_FAIL = 'atlas/orderDetails/PACKAGE_CANCEL_FAIL';

export const ORDER_CANCEL = 'atlas/orderDetails/ORDER_CANCEL';
export const ORDER_CANCEL_SUCCESS = 'atlas/orderDetails/ORDER_CANCEL_SUCCESS';
export const ORDER_CANCEL_FAIL = 'atlas/orderDetails/ORDER_CANCEL_FAIL';

export const ORDER_DETAILS_RAISE_QUERY = 'atlas/orderDetails/ORDER_DETAILS_RAISE_QUERY';
export const ORDER_DETAILS_RAISE_QUERY_CANCELLED = 'atlas/orderDetails/ORDER_DETAILS_RAISE_QUERY_CANCELLED';
export const ORDER_DETAILS_SUBMIT_RAISE_QUERY = 'atlas/orderDetails/ORDER_DETAILS_SUBMIT_RAISE_QUERY';
export const ORDER_DETAILS_SUBMIT_RAISE_QUERY_SUCCESS = 'atlas/orderDetails/ORDER_DETAILS_SUBMIT_RAISE_QUERY_SUCCESS';
export const ORDER_DETAILS_SUBMIT_RAISE_QUERY_FAIL = 'atlas/orderDetails/ORDER_DETAILS_SUBMIT_RAISE_QUERY_FAIL';