import PropTypes from 'prop-types';
import React from 'react';

export default class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

     renderMenuItems() {
         if (this.props.dataSource === undefined) {
             return "";
         }

         return this.props.dataSource.map((item, i) =>
              <option key={i} value={item.id}>{item.text}</option>
         );
    }

    handleChange(e) {
        const value = e.target.value;

        if (this.props.onItemSelected) {
            this.props.onItemSelected(value);
        }
    }

    render() {
        return (
            <div className="entry form-dropdown form-masked-label">
                <select id="filter-search-type" className="form-control-white" data-demo="search"
                    onChange={this.handleChange}
                    value={this.props.selectedItem}>
                    {this.renderMenuItems()}
                </select>
                <label htmlFor="filter-search-type">{this.props.hintText}</label>
            </div>
        );
    }
}

Dropdown.propTypes = {
    dataSource: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    onItemSelected: PropTypes.func,
    selectedItem: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    hintText: PropTypes.string
};