export function isResidential(pack){
    var packageCode = pack.code;
    if(packageCode.indexOf("COM_") > -1){
        return false;
    }

    return true;
}

export function tmIntegration(pack, allPackages){
	var ret = false;
	if(pack.products == undefined){
		// seems this is discount package
		/*_if(pack.subPackages != undefined){

		}*/

		return false;
	}

	pack.products.forEach(function(element) {
		if(element.tmIntegration){
			ret = true;
		}
	}, this);

	return ret;
}

export function requiresAdditionalInfo(pack){
	var ret = false;
	if(pack.products == undefined){
		// seems this is discount package
		/*_if(pack.subPackages != undefined){

		}*/

		return false;
	}

	pack.products.forEach(function(element) {
		if(element.requiresAdditionalInfo){
			ret = true;
		}
	}, this);

	return ret;
}