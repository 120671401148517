import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Filter = (props) => (
  <SvgIconEx  {...props}>
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
      <ellipse cx="10" cy="4" rx="9.5" ry="3.5"/>
      <path
        d="M.5 4v2.5c0 1.1.637 2.637 1.415 3.414L8.5 16.5M19.5 4v2.5c0 1.1-.636 2.637-1.414 3.414L11.5 16.5M11.5 16.5v6c0 .55-.449 1-1 1h-1c-.55 0-1-.45-1-1v-6"/>
      <path d="M11.5 18.606c.863.563 1.892.894 3 .894 3.037 0 5.5-2.463 5.5-5.5 0-1.646-.727-3.119-1.873-4.127"/>
    </g>
  </SvgIconEx>
);
Filter.displayName = 'Filter';
Filter.muiName = 'SvgIcon';

export default Filter;
