import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ReactGA from 'react-ga4';
import TextField from '../../components/TextField';
import * as actions from './actions';
import { selectContactAddress } from './selectors'
import Form from '../../components/Form';
import AlertWarning from '../../svg-icons/alertWarning';
import config from '../../utils/config';
import request from '../../utils/request';

export class PreferencesAddresses extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            subBuilding: '',
            houseName: '',
            houseNumber: '',
            street: '',
            locality: '',
            city: '',
            postcode: '',
            dxNumber: '',
            dxName: '',
            fieldRequired: true
        }
    }

    componentDidMount() {
        //this.props.loadCustomerAddress();

        const completeURL = config.webApiUrl + "/preferences/address";
        var self = this;
        request
            .getAuth(completeURL)
            .then(function (result) {
                self.setState({
                    subBuilding: result.subBuilding ||'',
                    houseName: result.houseName || '',
                    houseNumber: result.houseNo || '',
                    street: result.street || '',
                    locality: result.locality || '',
                    city: result.city || '',
                    postcode: result.postcode || '',
                    dxNumber: result.dxNumber || '',
                    dxName: result.dxName || ''
                });

                self.props.loadCustomerAddressSuccess(result);
                //self.textBox.focus();
            })
            .catch(e=>{
                self.props.loadCustomerAddressFail({msg:'Failed to load address.'});
                //self.textBox.focus();
            });

        //ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.send({ hitType: "pageview", page: "/preferences" });
    }

    handleTextChanged(value, textBoxName) {
        var newState = {};
        newState[textBoxName] = value;
        this.setState(newState);

        if (textBoxName === 'subBuilding' || textBoxName === 'houseNumber' || textBoxName === 'houseName') {
            if (this.state.subBuilding === '' && this.state.houseNumber === '' && this.state.houseName === '') {
                value !== '' ? this.setState({fieldRequired: false}) : this.setState({fieldRequired: true});
            }
            else {
                value === '' ? this.setState({fieldRequired: true}) : this.setState({fieldRequired: false});
            }
        }
    }

    submit = (event) => {
        this.props.updateCustomerAddress({
            subBuilding: this.state.subBuilding,
            houseName: this.state.houseName,
            houseNo: this.state.houseNumber,
            street: this.state.street,
            locality: this.state.locality,
            city: this.state.city,
            postcode: this.state.postcode,
            dxName: this.state.dxName,
            dxNumber: this.state.dxNumber
        });
    }

    render() {
        return (
            <div>
                <Form id="addresses-form" className="preferences-form">
                    <div className="preferences-fields">
                        <div className="preferences-form-title">
                            <h2 className="h1">Postal Address</h2>
                            <p className="h1-description">This will be your main contact address used for billing and searches.</p>
                        </div>
                        <fieldset className="preferences-form-content">
                            <div className="entry">
                                <TextField
                                    label="Flat / Unit Number"
                                    inputClassName="form-control-grey form-control-60"
                                    onChange={(event, value) => this.handleTextChanged(value, 'subBuilding')}
                                    value={this.state.subBuilding}
                                    isRequired={this.state.fieldRequired} />
                            </div>
                            <div className="entry">
                                <TextField
                                    label="House Number"
                                    inputClassName="form-control-grey form-control-30"
                                    onChange={(event, value) => this.handleTextChanged(value, 'houseNumber')}
                                    value={this.state.houseNumber}
                                    isRequired={this.state.fieldRequired}  />
                            </div>
                            <div className="entry">
                                <TextField
                                    label="House Name"
                                    inputClassName="form-control-grey form-control-60"
                                    onChange={(event, value) => this.handleTextChanged(value, 'houseName')}
                                    value={this.state.houseName}
                                    isRequired={this.state.fieldRequired}/>
                            </div>
                            <div className="entry">
                                <TextField
                                    label="street"
                                    inputClassName="form-control-grey form-control-60"
                                    onChange={(event, value) => this.handleTextChanged(value, 'street')}
                                    value={this.state.street}
                                    isRequired />
                            </div>
                            <div className="entry">
                                <TextField
                                    label="locality"
                                    inputClassName="form-control-grey form-control-60"
                                    onChange={(event, value) => this.handleTextChanged(value, 'locality')}
                                    value={this.state.locality}/>
                            </div>
                            <div className="entry">
                                <TextField
                                    id="town"
                                    inputClassName="form-control-grey form-control-60"
                                    onChange={(event, value) => this.handleTextChanged(value, 'city')}
                                    value={this.state.city}
                                    isRequired />
                            </div>
                            <div className="entry">
                                <TextField
                                    id="postcode"
                                    inputClassName="form-control-grey form-control-20"
                                    onChange={(event, value) => this.handleTextChanged(value, 'postcode')}
                                    value={this.state.postcode}
                                    required />
                            </div>
                        </fieldset>
                    </div>
                    <div className="preferences-fields">
                        <div className="preferences-form-title">
                            <h2 className="h1">Invoice Address</h2>
                        </div>
                        <fieldset className="preferences-form-content">
                            {/*<!-- If it's same as postal addres, show this:*/}
                            <div className="alert alert-block alert-info">
                                <AlertWarning id="validated-icon" className="icon icon-alert-info" role="img"/>
                                <div className="alert-content">
                                    <strong>Same As Postal Address</strong>
                                </div>
                            </div> {/* --> */}
                            <ul className="vcard">
                                <li>
                                    <span className="label">Flat/Unit Number</span>
                                    <span className="value">{this.props.contactAddress.subBuilding}</span>
                                </li>
                                <li>
                                    <span className="label">House Name</span>
                                    <span className="value">{this.props.contactAddress.houseName}</span>
                                </li>
                                <li>
                                    <span className="label">House Number</span>
                                    <span className="value">{this.props.contactAddress.houseNo}</span>
                                </li>
                                <li>
                                    <span className="label">Street</span>
                                    <span className="value">{this.props.contactAddress.street}</span>
                                </li>
                                <li>
                                    <span className="label">Locality</span>
                                    <span className="value">{this.props.contactAddress.locality}</span>
                                </li>
                                <li>
                                    <span className="label">City</span>
                                    <span className="value">{this.props.contactAddress.city}</span>
                                </li>
                                <li>
                                    <span className="label">Postcode</span>
                                    <span className="value">{this.props.contactAddress.postcode}</span>
                                </li>
                            </ul>
                        </fieldset>
                    </div>
                    <div className="preferences-fields">
                        <div className="preferences-form-title">
                            <h2 className="h1">DX Details</h2>
                            <p className="h1-description">Your Direct Exchange address details.</p>
                        </div>
                        <fieldset className="preferences-form-content">
                            <div className="entry">
                                <TextField id="dxnumber"
                                    label= "DX Number"
                                    inputClassName="form-control-grey form-control-30"
                                    onChange={(event, value) => this.handleTextChanged(value, 'dxNumber')}
                                    value={this.state.dxNumber} />
                            </div>
                            <div className="entry">
                                <TextField id="dxname"
                                    label= "DX Name"
                                    inputClassName="form-control-grey form-control-60"
                                    onChange={(event, value) => this.handleTextChanged(value, 'dxName')}
                                    value={this.state.dxName} />
                            </div>
                        </fieldset>
                    </div>
                    <div className="preferences-actions">
                        <button type="reset" className="button button-regular button-transparent">Cancel</button>
                        <button onClick={this.submit} className="button button-regular button-green">Save Changes</button>
                    </div>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    contactAddress: selectContactAddress()
})
const mapDispatchToProps = ({
    ...actions
})

export default connect(mapStateToProps, mapDispatchToProps)(PreferencesAddresses);
