
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Mining = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path strokeLinecap="round" d="M4.5 21.416c-1.157 0-2.145-1.249-2.344-2.387L.5 9.5h23l-1.658 9.529c-.198 1.138-1.187 2.429-2.342 2.429"/>
          <circle strokeLinecap="round" cx="7" cy="21" r="2.5"/>
          <circle strokeLinecap="round" cx="17" cy="21" r="2.5"/>
          <path strokeLinecap="round" d="M9.5 21.5h5"/>
          <path d="M22.5 9.479C22.5 7.015 20.933 5.5 19 5.5c-.582 0-1.129.143-1.612.394C16.877 3.386 14.658 1.5 12 1.5c-2.66 0-4.878 1.886-5.39 4.394C6.128 5.643 5.581 5.5 5 5.5c-1.934 0-3.5 1.5-3.5 4"/>
      </g>
  </SvgIconEx>
);
Mining.displayName = 'Mining';
Mining.muiName = 'SvgIcon';

export default Mining;