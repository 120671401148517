import React from 'react';
import Con29DW from '../../svg-icons/con29dw';
import LocalAuthority from '../../svg-icons/localAuthority';
import Enviromental from '../../svg-icons/enviromental';
import Mining from '../../svg-icons/mining';
import Specialist from '../../svg-icons/specialist';
import Chancel from '../../svg-icons/chancel';
import Insurance from '../../svg-icons/insurance';
import RegSearches from '../../assets/img/regIcon.png';
import Utility from '../../svg-icons/utility';
import ReactGA from 'react-ga4';
export default class ProductTabs extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    handleTabChanged(tabName) {
        if (this.props.changeTab) {
            this.props.changeTab(tabName);
        }
        const userName = JSON.parse(sessionStorage.getItem('jwt')).user_name;
        ReactGA.event({
            category: 'User actions',
            action: 'Tab changed',
            label: tabName + ' was selected form user: ' + userName
        });
    }

    //   {/* Product Group Tabs */}
    render() {
        return (

            <ul className="products-group-tabs">
                {this.props.activeCategoryTab == 'res' ?
                    <li className={this.props.activeTab == 'con29' ? "active" : ''} onClick={() => this.handleTabChanged('con29')}>
                        <Con29DW className="icon icon-con29dw" />
                        <strong>CON29DW searches</strong>
                    </li>
                    :
                    <li className={this.props.activeTab == 'comCon29' ? "active" : ''} onClick={() => this.handleTabChanged('comCon29')}>
                        <Con29DW className="icon icon-con29dw" />
                        <strong>CON29DW searches</strong>
                    </li>
                }
                <li className={this.props.activeTab == 'authority' ? "active" : ''} onClick={() => this.handleTabChanged('authority')}>
                    <LocalAuthority className="icon icon-local-authority" />
                    <strong>LA Searches (A-Z)</strong>
                </li>

                {(this.props.activeCategoryTab == 'res' || this.props.activeCategoryTab == 'com') ?
                    <li className={this.props.activeTab == 'land' ? "active" : ''} onClick={() => this.handleTabChanged('land')}>
                        <img src={RegSearches} alt='' className="icon icon-land-registry" />
                        <strong>Regulated LA Searches</strong>
                    </li>
                    : null}

                <li className={this.props.activeTab == 'env' ? "active" : ''} onClick={() => this.handleTabChanged('env')}>
                    <Enviromental className="icon icon-enviromental" />
                    <strong>Environmental</strong>
                </li>
                <li className={this.props.activeTab == 'mining' ? "active" : ''} onClick={() => this.handleTabChanged('mining')}>
                    <Mining className="icon icon-mining" />
                    <strong>Mining</strong>
                </li>
                <li className={this.props.activeTab == 'spe' ? "active" : ''} onClick={() => this.handleTabChanged('spe')}>
                    <Specialist className="icon icon-specialist" />
                    <strong>Miscellaneous</strong>
                </li>
                {this.props.activeCategoryTab == 'res' ?
                    <li className={this.props.activeTab == 'ins' ? "active" : ''} onClick={() => this.handleTabChanged('ins')}>
                        <Insurance className="icon icon-insurance" />
                        <strong>Insurance</strong>
                    </li>
                    : null}
                <li className={this.props.activeTab == 'chancel' ? "active" : ''} onClick={() => this.handleTabChanged('chancel')}>
                    <Chancel className="icon icon-chancel" />
                    <strong>Chancel</strong>
                </li>
                {this.props.activeCategoryTab == 'com' ?
                    <li className={this.props.activeTab == 'utility' ? "active" : ''} onClick={() => this.handleTabChanged('utility')}>
                        <Utility className="icon icon-utility" />
                        <strong>Utility</strong>
                    </li>
                    : null}
            </ul>


        );
    }
}
