import { take, call, put, select, fork, takeLatest } from 'redux-saga/effects';
import { QUOTE_DETAILS_LOAD_QUOTE } from './constants';
import { loadQuoteDetailsSuccess, setAlertsRecommendedPackages } from './actions';
import { showAppError } from 'containers/App/actions';
import config from 'utils/config';
import request from 'utils/request';
import { uploadInit } from '../../components/UploadFile/actions';
import {loadPackagesSaleInfoRequest} from './sagas';
//
// API call to load qoute details. Shows order page with qoute data
//
export function* getQuoteSaga() {
  yield takeLatest(QUOTE_DETAILS_LOAD_QUOTE, loadQuoteRequest);
}

export function* loadQuoteRequest(action) {
  var quoteId = action.quoteId || 0;
  var requestURL = `${config.webApiUrl}/quote/getQuote?quoteId=${quoteId}`;
  var requestType = request.getAuth;

  try {
    // load packages first. After that we will load and quote data.
    // we need this because recommended packages need all packages to be loaded
    yield loadPackagesSaleInfoRequest();
    const quote = yield call(requestType, requestURL);
    yield put(loadQuoteDetailsSuccess(quote.order));
    yield put(uploadInit(quote.order.attachments, 'create-order-upload-channel'));

    if (quote.alerts != undefined) {
      yield put(setAlertsRecommendedPackages(JSON.parse(quote.alerts)));
    }
  }
  catch (err) {
    console.error(err);
    yield put(showAppError(err));
  }
}

// Bootstrap sagas
export default function* rootSaga() {
  yield fork(getQuoteSaga);
}
