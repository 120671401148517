import React from 'react';
import Info from '../../../svg-icons/info';
import {blue100, purple100} from "../../../assets/less/base/_variables.js";

 class ProductColumnTemplate extends React.Component{
    render(){
        var product = this.props.row;

        if(product == undefined){
            return null;
        }

        var stroke=  blue100;// '#009FE3';
        var classEx = 'residential';

        if(product.IsResidential == false){
            classEx = 'commercial';
            stroke = purple100;//'#87189D';
        }
        /*else if(this.props.activeCategoryTab == 'dis'){
            classEx = 'package';
        }*/

        var statusClassEx = 'received'; //{'received', 'in-progress' or 'completed'}
        if(product.status == 2){
            statusClassEx= 'in-progress';
        }
        else if(product.status == 4){
            statusClassEx= 'completed';
        }

        //var isSelected = this.props.selectedPackages[row['id']] ===true ? true:false;
        return (
            <div className="cell-wrapper">
                <strong className="text-heading">
                    {this.props.datacontext}
                    <span className="show-mobile"><span className={`status-label ${statusClassEx} show-mobile`}></span>{product["statusString"]}</span>
                </strong>
                <div className="popover-icon hide-mobile">
                    <div className={`popover popover-description popover-${classEx}`}>
                        <p>{product.description}</p>
                    </div>
                    <Info className={`icon icon-attention icon-attention-${classEx}`} role="img" />
                </div>
            </div>
        );
    }
};

export default ProductColumnTemplate;
