import {
    SEARCH_DASHBOARD,
    LOAD_DASHBOARD,
    LOAD_DASHBOARD_SUCCESS,
    LOAD_DASHBOARD_ERROR
} from './constants';

export function loadDashboard() {
    return {
        type: LOAD_DASHBOARD
    };
}

export function searchDashboard(values) {
    return {
        type: SEARCH_DASHBOARD,
        values
    };
}

export function dashboardLoaded(values) {
    return {
        type: LOAD_DASHBOARD_SUCCESS,
        values
    };
}

export function dashboardLoadingError(error) {
    return {
        type: LOAD_DASHBOARD_ERROR,
        error,
    }
}
