import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import ReactGA from 'react-ga4';
import {selectLoading} from '../App/selectors';

import * as actions from './actions';
import {selectContacts, selectPrimaryContact, selectJobTitles, selectCustomerName} from './selectors'
import PreferencesContactsModalDialog from './PreferencesContactsModalDialog';


export class PreferencesContacts extends React.PureComponent {

  constructor(props) {
    super(props);

    this.renderContacts = this.renderContacts.bind(this);
    this.renderPrimaryContact = this.renderPrimaryContact.bind(this);
    this.renderMailTo = this.renderMailTo.bind(this);
    this.handleModalCancel = this.handleModalCancel.bind(this);
    this.handleModalSave = this.handleModalSave.bind(this);

    this.state = {
      contacts: [],
      primaryContact: {},
      isModalShown: false,
      isPrimaryContactSelected: false,
      selectedContact: {}
    }
  }

  componentDidMount() {
    this.props.loadPrimaryContact();
    this.props.loadContacts();
    this.props.loadJobTitles();
    //ReactGA.pageview(window.location.pathname + window.location.search);
      ReactGA.send({ hitType: "pageview", page: "/preferences" });
  }
 
  renderMailTo(email) {
    return "mailto:" + email;
  }

  handleEditContact(val) {
    const pId = this.props.primaryContact ? this.props.primaryContact.id : 0;
    const contact = pId == val ?
      this.props.primaryContact :
      this.props.contacts.filter(c => c.id == val)[0];

    this.setState({isPrimaryContactSelected: pId == val});
    this.setState({selectedContact: contact});
    this.setState({isModalShown: true});
  }

  handleModalCancel() {
    this.setState({isModalShown: false});
  }

  handleModalSave(contact) {
    this.setState({isModalShown: false});
    this.props.updateCustomerContact(contact);
  }

  renderContacts() {
    if (this.props.contacts == undefined) {
      return null;
    }

    return this.props.contacts.map(contact =>
      <tr key={contact.id}>
        <td className="cell-240">
          <div className="cell-wrapper">
            <a onClick={this.handleEditContact.bind(this, contact.id)}
               className="text-heading text-link">{contact.name}</a>
          </div>
        </td>
        <td>{contact.jobTitle}</td>
        <td className="hide-tablet">
          <a href={this.renderMailTo(contact.email)} className="text-link">{contact.email}</a>
        </td>
        <td className="cell-200 hide-mobile">{contact.phone}</td>
        <td className="cell-80 row-actions hide-mobile align-right">
          <button onClick={this.handleEditContact.bind(this, contact.id)} className="button button-small button-grey">
            Edit
          </button>
        </td>
      </tr>
    );
  }

  renderPrimaryContact() {
    if (this.props.primaryContact == undefined) {
      return null;
    }

    return (
      <tr>
        <td className="cell-240">
          <div className="cell-wrapper">
            <a onClick={this.handleEditContact.bind(this, this.props.primaryContact.id)}
               className="text-heading text-link">{this.props.primaryContact.name}</a>
            <span className="status-label primary-label">Primary Contact</span>
          </div>
        </td>
        <td>
          <div className="text-heading">{this.props.primaryContact.jobTitle}</div>
        </td>
        <td className="hide-tablet"><a href={this.renderMailTo(this.props.primaryContact.email)} className="text-link">
          {this.props.primaryContact.email}</a>
        </td>
        <td className="cell-200 hide-mobile">{this.props.primaryContact.phone}</td>
        <td className="cell-80 row-actions hide-mobile align-right">
          <button onClick={this.handleEditContact.bind(this, this.props.primaryContact.id)}
                  className="button button-small button-grey">Edit
          </button>
        </td>
      </tr>
    );
  }

  renderCurrentUser() {
    const accountNumber = JSON.parse(localStorage.getItem('userOptions')).customerPreferenceDto.customerId;
    return (
      <tr>
        <td className="cell-240">
          <div className="cell-wrapper">
            Current user:  <strong> {this.props.currentUser.userName}</strong>
          </div>
        </td>
        <td>
          Company name:  <strong> {this.props.currentUser.siteName}</strong>
        </td>
        <td colSpan={2}>
          Account number:  <strong>{accountNumber}</strong>
        </td>
      </tr>
    );
  }

  render() {
    return (
      <div>
        {this.props.isLoading == true
          ?
          <div className="alert alert-loading alert-success">
            <div className="loader"></div>
          </div>
          :
          null
        }
        {this.state.isModalShown ?
          <PreferencesContactsModalDialog contact={this.state.selectedContact}
                                          titles={this.props.titles}
                                          isPrimaryContact={this.state.isPrimaryContactSelected}
                                          handleCancel={this.handleModalCancel}
                                          handleSave={this.handleModalSave}
          /> :
          null}
        <div className="content-block content-wrapper">
          <div className="content-block-grid">
            <table className="content-grid">
              <thead><tr><th colSpan={4}>Current user information</th></tr></thead>
              <tbody>
              {this.renderCurrentUser()}
              </tbody>
            </table>
          </div>
        </div>
        <div className="content-block content-wrapper">
          <div className="content-block-grid">
            <table className="content-grid">
              <tbody>
              {this.renderPrimaryContact()}
              </tbody>
            </table>
          </div>
        </div>
        {/* All Other Contacts */}
        <div className="content-block content-wrapper">
          <div className="content-block-grid">
            <table className="content-grid content-grid-compact">
              <thead>
              <tr>
                <th className="cell-240 cell-sort cell-sort-asc">
                  <div className="cell-sort-label">Name</div>
                </th>
                <th className="cell-sort">
                  <div className="cell-sort-label">Role</div>
                </th>
                <th className="hide-tablet">Email Address</th>
                <th className="cell-200 hide-mobile">Phone Number</th>
                <th className="cell-80 row-actions hide-mobile"></th>
              </tr>
              </thead>
              <tbody>
              {this.renderContacts()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  primaryContact: selectPrimaryContact(),
  contacts: selectContacts(),
  titles: selectJobTitles(),
  isLoading: selectLoading(),
  currentUser: selectCustomerName(),
})
const mapDispatchToProps = ({
  ...actions
})
// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(PreferencesContacts);
