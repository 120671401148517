import React from 'react';
import Trash from '../../svg-icons/trash';
import Print from '../../svg-icons/print';
import { alertRed } from '../../assets/less/base/_variables.js';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading';

export default class NavigationColumnTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.handleDeleteQuoteClick = this.handleDeleteQuoteClick.bind(this);
    }

    handleDeleteQuoteClick(){
        let quote = this.props.row;
        this.props.openDeleteQuoteDialog(quote);
    }

    render() {
        var row = this.props.row;
        
        if(row == undefined){
            return null;
        }

        return (<div className="cell-wrapper">
            {this.props.isDeleting ? 
                <Loading/> 
                :
                <button title='Delete quote' onClick={this.handleDeleteQuoteClick} className="button button-icon button-icon-small button-grey">
                    <Trash className='icon' stroke={alertRed} />
                </button>
            }
            <Link title='Print quote' to={'/quote-confirmation/' + row.id} className="button button-small button-grey" style={{padding: '4px 4px 2px 4px'}}><Print stroke='white' fill='gray' className='icon'/></Link>
            <Link title='View quote details' to={'/quote/' + row.id} className="button button-small button-white color-green">Continue</Link>
        </div>
        );
    }
}