import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { createStructuredSelector } from 'reselect';
import * as actions from './actions';
import { selectPreferenceSettings } from './selectors';
import Form from '../../components/Form';

export class PreferencesSettings extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handleBillingFrequencyChange = this.handleBillingFrequencyChange.bind(this);
        this.handleMailPreferenceChange = this.handleMailPreferenceChange.bind(this);
        this.handleEmailConfirmationChange = this.handleEmailConfirmationChange.bind(this);
        this.handleWebAlertsChange = this.handleWebAlertsChange.bind(this);
        this.handleReceiptSuppression = this.handleReceiptSuppression.bind(this);
        this.submit = this.submit.bind(this);

        this.state = {
            billingFrequency: '',
            mailPreference: '',
            receiveEmailConfirmation: false,
            receiveWebAlerts: false,
            receiptSuppresion: false
        };
    }

    componentDidMount() {
        this.props.loadPreferenceSettings();
        //ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.send({ hitType: "pageview", page: "/preferences" });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.preferenceSettings === undefined) {
            return;
        }
        this.setState({ billingFrequency: nextProps.preferenceSettings.billingFrequency });
        this.setState({ mailPreference: nextProps.preferenceSettings.mailPreference });
        this.setState({ receiveEmailConfirmation: nextProps.preferenceSettings.receiveEmailConfirmation });
        this.setState({ receiveWebAlerts: nextProps.preferenceSettings.receiveWebAlerts });
        this.setState({ receiptSuppresion: nextProps.preferenceSettings.receiptSuppresion });
    }

    handleBillingFrequencyChange(event) {
        this.setState({ billingFrequency: event.target.value });
    }

    handleMailPreferenceChange(event) {
        this.setState({ mailPreference: event.target.value });
    }

    handleEmailConfirmationChange(event) {
        this.setState({ receiveEmailConfirmation: !this.state.receiveEmailConfirmation });
    }

    handleWebAlertsChange(event) {
        this.setState({ receiveWebAlerts: !this.state.receiveWebAlerts });
    }

    handleReceiptSuppression(event) {
        this.setState({ receiptSuppresion: !this.state.receiptSuppresion });
    }

    submit(event) {
        this.props.updatePreferenceSettings({
            billingFrequency: this.state.billingFrequency,
            mailPreference: this.state.mailPreference,
            receiveEmailConfirmation: this.state.receiveEmailConfirmation,
            receiveWebAlerts: this.state.receiveWebAlerts,
            receiptSuppresion: this.state.receiptSuppresion
        }, 
        this.props.navigate);
    }

    render() {
        return (
            <div>
                <Form id="settings-form" className="preferences-form">
                    <div className="preferences-fields">
                        <fieldset className="preferences-form-content">
                            <div className="entry">
                                <label htmlFor="billing-frequency">Billing Frequency</label>
                                <div className="form-dropdown">
                                    <select id="billing-frequency" disabled={true}
                                        className="form-control-grey"
                                        onChange={this.handleBillingFrequencyChange}
                                        value={this.state.billingFrequency}
                                    >
                                        <option value="Weekly">Weekly</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Yearly">Yearly</option>
                                    </select>
                                </div>
                            </div>
                            <div className="entry">
                                <label htmlFor="mail-preferences">Mail Preferences</label>
                                <div className="form-dropdown">
                                    <select id="mail-preferences"
                                        className="form-control-grey"
                                        onChange={this.handleMailPreferenceChange}
                                        value={this.state.mailPreference}
                                    >
                                        <option value="Post">Post</option>
                                        <option value="Email">E-mail</option>
                                        <option value="Both">Both</option>
                                    </select>
                                </div>
                            </div>
                            <div className="entry">
                                <div className="form-toggle-option">
                                    <label htmlFor="confirmation" className="form-toggle-label">Receive e-mail confirmation</label>
                                    <label htmlFor="confirmation" className="form-toggle-button">
                                        <input id="confirmation" type="checkbox"
                                            onChange={this.handleEmailConfirmationChange}
                                            checked={this.state.receiveEmailConfirmation} />
                                        <span className="toggle-indicator"><span className="toggle-line"></span></span>
                                    </label>
                                </div>
                                <div className="form-toggle-option">
                                    <label htmlFor="receipt-suppresion" className="form-toggle-label">Receive Invoice Receipts</label>
                                    <label htmlFor="receipt-suppresion" className="form-toggle-button">
                                        <input id="receipt-suppresion" type="checkbox"
                                            onChange={this.handleReceiptSuppression}
                                            checked={this.state.receiptSuppresion} />
                                        <span className="toggle-indicator"><span className="toggle-line"></span></span>
                                    </label>
                                </div>
                                <div className="form-toggle-option">
                                    <label htmlFor="web-alerts" className="form-toggle-label">Receive Web Alerts</label>
                                    <label htmlFor="web-alerts" className="form-toggle-button">
                                        <input id="web-alerts" type="checkbox"
                                            onChange={this.handleWebAlertsChange}
                                            checked={this.state.receiveWebAlerts} />
                                        <span className="toggle-indicator"><span className="toggle-line"></span></span>
                                    </label>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div className="preferences-actions">
                        <button type="reset" className="button button-regular button-transparent">Cancel</button>
                        <button onClick={this.submit} className="button button-regular button-green">Save Changes</button>
                    </div>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    preferenceSettings: selectPreferenceSettings()
})
const mapDispatchToProps = ({
    loadPreferenceSettings: actions.loadPreferenceSettings,
    updatePreferenceSettings: actions.updatePreferenceSettings

})

export default connect(mapStateToProps, mapDispatchToProps)(PreferencesSettings);
