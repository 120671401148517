import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { loadQuoteConfirmation } from './actions';
import OrderConfirmationInternal from './index';
import { loadCustomerContacts } from '../App/actions';
import { useInjectSaga } from 'utils/injectSaga';
import rootSaga from './sagas';
import { useParams } from 'react-router-dom';

export default function QuoteConfirmation(props) {

    useInjectSaga({ key: 'quote-confirmation', saga: rootSaga });

    const dispatch = useDispatch();
    const loadQuoteConfirmationAction = useCallback((quoteId) => dispatch(loadQuoteConfirmation(quoteId)), [dispatch]);
    const loadCustomerContactsAction = useCallback((info) => dispatch(loadCustomerContacts(info)), [dispatch]);

    let { quoteId } = useParams();
    useEffect(() => {
        loadQuoteConfirmationAction(quoteId);
        loadCustomerContactsAction();
    }, []);

    if (quoteId)
        return <OrderConfirmationInternal isQuote={true} quoteId={quoteId} />;

    return null;
}