import React from 'react';
import Attention from '../../svg-icons/attention';
import { blue100, purple100 } from "../../assets/less/base/_variables.js";
import Loading from '../../components/Loading';
import findIndex from 'lodash/findIndex';

class AdditionalInfoRowTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = { additionalText: '', checkboxLock: true };
    }

    unlockCheckbox() {
        this.setState({ checkboxLock: false });
    }

    handleTermsCbxOnChange(value, productId) {
        this.props.setAgreeCheckBoxToProduct(this.props.dataContext.package.id, productId, value);
    }

    handleTextChanged = (e) => {
        this.setState({ additionalText: e.target.value });
    }

    handleTextLostFocus = (productId) => {
        this.props.setAdditionalTextToProduct(this.props.dataContext.package.id, productId, this.state.additionalText);
    }

    handleSubpackageToggle(productId, priceDetails, value) {
        var datacontext = this.props.dataContext;
        this.props.selectTmIntegrationPackage(datacontext.package.id, productId, priceDetails, value)
    }

    isSelected = (productId, priceDetailsId) => {
        var pack = this.props.selectedPackages[this.props.dataContext.package.id];
        if (pack == undefined)
            return false;

        var additional = pack.additionalProductInfo[productId] ? pack.additionalProductInfo[productId].tm : [];
        var index = findIndex(additional, { 'priceDetailsId': priceDetailsId });
        if (index > -1)
            return true;

        return false;
    }

    _renderTmExtraQuestions(datacontext, product) {
        var rows = [];
        for (var index = 0; index < datacontext.items.length; index += 2) {
            var item1 = {...datacontext.items[index]};
            item1.isSelected = this.isSelected(product.productId, item1.priceDetailsId);

            var item2 = {};
            if (index + 1 < datacontext.items.length) {
                item2 = {...datacontext.items[index + 1]};
                item2.isSelected = this.isSelected(product.productId, item2.priceDetailsId);
            }

            var row = { column1: item1, column2: item2 };
            rows.push(row);
        }

        var rowsEl = rows.map((item, index) => {
            return <tr key={index}>
                <td>
                    <div className="cell-wrapper">
                        <label htmlFor={`residential-enquiry-${item.column1.priceDetailsId}`} className="form-checkbox">
                            <input id={`residential-enquiry-${item.column1.priceDetailsId}`} checked={item.column1.isSelected} type="checkbox" onChange={(event) => { this.handleSubpackageToggle(product.productId, item.column1, event.target.checked) }} />
                            <span className="indicator"></span>
                            <span className="label">{item.column1.productComponentType}</span>
                        </label>
                    </div>
                </td>
                <td>
                    {item.column2.priceDetailsId != undefined ?
                        <div className="cell-wrapper">
                            <label htmlFor={`residential-enquiry-${item.column2.priceDetailsId}`} className="form-checkbox">
                                <input id={`residential-enquiry-${item.column2.priceDetailsId}`} checked={item.column2.isSelected} type="checkbox" onChange={(event) => { this.handleSubpackageToggle(product.productId, item.column2, event.target.checked) }} />
                                <span className="indicator"></span>
                                <span className="label">{item.column2 ? item.column2.productComponentType : null}</span>
                            </label>
                        </div>
                        : null}
                </td>
            </tr>
        });

        return rowsEl;
    }

    render() {
        if (this.props.dataContext == undefined) return null;
        var datacontext = this.props.dataContext;

        if (datacontext.isLoading) {
            return <Loading />
        }

        if (datacontext.showAddressWarning) {
            return (
                <div style={{ margin: 20, textAlign: 'center', fontSize: '1.3em' }}>{datacontext.msg}</div>
            );
        }

        if (datacontext.package == undefined) {
            return (
                <div style={{ margin: 20, textAlign: 'center', fontSize: '1.3em' }}>No data cannot be shown.</div>
            );
        }

        var zeroPriceWarning = null;
        if (datacontext.type !== 'TM' && datacontext.type !== undefined) {
            zeroPriceWarning = <tr><td colSpan="2"><div style={{ margin: 20, textAlign: 'center', fontSize: '1.3em' }}>Specific optional enquiry prices could not be found. Please select from the list below and you will be contacted with prices prior to ordering.</div></td></tr>
        }


        var additionalRows = datacontext.package.products.map((product, index) => {
            var tmRows = [];
            if (product.tmIntegration) {
                tmRows = this._renderTmExtraQuestions(datacontext, product);
            }

            var additionalText = <tr key={index + 100}>
                <td colSpan="2">
                    <div className="entry">
                        {product.termsLink && product.termsLink != '' ?
                            <label htmlFor={`additional-info-${product.productId}`} className='form-checkbox' >
                                <div className={this.state.checkboxLock ? "popover-icon lock-mouse" : ''}>
                                    <input id={`additional-info-${product.productId}`} type="checkbox" required={product.termsRequired} onChange={(event) => this.handleTermsCbxOnChange(event.target.checked, product.productId)} />
                                    {this.state.checkboxLock ?
                                        <div className={"popover popover-description popover-residential"}>
                                            <p>You have to click and read 'Terms of Purchase', before you can click
                                                the checkbox</p>
                                        </div>
                                        : null}
                                    <span className="indicator"></span>
                                </div>
                                <span className="label">Please check the tick box to confirm you have read and agreed the <a href={product.termsLink} target="_blank" onClick={(event) => this.unlockCheckbox()} >Terms of Purchase</a></span>
                            </label> : null}

                        <label htmlFor="residential-additional-enquiry">{product.additionalInfoDescription}</label>
                        <textarea id="residential-additional-enquiry" value={this.state.addtionalText}
                            onChange={this.handleTextChanged}
                            onBlur={() => this.handleTextLostFocus(product.productId)}
                            rows="3" className="form-control-white"></textarea>
                    </div>
                </td>
            </tr>
            tmRows.push(additionalText);

            return tmRows;
        });

        return (
            <table className="content-grid products-grid enquiry-grid">
                <tbody>
                    {zeroPriceWarning}
                    {additionalRows}
                </tbody>
            </table>
        );
    }
};

export default AdditionalInfoRowTemplate;
