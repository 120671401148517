import { createSelector } from 'reselect';
const selectHome = () => (state) => state.quotes || {};

const selectQuotes = () => createSelector(
  selectHome(),
  (quotesState) => quotesState.quotes
);
const selectIsLoading = () => createSelector(
    selectHome(),
    (quotesState) => quotesState.isLoading
);
const selectTotalQuotesCount = () => createSelector(
    selectHome(),
    (quotesState) => quotesState.totalQuotesCount
);
const selectCurrentPage = () => createSelector(
    selectHome(),
    (quotesState) => quotesState.currentPage
);

export{
    selectQuotes,
    selectTotalQuotesCount,
    selectIsLoading,
    selectCurrentPage
}
