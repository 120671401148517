
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Print = (props) => (
  <SvgIconEx  {...props} viewBox="0 0 24 24">
    <path d="M18,3H6V7H18M19,12A1,1 0 0,1 18,11A1,1 0 0,1 19,10A1,1 0 0,1 20,11A1,1 0 0,1 19,12M16,19H8V14H16M19,8H5A3,3 0 0,0 2,11V17H6V21H18V17H22V11A3,3 0 0,0 19,8Z" />
  </SvgIconEx>
);
Print.displayName = 'Print';
Print.muiName = 'SvgIcon';

export default Print;