import { loadQuoteDetails, initCreatePageState } from './actions';
import CreateOrderInternal from './index-internal';
import { loadCustomerContacts } from '../App/actions';
import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import rootSaga from './sagas-quote';
import { useParams } from 'react-router-dom';
//import { RESTART_ON_REMOUNT } from '../../utils/constants';

export default function QuoteToOrder(props) {

    useInjectSaga({ key: 'quote-create', saga: rootSaga/*, mode: RESTART_ON_REMOUNT*/ });

    const dispatch = useDispatch();
    //const initCreatePageStateAction = useCallback((quoteId) => dispatch(initCreatePageState(quoteId)), [dispatch]);
    const loadQuoteDetailsAction = useCallback((quoteId) => dispatch(loadQuoteDetails(quoteId)), [dispatch]);
    //const loadCustomerContactsAction = useCallback(() => dispatch(loadCustomerContacts()), [dispatch]);

    let { quoteId } = useParams();
    useEffect(() => {
        //initCreatePageStateAction();
        //loadCustomerContactsAction();
        // loadPackagesInfo is called by load quote details saga
        loadQuoteDetailsAction(quoteId);
    }, []);

    if (quoteId)
       return<CreateOrderInternal quoteId={quoteId} />;

    return null;
}