import React from 'react';

export default class Form extends React.Component {

    handleFormSubmit = (event)=> {
        event.preventDefault();
        if(this.props.onSubmit){
            this.props.onSubmit(event);
        }
    }

    render(){
        return (
            <form className={this.props.className} style={this.props.style} onSubmit={this.handleFormSubmit} autoComplete="off">
                {this.props.children}
            </form>
        );
    }
}