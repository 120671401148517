import PropTypes from 'prop-types';
import React from 'react';
import Notification from '../../svg-icons/Notification';

export default class NotificationItem extends React.Component { // eslint-disable-line react/prefer-stateless-function

  handleClick = (event) => {
    this.props.onClick();
  }

  render() {
    if (this.props.receiveWebAlerts == false) {
      return null;
    }

    return (
      <li style={{ display: 'flex', alignItems: 'center', justifyContent:'center', minWidth: 'unset', padding: 10 }}
        onClick={this.handleClick}>
        <button title='Dismiss notification' className="button button-icon button-transparent">
          <Notification className="icon icon-notification" />
        </button>
        {this.props.notifications && this.props.notifications.length > 0
          ? <span className="badge hide-mobile">{`${this.props.notifications.length}`}</span>
          : null}
      </li>
    );
  }
}

NotificationItem.propTypes = {
  onClick: PropTypes.func.isRequired
}