import {produce} from "immer";
import {
    LOAD_USER_CONTACTS_SUCCESS,
    LOAD_PRIMARY_CONTACT_SUCCESS,
    LOAD_CUSTOMER_ADDRESS_SUCCESS,
    LOAD_PREFERENCE_SETTINGS_SUCCESS,
    LOAD_JOB_TITLES_SUCCESS,
    LOAD_CUSTOMER_NAME_SUCCESS,
    UPDATE_CUSTOMER_CONTACT_SUCCESS,
    ADD_NEW_CUSTOMER_CONTACT_SUCCESS,
    UPDATE_PREFERENCE_SETTINGS_SUCCESS,
    UPDATE_CUSTOMER_ADDRESS_SUCCESS
} from './constants';

const initialState = {
    showErrorMsg: false,
    contacts: [],
    contactAddress: {},
    userInfo: {},
    titles: []
};

export default function preferencesReducer(state = initialState, action) {
    return produce(state, (draft) => {
        switch (action.type) {

            case LOAD_USER_CONTACTS_SUCCESS:
                draft.contacts = action.contacts;
                break;

            case LOAD_PRIMARY_CONTACT_SUCCESS:
                draft.primaryContact = action.primaryContact;
                break;

            case LOAD_CUSTOMER_ADDRESS_SUCCESS:
            case UPDATE_CUSTOMER_ADDRESS_SUCCESS:
                draft.contactAddress = action.customerAddress;
                break;

            case LOAD_PREFERENCE_SETTINGS_SUCCESS:
                draft.preferenceSettings = action.preferenceSettings;
                break;

            case LOAD_JOB_TITLES_SUCCESS:
                draft.titles = action.titles;
                break;

            case LOAD_CUSTOMER_NAME_SUCCESS:
                draft.userInfo = action.userInfo;
                break;

            case ADD_NEW_CUSTOMER_CONTACT_SUCCESS:
                draft.contacts.push(action.createdContact);
                draft.contacts.sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1;
                    }

                    if (a.name > b.name) {
                        return 1;
                    }

                    return 0;
                });

                break;

            case UPDATE_CUSTOMER_CONTACT_SUCCESS:
                const contact = action.updatedContact;
                if (draft.primaryContact && contact.id == draft.primaryContact.id) {
                    draft.primaryContact.email = contact.email;
                    draft.primaryContact.firstName = contact.firstName;
                    draft.primaryContact.lastName = contact.lastName;
                    draft.primaryContact.name = contact.name;
                    draft.primaryContact.jobTitle = contact.jobTitle;
                    draft.primaryContact.newOrder = contact.newOrder;
                    draft.primaryContact.phone = contact.phone;
                    return;
                }

                draft.contacts
                    .filter(c => {
                        return c.id == contact.id;
                    })
                    .forEach((c, i) => {
                        c.email = contact.email;
                        c.firstName = contact.firstName;
                        c.lastName = contact.lastName;
                        c.name = contact.name;
                        c.jobTitle = contact.jobTitle;
                        c.newOrder = contact.newOrder;
                        c.phone = contact.phone;
                        return i;
                    });

                draft.contacts.sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1;
                    }

                    if (a.name > b.name) {
                        return 1;
                    }

                    return 0;
                });
                break;

            case UPDATE_PREFERENCE_SETTINGS_SUCCESS:
                // save them in browser local storage
                var userOptions = JSON.parse(localStorage.getItem('userOptions') || '{}');
                userOptions.customerPreferenceDto = action.preferenceSettings;
                localStorage.setObj('userOptions', userOptions);
                draft.preferenceSettings = action.preferenceSettings;
                break;
        }
    });
}
