
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Attachment = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path d="M7.701 15.202l8.501-8.5c.78-.781 2.047-.781 2.828 0 .781.781.78 2.046 0 2.828L7.548 21.048c-1.562 1.562-4.095 1.562-5.657 0-1.562-1.563-1.562-4.095 0-5.658l12-11.999c2.148-2.149 5.631-2.149 7.778 0 2.148 2.147 2.148 5.63 0 7.776l-8.5 8.5"/>
      </g>
  </SvgIconEx>
);
Attachment.displayName = 'Attachment';
Attachment.muiName = 'SvgIcon';

export default Attachment;