import config from '../../utils/config';
import React from 'react';
import { Link } from 'react-router-dom';
import SafeUrl from '../SafeUrl';

export default class Footer extends React.Component {
    renderUserInfo() {
        if (this.props.isUserLogged) {
            return (
                <div className="logged-user">
                    <div>Logged in as: </div><div><strong>{this.props.customerName}</strong></div>
                </div>
            );
        } else {
            return null;
        }
    }


    render() {
        return (
            <footer className="footer">
                {this.renderUserInfo()}
                <div className="content-wrapper">
                    <div className="footer-actions">
                        <a href="http://www.severntrentsearches.com/apply-become-account-holder/"
                            className="button button-small button-grey color-pink" target="_blank">Apply to Become an Account
                            Holder</a>
                        <Link to="/register" className="button button-small button-grey color-pink">Register to Pay by Card</Link>
                    </div>
                    <div className="footer-left">
                        <p>Telephone: <a href="tel:01159713550" className="text-link">0115 971 3550</a></p>
                        <p>Email: <a href="mailto:enquiries@severntrentsearches.com" className="text-link">enquiries@severntrentsearches.com</a></p>
                    </div>
                    <div className="footer-right">
                        <p>Find out how to use this service with the
                            <SafeUrl dangerousURL={config.envSettings.trainingPdfLink} text='Training Manual' className="text-link" target="_blank" /> &amp;
                            <SafeUrl dangerousURL={config.envSettings.faqLink} text='FAQ' className="text-link" target="_blank" />
                        </p>
                        <p>&#9400; Copyright Severn Trent Searches 2017 All Rights Reserved.</p>
                        <p>{config.version}</p>
                    </div>
                </div>
            </footer>
        );
    }
}