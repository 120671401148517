
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Star = (props) => (
  <SvgIconEx  {...props}>
      <g strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
	      <path d="M11.544 4.404l2.305 4.64L19 9.788 15.272 13.4l.88 5.1-4.608-2.408L6.938 18.5l.879-5.1-3.728-3.612 5.152-.744z"/>
      </g>
  </SvgIconEx>
);
Star.displayName = 'Star';
Star.muiName = 'SvgIcon';

export default Star;