import React from 'react';
import AlertError from '../../svg-icons/alertError';

export default class AlertMsg extends React.Component {
    static defaultProps = {
        msg: 'Such user already exists!'
    }

    render() {
        return (
            <div className="alert alert-block alert-error">
                <i className="icon icon-alert-error"><AlertError/></i>
                <div className="alert-content">
                    <p>{this.props.msg}</p>
                </div>
            </div>
        );
    }
}