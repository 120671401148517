
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Con29DW = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path d="M20.5 16c0 4.143-3.358 7.5-7.5 7.5-4.144 0-7.5-3.357-7.5-7.5S13 .5 13 .5s7.5 11.357 7.5 15.5z"/>
          <path d="M10.002 19.356C9.08 18.532 8.5 17.335 8.5 16c0-1.351 1.331-4.19 2.545-6.5"/>
      </g>
  </SvgIconEx>
);
Con29DW.displayName = 'Con29DW';
Con29DW.muiName = 'SvgIcon';

export default Con29DW;