
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let LocalAuthority = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
        <path d="M1.5 21.5v-9"/>
        <path strokeLinecap="round" d="M.5 21.5h6M.5 23.5h22M.5 12.5h6M8.5 21.5h6M8.5 12.5h6"/>
        <path d="M3.5 21.5v-9M5.5 21.5v-9M9.5 21.5v-9M11.5 21.5v-9M13.5 21.5v-9"/>
        <path strokeLinecap="round" d="M16.5 21.5h6M16.5 12.5h6"/>
        <path d="M17.5 21.5v-9M19.5 21.5v-9M21.5 21.5v-9M.5 10.5h22l-10.469-9z"/>
      </g>
  </SvgIconEx>
);
LocalAuthority.displayName = 'LocalAuthority';
LocalAuthority.muiName = 'SvgIcon';

export default LocalAuthority;