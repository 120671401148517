import { initCreatePageState, loadPackagesInfo } from './actions';
import CreateOrderInternal from './index-internal';
import { loadCustomerContacts } from '../App/actions';
import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import rootSaga from './sagas';
//import { RESTART_ON_REMOUNT } from '../../utils/constants';

export default function CreateOrder(props) {

    //useInjectSaga({ key: 'create-order', saga: rootSaga/*, mode: RESTART_ON_REMOUNT*/ });

    // const dispatch = useDispatch();
    // const initCreatePageStateAction = useCallback(() => dispatch(initCreatePageState()), [dispatch]);
    // const loadCustomerContactsAction = useCallback(() => dispatch(loadCustomerContacts()), [dispatch]);
    // const loadPackagesInfoAction = useCallback(() => {
    //     console.log("loadPackagesInfoAction")
    //     dispatch(loadPackagesInfo());
    // }, [dispatch]);

    // useEffect(() => {
    //     initCreatePageStateAction();
    //     loadPackagesInfoAction();
    //     loadCustomerContactsAction();
    // }, []);

    return <CreateOrderInternal />;
}