import { call, put, select, takeLatest, fork } from 'redux-saga/effects';
import { APP_USER_LOGOUT, LOAD_CUSTOMER_CONTACTS, APP_MENU_NOTIFICATIONS_REMOVE_ITEM, APP_MENU_NOTIFICATIONS_REMOVE_ALL_ITEMS } from './constants';
import {
  customerContactsLoaded, showAppError, customerNotificationsLoaded,
  customerNotificationsRemovedSuccess, customerNotificationsRemovedAllSuccess
} from './actions';
import { selectCustomerContacts } from './selectors';
import { selectCustomerPreferenceDto } from '../HomePage/selectors';
import config from '../../utils/config';
import request from '../../utils/request';
import { loadQueryDetailsSaga, replyToQueryRequestSaga, reopenQuerySaga } from '../Queries/sagas';
import { uploadRequestWatcherSaga } from '../../components/UploadFile/sagas';

//**************************** Logout  *******/
function logoutUser() {
  //yield put(logout());
  console.debug('logout saga');
  //yield put(push('/login'));
  window.location.href = '/';
}

export function* loadCustomerContactsRequest(action) {

  const contacts = yield select(selectCustomerContacts());
  if (contacts.length != 0) {
    yield put(customerContactsLoaded(contacts));
    return;
  }

  const requestURL = `${config.webApiUrl}/customer/getContacts`;
  const alertRequestURL = `${config.webApiUrlAlerts}`;

  try {
    // Call our request helper (see 'utils/request')
    const repos = yield call(request.getAuth, requestURL);
    yield put(customerContactsLoaded(repos));

    // get notifications and add to userOptions
    const customerPref = yield select(selectCustomerPreferenceDto());
    const customerId = customerPref.customerId;
    const notificationsURL = `${alertRequestURL}/Alerts/getNotificationsForCustomer?customerId=${customerId}`;
    const notifications = yield call(request.getAuth, notificationsURL);
    yield put(customerNotificationsLoaded(notifications));
  }
  catch (err) {
    //yield put(ordersLoadingError(err));
    console.error(err);
    yield put(showAppError(err));
  }
}

export function* removeNotification(action) {
  try {
    const notificationsURL = `${config.webApiUrlAlerts}/Alerts/removeNotificationForCustomer`;
    const removed = yield call(request.postAuth, notificationsURL, action.item);
    yield put(customerNotificationsRemovedSuccess(removed));
  }
  catch (err) {
    yield put(showAppError(err));
  }
}

export function* removeAllNotificationsRequest(action) {
  try {
    const notificationsURL = `${config.webApiUrlAlerts}/Alerts/removeAllNotificationsForCustomer`;
    yield call(request.postAuth, notificationsURL, action.customerId);
    yield put(customerNotificationsRemovedAllSuccess());
  }
  catch (err) {
    yield put(showAppError(err.msg != undefined ? err.msg : err.message));
  }
}

export function* removeNotificationSaga() {
  yield takeLatest(APP_MENU_NOTIFICATIONS_REMOVE_ITEM, removeNotification);
}
export function* loadCustomerContactsSaga() {
  yield takeLatest(LOAD_CUSTOMER_CONTACTS, loadCustomerContactsRequest);
}
export function* removeAllNotificationsSaga() {
  yield takeLatest(APP_MENU_NOTIFICATIONS_REMOVE_ALL_ITEMS, removeAllNotificationsRequest);
}
export function* logoutUserSaga() {
  yield takeLatest(APP_USER_LOGOUT, logoutUser);
}
// Bootstrap sagas
export default function* appSaga() {
  // Non-blocking fork effects
  // https://redux-saga.js.org/docs/advanced/RootSaga
  // rootSaga will terminate on the first error in any individual child effect or saga and crash your whole app! 
  // this is not so bad but we have to appropriate error handling
  yield fork(removeNotificationSaga);
  yield fork(loadCustomerContactsSaga);
  yield fork(removeAllNotificationsSaga);
  yield fork(logoutUserSaga);
  yield fork(loadQueryDetailsSaga); // start it here because i cannot cancel it on page and it is processed in increasing number on every page load
  yield fork(replyToQueryRequestSaga); // see above
  yield fork(reopenQuerySaga); // see above
  yield fork(uploadRequestWatcherSaga); // see above
}