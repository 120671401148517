
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Insurance = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path d="M16 3c4.87 2.43 7.48 0 7.48 2.52 0 6.35-6.87 18-11.5 18C8.61 23.47.5 11.86.5 5.51c0-2.51 2.64.22 7.81-2.58A16.11 16.11 0 0 0 12 .5s.71.83 4 2.5zm-2.79 8.57a30.26 30.26 0 0 1-1.07 8.77c-.14 0 .56-3.93.56-8.77S12 2.8 12.14 2.8a30.27 30.27 0 0 1 1.09 8.75z"/>
      </g>
  </SvgIconEx>
);
Insurance.displayName = 'Insurance';
Insurance.muiName = 'SvgIcon';

export default Insurance;