import React from 'react';
import {Link} from 'react-router-dom';

let AddressColumnTemplate = (props) => {
  var datacontext = props.datacontext;
  if (datacontext == undefined) {
    return null;
  }

  var row = props.row || {};
  var path = row.orderId ? '/order/' + row.orderId : '/quote/' + row.id;
  return (
    <div>
      <Link to={path} className="text-link">{datacontext.line1}</Link>
      <div className="text-user">{datacontext.createdBy}
        { row.orderId ?
          <Link style={{marginLeft: 10}} to={path} className="text-link">{`(Order ${row.orderId})`}</Link>
          : null
        }
      </div>
    </div>
  );
}

export default AddressColumnTemplate;
