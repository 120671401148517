import React from 'react';
import { Grid } from '../Grid'
import StarColumnTemplate from './StarColumnTemplate';
import ProductColumnTemplate from './ProductColumnTemplate';
import DocumentColumnTemplate from './DocumentColumnTemplate';
import {formatPrice} from '../../utils/format';

export default class GridDiscountPackages extends React.Component{
    constructor(props) {
        super(props);
        this.rowTemplateResolver=this.rowTemplateResolver.bind(this);
    }

    rowTemplateResolver(rowData){
        /*<!-- Add class 'selected' when product is selected, remove it when it's unselected -->*/

        /*var cellState = this.props.queryGridNavigationColumnState[rowData.queryId];
        if(cellState && cellState.state == 'open'){
            return 'row-open';
        }*/
        var isSelected = this.props.selectedPackages[rowData['id']];
        if(isSelected){
            return 'selected';
        }
        return '';
    }

    render(){
        return(
            <Grid 
                  datasource = {this.props.datasource}
                  tableClassName ={this.props.tableClassName}
                  columnsData={[
                    { 
                        header: 'Product',
                        style: {width: '*', textAlign: 'left'},
                        dataCellTemplate: (props)=>(<ProductColumnTemplate {...props} gridName={this.props.name}
                                                                            activeCategoryTab = {this.props.activeCategoryTab}
                                                                            selectPackageAction = {this.props.selectPackageAction}
                                                                            selectedPackages={this.props.selectedPackages}/>)
                    },
                    { 
                        header: 'Days',
                        dataField: 'packageSLA', 
                        style: {width: 40, textAlign: 'right'},
                        className: 'hide-mobile',
                        dataCellTemplate: (props)=>(<div className="text-product"> {props.datacontext == 0 ? 'NaN' : props.datacontext}</div>)
                    },
                    { 
                        header: 'Old Price (Inc VAT)',
                        dataField: 'priceWithVAT', 
                        style: {width: 120, textAlign: 'right'},
                        dataCellTemplate: (props)=>(<div className="text-price-old">{formatPrice(props.datacontext)}</div>)
                    },
                    { 
                        header: 'New Price (Inc VAT)',
                        dataField: 'finalPrice', 
                        style: {width: 120, textAlign: 'right'},
                        dataCellTemplate: (props)=>{
                            //var discount = props.row['discountAmount'];
                            return (<div className="text-price">{formatPrice(props.datacontext)}</div>);
                        }
                    }
                  ]}
                  
                  // use this prop if you want to set class to <tr> 
                  rowClassNameResolver= {this.rowTemplateResolver}>
            </Grid>
        );
    }
}