
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Cart = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <circle cx="19" cy="22" r="1.5"/><circle cx="8" cy="22" r="1.5"/><path d="M.5 3.5H4l4 17h11"/><path d="M7.529 18.5H20.5l3-9H5.412"/>
      </g>
  </SvgIconEx>
);
Cart.displayName = 'Cart';
Cart.muiName = 'SvgIcon';

export default Cart;