import { call, put, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { UploadActions, uploadProgress, uploadSuccess, uploadFailure, uploadDeleteSuccess, uploadDeleteFailure } from './actions';
import { createUploadFileChannel } from './createUploadFileChannel';
import { saveIsBusy } from '../../containers/CreateOrder/actions';
import config from '../../utils/config';
import request from '../../utils/request';

// Upload the specified file
//export function* uploadFileSaga(file, objectId, attachmentType, uploadChannel, options) {
export function* uploadFileRequest(action) {
    yield put(saveIsBusy(true));
    const { file, objectId, attachmentType, uploadChannel, options } = action;
    const requestURL = `${config.webApiUrl}/file/upload`;
    const channel = yield call(createUploadFileChannel, requestURL, file, objectId, attachmentType, options);
    while (true) {
        const { progress = 0, err, success, response } = yield take(channel);
        if (err) {
            yield put(saveIsBusy(false));
            yield put(uploadFailure(file.name, objectId, uploadChannel, err));
            return;
        }
        if (success) {
            yield put(saveIsBusy(false));
            yield put(uploadSuccess(file.name, objectId, response[0].path, uploadChannel, options || { isNotDownloadable: false }));
            return;
        }
        yield put(uploadProgress(file.name, objectId, progress, uploadChannel));
    }
}

// Watch for an upload request and then
// defer to another saga to perform the actual upload
export function* uploadRequestWatcherSaga() {
    yield takeEvery(UploadActions.UPLOAD_REQUEST, uploadFileRequest);
}

//
// API call to delete file
// this saga is used by OrderDetails page to remove file
export function* deleteFileRequest(action) {
    const requestURL = `${config.webApiUrl}/order/deleteAttachment/${action.objectId}/${action.fileName}`;
    try {
        // Call our request helper (see 'utils/request')
        const order = yield call(request.getAuth, requestURL);
        yield put(uploadDeleteSuccess(action.fileName, action.uploadChannel));
    }
    catch (err) {
        yield put(uploadFailure({ file: { name: action.fileName } }, action.objectId, action.uploadChannel, err));
    }
}

export function* uploadDeleteRequestWatcherSaga() {
    yield takeLatest(UploadActions.UPLOAD_DELETE_REQUEST, deleteFileRequest);
}

//
// API call to delete temporary file
// when user creates order it can upload files which are temporary. If user abondon order these files should be removed
export function* deleteTempFileRequest(action) {
    const requestURL = `${config.webApiUrl}/file/delete?filePath=${encodeURIComponent(action.filePath)}`;
    try {
        // Call our request helper (see 'utils/request')
        const order = yield call(request.getAuth, requestURL);
        yield put(uploadDeleteSuccess(action.fileName, action.uploadChannel));
    }
    catch (err) {
        yield put(uploadDeleteFailure(action.fileName, -1, action.uploadChannel, err));
    }
}

export function* uploadDeleteTempFileRequestWatcherSaga() {
    yield takeLatest(UploadActions.UPLOAD_DELETE_TEMP_FILE_REQUEST, deleteTempFileRequest);
}
