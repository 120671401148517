export function formatPrice(price) {
    if (price == null || price == "" || isNaN(price)) {
        return "POA";
    }

    return "£" + parseFloat(price).toFixed(2);
}

export function encodeValueChars(str) {
    if (str == null) {
        return "";
    }

    return encodeURIComponent(str).
        // Note that although RFC3986 reserves "!", RFC5987 does not,
        // so we do not need to escape it
        replace(/['()*]/g, c => "%" + c.charCodeAt(0).toString(16)). // i.e., %27 %28 %29 %2A
        // The following are not required for percent-encoding per RFC5987,
        // so we can allow for a little better readability over the wire: |`^
        replace(/%(7C|60|5E)/g, (str, hex) => String.fromCharCode(parseInt(hex, 16)));
}

//export function isPostalCode(inputValue) {
//    if (inputValue) {
//        inputValue = inputValue.trim();

//        var postCodeParts = inputValue.split(' ');
//        var partsCount = postCodeParts.length;

//        if (postCodeParts && partsCount > 0 && partsCount < 3) {
//            var part1 = postCodeParts[0];
//            var exp1 = /^[A-Z]{1,2}(?=\d|$)\d?[A-Z|\d]?$/i;
//            var res1 = part1.match(exp1);
//            if (res1) {
//                if (partsCount == 1) {
//                    return true;
//                } else {
//                    var part2 = postCodeParts[1];
//                    var expr2 = /^\d[A-Z]{0,2}?$/i;
//                    var res2 = part2.match(expr2);
//                    if (res2) {
//                        return true;
//                    }
//                }
//            }
//        }
//    }

//    return false;
//}

export function guid() {
    let r = (Math.random() + 1).toString(36).substring(7);
    return r;
}