import React from 'react';
import {Grid} from '../../components/Grid';
import { connect } from 'react-redux';
import { selectPackagesInOrder, selectProductCancelButtonState } from './selectors';
import { createStructuredSelector } from 'reselect';
import ExpDateCellTemplate from '../../components/GridCommon/ExpDateCellTemplate';
import ProductColumnTemplate from './components/ProductColumnTemplate';
import StatusColumnTemplate from './components/StatusColumnTemplate';
import ActionColumnTemplate from './components/ActionColumnTemplate';
import {formatPrice} from '../../utils/format';
import {loadPackageReports, cancelProduct} from './actions';

class Products extends React.PureComponent{
    constructor(props) {
        super(props);
        this.handleLoadReports = this.handleLoadReports.bind(this);
    }

    handleLoadReports(packageId){
        var orderId = this.props.order.orderId;
        this.props.loadPackageReports(orderId, packageId);
    }

    handleProductCancel = (product) =>{
        const item = {
            orderId: this.props.order.orderId,
            packageId: product.id,
            dueDate: product.dueDate
        };

        this.props.cancelProductAction(item);
    }

    renderGrid(){
        //{/*this.props.datasource}*/}
      var status = this.props.order.status;
        return (
            <Grid
                tableClassName='content-grid products-grid'
                datasource={this.props.products}
                columnsData={[
                    {
                        header: 'Due',
                        className: 'cell-sort',
                        style: {width: 60},
                        dataField: 'dueDate',
                        dataCellTemplate: (props)=>(<ExpDateCellTemplate {...props} orderStatus={status} />)
                    },
                    {
                        dataField: 'name',
                        header: 'Product',
                        style: {width: '*'},
                        className : 'cell-sort',
                        dataCellTemplate: (props)=>(<ProductColumnTemplate {...props} />)
                    },
                    {
                        dataField: 'statusString',
                        header: 'Status',
                        style: {width: 120},
                        className: 'hide-mobile',
                        dataCellTemplate: (props)=>(<StatusColumnTemplate {...props}/>)
                    },
                    {
                        dataField: 'price',
                        header: 'Price (Inc Vat)',
                        style: {width: 110, textAlign: 'right'},
                        className: 'hide-mobile',
                        dataCellTemplate: (props)=> (<div className="text-price">{formatPrice(props.datacontext)}</div>)
                    },
                    {
                        style: {width: 180},
                        className: 'align-right row-actions',
                        dataCellTemplate: (props) => {
                          return <ActionColumnTemplate {...props}
                            handleLoadReports = {this.handleLoadReports}
                            handleProductChase = {this.props.handleProductChase}
                            handleProductCancel = {this.handleProductCancel}
                            productChaseButtonState = {this.props.productChaseButtonState}
                            productCancelButtonState = {this.props.productCancelButtonState}/>
                        }
                    }
                ]}>
            </Grid>);
    }

    render(){

        return(
                <div className="content-block-grid">
                    {this.renderGrid()}
                </div>
            );
    }
}


const mapStateToProps = createStructuredSelector({
    products: selectPackagesInOrder(),
    productCancelButtonState: selectProductCancelButtonState()
})
const mapDispatchToProps=({
    loadPackageReports,
    cancelProductAction: cancelProduct
})
// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(Products);