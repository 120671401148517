export const LOAD_QUERY_PAGED_LIST_REQUEST = 'atlas/Queries/LOAD_QUERY_PAGED_LIST_REQUEST';
export const LOAD_QUERY_PAGED_LIST_SUCCESS = 'atlas/Queries/LOAD_QUERY_PAGED_LIST_SUCCESS';
export const LOAD_QUERY_DETAILS = 'atlas/Query/LOAD_QUERY_DETAILS';

export const SHOW_QUERY_REPLAY = 'atlas/Query/SHOW_QUERY_REPLAY';
export const HIDE_QUERY_REPLAY = 'atlas/Query/HIDE_QUERY_REPLAY';

export const CUSTOMER_REPLY_TO_QUERY = 'atlas/Query/CUSTOMER_REPLY_TO_QUERY';
export const CUSTOMER_REOPEN_QUERY = 'atlas/Query/CUSTOMER_REOPEN_QUERY';
export const CUSTOMER_REOPEN_QUERY_SUCCESS = 'atlas/Query/CUSTOMER_REOPEN_QUERY_SUCCESS';
export const CHANGE_REPLY_TEXT_IN_QUERY =  'atlas/Query/CHANGE_REPLY_TEXT_IN_QUERY'; 
export const DELETE_QUERY_FILE =  'atlas/Query/DELETE_QUERY_FILE'; 
export const QUERY_SWITCH_OPEN_CLOSE = 'atlas/Query/QUERY_SWITCH_OPEN_CLOSE';
export const SET_QUERY_GRID_DATA_SOURCE = 'atlas/Query/SET_QUERY_GRID_DATA_SOURCE';
export const QUERY_DETAILS_SHOW_EARLIER_MSGS = 'atlas/Query/QUERY_DETAILS_SHOW_EARLIER_MSGS';
export const QUERY_STORE_FILTER_INFO = 'atlas/Query/QUERY_STORE_FILTER_INFO';