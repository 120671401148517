import { takeLatest, call, put, select, fork, cancel } from 'redux-saga/effects';
import { LOAD_QUERY_DETAILS, CUSTOMER_REPLY_TO_QUERY, LOAD_QUERY_PAGED_LIST_REQUEST, CUSTOMER_REOPEN_QUERY } from './constants';
import { showQueryReplyRow, hideQueryReplyRow, loadQueryListSuccess, setQueryDataSource, customerReopenQuerySuccess } from './actions';
import { showAppError } from 'containers/App/actions';
import config from 'utils/config';
import request from 'utils/request';

//
// API call to `/api/query/loadList`. This will return query details.
// on success we will open the row in Query Grid
export function* loadQueryPagedListRequest(action) {

  var queryStatus = action.values.queryStatus;// == 'close';
  var startIndex = action.values.startIndex || 0;
  var pageSize = action.values.pageSize || 10;
  var createdBy = action.values.createdBy || null;
  var dateCreated = action.values.dateCreated || null;
  var queryWaitFor = action.values.queryWaitFor || null;
  var filterText = action.values.filterText || null;
  var gridName = action.values.gridName || null;

  if (gridName == undefined) {
    throw new Error('grid name is not defined');
  }

  var requestURL = `${config.webApiUrl}/query/loadPagedList?loadClosed=${queryStatus}&startIndex=${startIndex}&pageSize=${pageSize}&createdBy=${createdBy}&dateCreated=${dateCreated}&queryWaitFor=${queryWaitFor}&filterText=${filterText}`;
  try {
    const data = yield call(request.getAuth, requestURL);
    yield put(loadQueryListSuccess(data));
    yield put(setQueryDataSource(gridName, data));
  }
  catch (err) {
    //yield put(dashboardLoadingError(err));
    yield put(showAppError(err));
  }
}

export function* loadQueryListSaga() {
  yield takeLatest(LOAD_QUERY_PAGED_LIST_REQUEST, loadQueryPagedListRequest);
}

//
// API call to /api/query/1`. This will return query details.
// on success we will open the row in Query Grid
export function* loadQueryDetailsRequest(action) {

  console.log("start query request");
  const requestURL = `${config.webApiUrl}/query/${action.queryId}`;
  try {
    const data = yield call(request.getAuth, requestURL);
    yield put(showQueryReplyRow(action.rowTemplateKey, action.gridName, action.queryId, data));
  }
  catch (err) {
    //yield put(dashboardLoadingError(err));
    yield put(showAppError(err));
  }
}

export function* loadQueryDetailsSaga() {
  yield takeLatest(LOAD_QUERY_DETAILS, loadQueryDetailsRequest);
}

//
// API call to `/api/query/reply`. This will create new query details.
// on success we will close the row in Query Grid
export function* replyToQueryRequest(action) {

  const requestURL = `${config.webApiUrl}/query/reply`;
  try {
    const data = yield call(request.postAuth, requestURL, action.queryDto);
    yield put(hideQueryReplyRow(action.gridName, data.queryId, data));
  }
  catch (err) {
    console.error(err);
    yield put(hideQueryReplyRow(action.gridName, action.queryDto.queryId));
    yield put(showAppError(err));
  }
}

export function* replyToQueryRequestSaga() {
  yield takeLatest(CUSTOMER_REPLY_TO_QUERY, replyToQueryRequest);
}

//
// API call to `/api/query/reopen`. This will reopen query details.
// on success we will close the row in Query Grid
export function* reopenQueryRequest(action) {

  const requestURL = `${config.webApiUrl}/query/reopen`;
  try {
    const data = yield call(request.postAuth, requestURL, action.queryDto);
    yield put(customerReopenQuerySuccess(action.gridName, data.queryId, data));
  }
  catch (err) {
    console.error(err);
    yield put(hideQueryReplyRow(action.gridName, action.queryDto.queryId));
    yield put(showAppError(err));
  }
}

export function* reopenQuerySaga() {
  yield takeLatest(CUSTOMER_REOPEN_QUERY, reopenQueryRequest);
}

// Bootstrap sagas
export default function* rootSaga() {
  // Non-blocking fork effects
  // https://redux-saga.js.org/docs/advanced/RootSaga
  // rootSaga will terminate on the first error in any individual child effect or saga and crash your whole app! 
  // this is not so bad but we have to appropriate error handling
  //yield fork(replyToQueryRequestSaga);
  //yield fork(uploadRequestWatcherSaga);
  yield fork(loadQueryListSaga);
  //yield fork(reopenQuerySaga);
}