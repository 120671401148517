
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Mobile = (props) => (
  <SvgIconEx  {...props}>
      <g strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <circle cx="3" cy="12" r="2.5"/><circle cx="12" cy="12" r="2.5"/><circle cx="21" cy="12" r="2.5"/>
      </g>
  </SvgIconEx>
);
Mobile.displayName = 'Mobile';
Mobile.muiName = 'SvgIcon';

export default Mobile;