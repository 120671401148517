import { takeLatest, call, put, delay, fork } from 'redux-saga/effects';
import {
  LOAD_ORDER_DETAILS, OpenQueryGridName,
  ClosedQueryGridName, ORDER_CHASE, PACKAGE_CHASE, PACKAGE_CANCEL, LOAD_PACKAGE_REPORTS,
  ORDER_DETAILS_ADD_SELECTED_PACKAGES_TO_ORDER, ORDER_CANCEL,
  ORDER_DETAILS_SUBMIT_RAISE_QUERY
} from './constants';
import {
  loadOrder, orderLoaded, orderLoadingError,
  setAlertDatasource,
  chaseOrderSuccess, chaseOrderFail,
  loadPackageReportsSuccess, loadPackageReportsError,
  chasePackageSuccess, chasePackageFail, chaseOrderHideLabel, chasePackageHideLabel,
  addSelectedPackagesToOrderSuccess, cancelPackageSuccess, cancelPackageFail,
  submitQuerySucceed, submitQueryFail, cancelOrderSuccess, cancelOrderFail
} from './actions';
import { showAppError } from 'containers/App/actions';
import config from 'utils/config';
import request from 'utils/request';
//import { replyToQueryRequestSaga } from '../Queries/sagas';
import { uploadDeleteRequestWatcherSaga } from '../../components/UploadFile/sagas';
import { setQueryDataSource } from '../Queries/actions';
import { uploadInit } from '../../components/UploadFile/actions';
import { OrderDetailsUploadChannel } from './constants';
//
// API call to load order data 
//
export function* loadOrderDetailsRequest(action) {
  const requestURL = `${config.webApiUrl}/order/${action.orderId}`;
  const alertRequestURL = `${config.webApiUrlAlerts}/Alerts/getAlertsForOrder?orderId=${action.orderId}`;
  try {
    // Call our request helper (see 'utils/request')
    const order = yield call(request.getAuth, requestURL);
    yield put(orderLoaded(order));
    yield put(setQueryDataSource(OpenQueryGridName, { queries: order.queries }));
    yield put(setQueryDataSource(ClosedQueryGridName, { queries: order.closedQueries }));
    yield put(uploadInit(order.attachments, OrderDetailsUploadChannel));
    const alertsString = yield call(request.getAuth, alertRequestURL);

    //// console.log(alertsString);
    // create dictionary from order's packages
    var packageDict = {};
    order.packages.forEach(function (element) {
      packageDict[element.id] = true;
    });

    // push only those alerts which are not selected yet
    var filteredAlerts = [];
    var allerts = JSON.parse(alertsString);
    allerts.forEach(function (element) {
      if (packageDict[element.id] !== true) {
        filteredAlerts.push(element);
      }
    });
    yield put(setAlertDatasource(filteredAlerts));
  }
  catch (err) {
    // console.log('error');
    // console.log(err);
    yield put(orderLoadingError(err));
    yield put(showAppError(err));
  }
}

export function* loadOrderSaga() {
  yield takeLatest(LOAD_ORDER_DETAILS, loadOrderDetailsRequest);
}
//
// API call to get report files
//
export function* loadReportsRequest(action) {
  const requestURL = `${config.webApiUrl}/order/getReports/${action.orderId}/${action.packageId}`;
  try {
    // Call our request helper (see 'utils/request')
    const reports = yield call(request.getAuth, requestURL);
    yield put(loadPackageReportsSuccess(reports));
  }
  catch (err) {
    yield put(loadPackageReportsError(err));
    yield put(showAppError(err));
  }
}

export function* loadReportsSaga() {
  yield takeLatest(LOAD_PACKAGE_REPORTS, loadReportsRequest);
}

//
// Chase order saga
//
export function* chaseOrderSaga() {
  yield takeLatest(ORDER_CHASE, chaseOrderRequest);
}

export function* chaseOrderRequest(action) {
  const requestURL = `${config.webApiUrl}/order/chase/`;
  const queryUrl = `${config.webApiUrl}/query/getOpenQueriesForOrder?orderId=${action.info.orderId}`;
  const query = {
    orderId: action.info.orderId,
    dueDate: action.info.dueDate
  };
  try {

    const result = yield call(request.postAuth, requestURL, query);
    yield put(chaseOrderSuccess(query.orderId));
    var queries = yield call(request.getAuth, queryUrl);
    yield put(setQueryDataSource(OpenQueryGridName, { queries: queries }));
    yield delay(1000);
    yield put(chaseOrderHideLabel(query.orderId));
    chasePackageHideLabel
  }
  catch (err) {
    console.log(err);
    yield put(chaseOrderFail(query.orderId));
    yield put(showAppError(err));
  }
}

//
// Cancel order saga
//
export function* cancelOrderSaga() {
  yield takeLatest(ORDER_CANCEL, cancelOrderRequest);
}

export function* cancelOrderRequest(action) {
  const requestURL = `${config.webApiUrl}/order/cancel`;
  const queryUrl = `${config.webApiUrl}/query/getOpenQueriesForOrder?orderId=${action.item.orderId}`;
  try {

    const result = yield call(request.postAuth, requestURL, action.item);
    var queries = yield call(request.getAuth, queryUrl);
    yield put(cancelOrderSuccess(action.item.orderId));
    yield put(setQueryDataSource(OpenQueryGridName, { queries: queries }));
  }
  catch (err) {
    yield put(cancelOrderFail(action.item.orderId));
    yield put(showAppError(err));
  }
}

//
// Chase package saga
//
export function* chasePackageSaga() {
  yield takeLatest(PACKAGE_CHASE, chasePackageRequest);
}

export function* chasePackageRequest(action) {
  const requestURL = `${config.webApiUrl}/package/chase/`;
  const queryUrl = `${config.webApiUrl}/query/getOpenQueriesForOrder?orderId=${action.item.orderId}`;
  try {

    const result = yield call(request.postAuth, requestURL, action.item);
    var queries = yield call(request.getAuth, queryUrl);
    yield put(chasePackageSuccess(action.item.packageId));
    yield put(setQueryDataSource(OpenQueryGridName, { queries: queries }));
    yield delay(1000);
    yield put(chasePackageHideLabel(action.item.packageId));
  }
  catch (err) {
    // console.log(err);
    yield put(chasePackageFail(action.item.packageId));
    yield put(showAppError(err));
  }
}


//
// Cancel package saga
//
export function* cancelPackageSaga() {
  yield takeLatest(PACKAGE_CANCEL, cancelPackageRequest);
}

export function* cancelPackageRequest(action) {
  const requestURL = `${config.webApiUrl}/package/cancel`;
  const queryUrl = `${config.webApiUrl}/query/getOpenQueriesForOrder?orderId=${action.item.orderId}`;
  try {

    const result = yield call(request.postAuth, requestURL, action.item);
    var queries = yield call(request.getAuth, queryUrl);
    yield put(cancelPackageSuccess(action.item.packageId));
    yield put(setQueryDataSource(OpenQueryGridName, { queries: queries }));
    //yield delay(1000);
    ////yield put(chasePackageHideLabel(action.item.packageId));
  }
  catch (err) {
    yield put(cancelPackageFail(action.item.packageId));
    yield put(showAppError(err));
  }
}

//
// Adds selected packages(alerts) to order
//
export function* addSelectedPackagesRequest(action) {
  const requestURL = `${config.webApiUrl}/order/addPackages`;
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request.postAuth, requestURL, action.payload);
    yield put(addSelectedPackagesToOrderSuccess(action.payload, result.extraAmount));
    yield put(loadOrder(action.payload.orderId));
    // todo:  
    //window.location.href=`/order/${action.payload.orderId}`
  }
  catch (err) {
    yield put(showAppError(err));
  }
}

export function* addSelectedPackagesSaga() {
  yield takeLatest(ORDER_DETAILS_ADD_SELECTED_PACKAGES_TO_ORDER, addSelectedPackagesRequest);
}


//
// Creates new query which user creates using 'Raise Query' button
//
export function* createQueryRequest(action) {
  const requestURL = `${config.webApiUrl}/query/addQuery`;
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request.postAuth, requestURL, action.queryDto);
    yield put(submitQuerySucceed(action.gridName, result));
  }
  catch (err) {
    yield put(showAppError(err));
    yield put(submitQueryFail(action.gridName, action.queryDto));
  }
}

export function* createQuerySaga() {
  yield takeLatest(ORDER_DETAILS_SUBMIT_RAISE_QUERY, createQueryRequest);
}

// Bootstrap sagas
export default function* rootSaga(){
  yield fork(loadOrderSaga);
  //yield fork(replyToQueryRequestSaga);
  //yield fork(uploadRequestWatcherSaga);
  yield fork(uploadDeleteRequestWatcherSaga);
  yield fork(chaseOrderSaga);
  yield fork(chasePackageSaga);
  yield fork(cancelPackageSaga);
  yield fork(loadReportsSaga);
  yield fork(addSelectedPackagesSaga);
  yield fork(createQuerySaga);
  yield fork(cancelOrderSaga);
}
