import React from 'react';
import { calculateSLADate } from './slaFunctions';
import keys from 'lodash/keys';

export default class CompletionDate extends React.PureComponent {

    render() {

        if(keys(this.props.allPackages).length==0){
            return null;
        }
        
        const slaDate = calculateSLADate(this.props.selectedPackageIds, this.props.allPackages);

        return (
            <div className="details-aside-date">
                <strong>Estimated Completion</strong>
                <span className="date-badge">
                    <span className="month">{slaDate.format('MMM')}</span>
                    <span className="day">{slaDate.date()}</span>
                </span>
            </div>
        );
    }
}
