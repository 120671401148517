import React from 'react';
import MenuItem from './menuItem';
import LogoutItem from './LogoutItem';
import NotificationItem from './NotificationItem';

export default function NavigationMenu(props) {
  return (
    <ul className="navbar-menu">
      <MenuItem label='Overview' toUrl='/' badge={0} />
      <MenuItem label='Quotes' toUrl='/quotes' badge={0} />
      <MenuItem label='Orders' toUrl='/orders' badge={0} />
      <MenuItem label='Queries' toUrl='/queries' badge={0} />
      <MenuItem label='Preferences' toUrl='/preferences' badge={0} />
      <NotificationItem onClick={props.showNotificationAction} receiveWebAlerts={props.receiveWebAlerts} notifications={props.notifications} />
      <LogoutItem logout={props.logout} />
    </ul>
  );
}
