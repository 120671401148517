import React from 'react';
import ReactGA from 'react-ga4';
import TextField from '../../components/TextField';
import Form from '../../components/Form';
import AlertMsg from './alertMsg';
import { Link } from 'react-router-dom';

export default class LoginForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleUserNameChanged = this.handleUserNameChanged.bind(this);
        this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
        this.state = {
            userName: '',
            password: ''
        }
    }

    componentDidMount() {
        this.setState({
            userName: '',
            password: ''
        });


    }
    handleLogin(event) {
        if (this.state.userName == '')
            return;

        if (this.state.password == '')
            return;

        this.props.loginUserAction(this.state.userName, this.state.password, this.props.returnUrl, this.props.navigate);
        // Action tracking with GA
        ReactGA.event({
            category: 'User Actions',
            action: 'User login',
        });
    }

    handleUserNameChanged(event, value) {
        this.setState({ userName: value });
    }

    handlePasswordChanged(event, value) {
        this.setState({ password: value });
    }

    handleResetPswd = () => {
        this.props.changeViewAction("reset");
    }

    render() {
        return (
            <Form onSubmit={this.handleLogin} id="login-form" className="form user-form login-form">
                <div className="user-form-title">
                    <h1>Sign in to your account</h1>
                    {
                        this.props.showErrorMsg ? <AlertMsg /> : null
                    }
                </div>

                <TextField placeholder="Enter your username" label='Username' inputClassName='form-control-grey'
                    value={this.state.userName} onChange={this.handleUserNameChanged} isRequired={true} />

                <TextField type='password' placeholder="Enter your password" label='Password' inputClassName='form-control-grey'
                    value={this.state.password} onChange={this.handlePasswordChanged} isRequired={true} />
                {this.props.isLoading == true
                    ?
                    <div className="loader"></div>
                    :
                    <div className="actions">
                        <button type="submit" className="button button-large button-green">Sign In</button>
                        <Link style={{ cursor: 'pointer' }} onClick={this.handleResetPswd} className="text-button color-red">Forgot your password?</Link>
                    </div>
                }
            </Form>
        );
    }
}
