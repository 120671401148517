
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let AlertTriangle = (props) => (
  <SvgIconEx  {...props}>
      <g fill={props.fill} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path d="M19.64 16.36L11.53 2.3A1.85 1.85 0 0 0 10 1.21 1.85 1.85 0 0 0 8.48 2.3L.36 16.36C-.48 17.81.21 19 1.88 19h16.24c1.67 0 2.36-1.19 1.52-2.64zM11 16H9v-2h2zm0-4H9V6h2z"/>
      </g>
  </SvgIconEx>
);
AlertTriangle.displayName = 'AlertTriangle';
AlertTriangle.muiName = 'SvgIcon';

export default AlertTriangle;
