
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let BackArrow = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path d="M18.5.5L5.5 12l13 11.5"/>
      </g>
  </SvgIconEx>
);
BackArrow.displayName = 'BackArrow';
BackArrow.muiName = 'SvgIcon';

export default BackArrow;