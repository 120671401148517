/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const LOGIN_USER = 'atlas/Login/LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'atlas/Login/LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'atlas/Login/LOGIN_USER_ERROR';
export const REMIND_ME = 'atlas/Login/REMIND_ME';
export const REMIND_ME_SUCCESS = 'atlas/Login/REMIND_ME_SUCCESS';
export const REMIND_ME_ERROR = 'atlas/Login/REMIND_ME_ERROR';
export const CHANGE_VIEW = 'atlas/Login/CHANGE_VIEW';
export const INIT_LOGIN_PAGE = 'atlas/Login/INIT_LOGIN_PAGE';
export const STORE_CUSTOMER_OPTIONS = 'atlas/Login/STORE_CUSTOMER_OPTIONS';
export const LOGIN_PAGE_SHOW_NOTIFICATION = 'atlas/Login/LOGIN_PAGE_SHOW_NOTIFICATION';