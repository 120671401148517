import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { loadOrderConfirmation } from './actions';
import { loadCustomerContacts } from '../App/actions';
import { Link, useParams } from 'react-router-dom';
import { useInjectSaga } from 'utils/injectSaga';
import rootSaga from './sagas';
import OrderConfirmationInternal from './index';

export default function CreateOrderConfirmation(props) {
    useInjectSaga({ key: 'order-confirmation', saga: rootSaga });

    const dispatch = useDispatch();
    const loadOrderConfirmationAction = useCallback((orderId) => dispatch(loadOrderConfirmation(orderId)), [dispatch]);
    const loadCustomerContactsAction = useCallback((info) => dispatch(loadCustomerContacts(info)), [dispatch]);

    // static defaultProps = {
    //     isQuote: false// }


    let { orderId } = useParams();
    useEffect(() => {
        loadOrderConfirmationAction(orderId);
        loadCustomerContactsAction();
    }, []);

    return <OrderConfirmationInternal iisQuote={false} orderId={orderId}/>
}