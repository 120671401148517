import findIndex from 'lodash/findIndex';
import { produce } from "immer";
import {
    CUSTOMER_REPLY_TO_QUERY,
    SHOW_QUERY_REPLAY,
    HIDE_QUERY_REPLAY,
    SET_QUERY_GRID_DATA_SOURCE,
    CUSTOMER_REOPEN_QUERY_SUCCESS
} from './constants';

import { ORDER_DETAILS_RAISE_QUERY, ORDER_DETAILS_RAISE_QUERY_CANCELLED, ORDER_DETAILS_SUBMIT_RAISE_QUERY_SUCCESS } from '../OrderDetails/constants';
import { createQueryDetailsReducer } from './queryDetailsReducer';
import { combineReducers } from 'redux';

const initialState = {
    items: []
}

export function createQueryReducer(gridName) {

    return function queryGridReducer(state = initialState, action) {
        if (gridName != action.gridName) {
            return state;
        }
        
        var newList = state.items || [];
        return produce(state, (draft) => {
            switch (action.type) {
                case SET_QUERY_GRID_DATA_SOURCE:
                    draft.items = action.queryList.queries;
                    draft.totalQueriesCount = action.queryList.queriesCount;
                    break;

                case ORDER_DETAILS_RAISE_QUERY:
                    var newQuery = {
                        rowTemplateKey: action.rowTemplateKey,
                        data: { /*<<<data context for template*/
                            queryId: -1,
                            orderId: action.orderId,
                            queryDetails: []
                        }/*action.data*/
                    };
                    draft.items.splice(0, 0, newQuery);
                    break;

                case SHOW_QUERY_REPLAY:
                    var rowIndex = findIndex(newList, { 'queryId': action.queryId });
                    draft.items.splice(rowIndex + 1, 0, { rowTemplateKey: action.rowTemplateKey, data: action.data }/*<<<data context for template*/);
                    break;
                
                case CUSTOMER_REOPEN_QUERY_SUCCESS:
                    var rowIndex = findIndex(newList, { 'queryId': action.queryId });
                    // next row should be open query template
                    draft.items[rowIndex+ 1].data.status="Reopen"/*<<<data context for template*/;
                    break;

                case CUSTOMER_REPLY_TO_QUERY:
                    var rowIndex1 = findIndex(newList, { 'queryId': action.queryDto.queryId });
                    draft.items[rowIndex1].needCustomerReply = false; // reset flag
                    break;

                case HIDE_QUERY_REPLAY:
                case ORDER_DETAILS_RAISE_QUERY_CANCELLED:
                    var rowIndex2 = findIndex(newList, { 'queryId': action.queryId });
                    draft.items.splice(rowIndex2 + 1, 1);
                    break;

                case ORDER_DETAILS_SUBMIT_RAISE_QUERY_SUCCESS:
                    // remove details row
                    draft.items.splice(0, 1);

                    // add new query data
                    draft.items.splice(0, 0, action.queryDto);
                    break;
            }
        });
    }
}

export function create(gridName) {
    var red = {
        dataSource: createQueryReducer(gridName),
        ui: createQueryDetailsReducer(gridName)
        //ui:queryDetailsReducer
    };
    return combineReducers(red);
}
