import React from 'react';
import Form from '../Form';
import TextField from '../TextField';
import AlertWarning from '../../svg-icons/alertWarning';

export default class AddressSearchManually extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      organisation: '',
      subbuilding: '',
      buildingName: '',
      buildingNumber: '',
      thoroughfare: '',
      locality: '', //locality
      town: '',
      county: '',
      postcode: '',
      fieldRequired: true
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.setSearchState = this.setSearchState.bind(this);
    /*this.handleRequestClosePopover = this.handleRequestClosePopover.bind(this);
     this.timeout = null;*/
  }

  /*componentDidMount() {
   this.setState(this.props.address);
   }*/
  componentWillReceiveProps(nextProps) {
    this.setSearchState(nextProps)
  }

  componentDidMount() {
    this.setSearchState(this.props.address)
  }


  setSearchState(data) {
    var state = { ...data };
    for (let key in state) {
      if (state.hasOwnProperty(key)) {
        if (state[key] === null) {
          state[key] = '';
        }
      }
    }
    this.setState(state);
  }

  handleCancel() {
    if (this.props.cancel) {
      this.props.cancel();
    }
  }

  handleSave() {
    if ((this.state.subbuilding == '' &&
      this.state.buildingNumber == '' && this.state.buildingName == '') ||
      this.state.thoroughfare == '' ||
      this.state.town == '')
      return;

    if (this.props.save) {
      this.props.save(this.state);
    }
  }

  handleTextChanged(value, textBoxName) {
    var newState = {};
    newState[textBoxName] = value;
    this.setState(newState);

    if (this.props.textChanged) {
      this.props.textChanged(value, textBoxName);
    }

    if (textBoxName === 'subbuilding' || textBoxName === 'buildingName' || textBoxName === 'buildingNumber') {
      if (this.state.subbuilding === '' && this.state.buildingNumber === '' && this.state.buildingName === '') {
        value !== '' ? this.setState({ fieldRequired: false }) : this.setState({ fieldRequired: true });
      } else {
        value === '' ? this.setState({ fieldRequired: true }) : this.setState({ fieldRequired: false });
      }
    }
  }

  static defaultProps = {
    textFieldStyles: "form-control-white",
    fieldsetStyles: "address-details"
  };

  render() {
    var divStyle = { width: '100%' };
    return (
      <fieldset style={divStyle} className={this.props.fieldsetStyles}>
        {this.props.hideAlert ? null : <div className="alert alert-block alert-warning">
          <AlertWarning className="icon icon-alert-warning" />
          <div className="alert-content">
            <strong>We can't recommend products for addresses which are entered manually</strong>
          </div>
        </div>}
        <TextField placeholder="" label='ORGANISATION: '
          inputClassName={this.props.textFieldStyles + ' form-control-70'}
          value={this.state.organisation}
          onChange={(event, value) => this.handleTextChanged(value, 'organisation')}
          isRequired={false}
          maxLength="100" />
        <TextField placeholder="" label='Flat / Unit Number: '
          inputClassName={this.props.textFieldStyles}
          className="compact-position-25"
          value={this.state.subbuilding}
          onChange={(event, value) => this.handleTextChanged(value, 'subbuilding')}
          isRequired={this.state.fieldRequired}
          maxLength="50" />
        <TextField placeholder="" label='House Number: '
          inputClassName={this.props.textFieldStyles}
          className="compact-position-25"
          value={this.state.buildingNumber}
          onChange={(event, value) => this.handleTextChanged(value, 'buildingNumber')}
          isRequired={this.state.fieldRequired}
          maxLength="50" />
        <TextField placeholder="" label='House Name: '
          inputClassName={this.props.textFieldStyles}
          className="compact-position"
          value={this.state.buildingName}
          onChange={(event, value) => this.handleTextChanged(value, 'buildingName')}
          isRequired={this.state.fieldRequired}
          maxLength="50" />
        <TextField placeholder="" label='Street: '
          inputClassName={this.props.textFieldStyles}
          className="compact-position"
          value={this.state.thoroughfare}
          onChange={(event, value) => this.handleTextChanged(value, 'thoroughfare')} isRequired={true}
          maxLength="50" />
        <TextField placeholder="" label='Locality: '
          inputClassName={this.props.textFieldStyles}
          className="compact-position"
          value={this.state.locality}
          onChange={(event, value) => this.handleTextChanged(value, 'locality')}
          maxLength="50" />
        <TextField placeholder="" label='City: '
          inputClassName={this.props.textFieldStyles}
          className="compact-position"
          value={this.state.town}
          onChange={(event, value) => this.handleTextChanged(value, 'town')}
          isRequired={true}
          maxLength="50" />
        {/*<TextField placeholder="" label='County: ' inputClassName={this.props.textFieldStyles}
                   value={this.state.county}
                   onChange={(event, value) => this.handleTextChanged(value, 'county')}
                   maxLength="50"/>*/}
        <TextField placeholder="" label='Postcode: '
          inputClassName={this.props.textFieldStyles}
          className="compact-position"
          isRequired={true}
          value={this.state.postcode}
          onChange={(event, value) => this.handleTextChanged(value, 'postcode')}
          maxLength="10" />

        {this.props.hideSave && this.props.hideCancel ?
          null :
          <div className="actions">
            {this.props.hideCancel ?
              null :
              <button type="button" className="button button-small button-transparent" onClick={this.handleCancel}>
                Cancel</button>
            }
            {this.props.hideSave ?
              null :
                <button type="submit" className="button button-small button-green" onClick={this.handleSave} style={{position:'relative'}}>
                  Save Address
                  {this.props.showSaveTooltip ?<div className='btn-tooltip'>Please save address</div>:null}
               </button>
            }

          </div>
        }
      </fieldset>
    );
  }
}
