import React from 'react';
import IsLoading from '../../../components/Loading';

export default class ActionLine extends React.PureComponent {
    static defaultProps = {
        showSubmit : true,
        showAddToOrder: false,
        showQuote: true
    }

    state={showButtons:true};
    click = (shouldValidate, action)=>{
        // validate form first so if error we will show them to user.
        // Otherwise we will change state and browser will not show them
        if(shouldValidate === true && this.props.validateForm()===false){
            return;
        }

        this.setState({showButtons:false}, action);
    }

    static getDerivedStateFromProps(props, state) {
        // this method to adjust state before render. usialy we check previous props/state here
        if(props.isError !== state.prevPropsIsError){
            var s = {prevPropsIsError : props.isError};
            if (props.isError === true){
                s.showButtons=true;
            }
            return s;
        }
        return null;
    }

    render() {
        return (
            <div>
                {
                    this.state.showButtons === false ?
                        <IsLoading/>
                        :
                        <div className="details-aside-action">
                            {this.props.showAddToOrder == true ? <button type="submit" disabled={this.props.isBusy} onClick={()=>{this.click(false, this.props.handleAddPackages)}} className="button button-small button-green">Add to Order</button> : null}
                            {this.props.showQuote == true ? <button type="submit" disabled={this.props.isBusy} onClick={()=>{this.click(true, this.props.handleSaveQuote)}} className="button button-small button-white color-blue">Save Quote</button> : null}
                            {this.props.showSubmit == true ? <button type="submit" disabled={this.props.isBusy} onClick={()=>{this.click(true, this.props.handleSubmit)}} className="button button-small button-green">Place Order</button> : null}
                        </div>
                }
            </div>
        );
    }
}
