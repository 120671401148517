export const UploadActions = {
    UPLOAD_INIT:     'UPLOAD_INIT',
    UPLOAD_REQUEST:  'UPLOAD_REQUEST',
    UPLOAD_PROGRESS: 'UPLOAD_PROGRESS',
    UPLOAD_SUCCESS:  'UPLOAD_SUCCESS',
    UPLOAD_FAILURE:  'UPLOAD_FAILURE',
    UPLOAD_DELETE_REQUEST: 'UPLOAD_DELETE_REQUEST',
    UPLOAD_DELETE_SUCCESS: 'UPLOAD_DELETE_SUCCESS',
    UPLOAD_DELETE_FAILURE: 'UPLOAD_DELETE_FAILURE',
    UPLOAD_DELETE_TEMP_FILE_REQUEST: 'UPLOAD_DELETE_TEMP_FILE_REQUEST',
};

export const uploadInit = (attachments, uploadChannel) => ({
    type: UploadActions.UPLOAD_INIT,
    attachments,
    uploadChannel
});

export const uploadRequest = (file, objectId, attachmentType, uploadChannel, options) => ({
    type: UploadActions.UPLOAD_REQUEST,
    file,
    objectId, 
    attachmentType,
    uploadChannel,
    options
});
export const uploadProgress = (fileName, objectId, progress, uploadChannel) => ({
    type: UploadActions.UPLOAD_PROGRESS,
    progress,
    fileName, 
    objectId, 
    uploadChannel,
});
export const uploadSuccess = (fileName, objectId, responsePath, uploadChannel, fileOptions) => ({
    type: UploadActions.UPLOAD_SUCCESS,
    fileName,
    objectId, 
    uploadChannel,
    responsePath,
    fileOptions
});
export const uploadFailure = (fileName, objectId, uploadChannel, err) => ({
    type: UploadActions.UPLOAD_FAILURE,
    err,
    fileName, 
    objectId, 
    uploadChannel,
});
export const uploadDeleteRequest = (fileName, objectId, uploadChannel) => ({
    type: UploadActions.UPLOAD_DELETE_REQUEST,
    fileName,
    objectId, 
    uploadChannel
});
export const uploadDeleteTempFileRequest = (filePath, fileName, uploadChannel) => ({
    type: UploadActions.UPLOAD_DELETE_TEMP_FILE_REQUEST,
    filePath,
    fileName,
    uploadChannel
});
export const uploadDeleteSuccess = (fileName, uploadChannel) => ({
    type: UploadActions.UPLOAD_DELETE_SUCCESS,
    fileName,
    uploadChannel
});

export const uploadDeleteFailure = (fileName, objectId, uploadChannel, err) => ({
    type: UploadActions.UPLOAD_DELETE_FAILURE,
    err,
    fileName, 
    objectId, 
    uploadChannel,
});