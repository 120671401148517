/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

//Google Analytics
import ReactGA from 'react-ga4';
import React from 'react';
import { createRoot } from "react-dom/client";
import { Provider, useSelector } from 'react-redux';
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
  useLocation,
  Navigate,
  Route,
  Link,
  Outlet
} from "react-router-dom";

import './assets/less/pop.less';
import './assets/less/atlas.less';
import App from 'containers/App';
import Home from 'containers/HomePage';
import Quotes from 'containers/Quotes';
import Orders from 'containers/Orders';
import OrderDetails from 'containers/OrderDetails';
import CreateOrder from 'containers/CreateOrder';
import CopyOrder from 'containers/CreateOrder/index-copy-order';
import CancelledOrder from 'containers/CreateOrder/index-cancelled-order';
import AddProductToOrder from 'containers/CreateOrder/index-add-products';
import QuoteToOrder from 'containers/CreateOrder/index-quote';
import CreateOrderConfirmation from 'containers/CreateOrderConfirmation/index-order';
import QuoteConfirmation from 'containers/CreateOrderConfirmation/index-quote';
import Queries from 'containers/Queries';
import QueryDetails from 'containers/QueryDetails';
import Preferences from 'containers/Preferences';
import PreferencesContacts from 'containers/Preferences/PreferencesContacts';
import PreferencesAddress from 'containers/Preferences/PreferencesAddresses';
import PreferencesSettingsNav from 'containers/Preferences/PreferencesSettingsNav';
import Login from 'containers/Login';
import Register from 'containers/Register';
import NotFoundPage from 'containers/NotFoundPage';
import { OnBoardNavigate } from 'containers/OnBoard/index-nav';

import '!file-loader?name=[name].[ext]!./assets/img/logo.png';
import '!file-loader?name=[name].[ext]!./assets/img/funnel.png';
import '!file-loader?name=[name].[ext]!./favicon.ico';
import './manifest.json';
import config from 'utils/config';
import localStorage from 'utils/localStorage';
import store from './store';
import { selectIsUserLogged } from './containers/App/selectors';
const isUserLoggedSelector = selectIsUserLogged();

//Google Analytics set up
ReactGA.initialize(`${config.gATrackingID}`);

function RequireAuth({ children }) {

  let isUserLogged = useSelector(isUserLoggedSelector);
  let location = useLocation();

  if (!isUserLogged) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace={true} />;
  }

  return <Outlet />;
  // return children
}

const router = createBrowserRouter([
  {
    element: <App />, //layout
    children: [
      {
        //path: "/",
        element: (
          <RequireAuth />
        ),
        children: [
          {
            path: "/",
            element: (<Home />)
          },
          {
            path: "/quotes",
            element: (<Quotes />)
          },
          {
            path: "/quote/:quoteId",
            element: (<QuoteToOrder />)
          },
          {
            path: "/orders",
            element: (<Orders />)
          },
          {
            path: "/create-order",
            element: (<CreateOrder />)
          },
          {
            path: "/add-products/:orderId",
            element: (<AddProductToOrder />)
          },
          {
            path: "/copy-order/:orderId",
            element: (<CopyOrder />)
          },
          {
            path: "/cancelled/:orderId",
            element: (<CancelledOrder />)
          },
          {
            path: '/create-order-confirmation/:orderId',
            element: (<CreateOrderConfirmation />)
          },
          {
            path: '/quote-confirmation/:quoteId',
            element: (<QuoteConfirmation />)
          },
          {
            path: "/order/:id",
            element: (<OrderDetails />)
          },
          {
            path: "/queries",
            element: (<Queries />)
          },
          {
            path: "/query/:id",
            element: (<QueryDetails />)
          },
          {
            path: "/preferences",
            element: (<Preferences />),
            children: [
              {
                path: 'contacts?',
                element: (<PreferencesContacts />)
              },
              {
                path: 'addresses',
                element: (<PreferencesAddress />)
              },
              {
                path: 'settings',
                element: (<PreferencesSettingsNav />)
              }
            ]
          },
          // {
          //   path: "/quote/:id",
          //   element: (<Home />)
          // }
        ]
      },
      // routes without auth
      {
        path: "/login",//(/:returnUrl)
        element: <Login />,
      },
      {
        path: "/register",
        element: (<Register />)
      },
      {
        path: "/onboard",
        element: (<OnBoardNavigate />)
      },
      {
        path:'*',
        element:(<NotFoundPage/>)
      }
    ]
  },
]);


const render = (/*messages*/) => {
  var root = createRoot(document.getElementById("app"));
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        {/* <OnBoardNavigate /> */}
         <RouterProvider router={router} />
      </Provider>
    </React.StrictMode>

  );
};

render();
