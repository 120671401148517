import React from 'react';
import {uniqueId} from 'lodash';

export default class TextArea extends React.Component {
    static defaultProps ={
        rows: 4,
        placeholder : 'Enter text here.',
        label: 'TextFiled label',
        className: 'entry',
        description: '',
        isRequired: false
    }

    componentDidMount(){
        this.unqId = uniqueId("id_");
    }

    handleValueChanged = (event)=> {
        if(this.props.onChange){
            var value = event.target.value;
            this.props.onChange(event, value);
        }
    }

    handleLostFocus = (event) =>{
         if(this.props.onBlur){
            var value = event.target.value;
            this.props.onBlur(event, value);
        }
    }

    render(){
        return (
            <div className={this.props.className}>
                <label htmlFor={this.unqId} >
                    {this.props.label}
                    {this.props.isRequired ? <strong className="label-required"> *</strong> : null}
                </label>
                <textarea id = {this.unqId}  autoComplete="off"
                    rows = {this.props.rows} 
                    placeholder = {this.props.placeholder} 
                    className = {this.props.inputClassName} 
                    value = {this.props.value}
                    onChange = {this.handleValueChanged}
                    onBlur = {this.handleLostFocus}
                    required = {this.props.isRequired ? true : false} />
                <span className="form-control-description">{this.props.description}</span>
            </div>
        );
    }
}