import { createSelector } from 'reselect';
const selectHome = () => (state) => state.onBoard || {};
const selectJobTitles = () => createSelector(
    selectHome(),
    (homeState) => homeState.titles
);

const selectCustomerName = () => createSelector(
    selectHome(),
    (homeState) => homeState.userInfo
);

const selectIsLoading = () => createSelector(
    selectHome(),
    (homeState) => homeState.isLoading
);

const selectShowErrorMsg = () => createSelector(
    selectHome(),
    (homeState) => homeState.showErrorMsg
);
const selectErrorMsg = () => createSelector(
    selectHome(),
    (homeState) => homeState.errorMsg
);

export { 
    selectJobTitles, 
    selectCustomerName,
    selectIsLoading,
    selectShowErrorMsg,
    selectErrorMsg
 };
