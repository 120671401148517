import { buffers, eventChannel, END } from 'redux-saga';
import superagent from 'superagent';

/*
* replaced XmlHttpRequest with superagent since XmlHttpRequest is not supported by IE
*/
export function createUploadFileChannel(endpoint, file, objectId, attachmentType, options) {
    if(objectId == undefined || attachmentType == undefined){
        throw {message: 'Please specify objectId and attachment type before to upload'};
    }
    //var file = files[0]; // payload must be File[]
    // because of IE when we attach map it is Blob (File is not supported in IE). So we have to pass name here
    var dataForm = new FormData();
    dataForm.append('files', file, file.name); // The name 'files' has to be the same as paramenter name in Upload controler
    dataForm.append('attachmentType', attachmentType);
    dataForm.append('objectId', objectId);
    dataForm.append('enctype', 'multipart/form-data');

    var isNotDownloadableVal = "false";
    if(options){
        isNotDownloadableVal= options.isNotDownloadable || "false"
    }

    dataForm.append('isNotDownloadable', isNotDownloadableVal);

    var header={
        Authorization : 'Bearer ' + JSON.parse(sessionStorage.getItem('jwt')).access_token
    }

    return eventChannel(emitter => {
        const onProgress = (e) => {
            if (e.lengthComputable) {
                const progress = e.loaded / e.total;
                emitter({ progress });
            }
        };
        const onFailure = (e) => {
            var msg = e.response ? e.response.text : 'Error while uploading your file. Please remove the file and try again.';
            emitter({ err: new Error(msg) });
            emitter(END);
        };
        superagent.post(endpoint)
                .set('Authorization', header.Authorization)
                .on("progress", onProgress)
                .on("error", onFailure)
                .send(dataForm)
                .end(function(err, res){
                    if (/*readyState === 4*/res.ok) {
                        if (res.status === 200) {
                            var response = JSON.parse(res.text);
                            emitter({ success: true, response : response });
                            emitter(END);
                        }
                        else {
                            onFailure(null);
                        }
                    }
                })

        return () => {
            /*xhr.upload.removeEventListener("progress", onProgress);
            xhr.upload.removeEventListener("error", onFailure);
            xhr.upload.removeEventListener("abort", onFailure);
            xhr.onreadystatechange = null;*/
            //superagent.abort();
        };
    }, buffers.sliding(2));
}
