import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectDiscountPackagesModalOpen, selectDiscountPackagesDialogDatasource } from './selectors';
import { closeDiscountPackagesModal, addSelectedDiscountPackages } from './actions';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Form from '../../components/Form';
import GridDiscountPackages from '../../components/GridProducts/GridDiscountPackages';
import keys from 'lodash/keys';
class NewOrderDiscountPackagesDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selected: {}
        }
    }

    componentWillReceiveProps(nextProps) {
        var newSelected = {};
        keys(nextProps.selectedPackages).forEach(function (item) {
            newSelected = newSelected[item] = { value: true };
        }, this);

        this.setState({
            selected: newSelected
        });
    }

    handleCancel = () => {
        this.props.closeDiscountPackagesModal();
    }

    handleSubmit = () => {
        var packages = [];
        this.props.datasource.forEach(function (item) {
            var selectedItem = this.state.selected[item.id];
            if (selectedItem && selectedItem.value == true) {
                packages.push(item);
            }
        }, this);
        this.props.closeDiscountPackagesModal();
        this.props.addSelectedDiscountPackages(packages);//this.state.selected);
    }

    handleCheck = (id, isChecked) => {
        var newState = { ...this.state.selected };
        if (isChecked) {
            newState[id] = { value: true };
        }
        else {
            delete newState[id];
        }

        this.setState({ selected: newState });
    }

    render() {
        return (
            <Dialog open={this.props.open} modal="true" fullWidth={true} maxWidth='md'>
                <DialogTitle>
                    <div className="modal-title">
                        <h1>Discounts</h1>
                        <p className="h1-description">For the package you have selected, discounts are available on the following products</p>
                    </div>
                </DialogTitle>

                {this.props.datasource.length == 0 ?
                    <div className='loader'></div>
                    :
                    <Form className="modal-inner modal-packages" style={{ overflowY: 'auto', maxHeight: '75vh' }}>
                        <GridDiscountPackages tableClassName="content-grid content-grid-compact products-grid marg-bottom-150" datasource={this.props.datasource}
                            selectedPackages={this.state.selected} selectPackageAction={this.handleCheck} />

                    </Form>
                }
                <DialogActions style={{padding:8, justifyContent: 'center'}}>
                        <button type="reset" className="button button-regular button-transparent" onClick={this.handleCancel}>Cancel</button>
                        <button type="submit" className="button button-regular button-green" onClick={this.handleSubmit}>Add Selected</button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    open: selectDiscountPackagesModalOpen(),
    datasource: selectDiscountPackagesDialogDatasource()

})
const mapDispatchToProps = ({
    closeDiscountPackagesModal,
    addSelectedDiscountPackages
})

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(NewOrderDiscountPackagesDialog);
