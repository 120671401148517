import * as QuoteConstants from './constants';

export function loadQuotes(values) {
    return {
      type: QuoteConstants.LOAD_QUOTES,
      values
    };
  }

export function loadQuotesSuccess(values) {
    return {
      type: QuoteConstants.LOAD_QUOTES_SUCCESS,
      values      
    };
  }

  export function deleteQuote(payload) {
    return {
      type: QuoteConstants.DELETE_QUOTE,
      payload
    };
  }

  export function removeDeletedQuote(payload) {
    return {
      type: QuoteConstants.REMOVE_DELETED_QUOTE,
      payload
    };
  }