import React from 'react';
import Trash from '../../svg-icons/trash';
import {alertRed} from '../../assets/less/base/_variables.js';
import { Link } from 'react-router-dom';

let  QueriesDetailsColumnTemplate = (props) => {
    var datacontext = props.datacontext;
    if(datacontext == undefined){
        return null;
    }

    return (
    <div>
        <strong>{datacontext.address.line1}</strong>
        <div className="text-user">{datacontext.address.createdBy} (<Link to={'/order/'+ datacontext.orderId} className="text-link">Order {datacontext.orderId}</Link>)</div>
    </div>);
};

export default QueriesDetailsColumnTemplate;