import 'whatwg-fetch';
import config from './config';
import merge from 'lodash/merge';

    /**
     * Parses the JSON returned by a network request
     *
     * @param  {object} response A response from a network request
     *
     * @return {object}          The parsed JSON from the request
     */
    function parseJSON(response) {
        if (response.error) {
            //return {error: true, msg: response.status};
            return response.text().then((text) => {
                if (text)
                    return {
                        error: true,
                        msg: text
                    };

                return {
                    error: true,
                    msg: "Error ocures durring normal work."
                }
            });
        }

        return response.json();
    }

    /**
     * Checks if a network request came back fine, and throws an error if not
     *
     * @param  {object} response   A response from a network request
     *
     * @return {object|undefined} Returns either the response, or throws an error
     */
    function checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        }

        const error = new Error(response);
        response.error = error;
        return response;
    }

    function checkForError(response) {
        if (response.error) {
            return Promise.reject(response);
        }

        return response;
    }

    function throwError(error) {
        if (error.error == true)
            throw error;

        // status 500 throws in exception and there is only message props
        throw {msg: error.message};
    }


    function makeFetch(url, options) {
        //url = encodeURI(url);
        return fetch(url, options)
            .then(checkStatus)
            .then(parseJSON)
            .then(checkForError)
            .catch(throwError);
    }

    function getHeaders() {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'pragma': 'no-cache',
            'cache-control': 'no-cache'
        };
    }

    function getAuthHeaders(header) {
        //var header = getHeaders();
        // add auth to headers
        if (header == undefined) {
            header = {};
        }

        header.Authorization = 'Bearer ' + JSON.parse(sessionStorage.getItem('jwt')).access_token
        return header;

    }

    var resfreshTokenStartedFlag = false;
    var requestQueue=[];

    function checkAccessToken() {
        if (isExpired()) {
            if(resfreshTokenStartedFlag == false){
                // fetch new token only if there is not other call already
                return fetchNewAccessToken();
            }

            // dont execute new request. Create promise and return it. So later after the first
            // request is completed we will resolve those promises.
            var newPromise =  new Promise((resolve, reject) => {
                requestQueue.push({resolve : resolve, reject: reject});
            });

            return newPromise;
        }

        return Promise.resolve();
    }

    function isExpired() {
        //return true;
        const tokenExpiresIn = JSON.parse(sessionStorage.getItem('jwt')).expires_in;
        const expirationTime = new Date(tokenExpiresIn);
        const dateTimeNow = new Date();
        // // console.log(expirationTime <= dateTimeNow, expirationTime, dateTimeNow, tokenExpiresIn);
        return expirationTime <= dateTimeNow;
    }

    function fetchNewAccessToken() {
        resfreshTokenStartedFlag = true;
        const refToken = JSON.parse(sessionStorage.getItem('jwt')).refreshToken;
        var requestOptions = {
            method: 'POST',
            headers: getAuthHeaders(getHeaders()),
            body: JSON.stringify(refToken) // PASS refreshTokenHere
        }

        const requestURL = `${config.webApiLoginUrl}/refreshToken`;
        return makeFetch(requestURL, requestOptions)
                .then(function (jsonResponse) {
                    // save the new access token
                    sessionStorage.setItem('jwt', JSON.stringify(jsonResponse));

                    // reset the flag
                    resfreshTokenStartedFlag = false;

                    // resolve all waiting promises if any
                    requestQueue.forEach((p)=>{
                        // console.log("resolve refresh promise");
                        p.resolve();
                    });

                    // clear queue
                    requestQueue = [];
                })
                .catch(err=>{
                    resfreshTokenStartedFlag = false;
                    requestQueue.forEach((p)=>{
                        // console.log("reject refresh promise");
                        p.reject();
                    });

                    requestQueue = [];
                });
    }

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default {
    post: function (url, payload, options, headers) {
        var requestOptions = options || {};
        requestOptions.method = 'POST';
        requestOptions.mode = 'cors';
        requestOptions.headers = headers || getHeaders();
        requestOptions.body = JSON.stringify(payload);
        return makeFetch(url, requestOptions);
    },

    postSoap: function (url, xml, options) {
        var headers = {
        "User-Agent": "browser-soap/0.2.0",
        Accept: "text/html,application/xhtml+xml,application/xml",
        "Content-Type": "application/x-www-form-urlencoded",
        "Content-Type": "text/xml"
        };

        var requestOptions = options || {};
        requestOptions.method = 'POST';
        requestOptions.headers = headers;
        requestOptions.body = xml;
        return makeFetch(url, requestOptions);
    },

    postAuth: function (url, payload, options) {
        return checkAccessToken().then(function () {
            var requestOptions = options || {};
            requestOptions.method = 'POST';
            requestOptions.mode = 'cors';
            requestOptions.headers = getAuthHeaders(getHeaders());
            requestOptions.body = JSON.stringify(payload);
            return makeFetch(url, requestOptions)
        });
    },

    putAuth: function (url, payload, options) {
        return checkAccessToken().then(function () {
            var requestOptions = options || {};
            requestOptions.method = 'PUT';
            requestOptions.mode = 'cors';
            requestOptions.headers = getAuthHeaders(getHeaders());
            requestOptions.body = JSON.stringify(payload);
            return makeFetch(url, requestOptions)
        });
    },

    get: function (url, payload, options) {
        var requestOptions = options || {};
        requestOptions.method = 'GET';
        requestOptions.mode = 'cors';
        requestOptions.headers = getHeaders();
        requestOptions.body = JSON.stringify(payload);
        return makeFetch(url, requestOptions)
    },

    getAuth: function (url, payload, options) {
        return checkAccessToken().then(function () {
        var requestOptions = options || {};
        requestOptions.method = 'GET';
        requestOptions.mode = 'cors';
        requestOptions.headers = getAuthHeaders(getHeaders());
        requestOptions.body = JSON.stringify(payload);
        return makeFetch(url, requestOptions);
        });
    },

    deleteAuth: function (url, payload, options) {
        return checkAccessToken().then(function () {
        var requestOptions = options || {};
        requestOptions.method = 'DELETE';
        requestOptions.mode = 'cors';
        requestOptions.headers = getAuthHeaders(getHeaders());
        requestOptions.body = JSON.stringify(payload);
        return makeFetch(url, requestOptions);
        });
    }
}
