import { call, put, select, fork, takeLatest } from 'redux-saga/effects';
import {
    NEW_ORDER_ADDRESS_SELECTED, NEW_ORDER_SITE_GEOMETRY_CHANGED,
    SELECT_PACKAGE, SELECT_ALL_FAVORITE_PACKAGE, NEW_ORDER_GET_ALERTS
} from '../constants';
import { showAppError } from 'containers/App/actions';
import config from 'utils/config';
import request from 'utils/request';
import { selectAddress, selectAllPackages, selectSelectedPackages, selectHowIsAddressEntered, selectUploadedFiles, selectFavoriteDatasourceList } from '../selectors';
import {
    setLocalAuthorityRecommendedPackages, setAlertsRecommendedPackages, showAdditionalInfoPackage, showAddressWarning,
    hideAdditionalInfoPackage, setPackageAdditionalInfoData, setPackageAdditionalInfoDataError, clearGeometries, setAlertsLoading
} from '../actions';
import { tmIntegration, requiresAdditionalInfo, isResidential } from '../../../utils/packageEx';
import keys from 'lodash/keys';
import { uploadDeleteTempFileRequest } from '../../../components/UploadFile/actions';



function doesOrderContainResidentialPackages(selectedPackages, allPackages) {
    var props = keys(selectedPackages);
    if (props.length == 0) {
        return true;
    }

    var pack = allPackages[props[0]]
    if (pack.code.startsWith('RES_')) {
        return true;
    }

    return false;
}

export function* processPackage(packageId, gridName, isSelected) {
    try {
        const howIsAddressEntered = yield select(selectHowIsAddressEntered());
        const address = yield select(selectAddress());
        const packages = yield select(selectAllPackages());
        const seceltedPackages = yield select(selectSelectedPackages());

        const pack = packages[packageId];
        if (isSelected == true) {
            if (tmIntegration(pack)) {
                if ('gbg' != howIsAddressEntered && 'manual' != howIsAddressEntered) {
                    yield put(showAddressWarning(packageId, gridName))
                    return;
                }
                yield put(showAdditionalInfoPackage(packageId, gridName));
                var isResidential = doesOrderContainResidentialPackages(seceltedPackages, packages);
                const requestURL = `${config.webApiUrlAlerts}/Alerts/lapart2price?easting=${address.easting}&northing=${address.northing}&isResidential=${isResidential}`;

                // Call our request helper (see 'utils/request')
                const extraInfo = yield call(request.getAuth, requestURL);//, formatSoap(1));
                yield put(setPackageAdditionalInfoData(packageId, gridName, extraInfo));
            }
            else if (requiresAdditionalInfo(pack)) {
                yield put(showAdditionalInfoPackage(packageId, gridName));
                yield put(setPackageAdditionalInfoData(packageId, gridName, {}));
            }
        }
        else if (requiresAdditionalInfo(pack)) {
            yield put(hideAdditionalInfoPackage(packageId, gridName));
        }

    }
    catch (err) {
        console.error(err);
        yield put(setPackageAdditionalInfoDataError(packageId, gridName, err.msg));
    }
}

//
// API call to load LA part2 questions price from TM
//
export function* loadRecommendedPackagesRequest(action) {
    yield processPackage(action.packageId, action.gridName, action.value);
}

export function* loadRecommendedPackagesSaga() {
    yield takeLatest(SELECT_PACKAGE, loadRecommendedPackagesRequest);
}

// processes all favourite packages. We need to show extra info if any in FavGrid if user clicks select all fav packages
export function* showExtraInfoWhenAllFavAreSelected(action) {
    var gridName = action.gridName;
    var isSelected = action.value;
    const favPackages = yield select(selectFavoriteDatasourceList());
    for (var index = 0; index < favPackages.length; index++) {
        var item = favPackages[index];
        if (isResidential(item)) { // we have Select All only for res packages
            var packageId = item.id;
            yield processPackage(packageId, gridName, isSelected);
        }
    };
}

export function* selectAllFavSaga() {
    yield takeLatest(SELECT_ALL_FAVORITE_PACKAGE, showExtraInfoWhenAllFavAreSelected);
}

//
// API call to get local authority packages based on selected address
//
export function* getLocalAuthorityPackagesRequest(action) {

    try {
        const address = yield select(selectAddress());
        const requestURL = `${config.webApiUrl}/package/getLocalAuthorityPackages?areaCode=${address.area}`;

        // Call our request helper (see 'utils/request')
        const packageIds = yield call(request.getAuth, requestURL);//, formatSoap(1)); 
        yield put(setLocalAuthorityRecommendedPackages(packageIds));

        // search for property plan in attachments.
        // If found it will be deleted because the address has changed.
        // If not found do nothing
        // files are immutable. use get
        const files = yield select(selectUploadedFiles());
        var planFile = files.filter(item => {
            return item.fileName === 'property-plan.png';
        });
        if (planFile.size === 1) {
            yield put(uploadDeleteTempFileRequest(planFile[0].path, 'property-plan.png', 'create-order-upload-channel'));
            yield put(clearGeometries());
        }
    }
    catch (err) {
        console.error('getLocalAuthorityPackages', err);
        yield put(showAppError(err));
    }
}

export function* getLocalAuthorityPackagesSaga() {
    yield takeLatest(NEW_ORDER_ADDRESS_SELECTED, getLocalAuthorityPackagesRequest);
}

function getPolygon(geometry) {
    if (!geometry || geometry.length == 0) {
        return '';
    }

    var wkt = 'GEOMETRYCOLLECTION(';
    for (let i = 0; i < geometry.length; i++) {
        wkt += geometry[i].wkt + ',';
    }

    wkt = wkt.substring(0, wkt.length - 1) + ')';
    return wkt;
}
//
// API call to get alerts(recommendation) for selected address
//
export function* getAlertsRequest(action) {
    try {
        yield (put(setAlertsLoading()));
        const address = yield select(selectAddress());
        var requestURL = `${config.webApiUrlAlerts}/Alerts/alerts?easting=${address.easting}&northing=${address.northing}`;
        var geom = '';
        if (action.geometry && action.geometry.length > 0) {
            //requestURL += `&geom=${encodeURIComponent(getPolygon(action.geometry))}`;
            geom = `${(getPolygon(action.geometry))}`;
        }

        // Call our request helper (see 'utils/request')
        const packageIds = yield call(request.postAuth, requestURL, geom);
        yield put(setAlertsRecommendedPackages(packageIds));
    }
    catch (err) {
        console.error('getAlertsRequest', err);
        yield put(showAppError(err));
    }
}

export function* getAlertsSaga() {
    //yield fork(getAlertsSaga1);
    yield fork(getAlertsSaga2);
    yield fork(getAlertsSagaWhenSiteGeometryChangedSaga);
}

export function* getAlertsSaga1() {
    yield takeLatest(NEW_ORDER_ADDRESS_SELECTED, getAlertsRequest);

}
export function* getAlertsSaga2() {
    yield takeLatest(NEW_ORDER_GET_ALERTS, getAlertsRequest);
}

export function* getAlertsSagaWhenSiteGeometryChangedSaga() {
    yield takeLatest(NEW_ORDER_SITE_GEOMETRY_CHANGED, getAlertsRequest);
}