import {produce} from "immer";
import {
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR
} from './constants';

const initialState = {
    showErrorMsg: false,
    errorText: '',
    registedUserInfo: {}
};

export default function homeReducer(state = initialState, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case REGISTER_USER:
                draft.showErrorMsg = false;
                break;

            case REGISTER_USER_SUCCESS:
                draft.registedUserInfo = action.user;
                break;
                
            case REGISTER_USER_ERROR:
                draft.errorText = action.error.msg
                draft.showErrorMsg = true;
                break;
        }
    });
}