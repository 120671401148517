import React from 'react';
import { Link } from 'react-router-dom';
import NavigationMenu from '../NavigationMenu';
import Filter from '../../svg-icons/filter';

export default class Header extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = { toggleMenu: false };
  }

  handleShowFilter = () => {
    this.props.showFilter();
  };

  handleCloseMenu = () => {
    this.state.toggleMenu === true ? this.setState({ toggleMenu: false }) : null;
  };

  handleShowMenu = () => {
    this.state.toggleMenu === false ? this.setState({ toggleMenu: true }) : null;
  };

  render() {
    return (
      <nav className={this.state.toggleMenu ? 'navbar open' : 'navbar closing'} id="main-navbar" role="menu">
        <div onClick={this.handleCloseMenu} id="formMargin" className="content-wrapper">
          {
            this.props.isUserLogged
              ?
              <div
                className={this.props.hideFilterIconProps ?
                  'navbar-filters button-icon show-mobile display-none'
                  : 'navbar-filters button-icon show-mobile'}
                data-demo="filters"
                onClick={this.handleShowFilter}>
                <Filter className="icon icon-filter" role="img" />
              </div>
              : null}
          <Link to='/' className="navbar-logo">
            <img src="/logo.png" alt="" />
          </Link>
          {
            this.props.isUserLogged
              ?
              <div className="navbar-actions show-mobile">
                <Link to="/create-order" className="button button-small button-green">Create New Order</Link>
              </div>
              : null}
          {
            this.props.isUserLogged
              ?
              <div onClick={this.handleShowMenu} className="navbar-toggle show-mobile" data-demo="menu">
                <span>Menu</span></div>
              : null}
          {
            this.props.isUserLogged && document.location.pathname !== '/login'
              ?
              <NavigationMenu logout={this.props.logout}
                activeLink={this.props.activeLink}
                receiveWebAlerts={this.props.receiveWebAlerts}
                showNotificationAction={this.props.showNotificationAction}
                notifications={this.props.notifications} />
              :
              <div className="navbar-actions hide-mobile">
                <a href="http://www.severntrentsearches.com/apply-become-account-holder/"
                  className="button button-small button-grey color-pink" target="_blank">Apply to Become an Account
                  Holder</a>
                <Link to="/register" className="button button-small button-grey color-pink">Register to Pay by Card</Link>
              </div>
          }
        </div>
      </nav>);
  }
}
