
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Address = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path d="M19.5 8c0 4.144-7.5 15.5-7.5 15.5S4.5 12.144 4.5 8C4.5 3.858 7.857.5 12 .5c4.142 0 7.5 3.358 7.5 7.5z"/><circle cx="12" cy="8" r="3"/>
      </g>
  </SvgIconEx>
);
Address.displayName = 'Address';
Address.muiName = 'SvgIcon';

export default Address;