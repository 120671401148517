import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import GridTitle from './GridTitle';
import Pager from '../Pager';
import {
     Grid,
     Columns,
     Column
} from '../Grid'

import {
    NavigationColumnTemplate,
    AddressColumnTemplate,
    ProductColumnTemplate,
    StatusColumnTemplate
} from './index';

export default class GridOrdersClosed extends React.Component{
    constructor(props) {
        super(props);
        this.rowTemplateResolver=this.rowTemplateResolver.bind(this);
    }

    rowTemplateResolver(rowData){
        var cellState = this.props.queryGridNavigationColumnState[rowData.queryId];
        if(cellState && cellState.state == 'open'){
            return 'row-open';
        }

        return '';
    }

    render(){
        var pager;

        if (this.props.showPager === true)
        {
            pager = 
                <Pager onChangePage={this.props.onPageChange}
                    totalItemsCount={this.props.totalItemsCount}
                    currentPage={this.props.currentPage}
                    pageSize={this.props.pageSize}
                    gridName='orders'/>;
        }

        return(
            <section id="orders" className="content-block content-wrapper">
                {/*<GridTitle titleText='Closed Orders'/>*/}
                <Grid datasource = { this.props.datasource }
                      isLoading={this.props.isLoading}
                      columnsData={[
                        { 
                            header: 'Placed',
                            style: {width: 100},
                            dataField: 'placedDate',
                            className: 'hide-tablet',
                            dataCellTemplate: (props)=>{
                                var date = moment(props.datacontext).format('DD/MM/YYYY');
                                return <span >{date}</span>
                            }
                        },
                        { 
                            header: 'Closed',
                            style: {width: 100},
                            dataField: 'closedDate',
                            className: 'color-green',
                            dataCellTemplate: (props)=>{
                                var date = moment(props.datacontext).format('DD/MM/YYYY');
                                return <span className='color-green'>{date}</span>
                            }
                        },
                        { 
                            header: 'Address',
                            dataField: 'address', 
                            style: {width: '*'},
                            dataCellTemplate: AddressColumnTemplate
                        },
                        /*{ 
                            header: 'Order Ref.',
                            dataField: 'orderId', 
                            style: {width: 160},
                        },*/
                        { 
                            header: 'Your Ref.',
                            dataField: 'customerRef', 
                            style: {width: 160},
                        },
                        { 
                            dataField: 'price',
                            header: 'Price (Inc VAT)',
                            style: {width: 120, textAlign: 'right'}, 
                            dataCellTemplate: (props) => {
                                return <div className="text-price">{props.datacontext}</div>
                            }
                            //className: 'cell-120 align-right'
                        },
                        {
                            //className: 'cell-140',
                            style: {width: 220}, 
                            className: 'align-right row-actions hide-mobile',
                            dataCellTemplate: (props) => (<NavigationColumnTemplate {...props} />)
                        }
                      ]}>
                </Grid>
                {pager}
            </section>
        );
    }
}
