import React from 'react';
import Pdf from '../../svg-icons/pdf';
import { encodeValueChars } from '../../utils/format';
import Dialog from '@mui/material/Dialog';
import Modal from '../../components/Modal/index'

export default class DownloadLink extends React.Component {
    scrollBarWidth = 0;
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    componentDidMount() {
        const documentWidth = document.documentElement.clientWidth;
        const windowWidth = window.innerWidth;
        this.scrollBarWidth = windowWidth - documentWidth;
    }

    handleClick = () => {
        if (this.props.showFileInDialog === true) {
            this.handleClickShowDialog();
            return;
        }

        this.handleClickDownload();
    }

    handleClickShowDialog = () => {
        var token = null;
        if (sessionStorage.getItem('jwt') != undefined) {
            token = JSON.parse(sessionStorage.getItem('jwt')).access_token;
        }
        var filePath = this.props.file == undefined ? '' : encodeValueChars(this.props.file);
        this.fileUrl = `${this.props.url}${filePath}&authtoken=${token}`;

        this.setState({ open: true });
        this.stopScroll(true);
    }

    handleClickDownload = () => {
        /*request.get(encodeURI(completeURL)).then(function (result) {
            self.setState({ dataSource: result, popoverOpen: true, isloading: false });
            self.textBox.focus();
        });*/

        var token = null;
        if (sessionStorage.getItem('jwt') != undefined) {
            token = JSON.parse(sessionStorage.getItem('jwt')).access_token;
        }
        var filePath = this.props.file == undefined ? '' : encodeValueChars(this.props.file);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = `${this.props.url}${filePath}&authtoken=${token}`;
        link.setAttribute('type', 'hidden');
        link.setAttribute('target', '_blank');
        link.click();
        document.body.removeChild(link);
    }

    stopScroll(state) {
        const body = document.getElementsByTagName("BODY")[0];
        if (state) {
            body.style.paddingRight = (this.scrollBarWidth) + 'px';
            body.style.overflow = 'hidden';
        } else {
            body.style.overflow = 'inherit';
            body.style.paddingRight = '0px';
        }
    }

    handleClose = () => {
        this.setState({ open: false });
        this.stopScroll(false);
    }

    render() {
        return (
            <React.Fragment>
                {/*<Dialog open={this.state.open}  modal={true}*/}
                {/*contentStyle={{width:'80%', maxWidth: 'none'}}*/}
                {/*title={*/}
                {/*<div className="modal-title">*/}
                {/*<h1>Property plan</h1>*/}
                {/*<p className="h1-description">This plan will be attached to your order, we will use this plan when processing the address and products placed.</p>*/}
                {/*</div>*/}
                {/*}*/}
                {/*actions={*/}
                {/*<div className="actions" style={{textAlign: 'center'}}>*/}
                {/*<button type="submit" className="button button-regular button-green" onClick={this.handleClose}>Close</button>*/}
                {/*</div>*/}
                {/*}>*/}
                {/*/!*<div style={{height:600, width:'100%', background: `url(${this.fileUrl}) no-repeat center`, backgroundSize:'contain'}}>*!/*/}

                {/*/!*</div>*!/*/}
                {/**/}
                {/*</Dialog>*/}
                <Modal
                    showModal={this.state.open}
                    handleClose={(e) => {
                        this.handleClose(e)
                    }}
                    classNameCustom="property-plan-modal" title={'Property plan'}
                    icon={<img style={{ width: '100%' }} alt="Loading.." src={this.fileUrl} />}
                    msg={'This plan will be attached to your order, we will use this plan when processing the address and products placed.'} />

                <div className={this.props.className} style={{ cursor: 'pointer' }} onClick={this.handleClick} >
                    {this.props.children == undefined
                        ? <Pdf className="icon icon-document icon-pdf" />
                        : this.props.children}
                </div>
            </React.Fragment>
        );
    }
}
