import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { createStructuredSelector } from 'reselect';
import config from '../../utils/config';
import * as actions from './actions';
import { selectAddress, selectHowIsAddressEntered, selectShowSaveTooltip } from './selectors';
import ExpandableSection from '../../components/ExpandableSection';
import AddressSearch from '../../components/AddressSearch/AddressSearch';
import AddressValid from '../../components/AddressSearch/AddressValid';
import AddressSearchManually from '../../components/AddressSearch/AddressSearchManually';

class NewOrderAddress extends React.PureComponent {
    static defaultProps = {
        isExpandable: true,
        allowEdit: true
    }

    constructor(props) {
        super(props);
        this.state = {
            manuallySearch: false
        };
        this.handleAddressSelected = this.handleAddressSelected.bind(this);
        this.handleAddressSearchManuallySave = this.handleAddressSearchManuallySave.bind(this);
        this.handleAddressSearchManuallyCancel = this.handleAddressSearchManuallyCancel.bind(this);
        this.previousAddessType = '';
        this.editAddressChanged = false; // tracks if address selected for edit has changed
    }

    handleAddressSelected(address) {
        if (address.isValid === true) {
            this.setState({ manuallySearch: false });
            this.props.selectAddress(address, 'gbg');
            const userName = JSON.parse(sessionStorage.getItem('jwt')).user_name;
            ReactGA.event({
                category: 'Add address',
                action: 'save address automatically',
                label: 'user: ' + userName
            });
        }
    }

    handleEditAddress = () => {
        this.setState({ manuallySearch: true });
        // store previous value
        this.previousAddessType = this.props.howIsAddressEntered;
        this.props.changeAddressValidation('edit');
    }

    handleAddressSearchManuallySave(address) {
        this.setState({ manuallySearch: false });
        // get previous validation (gbg or manual)
        let addressValidation = this.previousAddessType;
        if(this.editAddressChanged){
            // if address has been adited by user change validation type to manual.
            // otherwise restore original one.
            addressValidation = 'manual';
        }

        this.props.selectAddress(address, addressValidation);

        const userName = JSON.parse(sessionStorage.getItem('jwt')).user_name;
        ReactGA.event({
            category: 'Add address',
            action: 'save address manually',
            label: 'user: ' + userName
        });

        this.editAddressChanged = false;
        this.props.removeValidationMessage('editAddress');
    }

    handleAddressSearchManuallyCancel() {
        this.setState({ manuallySearch: false });//, showAddressDetails: isEmpty(this.props.address)== false})
        // restore previous validation type
        this.props.changeAddressValidation(this.previousAddessType);
        this.props.removeValidationMessage('editAddress');

        this.editAddressChanged = false;
    }

    formatAddress(item) {
        return `${item.organisation == undefined ? '' : item.organisation + ', '} 
                ${item.subbuilding == undefined ? '' : item.subbuilding + ', '} 
                ${item.buildingNumber == undefined ? '' : item.buildingNumber + ', '} 
                ${item.buildingName == undefined ? '' : item.buildingName + ', '} 
                ${item.thoroughfare == undefined ? '' : item.thoroughfare + ', '} 
                ${item.locality == undefined ? '' : item.locality + ', '} 
                ${item.town == undefined ? '' : item.town + ', '} 
                ${item.postcode == undefined ? '' : item.postcode}`
    }
    handleAddressChanged=()=>{
        // track if address has changed during edit. If not and valid address was edited then restore to green. see task 1504
        this.editAddressChanged = true;
    }

    render() {
        var showAddressDetails = false;
        if (this.state.manuallySearch == false) {
            showAddressDetails = this.props.howIsAddressEntered == 'gbg' || this.props.howIsAddressEntered == 'manual' || this.state.manuallySearch;
        }

        return (
            <ExpandableSection title='Property Address' isExpandable={this.props.isExpandable}>
                {/* Property Search */}
                {this.props.allowEdit ? <AddressSearch
                    onMenuItemSelected={this.handleAddressSelected}
                    extraInfo={<span className="form-control-description">If you can't locate the address enter it <a className="text-link" onClick={this.handleEditAddress}>manually</a>.</span>}
                    formatMenuItem={this.formatAddress}
                    endPointUrl={`${config.webApiUrlAddressSearch}/address`}
                    isRequired={this.state.manuallySearch === true || showAddressDetails === true ? false : true} /> : null}

                <div id="address-details" className="content-block-form content-block-address">
                    {showAddressDetails == true ? <AddressValid address={this.props.address} isValid={this.props.howIsAddressEntered == 'gbg'} editAddress={this.handleEditAddress} /> : null}
                    {this.state.manuallySearch == true ?
                        <AddressSearchManually
                            save={this.handleAddressSearchManuallySave}
                            cancel={this.handleAddressSearchManuallyCancel}
                            address={this.props.address}
                            showSaveTooltip={this.props.showSaveTooltip}
                            textChanged={this.handleAddressChanged}/>
                        : null}
                </div>
            </ExpandableSection>
        );
    }
}
const mapStateToProps = createStructuredSelector({
    address: selectAddress(),
    howIsAddressEntered: selectHowIsAddressEntered(),
    showSaveTooltip: selectShowSaveTooltip()
})

const mapDispatchToProps = ({
    ...actions
})

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(NewOrderAddress);
