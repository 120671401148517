import React from 'react';
import Star from '../../svg-icons/star';

let  StarColumnTemplate = (props) => {
    /*var datacontext = props.datacontext;
    if(datacontext == undefined){
        return null;    
    }*/

    var classNameEx = '';
    var packageId = props.row['id'];
    var index = props.favoritePackages.findIndex(item=> {return item.id == packageId;});
    var isFavorite = false;
    if(index!=-1){
        classNameEx = 'favorite';
        isFavorite = true;
    }
    
    return (
        <Star onClick={()=>{props.toggleFavorite(packageId, !isFavorite)}} className={`icon icon-star ${classNameEx}`}/>
    );
};

export default StarColumnTemplate;