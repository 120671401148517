import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {bindAll} from 'lodash';

var IDLE_TIMEOUT = 1800; //seconds

export default class IdleTimer extends Component {

  constructor(props) {
    super(props);

    this.mouseClientX = 0;
    this.mouseClientY = 0;
    this._localStorageKey = 'atlas_countdown_last_reset_timestamp';
    this._idleSecondsTimer = null;
    this._lastResetTimeStamp = (new Date()).getTime();
    this._localStorage = localStorage;

    this.state = {
      showAlertDialog: false
    }
    bindAll(this, ['resetTime', 'idleAction'])

    this.getLastResetTimeStamp = this.getLastResetTimeStamp.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
    this.handleContinueWithSesion = this.handleContinueWithSesion.bind(this);
  }

  static propTypes = {
    timeout: PropTypes.number, // Activity timeout
    events: PropTypes.arrayOf(PropTypes.string), // Activity events to bind
    idleAction: PropTypes.func, // Action to call when user becomes inactive
    element: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // Element ref to watch activty on.
    isUserLogged: PropTypes.bool
  };

  static defaultProps = {
    //timeout: 1000 * 60 * 20, // 20 minutes
    events: ['mousemove', 'keydown', 'wheel', 'DOMMouseScroll', 'mouseWheel', 'mousedown', 'touchstart', 'touchmove', 'MSPointerDown', 'MSPointerMove'],
    idleAction: () => {
    },
    element: document,
    isUserLogged: false
  };

  componentDidMount() {
    this.props.events.forEach(e => this.props.element.addEventListener(e, this.resetTime));
    this._idleSecondsTimer = window.setInterval(this.checkIdleTime.bind(this, this), 1000);
  }

  componentWillUnmount() {
    // Clear timeout to prevent delayed state changes
    window.clearInterval(this._idleSecondsTimer);
    // Unbind all events
    this.props.events.forEach(e => this.props.element.removeEventListener(e, this.resetTime));
  }

  idleAction() {
    if (this.props.idleAction) {
      this.props.idleAction();
    }
  }

  render() {

    return <React.Fragment>
      {this.props.children}
      {
        process.env.NODE_ENV === 'development' ?
          <div style={{position: 'fixed', bottom: 0, right: 0}}>
            You will be auto logged out in <span id="SecondsUntilExpire"></span> seconds.
          </div>
          :null
      }
      <div className={this.state.showAlertDialog ? 'alert-dialog-shadow hide-mobile' : 'display-none'}>
        <div className={'alert-dialog-box'}>
          <div>
            <span className="time-left" id="fiveMinutes"></span>
            <p>
              You will be auto logged out after the time expire.
            </p>
          </div>
          <div>
            <button onClick={this.handleContinueWithSesion} type="button"
                    className="button button-small button-green">Continue with current session
            </button>
            <button onClick={this.handleLogOut} style={{marginLeft: '10px'}} type="button"
                    className="button button-small button-red">Log out
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  }

  resetTime(event) {
    if (this.state.showAlertDialog === false) {
      if (event.clientX == this.mouseClientX &&
        event.clientY == this.mouseClientY) {
        // browser raises mouse move even the mouse is not really moving
        return;
      }

      if (event.clientX) {
        this.mouseClientX = event.clientX;
        this.mouseClientY = event.clientY;
      }

      this.setLastResetTimeStamp((new Date()).getTime());
    }
  }

  handleContinueWithSesion() {
    this.setState({showAlertDialog: false})
    this.setLastResetTimeStamp((new Date()).getTime());
  }

  handleLogOut() {
    this.props.idleAction();
    this.setState({showAlertDialog: false});
  }

  checkIdleTime(component) {
    if (this.props.isUserLogged != true)
      return;

    var currentTimeStamp = (new Date()).getTime();
    var lastResetTimeStamp = component.getLastResetTimeStamp();
    var secondsDiff = Math.floor((currentTimeStamp - lastResetTimeStamp) / 1000);

    component.writeProgress((IDLE_TIMEOUT - secondsDiff) + "");
    if (secondsDiff >= IDLE_TIMEOUT) {

      //window.clearInterval(component._idleSecondsTimer);
      component.idleAction();
      //alert("Time expired!");
    }
    if (this.state.showAlertDialog === false && secondsDiff >= 1500) {
      this.setState({showAlertDialog: true});
    }
  }

  getLastResetTimeStamp() {
    var lastResetTimeStamp = 0;
    if (this._localStorage) {
      lastResetTimeStamp = parseInt(this._localStorage[this._localStorageKey], 10);
      if (isNaN(lastResetTimeStamp) || lastResetTimeStamp < 0) {
        lastResetTimeStamp = (new Date()).getTime();
      }
    }
    else {
      lastResetTimeStamp = this._lastResetTimeStamp;
    }

    return lastResetTimeStamp;
  }

  setLastResetTimeStamp(timeStamp) {
    if (this._localStorage) {
      this._localStorage[this._localStorageKey] = timeStamp;
    }
    else {
      this._lastResetTimeStamp = timeStamp;
    }
  }

  writeProgress(msg) {
    var oPanel = document.getElementById("SecondsUntilExpire");
    var dialogTimer = document.getElementById("fiveMinutes");
    var minutes = Math.floor(msg / 60);
    var seconds = msg - minutes * 60;

    if (oPanel) {
        oPanel.innerHTML = minutes + 'm ' + seconds + ' sec';
    }

    if (dialogTimer) {
        dialogTimer.innerHTML = minutes + 'm ' + seconds + ' sec';
    }
    
    //else if (console)
    //    console.debug(msg);
  }
}
