import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { createStructuredSelector } from 'reselect';
import { hideFilterIcon } from '../App/actions';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Address from '../../svg-icons/address';
import Settings from '../../svg-icons/settings';
import Contacts from '../../svg-icons/con29dw';
import PreferencesContactsModalDialog from './PreferencesContactsModalDialog';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import homeSaga from './sagas';
import homeReducer from './reducer';
import { compose } from '@reduxjs/toolkit';
import * as actions from './actions';
import { selectJobTitles } from './selectors'
//import { RESTART_ON_REMOUNT } from '../../utils/constants';

function PreferencesNavBar(props) {
    let params = useLocation();

    console.log("Pref useLocation", params);
    return (
        <ul className="page-title-menu">
            {
                params.pathname == '/preferences/contacts' || params.pathname === '/preferences'
                    ? <button onClick={props.handleCreateNewContact} className="button button-small button-green hide-mobile">Create New Contact</button>
                    : null
            }
            <li className={params.pathname == '/preferences/contacts' || params.pathname === '/preferences' ? "active" : ""}>
                <Link to="/preferences/contacts" className="text-link">
                    <Contacts className="icon icon-tab-contact hide-mobile" role="img" />
                    <span className="text">Contacts</span>
                </Link>
            </li>
            <li className={params.pathname == '/preferences/addresses' ? "active" : ""}>
                <Link to="/preferences/addresses" className="text-link">
                    <Address className="icon icon-tab-address hide-mobile" role="img" />
                    <span className="text">Addresses</span>
                </Link>
            </li>
            <li className={params.pathname == '/preferences/settings' ? "active" : ""}>
                <Link to="/preferences/settings" className="text-link">
                    <Settings className="icon icon-tab-settings hide-mobile" role="img" />
                    <span className="text">Settings</span>
                </Link>
            </li>
        </ul>);
}

export class Preferences extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleCreateNewContact = this.handleCreateNewContact.bind(this);
        this.handleModalCancel = this.handleModalCancel.bind(this);
        this.handleModalSave = this.handleModalSave.bind(this);

        this.state = {
            isModalShown: false,
            selectedContact: {
                name: "New Contact"
            }
        };
    }

    componentDidMount() {
        this.props.loadCustomerName();
        //ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.send({ hitType: "pageview", page: "/preferences" });
        this.props.hideFilterIcon(true);
    }

    componentWillUnmount() {
        this.props.hideFilterIcon(false);
    }

    handleCreateNewContact() {
        this.setState({ isModalShown: true });
    }

    handleModalCancel() {
        this.setState({ isModalShown: false });
    }

    handleModalSave(contact) {
        this.setState({ isModalShown: false });
        this.props.addNewCustomerContact(contact);
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isModalShown ?
                    <PreferencesContactsModalDialog contact={this.state.selectedContact}
                        titles={this.props.titles}
                        isPrimaryContact={false}
                        handleCancel={this.handleModalCancel}
                        handleSave={this.handleModalSave}
                    /> :
                    null}
                <div className="page-title"> {/*Add class "fixed" when users scroll down and this reaches the top of the screen */}
                    <div className="content-wrapper">
                        <h1 className="hide-mobile">Contacts</h1>
                        <PreferencesNavBar handleCreateNewContact={this.handleCreateNewContact} />
                    </div>
                </div>
                <Outlet />
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    titles: selectJobTitles(),
})
const mapDispatchToProps = ({
    ...actions,
    hideFilterIcon
})
// Wrap the component to inject dispatch and state into it
var withSaga = injectSaga({ key: 'preferences', saga: homeSaga/*, mode: RESTART_ON_REMOUNT*/ });
var withReducer = injectReducer({ key: 'preferences', reducer: homeReducer });

// Wrap the component to inject dispatch and state into it
export default compose(
    withSaga,
    withReducer,
    connect(mapStateToProps, mapDispatchToProps)
)(Preferences);
