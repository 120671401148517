
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let QueryArchive = (props) => (
  <SvgIconEx {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path d="M18.5 14.5v-13h-13v13M8.5 4.5h1M10.5 6.5h5M8.5 8.5h7M8.5 10.5h7M8.5 12.5h7M23.5 23.5H.5v-7H8v1c0 1.1.9 2 2 2h4.5c1.1 0 2-.9 2-2v-1h7v7zM.5 16.5l3.5-6h1.5M18.5 10.5H20l3.5 6"/>
      </g>
  </SvgIconEx>
);
QueryArchive.displayName = 'QueryArchive';
QueryArchive.muiName = 'SvgIcon';

export default QueryArchive;