
import {
    selectSelectedPackages, selectAllPackages, selectAddress, selectCustomerReference, selectDeveloperName,
    selectSiteName, selectAdditionalInfo, selectContactPerson, selectUploadedFiles, selectHowIsAddressEntered, selectRecommendedRes, selectRecommendedCom,
    selectVatRate, selectDiscountPackageCore, selectLocalAuthoritySearchPackage, selectAlerts, selectLaAuthorityPackagesForSelectedAddress,
    selectGeometries
} from './selectors';
import { getPriceCore } from './basket/priceFunctions';
import { calculateSLADatePackages } from './basket/slaFunctions';
import keys from 'lodash/keys';

export function processRegularPackages(selectedPackageIds, allPackages) {
    // get all packages in one array. Real discount package is in . in core they are bundled
    var packages = keys(selectedPackageIds).map((item) => {
        var isOptinalEnquiry = false;
        var optinalEnquiryPriceLines = [];
        var pack = {...allPackages[item]};
        var additionalProductInfo = selectedPackageIds[item].additionalProductInfo || {};
        var addInfoDto = keys(additionalProductInfo).map(productId => {
            var info = additionalProductInfo[productId];
            if (info['tm'] != undefined) {
                // extract price for optional questions
                isOptinalEnquiry = true;
                var optionalPriceLine = {
                    defaultPrice: 0,
                    vatRate: 0,
                    priceWithVAT: 0
                }

                info.tm.forEach(questionPrice => {
                    optionalPriceLine.vatRate = questionPrice.costVatable == 0 ? 0 : ((questionPrice.costVat / questionPrice.costVatable) * 100).toFixed(2);
                    optionalPriceLine.priceWithVAT += questionPrice.costVatable + questionPrice.costVat;
                    optionalPriceLine.defaultPrice += questionPrice.costVatable;
                    pack.priceWithVAT += questionPrice.costVatable + questionPrice.costVat;
                });

                optinalEnquiryPriceLines.push(optionalPriceLine);
            }

            return { id: productId, extraInfo: JSON.stringify(info) };
        });

        var discountInfo = selectedPackageIds[item].discount || {};
        return {
            id: pack.id,
            classification: pack.classification,
            additionalInfo: addInfoDto,
            termsRequired: selectedPackageIds[item].termsRequired || false,
            termsAndConditions: selectedPackageIds[item].termsAndConditions || false,
            name: pack.name,
            code: pack.code,
            packageSLA: pack.packageSLA,
            amount: pack.priceWithVAT,
            discountAmount: discountInfo.discountAmount || 0,
            priceLines: isOptinalEnquiry === true ? optinalEnquiryPriceLines : pack.priceLines,
            isDiscountApplied: discountInfo.isDiscountPackage || false,
            isLocalAuthorityPackageInDiscountBundle: selectedPackageIds[item].isLocalAuthorityPackageInDiscountBundle || false,
            isOptinalEnquiry: isOptinalEnquiry
        };
    });

    return packages;
}

function processDiscountPackages(props) {
    var orderedPackages = [];
    var discProps = keys(props.selectedDiscountPackageCore);
    if (discProps.length > 0) {
        var item = props.allPackages[discProps[0]];
        item.subPackages.forEach((subPackage) => {
            orderedPackages.push(subPackage.subPackageId);
        });


        return [{
            packageId: discProps[0],
            subPackages: orderedPackages
        }]
    }

    return [];
}

function getRecommendation(props) {
    // check if order contains residential or commercial packages
    // so we can get the correct list without to care about ui tab
    var packageId = keys(props.selectedPackageIds)[0];
    var pack = props.allPackages[packageId];
    if (pack.code.indexOf('RES_') > -1) {
        return props.recommendationsRes;
    }

    return props.recommendationsCom;
}

export function createQuote(props) {
    var order = _createOrder(props);
    var laPackages = props.laPackages;
    // alerts with the quote
    var alerts = {...props.alerts};
    alerts.commercialAlerts = props.alerts.commercialAlerts != undefined ? props.alerts.commercialAlerts.concat(laPackages.commercial) : [];
    alerts.residentialAlerts = props.alerts.residentialAlerts != undefined ? props.alerts.residentialAlerts.concat(laPackages.residential) : [];

    return { order: order, alerts: JSON.stringify(alerts), isFromQuote: false, quoteId: undefined };
}

export function createOrderFromQuote(quoteId, props) {
    var order = _createOrder(props);
    return { order: order, alerts: JSON.stringify(getRecommendation(props)), isFromQuote: true, quoteId: quoteId };
}

export function createOrder(props) {
    var order = _createOrder(props);
    // save all recomendations
    return { order: order, alerts: JSON.stringify(getRecommendation(props)), isFromQuote: false, quoteId: undefined };
}

function _createOrder(props) {
    var files = [];
    props.uploadedFiles.forEach(mapItem => {
        var item = mapItem;
        if (item.failed === true) {
        }
        else {
            files.push({ name: item.fileName, fileLocation: item.path, isNotDownloadable: item.isNotDownloadable });
        }
    });

    var price = getPriceCore(props.selectedPackageIds, props.selectedDiscountPackageCore, props.allPackages, props.vatRate);

    // 560. Exclude street descriptions from GB data results. cut street name if longer than 50
    var street = props.address.thoroughfare;
    if (street && street.length > 50) {
        street = props.address.thoroughfare.substr(0, 50);
    }

    const json = {
        customerId: "1234", // this is from JWT
        customerRef: props.customerReference,
        howIsAddressEntered: props.howIsAddressEntered,
        flatNo: props.address.subbuilding,
        houseNo: props.address.buildingNumber,
        houseName: props.address.buildingName,
        street: street, //props.address.thoroughfare,
        locality: props.address.locality,
        county: props.address.county,
        city: props.address.town,
        postalCode: props.address.postcode,
        uprn: props.address.uprn,
        udprn: props.address.udprn,
        organisation: props.address.organisation,
        developerName: props.developerName,
        siteName: props.siteName,
        additionalInfo: props.additionalInfo,

        paymentMethod: "3",
        chequeNo: "",
        orderValue: price.totalPrice, //getTotalPrice(),
        priceDetails: price,
        //paymentValue:"", Atlas can not provide this info. It is chequeAmount when enter from Zeus
        overpayment: true, // always is true. I dont know why :)

        contactPersonId: props.contactPerson.id,
        contactPersonName: props.contactPerson.name,
        packages: processRegularPackages(props.selectedPackageIds, props.allPackages),
        discountPackages: processDiscountPackages(props),
        attachments: files, //[{"fileId":"0","name":"Welcome Scan.jpg","fileExt":".jpg","fileLocation":"c:\\test\\gemini_upload\\orders\\BA39AFB5-24E8-09AC-7B6E-55959992EC0B_Welcome%20Scan.jpg"}],
        tempOrderId: "BA39AFB5-24E8-09AC-7B6E-55959992EC0B",
        gridReference: _cleanGrid(props.address.easting) + _cleanGrid(props.address.northing) || '',
        orderOriginator: "Atlas",
        createdByUserId: 0,
        localAuthorityPackageId: props.localAuthorityPackageId || -1,
        geometries: props.geometries ? JSON.stringify(props.geometries) : null
    }

    json.sLADate = calculateSLADatePackages(json.packages).toISOString(true);//toDate();

    return json;
}

function _cleanGrid(number) {
    var num = Math.trunc(number);
    if (isNaN(num)) {
        return '';
    }

    var s = "000000" + num;
    return s.substr(s.length - 6);
}


export const mapStateToPropsOS = {
    selectedPackageIds: selectSelectedPackages(),
    allPackages: selectAllPackages(),
    address: selectAddress(),
    customerReference: selectCustomerReference(),
    developerName: selectDeveloperName(),
    siteName: selectSiteName(),
    additionalInfo: selectAdditionalInfo(),
    contactPerson: selectContactPerson(),
    uploadedFiles: selectUploadedFiles(),
    howIsAddressEntered: selectHowIsAddressEntered(),
    recommendationsRes: selectRecommendedRes(),
    recommendationsCom: selectRecommendedCom(),
    alerts: selectAlerts(),
    vatRate: selectVatRate(),
    selectedDiscountPackageCore: selectDiscountPackageCore(),
    localAuthorityPackageId: selectLocalAuthoritySearchPackage(),
    laPackages: selectLaAuthorityPackagesForSelectedAddress(),
    geometries: selectGeometries()
};