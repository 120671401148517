import {
  LOAD_PAGED_ORDERS,
  LOAD_ORDERS_SUCCESS,
  LOAD_ORDERS_ERROR,
  LOAD_ORDER_STATUSES,
  LOAD_ORDER_STATUSES_SUCCESS,
  LOAD_TOTAL_OPEN_ORDERS_COUNT,
  LOAD_TOTAL_OPEN_ORDERS_COUNT_SUCCESS,
  ORDER_SWITCH_OPEN_CLOSE,
  ORDER_STORE_FILTER_INFO
} from './constants';

export function switchOpenCloseQuery(activeTab) {
  return {
    type: ORDER_SWITCH_OPEN_CLOSE,
    activeTab
  };
}

export function loadTotalOpenOrdersCount() {
  return {
    type: LOAD_TOTAL_OPEN_ORDERS_COUNT
  };
}

export function totalOpenOrdersCountLoaded(values) {
  return {
    type: LOAD_TOTAL_OPEN_ORDERS_COUNT_SUCCESS,
    values
  };
}

export function loadPagedOrders(values) {
  return {
    type: LOAD_PAGED_ORDERS,
    values
  };
}

/**
 * Load the repositories, this action starts the request saga
 *
 * @return {object} An action object with a type of LOAD_ORDER_STATUSES
 */
export function loadOrderStatuses(values) {
  return {
    type: LOAD_ORDER_STATUSES,
    values
  };
}


export function orderStatusesLoaded(values) {
  return {
    type: LOAD_ORDER_STATUSES_SUCCESS,
    values
  };
}


export function ordersLoaded(values) {
  return {
    type: LOAD_ORDERS_SUCCESS,
    values
  };
}


export function ordersLoadingError(error) {
  return {
    type: LOAD_ORDERS_ERROR,
    error,
  };
}

export function storeOrderFilters(filterInfo){
  return {
      type: ORDER_STORE_FILTER_INFO,
      filterInfo
  }
}