import React from 'react';
import {alertRed} from '../../assets/less/base/_variables.js';
import { Link } from 'react-router-dom';

export default class NavigationColumnTemplate extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    
    handleReply = ()=>{
        if(this.props.showQueryReplyRow){
            this.props.showQueryReplyRow('queryDetails', this.props.gridName, this.props.row.queryId);
        }
    }

    handleClose = ()=>{
        if(this.props.hideQueryReplyRow){
            this.props.hideQueryReplyRow(this.props.gridName, this.props.row.queryId);
        }
    }

    render()
    {
        var queryDetailsRowState = this.props.queryGridNavigationColumnState[this.props.row.queryId];
        var queryStatus = this.props.row.status;
        var needCustomerReply = this.props.row.needCustomerReply;
        var state = 'close';
        var needCustomerReplyState = true;
        if(queryDetailsRowState){
            state = queryDetailsRowState.state;
            needCustomerReplyState = queryDetailsRowState.needCustomerReply;
        }
        
        var actionButton = null;

        if(state == 'open'){
            actionButton = <Link onClick={this.handleClose} className="button button-small button-full-width button-grey color-red">Close</Link>;
        }
        else if(needCustomerReply === true && needCustomerReplyState === true && queryStatus != 'Close'){
            actionButton =  <Link onClick={this.handleReply} className="button button-small button-full-width button-teal"> {state=='busy'? <div className="loader"></div> : 'Reply'}</Link>;
        }
        else{
            //if(queryStatus == 'Close'){
                actionButton = <Link onClick={this.handleReply} className="button button-small button-full-width button-grey"> {state=='busy'? <div className="loader"></div> : 'Details'}</Link>;
            //}
        }

        return(
         <div className="cell-wrapper">
            {actionButton}
        </div>);
    };
}