import * as AppConst from './constants';


/**
 * Shows app error
 *
 * @param {object} error  The error to show
 */
export function showAppError(error) {
  var errorText = error.msg != undefined ? error.msg : error.message;
  return {
    type: AppConst.APP_ERROR,
    error: errorText
  };
}

export function hideSnackBar() {
  return {
    type: AppConst.HIDE_APP_SNACK_BAR
  }
}

export function logout() {
  return {
    type: AppConst.APP_USER_LOGOUT
  }
}

export function menuNotificationsClicked() {
    return {
      type: AppConst.APP_MENU_NOTIFICATIONS_TOGGLE
    }
}
export function removeNotificationItem(item) {
  return {
    type: AppConst.APP_MENU_NOTIFICATIONS_REMOVE_ITEM,
    item
  }
}

export function removeAllNotificationItems(customerId) {
  return {
    type: AppConst.APP_MENU_NOTIFICATIONS_REMOVE_ALL_ITEMS,
    customerId
  }
}

export function loadCustomerContacts() {
  return {
    type: AppConst.LOAD_CUSTOMER_CONTACTS
  };
}

export function customerContactsLoaded(values) {
  return {
    type: AppConst.LOAD_CUSTOMER_CONTACTS_SUCCESS,
    values
  };
}

export function customerNotificationsLoaded(values) {
  return {
    type: AppConst.LOAD_CUSTOMER_NOTIFICATIONS_SUCCESS,
    values
  };
}

export function customerNotificationsRemovedSuccess(note) {
  return {
    type: AppConst.APP_MENU_NOTIFICATIONS_REMOVE_ITEM_SUCCESS,
    note
  };
}
export function customerNotificationsRemovedAllSuccess() {
  return {
    type: AppConst.APP_MENU_NOTIFICATIONS_REMOVE_ALL_ITEMS_SUCCESS
  };
}
export function hideFilterIcon(value) {
  return {
    type: AppConst.HIDE_FILTER_ICON,
    value
  }
}

export function showFilters() {
  return {
    type: AppConst.SHOW_FILTERS
  }
}

export function changeIsLoginPageVisible(value) {
  return {
    type: AppConst.IS_LOGIN_PAGE_VISIBLE,
    value
  }
}