import React from 'react';
import {uniqueId} from 'lodash';
import EyeOpen from '../../svg-icons/eye-open';
import EyeClosed from '../../svg-icons/eye-closed';

export default class PasswordField extends React.Component {
  static defaultProps = {
    type: 'password',
    placeholder: 'Enter your password',
    label: 'Password',
    className: 'entry',
    description: '',
    isRequired: false
  }

  constructor(props) {
    super(props);

    this.state = {
      showPassword: false
    };
  }

  componentDidMount() {
    this.unqId = uniqueId("id_");
  }

  handleValueChanged = (event) => {
    if (this.props.onChange) {
      var value = event.target.value;
      this.props.onChange(event, value);
    }
  }

  handleLostFocus = (event) => {
    if (this.props.onBlur) {
      var value = event.target.value;
      this.props.onBlur(event, value);
    }
  }

  handleTogglePassword(show) {
    this.setState({showPassword: show});
  }

  render() {
    return (
      <div className={this.props.className}>
        <label htmlFor={this.unqId}>
          {this.props.label}
          {this.props.isRequired ? <strong className="label-required"> *</strong> : null}
        </label>
        <div className="form-control-icon">
          <input id={this.unqId} autoComplete="off"
                 pattern={this.props.pattern}
                 title={this.props.title}
                 type={this.state.showPassword ? "text" : "password"}
                 placeholder={this.props.placeholder}
                 className={this.props.inputClassName}
                 value={this.props.value}
                 onChange={this.handleValueChanged}
                 required={this.props.isRequired}/>
          <div className="button button-icon button-icon-display button-transparent" data-demo="password">
            {this.state.showPassword ?
              <EyeOpen onClick={() => this.handleTogglePassword(false)} className="icon icon-eye-open"/>
              :
              <EyeClosed onClick={() => this.handleTogglePassword(true)} className="icon icon-eye-closed"/>
            }
          </div>
        </div>
        <span className="form-control-description">
          {this.props.description}
        </span>
      </div>
    );
  }
}
