import { takeLatest, call, put, select, fork, cancel } from 'redux-saga/effects';
import { REGISTER_USER } from './constants';
import { registerUserSucceed, registerUserFail } from './actions';
import config from 'utils/config';
import request from 'utils/request';

export function* postRegisterUser(action) {

    const requestURL = config.webApiIdentityUrl + "/user/registration";
    try {

        var newUser = yield call(request.post, requestURL, { customerName: action.customerName, userName: action.userName, password: action.password });
        yield put(registerUserSucceed(newUser));
        console.log('history.go("/onboard");');
        action.navigate("/onboard");
    }
    catch (error) {
        yield put(registerUserFail(error || { msg: "Error while register your account." }));
    }

}

export function* registerUserSaga() {
    yield takeLatest(REGISTER_USER, postRegisterUser)
}

export default function* rootSaga() {
    // Non-blocking fork effects
    // https://redux-saga.js.org/docs/advanced/RootSaga
    // rootSaga will terminate on the first error in any individual child effect or saga and crash your whole app! 
    // this is not so bad but we have to appropriate error handling
    yield fork(registerUserSaga);
}