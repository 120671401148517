export const LOAD_USER_CONTACTS = 'atlas/Preferences/LOAD_USER_CONTACTS';
export const LOAD_USER_CONTACTS_SUCCESS = 'atlas/Preferences/LOAD_USER_CONTACTS_SUCCESS';
export const LOAD_USER_CONTACTS_FAIL = 'atlas/Preferences/LOAD_USER_CONTACTS_FAIL';
export const LOAD_PRIMARY_CONTACT = 'atlas/Preferences/LOAD_PRIMARY_CONTACT';
export const LOAD_PRIMARY_CONTACT_SUCCESS = 'atlas/Preferences/LOAD_PRIMARY_CONTACT_SUCCESS';
export const LOAD_PRIMARY_CONTACT_FAIL = 'atlas/Preferences/LOAD_PRIMARY_CONTACT_FAIL';
export const LOAD_CUSTOMER_ADDRESS = 'atlas/Preferences/LOAD_CUSTOMER_ADDRESS';
export const LOAD_CUSTOMER_ADDRESS_SUCCESS = 'atlas/Preferences/LOAD_CUSTOMER_ADDRESS_SUCCESS';
export const LOAD_CUSTOMER_ADDRESS_FAIL = 'atlas/Preferences/LOAD_CUSTOMER_ADDRESS_FAIL';
export const LOAD_PREFERENCE_SETTINGS = 'atlas/Preferences/LOAD_PREFERENCE_SETTINGS';
export const LOAD_PREFERENCE_SETTINGS_SUCCESS = 'atlas/Preferences/LOAD_PREFERENCE_SETTINGS_SUCCESS';
export const LOAD_PREFERENCE_SETTINGS_FAIL = 'atlas/Preferences/LOAD_PREFERENCE_SETTINGS_FAIL';
export const UPDATE_PREFERENCE_SETTINGS = 'atlas/Preferences/UPDATE_PREFERENCE_SETTINGS';
export const UPDATE_PREFERENCE_SETTINGS_SUCCESS = 'atlas/Preferences/UPDATE_PREFERENCE_SETTINGS_SUCCESS';
export const UPDATE_PREFERENCE_SETTINGS_FAIL = 'atlas/Preferences/UPDATE_PREFERENCE_SETTINGS_FAIL';
export const ADD_NEW_CUSTOMER_CONTACT = 'atlas/Preferences/ADD_NEW_CUSTOMER_CONTACT';
export const ADD_NEW_CUSTOMER_CONTACT_SUCCESS = 'atlas/Preferences/ADD_NEW_CUSTOMER_CONTACT_SUCCESS';
export const ADD_NEW_CUSTOMER_CONTACT_FAIL = 'atlas/Preferences/ADD_NEW_CUSTOMER_CONTACT_FAIL';


export const UPDATE_CUSTOMER_CONTACT = 'atlas/Preferences/UPDATE_CUSTOMER_CONTACT';
export const UPDATE_CUSTOMER_CONTACT_SUCCESS = 'atlas/Preferences/UPDATE_CUSTOMER_CONTACT_SUCCESS';
export const UPDATE_CUSTOMER_CONTACT_FAIL = 'atlas/Preferences/UPDATE_CUSTOMER_CONTACT_FAIL';

export const UPDATE_CUSTOMER_ADDRESS = 'atlas/Preferences/UPDATE_CUSTOMER_ADDRESS';
export const UPDATE_CUSTOMER_ADDRESS_SUCCESS = 'atlas/Preferences/UPDATE_CUSTOMER_ADDRESS_SUCCESS';
export const UPDATE_CUSTOMER_ADDRESS_FAIL = 'atlas/Preferences/UPDATE_CUSTOMER_ADDRESS_FAIL';

export const LOAD_JOB_TITLES = 'atlas/Preferences/LOAD_JOB_TITLES';
export const LOAD_JOB_TITLES_SUCCESS = 'atlas/Preferences/LOAD_JOB_TITLES_SUCCESS';
export const LOAD_JOB_TITLES_ERROR = 'atlas/Preferences/LOAD_JOB_TITLES_ERROR';

export const LOAD_CUSTOMER_NAME = 'atlas/Preferences/LOAD_CUSTOMER_NAME';
export const LOAD_CUSTOMER_NAME_SUCCESS = 'atlas/Preferences/LOAD_CUSTOMER_NAME_SUCCESS';
export const LOAD_CUSTOMER_NAME_FAIL = 'atlas/Preferences/LOAD_CUSTOMER_NAME_FAIL';
