import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Grid } from '../../../components/Grid';

import * as queryActions from '../../Queries/actions';
import { uploadRequest } from '../../../components/UploadFile/actions';
import { createStructuredSelector } from 'reselect';
import { selectClosedQueryGridDataSource, selectClosedQueryGridNavigationColumnState, selectIsQueryLoading } from '../selectors';
import { ClosedQueryGridName } from '../constants';

import {
    NavigationColumnTemplate,
    QueryDetailsCloseColumnTemplate,
    QueryDetailsConversationTemplate
} from '../../../components/GridQueries';

export class GridQueriesInOrderPageClosed extends React.Component {
    constructor(props) {
        super(props);
        this.rowTemplateResolver = this.rowTemplateResolver.bind(this);
    }

    rowTemplateResolver(rowData) {
        var cellState = this.props.queryGridNavigationColumnState[rowData.queryId];
        if (cellState && cellState.state == 'open') {
            return 'row-open';
        }

        return '';
    }

    render() {
        return (
            <div className="content-block-grid">
                <Grid
                    gridName={ClosedQueryGridName}
                    isLoading={this.props.isLoading}
                    tableClassName='content-grid content-grid-compact'
                    datasource={this.props.queries}
                    columnsData={[
                        {
                            header: 'Details',
                            dataField: 'queryDetails',
                            style: { width: '*' },
                            dataCellTemplate: QueryDetailsCloseColumnTemplate
                        },
                        {
                            header: 'Query ref',
                            style: { width: 100 },
                            dataField: 'queryId',
                        },
                        {
                            header: 'Raised',
                            style: { width: 100 },
                            dataField: 'date',
                            dataCellTemplate: (props) => {
                                var date = moment(props.datacontext);
                                var dateString = date.format('DD/MM/YYYY');
                                var timeString = date.format('hh:mm A');
                                return <div>
                                    {dateString}
                                    <div className="text-user">{timeString}</div>
                                </div>
                            }
                        },
                        {
                            header: 'Completed',
                            style: { width: 100 },
                            dataField: 'lastStatusChangeDateTime',
                            dataCellTemplate: (props) => {
                                var date = moment(props.datacontext);
                                var dateString = '';
                                if (date.isValid()) {
                                    dateString = date.format('DD/MM/YYYY');
                                }
                                //var timeString = date.format('hh:mm A');
                                return <div>
                                    {dateString}
                                    {/*<div className="text-user">{timeString}</div>*/}
                                </div>
                            }
                        },
                        {
                            header: '',
                            style: { width: 90, textAlign: 'center' },
                            dataCellTemplate: (props) => (<NavigationColumnTemplate {...props}
                                gridName={ClosedQueryGridName}
                                queryGridNavigationColumnState={this.props.queryGridNavigationColumnState}
                                showQueryReplyRow={this.props.showQueryReplyRow}
                                hideQueryReplyRow={this.props.hideQueryReplyRow} />)
                        }
                    ]}
                    // if data contains key as prop value then the template will be applied
                    rowTemplates={[
                        {
                            key: 'queryDetails', rowClassName: 'query-row', template: (props) => (<QueryDetailsConversationTemplate {...props}
                                gridName={ClosedQueryGridName}
                                queryGridNavigationColumnState={this.props.queryGridNavigationColumnState}
                                addAttachmentToQuery={this.props.addAttachmentToQuery}
                                customerReplyToQuery={this.props.customerReplyToQuery}
                                customerReopenQuery={this.props.customerReopenQuery}
                                changeReplyText={this.props.changeReplyText}
                                deleteQueryFile={this.props.deleteQueryFile}
                                showEarlierMsgs={this.props.showEarlierMsgs} />)
                        }

                    ]}
                    // use this prop if you want to set class to <tr> 
                    rowClassNameResolver={this.rowTemplateResolver}>
                </Grid>
            </div>
        );
    }
}


const mapStateToProps = createStructuredSelector({
    isLoading: selectIsQueryLoading(),
    queries: selectClosedQueryGridDataSource(),
    queryGridNavigationColumnState: selectClosedQueryGridNavigationColumnState(),
})

const mapDispatchToProps = ({
    showQueryReplyRow: queryActions.loadQuery,
    hideQueryReplyRow: queryActions.hideQueryReplyRow,
    addAttachmentToQuery: uploadRequest,
    customerReplyToQuery: queryActions.customerReplyToQuery,
    customerReopenQuery: queryActions.customerReopenQuery,
    changeReplyText: queryActions.changeReplyText,
    deleteQueryFile: queryActions.deleteQueryFile,
    showEarlierMsgs: queryActions.showEarlierMsgs
})

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(GridQueriesInOrderPageClosed);