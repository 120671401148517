import { produce } from "immer";
import { LOAD_ORDER_CONFIRMATION_SUCCESS, LOAD_ORDER_CONFIRMATION_ERROR } from './constants';

const initialState = {
  order: null,
  showError: false
};

export default function pageReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOAD_ORDER_CONFIRMATION_SUCCESS:
        draft.order = action.order
        draft.showError = false;
        break;
      case LOAD_ORDER_CONFIRMATION_ERROR:
        draft.showError = true;
        break;
    }
  });
}