import React from 'react';
import Column from './Column';
import Columns from './Columns';
import RowWithTemplate from './RowWithTemplate';
import Rows from './Rows';
import Broken from '../../svg-icons/Broken';

export default class Grid extends React.PureComponent {

    static defaultProps = {
        showEmptyContent : true
    }

    renderEmptyContent = ()=>{
        if(this.props.showEmptyContent != true)
            return null;

        return (<div className="no-content">
                    <Broken className="icon icon-broken" role="img" />
                    <span>No data to display.</span>
                </div> );
    }

    render(){
        var columns = this.props.columnsData.map((column, index)=>{
            return <Column key={index} datacontext={column}/>
        })

        var tableClassName="content-grid";
        if(this.props.tableClassName != undefined){
            tableClassName = this.props.tableClassName;
        }

        var itemsCount = this.props.datasource ? this.props.datasource.length : 0;

        return (
            <table className={tableClassName} style={this.props.style}>
                {this.props.showHeader == true || this.props.showHeader==undefined
                ?
                    <Columns>
                        {columns}
                    </Columns>
                : null}

                {
                    this.props.isLoading == true // show loading indicator
                    ? <tbody><tr><td colSpan= {columns.length} style={{ color: 'green', textAlign: 'center', fontSize:'1em'}} ><div className='loader'></div></td></tr></tbody>
                    : this.props.datasource == undefined || itemsCount == 0
                            // show empty content component
                         ? <tbody><RowWithTemplate rowTemplateObj={{template: this.renderEmptyContent}} columns={this.props.columnsData} /></tbody>
                            // show data rows
                         : <Rows datasource={this.props.datasource} columns={this.props.columnsData} rowTemplates={this.props.rowTemplates} rowClassNameResolver = {this.props.rowClassNameResolver}/>
                }
                
            </table>
        );
    }
}