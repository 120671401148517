import React from 'react';
import Info from '../../svg-icons/info';
import {teal100} from '../../assets/less/base/_variables';
import {Link} from 'react-router-dom';

export default class GridTitle extends React.PureComponent {
  render() {
    return (
      <div className="content-block-title">
        <h3>Recent Quotes</h3>
        <div className="tooltip-custom hide-mobile" >
          <span className="tooltiptext-custom">Quotes are valid for 30 days. After that they're deleted automatically.</span>
          <Info className="icon icon-info" stroke={teal100}/>
        </div>
        {this.props.itemsCount > 0 ?
          <p className="count hide-mobile">Showing {this.props.datasource.length} of {this.props.itemsCount } quotes</p> :
          null
        }
        <Link to='/quotes' className="button button-small button-icon-right button-white color-blue">
          View All
        </Link>
      </div>
    );
  }
}
