import { createSelector } from 'reselect';
import keys from 'lodash/keys';

const selectHome = () => (state) => state['create-order'] || {};
const selectOrderDetails = () => (state) => {
  if (typeof state['order-details'] !== "undefined") {
    return state['order-details'];
  }

  return null;
};
const selectUIDetails = () => createSelector(
  selectOrderDetails(), (state) => {
    if (state !== null) {
      return state.ui;
    }

    return null;
  }
);
const selectGlobal = () => (state) => state.global;

const selectTemplate = () => createSelector(
  selectHome(), (data) => data.packages || {}
);
const selectAddressData = () => createSelector(
  selectHome(), (data) => data.address
);
const selectUI = () => createSelector(
  selectHome(), (data) => data.ui || {}
);

const selectShowSwitchPackageDlg = () => createSelector(
  selectUI(),
  (globalState) => globalState.showSwitchPackageDlg
);
const selectSwitchDlgSource = () => createSelector(
  selectUI(),
  (globalState) => globalState.switchDlgSource
);

const selectIsMapLocked = () => createSelector(
  selectUI(),
  (globalState) => globalState.isMapLocked
);

const selectShowAlertForSave = () => createSelector(
  selectUI(),
  (globalState) => globalState.showAlertForSave
);

const selectIsBusy = () => createSelector(
  selectUI(),
  (globalState) => globalState.isBusy
);
const selectValidationMsgs = () => createSelector(
  selectUI(),
  (globalState) => globalState.validationMsgs || {}
);

const selectCreatedOrderSelector = () => createSelector(
  selectHome(), (data) => data.createdOrder
);

const selectCreatedOrder = () => createSelector(
  selectCreatedOrderSelector(),
  (data) => data.createdOrder
);

const selectAddressRoot = () => createSelector(
  selectHome(), (data) => data.address || {}
);

const selectCurrentUserRoot = () => createSelector(
  selectGlobal(), (data) => data.currentUser
);

const selectUploadRoot = () => createSelector(
  selectHome(), (data) => data.upload || {}
);
const selectSelectedAlerts = () => createSelector(
  selectUIDetails(), (order) => {
    if (order !== null) {
      return order.selectedAlerts;
    }

    return {};
  });
const selectAdditionalInfoRoot = () => createSelector(
  selectHome(), (data) => data.additionalInfo || {}
);

const selectProductDatasource = () => createSelector(
  selectTemplate(), (data) => data.productDatasource
);

const selectAllPackages = () => createSelector(
  selectTemplate(), (data) => data.allPackages
);

const selectSelectedAddress = () => createSelector(
  selectAddressData(), (data) => data.selectedAddress
);
const selectRecommendedDatasource = () => createSelector(
  selectTemplate(), (data) => data.recommendedDatasource
);
const selectIsRecommendedLoading = () => createSelector(
  selectTemplate(), (data) => data.isRecommendedLoading
);
const selectRecommendedRes = () => createSelector(
  selectTemplate(), (data) => data.recommendedResidentialList
);
const selectRecommendedCom = () => createSelector(
  selectTemplate(), (data) => data.recommendedCommercialList
);

const selectAlerts = () => createSelector(
  selectTemplate(), (data) => data.alerts
);

const selectLocalAuthoritySearchPackage = () => createSelector(
  selectTemplate(), (data) => data.localAuthoritySearchPackageId
);
const selectLaAuthorityPackagesForSelectedAddress = () => createSelector(
  selectTemplate(), (data) => data.laAuthorityPackagesForSelectedAddress
);
const selectFavoriteDatasource = () => createSelector(
  selectTemplate(), (data) => data.favoriteDatasource
);
const selectFavoriteDatasourceList = () => createSelector(
  selectTemplate(), (data) => data.favoriteDatasourceList
);


const selectLaAuthorityDatasource = () => createSelector(
  selectTemplate(), (data) => data.laAuthorityDatasource
);
const selectLaAuthorityOtherDatasource = () => createSelector(
  selectTemplate(), (data) => data.laAuthorityOtherDatasource
);

const selectDiscountPackagesDatasource = () => createSelector(
  selectTemplate(), (data) => data.discountPackagesDatasource
);

const selectActiveCategoryTab = () => createSelector(
  selectTemplate(), (data) => data.activeProductCategoryTab
);

const selectActiveClassificationTab = () => createSelector(
  selectTemplate(), (data) => data.activeProductClassificationTab
);

const selectActiveLALetterTab = () => createSelector(
  selectTemplate(), (data) => data.activeLALetterTab
);
const selectSelectedPackages = () => createSelector(
  selectTemplate(), (data) => data.selectedPackages
);
const selectSelectedPackagesInOrder = () => createSelector(
  selectTemplate(), (data) => data.selectedPackagesInOrder
);

const selectSelectedDiscountPackagesInOrder = () => createSelector(
  selectTemplate(), (data) => data.selectedDiscountPackagesInOrder
);

const selectUploadedFiles = () => createSelector(
  selectUploadRoot(), (data) => data.uploadedFiles//
);

const selectContactPerson = () => createSelector(
  selectTemplate(), (data) => data.contactPerson
);

const selectCustomerReference = () => createSelector(
  selectAdditionalInfoRoot(), (data) => data.customerReference
);

const selectMapSaveDiscardAction = () => createSelector(
  selectUI(), (data) => data.mapSaveDiscardAction
);

const selectDeveloperName = () => createSelector(
  selectAdditionalInfoRoot(), (data) => data.developerName
);

const selectSiteName = () => createSelector(
  selectAdditionalInfoRoot(), (data) => data.siteName
);

const selectGeometries = () => createSelector(
  selectAdditionalInfoRoot(), (data) => data.geometries
);

const selectAdditionalInfo = () => createSelector(
  selectAdditionalInfoRoot(), (data) => data.additionalInfo
);

const selectDiscountPackagesModalOpen = () => createSelector(
  selectTemplate(), (data) => data.discountPackagesOpen
);

const selectDiscountPackagesDialogDatasource = () => createSelector(
  selectTemplate(), (data) => data.discountPackagesDialogDatasource
);

const selectDiscountPackageCore = () => createSelector(
  selectTemplate(), (data) => data.selectedDiscountPackageCore
);


const selectDiscountPackageSubPackages = () => createSelector(
  selectTemplate(), (data) => {
    var selectedDiscountPackageCore = data.selectedDiscountPackageCore;
    var selectedDiscountPackageCoreLength = keys(data.selectedDiscountPackageCore).length;
    var allPackages = data.allPackages;
    var packageCount = keys(allPackages).length;

    if (selectedDiscountPackageCoreLength != 0 && packageCount > 0) {
      var item = allPackages[Object.keys(selectedDiscountPackageCore)[0]];
      var subs = {};
      item.subPackages.forEach((subPackage) => {
        var subPackageId = subPackage.subPackageId;
        if (subPackage.isLocalAuthoritySearch) {
          var localAuthId = data.localAuthoritySearchPackageId;
          if (localAuthId > -1) {
            subPackageId = localAuthId;
          }
        }
        subs[subPackageId] = true;
      })
      return subs;
    }
  }
);

const selectVatRate = () => createSelector(
  selectTemplate(), (data) => data.vatRate
);

const selectBasketOpenClassName = () => createSelector(
  selectUI(), (data) => data.openClassName
);

const selectIsLoading = () => createSelector(
  selectUI(), (data) => data.isLoading
);

const selectVisitedSteps = () => createSelector(
  selectUI(), (data) => data.visitedSteps
);

const selectWizardStep = () => createSelector(
  selectUI(), (data) => data.wizardStep
);

const selectIsCreating = () => createSelector(
  selectUI(), (data) => data.isCreating
);

const selectAddress = () => createSelector(
  selectAddressRoot(), (data) => data.selectedAddress
);
const selectShowSaveTooltip = () => createSelector(
  selectAddressRoot(), (data) => data.showSaveTooltip
);
const selectCurrentUserOptions = () => createSelector(
  selectCurrentUserRoot(), (data) => data.userOptions
);

// const selectCustomerPreferences = () => createSelector(
//   selectCurrentUserRoot(), (data) => data.customerPreferenceDto
// );

const selectHowIsAddressEntered = () => createSelector(
  selectAddressRoot(), (data) => data.howIsAddressEntered
);

const selectWarningPackage = () => createSelector(
  selectUI(),
  (globalState) => globalState.warningPackages
);

const isAnyWaterPackageSelected = () => createSelector(
    selectTemplate(), (data) => data.isAnyWaterPackageSelected
);

export {
  selectSelectedAlerts,
  selectBasketOpenClassName,
  selectVatRate,
  selectDiscountPackageCore,
  selectDiscountPackagesDialogDatasource,
  selectDiscountPackagesModalOpen,
  selectVisitedSteps,
  selectHowIsAddressEntered,
  selectWizardStep,
  selectIsCreating,
  selectDeveloperName,
  selectSiteName,
  selectAdditionalInfo,
  selectAllPackages,
  selectSelectedAddress,
  selectCurrentUserOptions,
  selectContactPerson,
  selectCustomerReference,
  selectRecommendedDatasource,
  selectRecommendedRes,
  selectRecommendedCom,
  selectDiscountPackagesDatasource,
  selectActiveCategoryTab,
  selectActiveClassificationTab,
  selectLaAuthorityDatasource,
  selectLaAuthorityOtherDatasource,
  selectProductDatasource,
  selectFavoriteDatasource,
  selectFavoriteDatasourceList,
  selectActiveLALetterTab,
  selectSelectedPackages,
  selectUploadedFiles,
  selectAddress,
  selectSelectedPackagesInOrder,
  selectSelectedDiscountPackagesInOrder,
  selectIsLoading,
  selectCreatedOrder,
  selectDiscountPackageSubPackages,
  selectLocalAuthoritySearchPackage,
  selectAlerts,
  selectLaAuthorityPackagesForSelectedAddress,
  selectIsRecommendedLoading,
  selectGeometries,
  selectIsMapLocked,
  selectShowSwitchPackageDlg,
  selectShowAlertForSave,
  selectMapSaveDiscardAction,
  selectIsBusy,
  selectSwitchDlgSource,
  selectWarningPackage,
  selectShowSaveTooltip,
  selectValidationMsgs,
  isAnyWaterPackageSelected
}
