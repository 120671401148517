import React from 'react';
//import Dialog from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import { deleteQuote } from './actions';
import { connect } from 'react-redux';
import Form from '../../components/Form';
import AlertWarning from '../../svg-icons/alertWarning';

class DeleteQuoteDialog extends React.Component {
    constructor(props) {
        super(props);

        this.handleDeleteQuote = this.handleDeleteQuote.bind(this);
    }

    handleDeleteQuote() {
        this.props.deleteQuote(this.props.quoteId);
        this.props.handleCancel();
    }

    render() {
        return (
            <Dialog open={this.props.isShown}>
                <Form id="confirm-quote-delete" className="modal-confirmation modal-inner">
                    <h1>Delete Quote</h1>
                    <p className="h1-description">{this.props.quoteAddress}</p>
                    <div className="alert alert-block alert-warning">
                        <AlertWarning className="icon icon-alert-warning" role="img" />
                        <div className="alert-content">
                            <p>Are you sure you want to delete this quote?</p>
                        </div>
                    </div>
                    <div className="actions">
                        <button type="button" onClick={this.props.handleCancel} className="button button-regular button-transparent">Cancel</button>
                        <button type="button" onClick={this.handleDeleteQuote} className="button button-regular button-red">Delete Quote</button>
                    </div>
                </Form>
            </Dialog>
        )
    }
}

const mapDispatchToProps = ({
    deleteQuote
})

export default connect(null, mapDispatchToProps)(DeleteQuoteDialog);
