import React from 'react';
import {Link} from 'react-router-dom';
import Info from '../../svg-icons/info';
import {blue100, purple100} from "../../assets/less/base/_variables.js";

let  ProductColumnTemplate = (props) => {
    var datacontext = props.datacontext;
    if(datacontext == undefined){
        return null;
    }

    var residentialStyle = 'residential';
    if(datacontext.isCommercial == true){
        residentialStyle = 'commercial';
    }

    var products = datacontext.products.map((item, index)=>{
        return <li key={index}>
                    <strong className={`color-${residentialStyle}`}>{item.name}</strong><br/>
                    <span>(£{item.price} + VAT)</span>
                </li>
    })

    return (
         <div className="cell-wrapper">
            <div className="text-product">{datacontext.productCount}</div>
            <div className="popover-icon">
                <div className="popover popover-products" >
                    <ul>
                        {products}
                    </ul>
                     <Link to={`/add-products/${props.row.orderId}`} className="button button-small button-green">Add More Products</Link>
                </div>
                <Info className={`icon icon-attention icon-attention-${residentialStyle}`}/>
            </div>
        </div>
)}

export default ProductColumnTemplate;
