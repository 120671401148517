import React from 'react';

let  CancelButton = (props) => {
    // orderIdChasing, orderIdChasingMessage

    var className = props.className || "button button-small button-white color-red hide-mobile";
    var content = props.text || "Cancel";
    var item = props.item || {};
    var rowState = props.uiState[props.itemId] || {};
    var chaseContent=null;
    if(rowState.canceling){
        chaseContent=<div className='loader'></div>;
    }
    else if(rowState.showCancelLabel == true || item.isCancelRequested){
        chaseContent = <div className="status-label color-teal">Cancellation requested</div>;
    }
    else{
        chaseContent = <button onClick={() => { props.handleCancel(item) }} className = {className}>{content}</button>;
    }
  
    return chaseContent;
};

export default CancelButton;