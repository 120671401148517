import React from 'react';

export default class Notification extends React.PureComponent {
    static defaultProps={
        msg: 'Username and/or password incorrect. Please try again or call us on 0115 9713550 for assistance.'
    }

    render(){
        return(
            <div className="form user-form login-form" style={{padding:20, margin:20}}>
                <h2 style={{textAlign:'center', margin:5, fontWeight:'bold'}}>Messages</h2>
                <p>{this.props.message}</p>
            </div>
        );
    }
}
