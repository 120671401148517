import { take, call, put, select, fork, takeLatest } from 'redux-saga/effects';
import {
  LOAD_PACKAGE_SALE_INFO, NEW_ORDER_SUBMIT, NEW_ORDER_SAVE_QUOTE, TOGGLE_FAVORITE_PACKAGE, ADD_PACKAGES_REQUEST,
  NEW_ORDER_DISCOUNT_PACKAGE_OPEN, NEW_ORDER_LOAD_OERDER_PACKAGES, DELETE_MAP
} from './constants';
import {
  packagesSaleInfoLoaded, packagesSaleInfoLoadingError,
  initState, setAlertsPackagesForOrder,
  clearGeometries, submitOrderFailed,
  loadDiscountPackagesSuccess,
  submitQuoteFailed, addPackagesToOrderSuccess, addPackagesToOrderFailed,
  loadQuoteDetailsSuccess, getAlertsFromLandmark, setFavoritePackages
} from './actions';

import { showAppError } from 'containers/App/actions';
import config from 'utils/config';
import request from 'utils/request';
import { uploadDeleteTempFileRequestWatcherSaga } from '../../components/UploadFile/sagas';
import { loadRecommendedPackagesSaga, getLocalAuthorityPackagesSaga, getAlertsSaga, selectAllFavSaga } from './tm-saga/sagas';
import { selectOrderDetails, selectOrder, selectPackagesInOrder, selectAlerts } from '../OrderDetails/selectors';
import { selectAllPackages, selectFavoriteDatasourceList, selectVatRate } from './selectors';
import { uploadDeleteTempFileRequest } from '../../components/UploadFile/actions';

//
// API call to load dashboard data
//
export function* loadPackagesSaleInfoRequest() {
  const packagesInfo = yield select(selectAllPackages());
  const favoritePackages = yield select(selectFavoriteDatasourceList());
  const vat = yield select(selectVatRate());

  var size = 0;
  if (packagesInfo) {
    size = Object.keys(packagesInfo).length;
  }

  if (size != 0) {
    yield put(packagesSaleInfoLoaded({ packages: packagesInfo, favoritePackages: favoritePackages, vatRate: vat }));
    return;
  }

  const requestURL = `${config.webApiUrl}/package/GetAllPackagesSalesInfo`;
  try {
    // Call our request helper (see 'utils/request')
    const repos = yield call(request.getAuth, requestURL);
    yield put(packagesSaleInfoLoaded(repos));
  }
  catch (err) {
    yield put(packagesSaleInfoLoadingError(err));
    yield put(showAppError(err));
  }
}

export function* loadPackagesSaleInfoSaga() {
  yield takeLatest(LOAD_PACKAGE_SALE_INFO, loadPackagesSaleInfoRequest);
}

//
// API call to save/delete favorite packages
//
export function* saveDeleteFavoritePackageRequest(action) {
  var requestURL = `${config.webApiUrl}/package/SaveFavorite`;
  if (action.value == false) {
    requestURL = `${config.webApiUrl}/package/deleteFavorite`;
  }

  try {
    // Call our request helper (see 'utils/request')
    const repos = yield call(request.postAuth, requestURL, action.packageId);
  }
  catch (err) {
    yield put(showAppError('Could not change favorite packages. Lets reload them'));
    requestURL = `${config.webApiUrl}/package/loadFavorite`;
    const repos = yield call(request.getAuth, requestURL);
    yield put(setFavoritePackages(repos));
  }
}

export function* saveDeleteFavoritePackageSaga() {
  yield takeLatest(TOGGLE_FAVORITE_PACKAGE, saveDeleteFavoritePackageRequest);
}


//
// API call to create new order
//
export function* submitOrderRequest(action) {

  console.log("submitOrderRequest");
  const requestURL = `${config.webApiUrl}/order/create`;
  try {
    // Call our request helper (see 'utils/request')
    const createdOrder = yield call(request.postAuth, requestURL, action.order);

    if (createdOrder.redirectUrl != undefined) {
      // console.log('redirect to: ' + createdOrder.redirectUrl);
      window.location = createdOrder.redirectUrl;
      return;
    }

    // second action is not fired but lets keep it here

    //localStorage.setObj(`createdOrder${createdOrder.orderId}`, true);
    //yield put(submitOrderSuccess(createdOrder));
    //yield put(push(`/create-order-confirmation/${createdOrder.orderId}`)); // go to home. or order confirmation form 
    window.location.href = `/create-order-confirmation/${createdOrder.orderId}`;
  }
  catch (err) {
    yield put(submitOrderFailed(action.order));
    yield put(showAppError(err));
  }
}

export function* submitOrderSaga() {
  yield takeLatest(NEW_ORDER_SUBMIT, submitOrderRequest);
}

//
// API call to save quote
//
export function* saveQuoteRequest(action) {
  var requestURL = `${config.webApiUrl}/quote`;
  var order = action.payload.order;
  var quoteId = action.payload.quoteId || 0;
  var requestType = request.postAuth;

  if (quoteId != 0) {
    requestURL += `?quoteId=${quoteId}`;
    requestType = request.putAuth;
  }

  try {
    // Call our request helper (see 'utils/request')
    const quoteId = yield call(requestType, requestURL, order);
    //yield put(saveQuoteSuccess(quoteId));
    window.location.href = `/quote-confirmation/${quoteId}`;// server redenred
  }
  catch (err) {
    yield put(submitQuoteFailed());
    yield put(showAppError(err));
  }
}

export function* saveQuoteSaga() {
  yield takeLatest(NEW_ORDER_SAVE_QUOTE, saveQuoteRequest);
}

//
// API call to load discount packages
//
export function* loadDiscountPackagesRequest(action) {
  const requestURL = `${config.webApiUrl}/package/getDiscountPackages?packageId=${action.packageId}`;
  try {
    // Call our request helper (see 'utils/request')
    const packages = yield call(request.getAuth, requestURL);
    yield put(loadDiscountPackagesSuccess(packages));
  }
  catch (err) {
    yield put(showAppError(err));
  }
}

export function* loadDiscountPackagesSaga() {
  yield takeLatest(NEW_ORDER_DISCOUNT_PACKAGE_OPEN, loadDiscountPackagesRequest);
}


//
// API call to load order packages when user wants to add more packages to already created order
//
export function* loadOrderPackagesRequest(action) {
  // Load order unless it is cached
  const detailState = yield select(selectOrderDetails());
  if (detailState == undefined || detailState.order == undefined) {
    const requestURL = `${config.webApiUrl}/order/getOrderPackages/${action.orderId}`;
    const alertRequestURL = `${config.webApiUrlAlerts}/Alerts/getAlertsForOrder?orderId=${action.orderId}`;
    try {
      // Call our request helper (see 'utils/request')
      const response = yield call(request.getAuth, requestURL);
      yield put(initState({ packages: response.packages, discountPackages: response.discountPackageId }));

      // get saved alerts from server for that order
      const alertsString = yield call(request.getAuth, alertRequestURL);

      // create dictionary from order's packages
      var packageDict = {};
      response.packages.forEach(function (element) {
        packageDict[element.id] = true;
      });

      // push only those alerts which are not selected yet
      var filteredAlerts = [];
      var allerts = JSON.parse(alertsString);
      allerts.forEach(function (element) {
        if (packageDict[element.id] !== true) {
          filteredAlerts.push(element);
        }
      });
      yield put(setAlertsPackagesForOrder(filteredAlerts));
    }
    catch (err) {
      yield put(showAppError(err));
    }
  }
  else {
    const packages = yield select(selectPackagesInOrder());
    yield put(initState({ packages: packages }));

    const alerts = yield select(selectAlerts());
    yield put(setAlertsPackagesForOrder(alerts));
  }
}

export function* loadOrderPackagesRequestSaga() {
  yield takeLatest(NEW_ORDER_LOAD_OERDER_PACKAGES, loadOrderPackagesRequest);
}

//
// API call to add packages to order
//
export function* addPackageToOrderRequest(action) {
  const requestURL = `${config.webApiUrl}/order/addPackages`;
  try {
    // Call our request helper (see 'utils/request')
    const createdOrder = yield call(request.postAuth, requestURL, action.payload);

    /*if(createdOrder.redirectUrl!=undefined){
        // // console.log('redirect to: ' + createdOrder.redirectUrl);
        window.location = createdOrder.redirectUrl;
        return;
    }*/

    //yield put(submitOrderSuccess(createdOrder));
    yield put(addPackagesToOrderSuccess());
    window.location.href = `/order/${createdOrder.orderId}`; // go to home. or order confirmation form
  }
  catch (err) {
    yield put(addPackagesToOrderFailed());
    yield put(showAppError(err));
  }
}

export function* addPackageToOrderSaga() {
  yield takeLatest(ADD_PACKAGES_REQUEST, addPackageToOrderRequest);
}

/**
 * User deleted property-plan.png from order attachments
 */
export function* deleteMap(action) {
  // delete file
  const { filePath, fileName, uploadChannel } = action;
  yield put(uploadDeleteTempFileRequest(filePath, fileName, uploadChannel));

  // delete geometry from order
  yield put(clearGeometries());
}
export function* deleteMapSaga() {
  yield takeLatest(DELETE_MAP, deleteMap);
}
// Bootstrap sagas
export default function* rootSaga() {
  yield fork(loadPackagesSaleInfoSaga);
  //yield fork(uploadRequestWatcherSaga);
  yield fork(uploadDeleteTempFileRequestWatcherSaga);
  yield fork(loadRecommendedPackagesSaga);
  yield fork(submitOrderSaga);
  yield fork(saveDeleteFavoritePackageSaga);
  yield fork(loadDiscountPackagesSaga);
  yield fork(loadOrderPackagesRequestSaga);
  yield fork(saveQuoteSaga);
  yield fork(addPackageToOrderSaga);
  yield fork(getLocalAuthorityPackagesSaga);
  yield fork(getAlertsSaga);
  yield fork(selectAllFavSaga);
  yield fork(deleteMapSaga);
}
