import React from 'react';
import {connect} from 'react-redux';
import ReactGA from 'react-ga4';
import * as actions from './actions';
import {uploadRequest} from '../../components/UploadFile/actions';
import GridQueriesDashboard from '../../components/GridQueries/GridQueriesDashboard';
import {loadCustomerContacts} from '../App/actions';
import GridQueriesClosed from '../../components/GridQueries/GridQueriesClosed';
import {createStructuredSelector} from 'reselect';
import {selectCustomerContacts} from '../App/selectors';
import {
  selectGridDataSource,
  selectQueryGridNavigationColumnState,
  selectGridClosedDataSource,
  selectQueryGridClosedNavigationColumnState,
  selectActiveTab,
  selectGridDataSourceTotalCount,
  selectGridClosedDataSourceTotalCount,
  selectIsLoading,
  selectQuieryFilters,
  selectCurrentPage
} from './selectors';
import {selectShowFilters} from '../App/selectors';
import Filters from '../../components/Filters';
import PageTitle from './PageTitle';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import homeSaga from './sagas';
import homeReducer from './reducer';
import { compose } from '@reduxjs/toolkit';
//import { RESTART_ON_REMOUNT } from '../../utils/constants';

export class Queries extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    constructor(props) {
        super(props);
        this.filtersRef = React.createRef();
    }

    componentDidMount() {
        this.loadData({startIndex: 0, pageSize: 10});
        this.props.loadCustomerContacts();
        //ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.send({ hitType: "pageview", page: "/queries" });
    }

    executeSearch = (filterInfo) => {
        filterInfo.startIndex =0;
        filterInfo.pageSize =10;
        this.props.storeQueryFilters(filterInfo);
        this.loadData(filterInfo);
    }

    clearSearch = ()=>{
        this.props.storeQueryFilters({});
        this.loadData({
            startIndex: 0, 
            pageSize: 10
        });
    }

    handleQueriesGridPageChange = (startIndex, pageSize, gridName, currentPage) => {
        var filterInfo = {...this.props.filterInfo};
        filterInfo.startIndex = startIndex;
        filterInfo.pageSize = pageSize;
        filterInfo.currentPage = currentPage;
        this.loadData(filterInfo);
    }

    
    handleTabSwitch = (newTab) =>{
        this.props.storeQueryFilters({});
        this.props.switchOpenCloseQuery(newTab);
        this.props.loadPagedQueryList({
            startIndex: 0, 
            pageSize: 10,
            queryStatus: newTab == 'close',
            gridName : newTab == 'close' ? 'queriesPageGridClosed' : 'queriesPageGrid'
        });

        this.filtersRef.current.clear();
    }

    loadData(filterInfo){
        this.props.loadPagedQueryList({
            dateCreated: filterInfo.dateCreated,
            filterText: filterInfo.filterText,
            createdBy: filterInfo.createdBy,
            queryWaitFor: filterInfo.waitFor,
            queryStatus: this.props.activeTab == 'close',
            startIndex: filterInfo.startIndex,
            pageSize: filterInfo.pageSize,
            currentPage: filterInfo.currentPage,
            gridName : this.getGridName()
        });
    }

    getGridName() {
        var gridName = 'queriesPageGrid';
        if (this.props.activeTab == 'close') {
            gridName = 'queriesPageGridClosed';
        }

        return gridName;
    }

    getCustomerContactsFilterData() {
        const modifiedCustContacts = [];

        for (let i = 0; i < this.props.customerContacts.length; i++) {
        modifiedCustContacts.push({
            id: this.props.customerContacts[i].id,
            text: this.props.customerContacts[i].name
        });
        }

        return modifiedCustContacts;
    }

    render() {
        return (  
        <React.Fragment>
            <PageTitle activeTab={this.props.activeTab} switchOpenClose={this.handleTabSwitch} openQueriesCount={this.props.queriesTotalCount} />  
            <Filters dropdownCreatedByDataSource={this.getCustomerContactsFilterData()}
                        executeSearchAction = {this.executeSearch}
                        filterClearedAction = {this.clearSearch}
                        showFilterProp={this.props.showFilterProp}
                        showWaitingFor={this.props.activeTab == 'open' ? true : false}
                        ref={this.filtersRef}/>
            {this.props.activeTab == 'open'?
                <GridQueriesDashboard
                    showPager={true}
                    currentPage={this.props.currentPage}
                    gridName='queriesPageGrid'
                    datasource={this.props.queries}
                    showQueryReplyRow={this.props.loadQuery}
                    hideQueryReplyRow={this.props.hideQueryReplyRow}
                    addAttachmentToQuery={this.props.addAttachmentToQuery}
                    customerReplyToQuery={this.props.customerReplyToQuery}
                    changeReplyText={this.props.changeReplyText}
                    queryGridNavigationColumnState={this.props.queryGridNavigationColumnState}
                    deleteQueryFile={this.props.deleteQueryFile}
                    showEarlierMsgs={this.props.showEarlierMsgs}
                    totalItemsCount={this.props.queriesTotalCount}
                    onPageChange={this.handleQueriesGridPageChange}
                    isLoading={this.props.isLoading}
                    showTitle = {false}/>
                :
                <GridQueriesClosed
                    showPager={true}
                    currentPage={this.props.currentPage}
                    gridName='queriesPageGridClosed'
                    datasource={this.props.queriesClosed}
                    showQueryReplyRow={this.props.loadQuery}
                    hideQueryReplyRow={this.props.hideQueryReplyRow}
                    addAttachmentToQuery={this.props.addAttachmentToQuery}
                    customerReplyToQuery={this.props.customerReplyToQuery}
                    customerReopenQuery={this.props.customerReopenQuery}
                    changeReplyText={this.props.changeReplyText}
                    queryGridNavigationColumnState={this.props.queryGridClosedNavigationColumnState}
                    deleteQueryFile={this.props.deleteQueryFile}
                    showEarlierMsgs={this.props.showEarlierMsgs}
                    totalItemsCount={this.props.queriesClosedTotalCount}
                    onPageChange={this.handleQueriesGridPageChange}
                    isLoading={this.props.isLoading}/>
            }
        </React.Fragment>);
    }
}

const mapStateToProps = createStructuredSelector({
    activeTab: selectActiveTab(),
    customerContacts: selectCustomerContacts(),
    queries: selectGridDataSource(),
    queriesTotalCount: selectGridDataSourceTotalCount(),
    queryGridNavigationColumnState: selectQueryGridNavigationColumnState(),
    queriesClosed: selectGridClosedDataSource(),
    queriesClosedTotalCount: selectGridClosedDataSourceTotalCount(),
    queryGridClosedNavigationColumnState: selectQueryGridClosedNavigationColumnState(),
    showFilterProp: selectShowFilters(),
    isLoading: selectIsLoading(),
    filterInfo: selectQuieryFilters(),
    currentPage: selectCurrentPage()
});
const mapDispatchToProps = ({
    ...actions,
    loadCustomerContacts: loadCustomerContacts,
    addAttachmentToQuery : uploadRequest,
});
// Wrap the component to inject dispatch and state into it
var withSaga = injectSaga({ key: 'queries', saga: homeSaga/*, mode: RESTART_ON_REMOUNT*/ });
var withReducer = injectReducer({ key: 'queries', reducer: homeReducer });

export default compose(
  withSaga,
  withReducer,
  connect(mapStateToProps, mapDispatchToProps)
)(Queries);
