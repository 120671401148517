import PropTypes from 'prop-types';
import React from 'react';
import isFunction from 'lodash/isFunction';

export default class Column extends React.PureComponent {
    /**
     * Properties of column object. it is passed as datacontext to the Column
     * 
     * width : number,
     * textAlign: 'left|center|right',
     * dataField: [ string |func ],
     * header:[ string, func ],
     * dataCellTemplate: PropTypes.func
     * className : css class, string
    */

    static propTypes={
        datacontext : PropTypes.object,//isRequired
    }

    render(){
        
        var column = this.props.datacontext;
        var content = column.header;
        if(isFunction(column.header)){
            content = column.header({ datacontext: this.props.datacontext });
        }
        
        var style = Object.assign({
            width: 100,
            textAlign: 'left'
        }, column.style);
        
        if(column.style == undefined || column.style.width ==='*'){
            // the column gets all remaining space
            delete style.width;
        }

        return (
            <th style= {style} className= {column.className}>
                <div className="cell-sort">{content}</div>
            </th>
        );
    }
}
