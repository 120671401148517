import React from 'react';
import Pdf from '../../svg-icons/pdf';

let  DocumentColumnTemplate = (props) => {
    var datacontext = props.datacontext;
    if(datacontext == undefined || datacontext.length==0){
        return null;
    }

    let handleLinkClick = () => {
        props.checkForTermsClick(props.row);

    }

    var docs = datacontext.map((doc, index) => {
        return (
        <a href={doc.documentURL} target="_blank" className="text-link cell-wrapper" key ={index}>
             <Pdf className="icon icon-document icon-pdf" />
            <div className="text-reference">{doc.documentToolTipDescription}</div>
        </a>);
    })
    return (
        <div>
            {docs}
        </div>
    );
};

export default DocumentColumnTemplate;
