
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Chancel = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path d="M23.5 16.55v6.89H.5v-6.89l3.56-2.9V6.56l7.94-6 7.9 5.82v7.34zm-14.9.61H5.85V8.72s.06-1.43 1.35-1.39a1.39 1.39 0 0 1 1.4 1.39zm4.78 0h-2.76V8.72s.07-1.43 1.38-1.39a1.39 1.39 0 0 1 1.4 1.39zm4.78 0h-2.75V8.72s.06-1.43 1.36-1.39a1.39 1.39 0 0 1 1.4 1.39v8.45z"/>
      </g>
  </SvgIconEx>
);
Chancel.displayName = 'Chancel';
Chancel.muiName = 'SvgIcon';

export default Chancel;