import React from 'react';
import { Link } from 'react-router-dom';

export default class GridTitle extends React.PureComponent{
    render(){
        return(
            <div className="content-block-title">
                <h3>{this.props.titleText}</h3>
                {this.props.itemsCount > 0 ?
                  <p className="count hide-mobile">Showing {this.props.pageItemsCount} of {this.props.itemsCount} open orders</p> :
                  null
                }
                <Link to = "/orders" className="button button-small button-icon-right button-white color-blue">
                    View All
                </Link>
            </div>
        );
    }
}
