import {produce} from "immer";
import {
  LOAD_ORDERS_SUCCESS,
  LOAD_ORDER_STATUSES_SUCCESS,
  ORDER_SWITCH_OPEN_CLOSE,
  ORDER_STORE_FILTER_INFO,
  LOAD_TOTAL_OPEN_ORDERS_COUNT_SUCCESS, LOAD_PAGED_ORDERS
} from './constants';
import {
  ORDER_CHASE,
  ORDER_CHASE_SUCCESS,
  ORDER_CHASE_FAIL,
  ORDER_CHASE_HIDE_LABEL
} from '../OrderDetails/constants';

const initialState = {
  data: {
    orders: [],
    orderStatuses: [],
    totalOpenOrdersCount: 0,
    filterInfo: {}
  },
  ui: {
    activeTab: 'open',
    orderGridRowState: {},
    isLoading: true,
    currentPage: 1
  }
};

export default function ordersReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOAD_ORDERS_SUCCESS:

        draft.data.orders = action.values.orders;
        draft.data.totalOrdersCount = action.values.totalOrdersCount;
        draft.ui.isLoading = false;
        break;

      case LOAD_ORDER_STATUSES_SUCCESS:
        draft.data.orderStatuses = action.values;
        break;

      case LOAD_PAGED_ORDERS:
        draft.ui.isLoading = true;
        draft.ui.currentPage = action.values.currentPage;
        break;

      case ORDER_SWITCH_OPEN_CLOSE:
        draft.ui.activeTab = action.activeTab;
        draft.ui.isLoading = true;
        break;

      case LOAD_TOTAL_OPEN_ORDERS_COUNT_SUCCESS:
        draft.data.totalOpenOrdersCount = action.values
        break;

      case ORDER_CHASE:
        draft.ui.orderGridRowState[action.info.orderId] = { 'chasing': true, 'showChasedLabel': false, 'chased': false };
        break;

      case ORDER_CHASE_SUCCESS:
        draft.ui.orderGridRowState[action.orderId] = { 'chasing': false, 'showChasedLabel': true, 'chased': false };
        break;

      case ORDER_CHASE_FAIL:
        draft.ui.orderGridRowState[action.orderId] = { 'chasing': false, 'showChasedLabel': false, 'chased': false };
        break;

      case ORDER_CHASE_HIDE_LABEL:
        draft.ui.orderGridRowState[action.orderId] = { 'chasing': false, 'showChasedLabel': false, 'chased': true };
        break;

      case ORDER_STORE_FILTER_INFO:
        draft.data.filterInfo = action.filterInfo;
        break;
    }
  });
}
