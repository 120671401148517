import React from 'react';
import {Link} from 'react-router-dom';
import QueryArchive from '../../svg-icons/query-archive';

export default class PageTitle extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    render() {
        var openClassName = "active";
        var closeClassName = "";

        if (this.props.activeTab == 'close') {
            openClassName = "";
            closeClassName = "active";
        }
        return (
            <div
                className="page-title"> {/*<!-- Add className "fixed" when users scroll down and this reaches the top of the screen -->*/}
                <div className="content-wrapper">
                    <h1 className="hide-mobile">Open Queries</h1>
                    <div className="page-title-actions hide-mobile">
                        <Link to="/create-order" className="button button-small button-green align-left hide-mobile">Create New
                        Order</Link>
                    </div>
                    <ul className="page-title-menu">
                        <li className={openClassName}>
                        <Link onClick={()=>this.props.switchOpenClose('open')} className="text-link">
                        {this.props.openQueriesCount != 0 
                            ? <span className="count hide-mobile">{this.props.openQueriesCount}</span> 
                            : null}
                            <span className="text">Open Queries</span>
                        </Link>
                        </li>
                        <li className={closeClassName}>
                        <Link onClick={()=>this.props.switchOpenClose('close')} className="text-link">
                            <QueryArchive className="icon icon-tab-query hide-mobile"/>
                            <span className="text">Closed Queries</span>
                        </Link>
                        </li>
                    </ul>
                </div>
            </div>);
    };
}
