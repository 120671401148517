import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
//import config from '../utils/config';
import config from '../../utils/config';
import { createStructuredSelector } from 'reselect';
import * as actions from './actions';
import { selectJobTitles, selectErrorMsg, selectShowErrorMsg, selectIsLoading } from './selectors';
import { selectRegisterUser } from '../Register/selectors';
import Form from '../../components/Form';
import TextField from '../../components/TextField';
import AddressSearch from '../../components/AddressSearch/AddressSearch';
import AddressValid from '../../components/AddressSearch/AddressValid';
import AddressSearchManually from '../../components/AddressSearch/AddressSearchManually';
import Loading from '../../components/Loading';
import AlertMsg from './alertMsg';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import homeSaga from './sagas';
import homeReducer from './reducer';
import { compose } from '@reduxjs/toolkit';
import { changeIsLoginPageVisible } from '../App/actions';
//import { RESTART_ON_REMOUNT } from '../../utils/constants';

export class OnBoard extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handleDXNumberChanged = this.handleDXNumberChanged.bind(this);
        this.handleDXNameChanged = this.handleDXNameChanged.bind(this);
        this.handlePhoneChanged = this.handlePhoneChanged.bind(this);
        this.handleFaxNumberChanged = this.handleFaxNumberChanged.bind(this);
        this.handleContactJobTitleChanged = this.handleContactJobTitleChanged.bind(this);
        this.handleContactFirstNameChanged = this.handleContactFirstNameChanged.bind(this);
        this.handleContactSurNameChanged = this.handleContactSurNameChanged.bind(this);
        this.handleContactEmailChanged = this.handleContactEmailChanged.bind(this);
        this.handleContactPhoneChanged = this.handleContactPhoneChanged.bind(this);
        this.handleNotificationsChanged = this.handleNotificationsChanged.bind(this);
        this.handleSaveAndContinue = this.handleSaveAndContinue.bind(this);
        this.renderMenuItems = this.renderMenuItems.bind(this);
        //address search
        this.handleAddressSelected = this.handleAddressSelected.bind(this);
        this.handleAddressSelected = this.handleAddressSelected.bind(this);
        this.handleAddressSearchManually = this.handleAddressSearchManually.bind(this);
        this.handleAddressSearchManuallyCancel = this.handleAddressSearchManuallyCancel.bind(this);
        this.formatAddress = this.formatAddress.bind(this);
        this.handleTextChanged = this.handleTextChanged.bind(this);

        this.state = {
            address: {
                subbuilding: '',
                buildingName: '',
                buildingNumber: '',
                thoroughfare: '',
                department: '', //locality
                town: '',
                county: '',
                postcode: ''
            },
            dxNumber: '',
            dxName: '',
            phone: '',
            faxNumber: '',
            contactJobTitle: '',
            contactFirstName: '',
            contactSurName: '',
            contactEmail: '',
            contactPhone: '',
            notifications: true,
            titles: [],
            //address search
            manuallySearch: false,
            isExpandable: true,
            allowEdit: true
        };
    }

    componentDidMount() {
        console.log('componentDidMount', this.props);

        if (this.props.firstStepUser == undefined || this.props.firstStepUser.id == undefined) {
            console.log('redirect');
            window.location.href = '/';
            return;
        }

        this.props.loadJobTitles();
        this.props.changeIsLoginPageVisibleAction(true);
        ReactGA.send({ hitType: "pageview", page: "/onboard" });
    }

    renderMenuItems() {
        if (this.props.titles == undefined) {
            return "";
        }
        //to be able to work html native validation for <select></select> the first suggestion element should have NO value set
        return this.props.titles.map(item =>
            <option key={item} value={item !== 'Select JobTitle' ? item : ''}>{item}</option>
        );
    }

    handleDXNumberChanged(event, value) {
        this.setState({ dxNumber: value });
    }

    handleDXNameChanged(event, value) {
        this.setState({ dxName: value });
    }

    handlePhoneChanged(event, value) {
        this.setState({ phone: value });
        this.setState({ contactPhone: value });
    }

    handleFaxNumberChanged(event, value) {
        this.setState({ faxNumber: value });
    }

    handleContactJobTitleChanged(event) {
        var value = event.target.value;
        if (value == undefined) {
            return;
        }

        if (value == 'Select Job Title') {
            this.setState({ contactJobTitle: '' });
        }

        this.setState({ contactJobTitle: value });
    }

    handleContactFirstNameChanged(event, value) {
        this.setState({ contactFirstName: value });
    }

    handleContactSurNameChanged(event, value) {
        this.setState({ contactSurName: value });
    }

    handleContactEmailChanged(event, value) {
        this.setState({ contactEmail: value });
    }

    handleContactPhoneChanged(event, value) {
        this.setState({ contactPhone: value });
    }

    handleNotificationsChanged(event) {
        this.setState({ notifications: event.target.checked });
    }

    validateInputForm() {
        if (this.state.address.postcode.length <= 0) {
            return false;
        }
        if (this.state.phone.length <= 0) {
            return false;
        }
        if (this.state.contactFirstName.length <= 0) {
            return false;
        }
        if (this.state.contactSurName.length <= 0) {
            return false;
        }
        if (this.state.contactEmail.length <= 0) {
            return false;
        }
        if (this.state.contactPhone.length <= 0) {
            return false;
        }
        if (this.state.contactJobTitle.length <= 0) {
            return false;
        }

        return true;
    }

    handleSaveAndContinue(event) {
        if (this.validateInputForm() !== true) {
            return;
        }

        var user = {
            unitNumber: this.state.address.subbuilding,
            houseName: this.state.address.buildingName,
            houseNumber: this.state.address.buildingNumber,
            street: this.state.address.thoroughfare,
            locality: this.state.address.locality,
            county: this.state.address.county,
            city: this.state.address.town,
            postCode: this.state.address.postcode,
            dxNumber: this.state.dxNumber,
            dxName: this.state.dxName,
            phoneNumber: this.state.phone,
            faxNumber: this.state.faxNumber,
            jobTitle: this.state.contactJobTitle,
            firstName: this.state.contactFirstName,
            surName: this.state.contactSurName,
            email: this.state.contactEmail,
            contactPhone: this.state.contactPhone,
            confirmationOnNewOrder: this.state.notifications,
            id: this.props.firstStepUser.id,
            userName: this.props.firstStepUser.userName,
            password: this.props.firstStepUser.password,
            customerName: this.props.firstStepUser.customerName
        };

        this.props.onBoardUser(user, this.props.navigate);
    }

    handleAddressSelected(address) {
        if (address.isValid === true) {
            this.setState({ manuallySearch: false });
            this.setState({ howIsAddressEntered: 'gbg' });
            this.setState({ address: address });
        }
    }

    handleAddressSearchManually() {
        this.setState({ manuallySearch: true })
    }

    formatAddress(item) {
        return `${item.organisation == undefined ? '' : item.organisation + ', '} 
                ${item.subbuilding == undefined ? '' : item.subbuilding + ', '} 
                ${item.buildingNumber == undefined ? '' : item.buildingNumber + ', '} 
                ${item.buildingName == undefined ? '' : item.buildingName + ', '} 
                ${item.thoroughfare == undefined ? '' : item.thoroughfare + ', '} 
                ${item.locality == undefined ? '' : item.locality + ', '} 
                ${item.town == undefined ? '' : item.town + ', '} 
                ${item.postcode == undefined ? '' : item.postcode}`
    }

    handleEditAddress = () => {
        this.setState({ manuallySearch: true });
    }

    handleAddressSearchManuallyCancel() {
        this.setState({ manuallySearch: false });
    }

    handleTextChanged(value, textBoxName) {
        var newAddess = this.state.address;
        newAddess[textBoxName] = value;
        this.setState({ address: newAddess })
    }

    render() {
        const addressValidStyles = {
            width: '100%'
        };
        var showAddressDetails = false;
        if (this.state.manuallySearch == false) {
            showAddressDetails = this.state.howIsAddressEntered == 'gbg' || this.state.howIsAddressEntered == 'manual' || this.state.manuallySearch;
        }

        return (
            <div className='long-form'>
                <Form onSubmit={this.handleSaveAndContinue} id="onboard-form" className="user-form register-form onboard-form">
                    <div className="user-form-title">
                        <h1>One more step ...</h1>
                        <p className="h1-description">Before you can proceed, we need to collect some additional information about you</p>
                    </div>
                    <div className="entry-block entry-break">
                        <div className="entry-block-left">
                            <h2>Postal Address</h2>
                            <span className="h2-description">This will be your main contact address used for billing and searches.</span>
                        </div>
                        <div className="entry-block-right">
                            <AddressSearch
                                isRequired={!this.state.manuallySearch}
                                internalDivClassName={"form-control-100"}
                                onMenuItemSelected={this.handleAddressSelected}
                                extraInfo={<span className="form-control-description">If you can't locate the address enter it <a className="text-link" onClick={this.handleAddressSearchManually}>manually</a>.</span>}
                                formatMenuItem={this.formatAddress}
                                endPointUrl={`${config.webApiUrlAddressSearch}/address`} />

                            <div id="address-details" className="content-block-form content-block-address">
                                {showAddressDetails == true
                                    ? <AddressValid
                                        address={this.state.address}
                                        itemStyles={addressValidStyles}
                                        isValid={this.state.howIsAddressEntered == 'gbg'}
                                        editAddress={this.handleEditAddress} /> :
                                    null
                                }
                                {this.state.manuallySearch == true ?
                                    <AddressSearchManually
                                        hideSave={true}
                                        address={this.state.address}
                                        fieldsetStyles={''}
                                        textFieldStyles={'form-control-grey'}
                                        textChanged={this.handleTextChanged}
                                        save={this.handleAddressSearchManuallySave}
                                        cancel={this.handleAddressSearchManuallyCancel}
                                        hideAlert={true} /> :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="entry-block entry-break">
                        <div className="entry-block-left">
                            <h2>DX Details</h2>
                            <span className="h2-description">Provide your Direct Exchange address details.</span>
                        </div>
                        <div className="entry-block-right">
                            <TextField placeholder=""
                                className="entry"
                                label="DX Number"
                                inputClassName="form-control-grey"
                                value={this.state.dxNumber}
                                isRequired={false}
                                onChange={this.handleDXNumberChanged} />

                            <TextField placeholder=""
                                className="entry"
                                label="DX Name"
                                inputClassName="form-control-grey"
                                value={this.state.dxName}
                                isRequired={false}
                                onChange={this.handleDXNameChanged} />
                        </div>
                    </div>
                    <div className="entry-block entry-break">
                        <div className="entry-block-left">
                            <h2>Telephone &amp; Fax</h2>
                            <span className="h2-description">We need these numbers so we can contact you quickly if we need additional information about your orders.</span>
                        </div>
                        <div className="entry-block-right">
                            <TextField placeholder="Valid UK phone number format"
                                className="entry"
                                label="Phone"
                                inputClassName="form-control-grey"
                                value={this.state.phone}
                                isRequired={true}
                                onChange={this.handlePhoneChanged} />

                            <TextField placeholder="Valid UK phone number format"
                                className="entry"
                                label="Fax Number"
                                inputClassName="form-control-grey"
                                value={this.state.faxNumber}
                                isRequired={false}
                                onChange={this.handleFaxNumberChanged} />
                        </div>
                    </div>
                    <div className="entry-block entry-break">
                        <div className="entry-block-left">
                            <h2>Primary Contact</h2>
                            <span className="h2-description">The Primary Contact is the person at your firm you would prefer us to deal with. Please select their job title from the list..</span>
                        </div>
                        <div className="entry-block-right">
                            <div className="entry">
                                <label htmlFor="job-title">Job Title <strong className="label-required">*</strong></label>
                                <div className="form-dropdown">
                                    <select id="job-title"
                                        className="form-control-grey"
                                        onChange={this.handleContactJobTitleChanged}
                                        required>
                                        {this.renderMenuItems()}
                                    </select>
                                </div>
                            </div>
                            <TextField placeholder=""
                                className="entry"
                                label="First Name"
                                inputClassName="form-control-grey"
                                value={this.state.contactFirstName}
                                isRequired={true}
                                onChange={this.handleContactFirstNameChanged} />

                            <TextField placeholder=""
                                className="entry"
                                label="Surname"
                                inputClassName="form-control-grey"
                                value={this.state.contactSurName}
                                isRequired={true}
                                onChange={this.handleContactSurNameChanged} />

                            <TextField placeholder=""
                                type="email"
                                className="entry"
                                label="Email Address"
                                inputClassName="form-control-grey"
                                value={this.state.contactEmail}
                                isRequired={true}
                                onChange={this.handleContactEmailChanged} />

                            <TextField placeholder="Valid UK phone number format"
                                className="entry"
                                label="Phone"
                                inputClassName="form-control-grey"
                                value={this.state.contactPhone}
                                isRequired={true}
                                onChange={this.handleContactPhoneChanged} />

                        </div>
                    </div>
                    <div className="entry-block entry-break">
                        <div className="entry-block-left">
                            <h2>Notifications</h2>
                            <span className="h2-description">Manage your notification preferences.</span>
                        </div>
                        <div className="entry-block-right">
                            <div className="form-toggle-option">
                                <label htmlFor="confirmation" className="form-toggle-label">Send me confirmation every time I place an order</label>
                                <label htmlFor="confirmation" className="form-toggle-button">
                                    <input id="confirmation" type="checkbox" checked={this.state.notifications} onChange={this.handleNotificationsChanged} />
                                    <span className="toggle-indicator"><span className="toggle-line"></span></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    {
                        this.props.showErrorMsg ? <AlertMsg msg={this.props.errorMsg} /> : null
                    }
                    <div className="actions">
                        {this.props.isLoading
                            ? <Loading />
                            : <button type="submit" className="button button-large button-green">Save &amp; Continue</button>
                        }
                    </div>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    titles: selectJobTitles(),
    firstStepUser: selectRegisterUser(),
    isLoading: selectIsLoading(),
    errorMsg: selectErrorMsg(),
    showErrorMsg: selectShowErrorMsg()

})
const mapDispatchToProps = ({
    ...actions,
    changeIsLoginPageVisibleAction: changeIsLoginPageVisible
})

// Wrap the component to inject dispatch and state into it
var withSaga = injectSaga({ key: 'onBoard', saga: homeSaga/*, mode: RESTART_ON_REMOUNT*/ });
var withReducer = injectReducer({ key: 'onBoard', reducer: homeReducer });

// Wrap the component to inject dispatch and state into it
export default compose(
    withSaga,
    withReducer,
    connect(mapStateToProps, mapDispatchToProps)
)(OnBoard);