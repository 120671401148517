import { SEARCH_DASHBOARD, LOAD_DASHBOARD, LOAD_DASHBOARD_SUCCESS } from './constants';
import {
  ORDER_CHASE,
  ORDER_CHASE_SUCCESS,
  ORDER_CHASE_FAIL,
  ORDER_CHASE_HIDE_LABEL
} from '../OrderDetails/constants';
import { REMOVE_DELETED_QUOTE } from '../Quotes/constants';
import { combineReducers } from 'redux';
import { create } from '../Queries/queryGridReducer';
import findIndex from 'lodash/findIndex';
import {produce} from "immer";

const initialState = {
  orders: [],
  quotes: [],
  ordersCount: "",
  quotesCount: "",
  orderGridRowState: {}
};

const initialStateUI = {
  isLoading: false
}

export function uiHomeRedicer(state = initialStateUI, action) {
  switch (action.type) {
    case LOAD_DASHBOARD:
    case SEARCH_DASHBOARD:
      return {
        ...state,
        isLoading: true
      };

    case LOAD_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
      default:
        return state;
  }
}

export function homeReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOAD_DASHBOARD_SUCCESS:
        draft.orders = action.values.orders;
        draft.quotes = action.values.quotes;
        draft.ordersCount = action.values.ordersCount;
        draft.quotesCount = action.values.quotesCount;
        break;

      case ORDER_CHASE:
        draft.orderGridRowState[action.info.orderId] = { 'chasing': true, 'showChasedLabel': false, 'chased': false };
        break;
      case ORDER_CHASE_SUCCESS:
        draft.orderGridRowState[action.orderId] = { 'chasing': false, 'showChasedLabel': true, 'chased': false };
        break;
      case ORDER_CHASE_FAIL:
        draft.orderGridRowState[action.orderId] = { 'chasing': false, 'showChasedLabel': false, 'chased': false };
        break;
      case ORDER_CHASE_HIDE_LABEL:
        draft.orderGridRowState[action.orderId] = { 'chasing': false, 'showChasedLabel': false, 'chased': true };
        break;

      case REMOVE_DELETED_QUOTE:
        var quotes = draft.quotes;
        var index = findIndex(quotes, item => { return item.id == action.payload; });
        if (index != -1) {
          draft.quotes.splice(index, 1);
        }
        break;
    }
  });
}

export default combineReducers({
  ui: uiHomeRedicer,
  data: homeReducer,
  queryGrid: create('dashboardQueryGrid') // here is where datasource of query grid is set
})
