import { createSelector } from 'reselect';

// makeSelectLocationState expects a plain JS object for the routing state
const makeSelectLocationState = () => {
  let prevRoutingState;

  return (state) => {
    const routingState = state.route; // or state.route

    if (!routingState.equals(prevRoutingState)) {
      prevRoutingState = routingState;
    }

    return prevRoutingState;
  };
};


//const selectGlobal = () => (state) => state.global;
const selectGlobal = () => (state) => {
  return state.global;
}

const selectCurrentUser = () => createSelector(
  selectGlobal(),
  (globalState) => globalState.currentUser
);

const selectUiProps = () => createSelector(
  selectGlobal(),
  (globalState) => globalState.ui
);
const selectIsUserLogged = () => createSelector(
  selectCurrentUser(),
  (currentUser) => currentUser.isLogged
);

const selectIsUserFeatures = () => createSelector(
  selectUserOptions(),
  (currentUser) => {
    return currentUser.userFeatures;
  }
);

const selectUserNotifications = () => createSelector(
  selectData(),
  (data) => data.notifications
);

const selectMap = () => createSelector(
  selectIsUserFeatures(),
  (feature) => {
    const mapOptions = feature.ShowMap;
    return mapOptions;
  }
);
const selectDiscountPackages = () => createSelector(
  selectIsUserFeatures(),
  (feature) => {
    const userOptions = feature.hideDiscountPackages;
    return userOptions;
  }
);

const selectUserName = () => createSelector(
  selectCurrentUser(),
  (currentUser) => {
    return currentUser.userName;
  }
);

const selectUserOptions = () => createSelector(
  selectCurrentUser(),
  (currentUser) => currentUser.userOptions
);

const selectCustomerName = () => createSelector(
  selectCustomerPreferenceDto(),
  (options) => {
    if (options === undefined) {
      return '';
    }
    return options.customerName
  })

const selectCustomerPreferenceDto = () => createSelector(
  selectUserOptions(),
  (options) => options.customerPreferenceDto
)

const selectIsAccountCustomer = () => createSelector(
  selectCustomerPreferenceDto(),
  (options) => {
    return options.customerType == 'Account';
  }
)

const selectReceiveWebNotification = () => createSelector(
  selectCustomerPreferenceDto(),
  (options) => {
    if(options){
      return options.receiveWebAlerts == true;
    }

    return true;
  }
)

const selectActiveLink = () => createSelector(
  selectUiProps(),
  (ui) => ui.activeLink

);

const selectLoading = () => createSelector(
  selectGlobal(),
  (globalState) => globalState.loading
);
const selectIsLoginPageVisible = () => createSelector(
  selectGlobal(),
  (globalState) => globalState.isLoginPageVisible
);
const selectSnackbarOpen = () => createSelector(
  selectUiProps(),
  (ui) => ui.snackbarOpen
);
const selectNotificationsOpen = () => createSelector(
  selectUiProps(),
  (ui) => ui.notificationsOpen
);
const selectSnackbarMsg = () => createSelector(
  selectUiProps(),
  (ui) => ui.snackbarMsg
);
const selectHideFilterIcon = () => createSelector(
  selectUiProps(),
  (ui) => ui.hideFilterIcon
);

const selectShowFilters = () => createSelector(
  selectUiProps(),
  (ui) => ui.showFilters
);

const selectData = () => createSelector(selectGlobal(),
  (globalState) => globalState.data
);

const selectCustomerContacts = () => createSelector(
  selectData(),
  (data) => data.customerContacts
);


export {
  selectCustomerContacts,
  makeSelectLocationState,
  selectLoading,
  selectSnackbarOpen,
  selectNotificationsOpen,
  selectSnackbarMsg,
  selectCurrentUser,
  selectIsUserLogged,
  selectActiveLink,
  selectHideFilterIcon,
  selectShowFilters,
  selectIsLoginPageVisible,
  selectIsAccountCustomer,
  selectUserName,
  selectCustomerName,
  selectIsUserFeatures,
  selectUserOptions,
  selectMap,
  selectDiscountPackages,
  selectUserNotifications,
  selectReceiveWebNotification
};
