import React from 'react';
import { connect } from 'react-redux';
import GridProducts from '../../components/GridProducts/GridProducts';
import { createStructuredSelector } from 'reselect';
import {
    selectActiveCategoryTab, selectFavoriteDatasource, selectSelectedPackages, selectSelectedPackagesInOrder,
    selectDiscountPackageSubPackages,
    isAnyWaterPackageSelected
} from './selectors';
import { selectPackage, toggleFavorite, toggleAllFavorite, selectTmIntegrationPackage, setAdditionalTextToProduct, setAgreeCheckBoxToProduct } from './actions';

class GridProductContainer extends React.PureComponent {
    render() {
        // name
        // datasource
        // style
        return (
            <GridProducts {... this.props}
                tableClassName={`content-grid products-grid ${this.props.productStyle}-products-grid`}

                selectPackageAction={this.props.selectPackage}
                toggleFavoriteAction={this.props.toggleFavorite}
                toggleAllFavoriteAction={this.props.toggleAllFavorite}
                selectTmIntegrationPackage={this.props.selectTmIntegrationPackage}
                setAdditionalTextToProduct={this.props.setAdditionalTextToProduct}
                setAgreeCheckBoxToProduct={this.props.setAgreeCheckBoxToProduct}

                activeCategoryTab={this.props.activeCategoryTab}
                selectedPackages={this.props.selectedPackages}
                discountPackages={this.props.discountPackages}
                favoritePackages={this.props.favoritesDatasource}
                selectedPackagesInOrder={this.props.selectedPackagesInOrder}
                isAnyWaterPackageSelected={this.props.isAnyWaterPackageSelected}
            />);
    }
}

const mapStateToProps = createStructuredSelector({
    favoritesDatasource: selectFavoriteDatasource(),
    activeCategoryTab: selectActiveCategoryTab(),
    selectedPackages: selectSelectedPackages(),
    selectedPackagesInOrder: selectSelectedPackagesInOrder(),
    discountPackages: selectDiscountPackageSubPackages(),
    isAnyWaterPackageSelected: isAnyWaterPackageSelected(),
})

const mapDispatchToProps = {
    selectPackage,
    toggleFavorite,
    toggleAllFavorite,
    selectTmIntegrationPackage,
    setAdditionalTextToProduct,
    setAgreeCheckBoxToProduct
}
// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(GridProductContainer);
