import PropTypes from 'prop-types';
import React from 'react';
import {
  Grid,
  Columns,
  Column
} from '../Grid'

import {
  ExpDateCellTemplate,
  NavigationColumnTemplate,
  QueriesDetailsColumnTemplate,
  QueryDetailsConversationTemplate
} from './index';
import Pager from '../Pager';

import GridTitle from './GridTitle';
/*
 {
 queryId: 1,
 date: '2017-03-06',
 queryName: 'Land Registry Plan Request',
 queryDetails: {
 line1: '7, HOBBEN CRESCENT, NOTTINGHAM, NG15 8HN', createdBy:'Created 31/03/2017 06:21 AM by Afsheen Azizi'
 },
 customerRef: 'reference',
 status: 'open'
 }
 */
export default class GridQueriesDashboard extends React.PureComponent {
  static defaultProps = {
    showTitle: true
  };
  constructor(props) {
    super(props);
    this.rowTemplateResolver = this.rowTemplateResolver.bind(this);
  }

  rowTemplateResolver(rowData) {
    var cellState = this.props.queryGridNavigationColumnState[rowData.queryId];
    if (cellState && cellState.state == 'open') {
      return 'row-open';
    }

    return '';
  }

  render() {
    var pager;
    if (this.props.showPager === true)
    {
      pager =
        <Pager onChangePage={this.props.onPageChange}
               totalItemsCount={this.props.totalItemsCount}
               currentPage={this.props.currentPage}
               pageSize={this.props.pageSize}
               gridName='queries'/>;
    }
    return (
        <section id="queries" className="content-block content-wrapper">
          {this.props.showTitle
            ? <GridTitle titleText="Open Queries"
                                    itemsCount={this.props.queriesCount}
                                    pageItemsCount={this.props.datasource.length} />:null}
        <Grid
          isLoading={this.props.isLoading}
          datasource={this.props.datasource}
          columnsData={[
            /*{
             header: 'id',
             style: {width: 60},
             dataField: 'queryId',
             },*/
            {
              header: 'date raised',
              style: {width: 60},
              dataField: 'date',
              dataCellTemplate: (props) => (<ExpDateCellTemplate {...props} objType="query"/>)
            },
            {
              header: 'Details',
              dataField: 'queryDetails',
              style: {width: '*'},
              dataCellTemplate: QueriesDetailsColumnTemplate
            },
            {
              header: 'YOUR REF.',
              dataField: 'customerRef',
              className: 'hide-tablet',
              style: {width: 160},
            },
            {
              header: 'Query',
              dataField: 'queryName',
              className: 'hide-tablet',
              style: {width: '*'},
              dataCellTemplate: (props) => (<span>{props.datacontext}</span>)
            },
            {
              header: 'Status',
              dataField: 'status',
              style: {width: 90, textAlign: 'center'},
              dataCellTemplate: (props) => (<NavigationColumnTemplate {...props}
                                                                      gridName={this.props.gridName}
                                                                      queryGridNavigationColumnState={this.props.queryGridNavigationColumnState}
                                                                      showQueryReplyRow={this.props.showQueryReplyRow}
                                                                      hideQueryReplyRow={this.props.hideQueryReplyRow}/>)
            }
          ]}
          // if data contains key as prop value then the template will be applied
          rowTemplates={[
            {
              key: 'queryDetails',
              rowClassName: 'query-row',
              template: (props) => (<QueryDetailsConversationTemplate {...props}
                                                                      gridName={this.props.gridName}
                                                                      queryGridNavigationColumnState={this.props.queryGridNavigationColumnState}
                                                                      addAttachmentToQuery={this.props.addAttachmentToQuery}
                                                                      customerReplyToQuery={this.props.customerReplyToQuery}
                                                                      customerReopenQuery={this.props.customerReopenQuery}
                                                                      changeReplyText={this.props.changeReplyText}
                                                                      deleteQueryFile={this.props.deleteQueryFile}
                                                                      showEarlierMsgs={this.props.showEarlierMsgs}/>)
            }

          ]}
          // use this prop if you want to set class to <tr>
          rowClassNameResolver={this.rowTemplateResolver}>
        </Grid>
          {pager}
        </section>
    );
  }
}

GridQueriesDashboard.propTypes = {
  totalItemsCount: PropTypes.number,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func,
  initialPage: PropTypes.number,
  showPager: PropTypes.bool,
  showTitle: PropTypes.bool
};
