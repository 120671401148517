/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux';
// import { fromJS } from 'immutable';

//import languageProviderReducer from './containers/LanguageProvider/reducer';
import globalReducer from './containers/App/reducer';
/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 *
 */

// // Initial routing state
// const routeInitialState = fromJS({
//   locationBeforeTransitions: null,
// });

// /**
//  * Merge route into the global application state
//  */
// function routeReducer(state = routeInitialState, action) {
//   switch (action.type) {
//     /* istanbul ignore next */
//     case LOCATION_CHANGE:
//       return state.merge({
//         locationBeforeTransitions: action.payload,
//       });
//     default:
//       return state;
//   }
// }

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers = {}) {
  return combineReducers({
    global: globalReducer,
    //route: routeReducer,
    //language: languageProviderReducer,
    ...asyncReducers,
  });
}