import React from 'react';

export default class AZrow extends React.Component {

    handleTabChange(tabName) {
        if (this.props.data[tabName] == undefined || this.props.data[tabName].length == 0) {
            return;
        }

        if (this.props.changeTab) {
            this.props.changeTab(tabName);
        }
    }

    render() {
        var color = 'blue';
        if (this.props.productType == 'com') {
            color = 'purple';
        }

        var alphabet = "abcdefghijklmnopqrstuvwxyz"/*.toUpperCase()*/.split("");
        var letters = alphabet.map((item, index) => {

            /* For inactive tabs, use this */
            var className = `button button-small button-white color-${color}`;
            if (this.props.activeTab == 'la-' + item) {
                /* For active tab, use this */
                className = `button button-small button-${color}`;
            }

            if (this.props.data['la-' + item] == undefined || this.props.data['la-' + item].length == 0) {
                /* For letters with no products available, use this */
                className = "";
            }

            return <li key={index} className={className} onClick={() => this.handleTabChange('la-' + item)}>{item.toUpperCase()}</li>;
        });

        return (

            <ul className="products-grid-tabs">
                {letters}
            </ul>
        );
    }
}
