
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Broken = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M11.5 23.5h12l-6-12zM17.5 16.5V20"/>
          <path d="M17.5 21c-.276 0-.5.224-.5.5s.224.5.5.5.5-.224.5-.5-.224-.5-.5-.5z"/>
          <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
              <path d="M3.5 6.5H8M3.5 9.5h8M3.5 12.5h6M3.5 15.5h5M10 21.5H.5V.5h11l5 5v4"/>
              <path d="M11.5.5v5h5"/>
          </g>
      </g>
  </SvgIconEx>
);
Broken.displayName = 'Broken';
Broken.muiName = 'SvgIcon';

export default Broken;