/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import * as AppConst from './constants';
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REMIND_ME,
  REMIND_ME_SUCCESS,
  REMIND_ME_ERROR, INIT_LOGIN_PAGE, STORE_CUSTOMER_OPTIONS
} from '../Login/constants';
import { REGISTER_USER, REGISTER_USER_ERROR } from '../Register/constants';
import {
  LOAD_USER_CONTACTS, LOAD_USER_CONTACTS_SUCCESS, LOAD_USER_CONTACTS_FAIL,
  UPDATE_CUSTOMER_CONTACT_SUCCESS, UPDATE_PREFERENCE_SETTINGS_SUCCESS
} from '../Preferences/constants';
import { ON_BOARD_SUCCESS } from '../OnBoard/constants';
import {produce} from "immer";

const userstructure = {
  customerPreferenceDto: {
    newOrderCreation: 'template'
  }
}

var userOptions = JSON.parse(localStorage.getItem('userOptions'));
if (userOptions == undefined) {
  userOptions = userstructure
}

// The initial state of the App
const initialState = {
  isLoginPageVisible: false,
  loading: false,
  error: false,
  currentUser: {
    isLogged: sessionStorage.getItem('jwt') != undefined,
    userOptions: userOptions,
    userName: JSON.parse(sessionStorage.getItem('jwt') || '{}').user_name,
    //userFeatures: localStorage.getItem('userFeatures')
  },
  ui: {
    activeLink: 'Dashboard',
    snackbarOpen: false,
    snackbarMsg: '',
    hideFilterIcon: false,
    showFilters: false
  },
  data: {
    customerContacts: [],
    notifications:[]
  },

  /*currentUser: false,
   userData: {
   repositories: false,
   },*/
};

function appReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case AppConst.APP_ERROR:
        draft.loading = false;
        draft.ui.snackbarOpen = true;
        draft.ui.snackbarMsg = action.error;
        break;

      case AppConst.HIDE_APP_SNACK_BAR:
        draft.ui.snackbarOpen = false;
        draft.ui.snackbarMsg = "";
        break;

      case AppConst.SHOW_FILTERS:
        draft.ui.showFilters = !state.ui.showFilters;
        break;

      case AppConst.APP_MENU_NOTIFICATIONS_TOGGLE:
        draft.ui.notificationsOpen = !state.ui.notificationsOpen;
        break;

      case AppConst.APP_MENU_NOTIFICATIONS_REMOVE_ITEM_SUCCESS:
        var removeIndex = state.data.notifications.findIndex(item => item.id == action.note.id);
        draft.data.notifications.splice(removeIndex, 1);
        break;

      case AppConst.APP_MENU_NOTIFICATIONS_REMOVE_ALL_ITEMS_SUCCESS:
        draft.data.notifications = [];
        break;

      case AppConst.HIDE_FILTER_ICON:
        draft.ui.hideFilterIcon = action.value;
        break;

      case LOAD_USER_CONTACTS:
      case REMIND_ME:
      case REGISTER_USER:
      case LOGIN_USER:
        draft.error = "";
        draft.loading = true;
        break;

      case LOGIN_USER_SUCCESS:
      case ON_BOARD_SUCCESS:
        sessionStorage.setItem('jwt', JSON.stringify(action.jwt));
        draft.currentUser.isLogged = true;
        draft.currentUser.userName = action.jwt.user_name;
        break;

      case STORE_CUSTOMER_OPTIONS:
        localStorage.setItem('userOptions', JSON.stringify(action.options));
        draft.currentUser.userOptions = action.options;
        break;

      // use this to clear isLoading
      case LOAD_USER_CONTACTS_SUCCESS:
      case LOAD_USER_CONTACTS_FAIL:
      case REMIND_ME_SUCCESS:
      case REMIND_ME_ERROR:
      case REGISTER_USER_ERROR:
      case LOGIN_USER_ERROR:
      case INIT_LOGIN_PAGE:
        draft.loading = false;
        break;

      case AppConst.APP_USER_LOGOUT:
        sessionStorage.removeItem('jwt');
        localStorage.removeItem('userOptions');
        draft.currentUser.isLogged = false;
        draft.loading = false;
        break;

      case AppConst.LOAD_CUSTOMER_CONTACTS_SUCCESS:
        draft.data.customerContacts = action.values;
        break;

      case AppConst.LOAD_CUSTOMER_NOTIFICATIONS_SUCCESS:
        draft.data.notifications = action.values;
        break;

      case AppConst.IS_LOGIN_PAGE_VISIBLE:
        draft.isLoginPageVisible = action.value;
        break;

      case UPDATE_CUSTOMER_CONTACT_SUCCESS:
        if (action.updatedContact.isPrimaryContact) {
          draft.currentUser.userOptions.customerPreferenceDto.newOrderCreation = action.updatedContact.newOrder;
          return;
        }

        break;

      case UPDATE_PREFERENCE_SETTINGS_SUCCESS:
        draft.currentUser.userOptions.customerPreferenceDto.receiveWebAlerts = action.preferenceSettings.receiveWebAlerts;
        draft.data.notifications = [];
        break;

      default:
        return state;
    }
  });
}

export default appReducer;
