import React, {useEffect, useCallback} from 'react';
import ReactGA from 'react-ga4';
import PageTitle from './PageTitle';
import Alerts from './Alerts';
import Products from './Products';
import Aside from './Aside';
import { loadOrder, changeQueryTab, chaseOrder, chaseProduct, closeReportDialog, raiseQuery, cancelOrder } from './actions';
import { uploadRequest, uploadDeleteRequest } from '../../components/UploadFile/actions';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectOrder, selectAttachments,
    selectActiveQueryTab, selectPackagesCount,
    selectOpenQueryCount, selectClosedQueryCount, selectReports,
    selectOrderChaseButtonState, selectProductChaseButtonState, selectIsReportDialogOpen, selectRaiseButtonVisibility, selectOrderCancelButtonState
} from './selectors';
import { selectIsAccountCustomer } from '../App/selectors';
import GridQueriesInOrderPage from './grids/GridQueriesInOrderPage';
import GridQueriesInOrderPageClosed from './grids/GridQueriesInOrderPageClosed';
import QueriesTitle from './QueriesTitle';
import { Link, useParams } from 'react-router-dom';
import ReportDialog from './ReportDialog';
import { OpenQueryGridName } from './constants';
import ExpandableSection from '../../components/ExpandableSection';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import rootSaga from './sagas';
import detailsReducer from './reducer';
//import { RESTART_ON_REMOUNT } from '../../utils/constants';

const orderSelector= selectOrder();
const attachmentsSelector= selectAttachments();
const activeQueryTabSelector= selectActiveQueryTab();
const productCountSelector= selectPackagesCount();
const openQueryCountSelector= selectOpenQueryCount();
const closedQueryCountSelector= selectClosedQueryCount();
const orderChaseButtonStateSelector= selectOrderChaseButtonState();
const orderCancelButtonStateSelector= selectOrderCancelButtonState();
const productChaseButtonStateSelector= selectProductChaseButtonState();
const isReportDialogOpenSelector= selectIsReportDialogOpen();
const reportsSelector= selectReports();
const isRaiseButtonVisibileSelector= selectRaiseButtonVisibility();
const isAccountCustomerSelector= selectIsAccountCustomer();

export default function OrderDetails(props)/* extends React.PureComponent */ {
    useInjectReducer({ key: 'order-details', reducer: detailsReducer });
    useInjectSaga({ key: 'order-details', saga: rootSaga/*, mode: RESTART_ON_REMOUNT*/ });
    const order= useSelector(orderSelector);
    const attachments= useSelector(attachmentsSelector);
    const activeQueryTab= useSelector(activeQueryTabSelector);
    const productCount= useSelector(productCountSelector);
    const openQueryCount= useSelector(openQueryCountSelector);
    const closedQueryCount= useSelector(closedQueryCountSelector);
    const orderChaseButtonState= useSelector(orderChaseButtonStateSelector);
    const orderCancelButtonState= useSelector(orderCancelButtonStateSelector);
    const productChaseButtonState= useSelector(productChaseButtonStateSelector);
    const isReportDialogOpen= useSelector(isReportDialogOpenSelector);
    const reports= useSelector(reportsSelector);
    const isRaiseButtonVisibile= useSelector(isRaiseButtonVisibileSelector);
    const isAccountCustomer= useSelector(isAccountCustomerSelector);
   
    const dispatch = useDispatch();
    const loadOrderAction = useCallback((orderId) => dispatch(loadOrder(orderId)), [dispatch]);
    const chaseOrderAction = useCallback((info) => dispatch(chaseOrder(info)), [dispatch]);
    const chaseProductAction = useCallback((item) => dispatch(chaseProduct(item)), [dispatch]);
    const uploadRequestAction = useCallback((file, objectId, attachmentType, uploadChannel, options) => dispatch(uploadRequest(file, objectId, attachmentType, uploadChannel, options)), [dispatch]);
    const deleteFileAction = useCallback((fileName, objectId, uploadChannel) => dispatch(uploadDeleteRequest(fileName, objectId, uploadChannel)), [dispatch]);
    const changeQueryTabAction = useCallback((tabName) => dispatch(changeQueryTab(tabName)), [dispatch]);
    const closeReportDialogAction = useCallback(() => dispatch(closeReportDialog()), [dispatch]);
    const raiseQueryAction = useCallback((orderId, gridName, rowTemplateKey) => dispatch(raiseQuery(orderId, gridName, rowTemplateKey)), [dispatch]);
    const cancelOrderAction = useCallback((item) => dispatch(cancelOrder(item)), [dispatch]);

    let { id } = useParams();
    useEffect(() => {
        loadOrderAction(id);
        ReactGA.send({ hitType: "pageview", page: "/order" });
    }, []);

    function handleProductChase(product) {

        const item = {
            orderId: order.orderId,
            packageId: product.id,
            dueDate: product.dueDate
        };

        chaseProductAction(item);
    }

    function handleChase() {
        const info = {
            orderId: order.orderId,
            dueDate: order.dueDate
        };

        chaseOrderAction(info);
    }

    function handleRaiseQuery() {
        raiseQueryAction(order.orderId, OpenQueryGridName, 'queryDetails');
    }

    function handleCancelQuery() {
        cancelQueryAction(order.orderId, OpenQueryGridName, 'queryDetails');
    }

    function _renderAddMorePackages(status) {
        if (isAccountCustomer != true) {
            return null;
        }

        if (status == 'Completed') {
            return null;
        }

        return <Link key={2} to={`/add-products/${order.orderId}`} className="button button-small button-green hide-mobile">Add More Products</Link>;
    }

    function _renderTitle() {
        const status = order.status;
        var arr = [];
        arr.push(<h2 key={1} id="products-count">Products ({productCount})</h2>);
        arr.push(_renderAddMorePackages(status));
        return arr;
    }

    function hideShowMoreProducts() {
        const status = order.status;
        return (status === 'Completed') || (status === 'NotStarted') || (status === 'InProgress');
    }

    if(order==undefined) return null;

    return (
        <div>
            <PageTitle order={order}
                handleChase={handleChase}
                orderChaseButtonState={orderChaseButtonState}
                orderCancelButtonState={orderCancelButtonState}
                raiseQueryAction={handleRaiseQuery}
                cancelOrderAction={cancelOrderAction}
                isAccountCustomer={isAccountCustomer} />
            <div className="content-wrapper details-wrapper">

                <section className="details-main">
                    {/* Products */}
                    <div className="content-block">
                        {hideShowMoreProducts() ?
                            <ExpandableSection className='' titleContent={_renderTitle()} isExpandable={true}>
                                <Alerts orderId={order.orderId}
                                    status={order.status}
                                />
                            </ExpandableSection> : null}
                        <Products 
                            order={order}
                            
                            //loadPackageReports = {loadPackageReports}
                            handleProductChase={handleProductChase}
                            productChaseButtonState={productChaseButtonState} />
                    </div>
                    {/* Queries */}
                    <div className="content-block">
                        <QueriesTitle activeTab={activeQueryTab}
                            tabChanged={changeQueryTabAction}
                            openQueryCount={openQueryCount}
                            closedQueryCount={closedQueryCount}
                            raiseQueryAction={handleRaiseQuery}
                            isRaiseButtonVisibile={isRaiseButtonVisibile} />
                        <div className="content-block-grid">
                            {activeQueryTab == 'open' ?
                                <GridQueriesInOrderPage />
                                :
                                <GridQueriesInOrderPageClosed />
                            }
                        </div>
                    </div>
                </section>
                <Aside order={order} addAttachmentToOrder={uploadRequestAction}
                    deleteAttachmentFromOrder={deleteFileAction} attachments={attachments} />
            </div>
            <ReportDialog open={isReportDialogOpen} reports={reports} closeDialog={closeReportDialogAction} />
        </div>
    )
}