import React from 'react';
import ReactGA from 'react-ga4';
import Info from '../../svg-icons/info';
import { blue100, purple100 } from "../../assets/less/base/_variables.js";

class ProductColumnTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = { onlyInFavTab: false, checkboxLock: true };
    }

    componentDidMount() {
        if (this.props.gridName === 'gridResFavorite') {
            this.setState({ onlyInFavTab: true });
            this.checkForTerms();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.onlyInFavTab &&
            this.props.row.products !== null &&
            this.props.row.products[0].termsRequired === true &&
            this.props.row.products[0]['termsRequiredChecked'] !== prevProps.row.products[0]['termsRequiredChecked']
        ) {
            this.props.row.products[0]['termsRequiredChecked'] = prevProps.row.products[0]['termsRequiredChecked']
            this.checkForTerms();
        }
    }

    checkForTerms() {
        let termsReq = false;
        if (
            this.props.row.products !== null &&
            this.props.row.products[0].termsRequired === true &&
            this.props.row.products[0]['termsRequiredChecked'] === undefined
        ) {
            termsReq = true;
        }
        this.setState({ checkboxLock: termsReq });
    }

    /***
     * Checks if package is selected.
     * Package is selected when participate in selected packages
     * or is already selected as package in order (when added more packages to order)
     */
    isSelected(packageId){
        var isSelected = this.props.selectedPackages[packageId] && this.props.selectedPackages[packageId].value === true ? true : false;

        if (this.props.selectedPackagesInOrder != undefined) {
            return this.props.selectedPackagesInOrder[packageId] || isSelected;
        }
        else {
            return isSelected;
        }
    }

    isDisabled(packageId, isSelected) {
        if (this.props.selectedPackagesInOrder == undefined) {
            return false;
        }

        var isDisabled = this.props.selectedPackagesInOrder[packageId];

        // CON29DW Residential Expedited
        // CON29DW Residential Refresh Report
        // CON29DW Residential printed copy 
        // are type water-ex
        if (!isSelected && (this.props.row.type == 'water' || this.props.row.type == 'water-ex')) {
            if (this.props.isAnyWaterPackageSelected == true) {
                isDisabled = true;
            }
        }

        //
        // Check discount packages
        if (this.props.discountPackages == undefined) {
            return isDisabled || false;
        }

        isDisabled = this.props.discountPackages[packageId] || isDisabled;
        return isDisabled || false;
    }

    handleOnChange(packageId, value) {
        this.props.selectPackageAction(
            packageId,
            value,
            this.props.gridName,
            (this.props.row.products !== null ? this.props.row.products[0].termsRequired : false));
        if (value === true) {
            const userName = JSON.parse(sessionStorage.getItem('jwt')).user_name;
            ReactGA.event({
                category: 'Selecting products',
                action: 'click on product from ' + this.props.gridName,
                label: 'user: ' + userName
            });
        }
    }

    render() {
        var row = this.props.row;

        if (row == undefined) {
            return null;
        }

        var stroke = blue100;// '#009FE3';
        var classEx = 'residential';

        if (this.props.activeCategoryTab == 'com') {
            classEx = 'commercial';
            stroke = purple100;//'#87189D';
        }
        else if (this.props.activeCategoryTab == 'dis') {
            classEx = 'package';
        }

        var packageId = row['id'];
        var isSelected = this.isSelected(packageId);// this.props.selectedPackages[row['id']] && this.props.selectedPackages[row['id']].value === true ? true : false;
        var isDisabled = this.isDisabled(packageId, isSelected);

        //isSelected = isSelected || isDisabled;
        return (
            <div className="cell-wrapper">
                <label htmlFor={this.props.gridName + "-product-" + packageId}
                    className={`form-checkbox ${isDisabled ? 'disabled' : null}`}> {/* Add className 'disabled' if product should be locked for removal (e.g. when users add new products to existing orders, we want to mark all products already in order but disable the users to remove them if they are in progress) */}
                    <input id={this.props.gridName + "-product-" + packageId} type="checkbox" disabled={isDisabled}
                        checked={isSelected}
                        onChange={(event) => this.handleOnChange(packageId, event.target.checked)} /> {/* Add attr 'disabled' if product should be locked for removal */}

                    <span className="indicator"></span>
                    <span className="label">{row.name}</span>
                </label>
                <div className="popover-icon hide-mobile">
                    <div className={"popover popover-description popover-" + classEx}>
                        <p>{row.description}</p>
                    </div>
                    <Info className={"icon icon-attention icon-attention-" + classEx}
                        stroke={stroke} /> {/* Show L15-L17 when mouse over this */}
                </div>
            </div>
        );
    }
}
;

export default ProductColumnTemplate;
