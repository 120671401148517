import React from 'react';
import ReactGA from 'react-ga4';
import GridQuotesDashboard from '../../components/GridQuotes/GridQuotesDashboard';
import {
    selectQuotes,
    selectTotalQuotesCount,
    selectIsLoading,
    selectCurrentPage
} from './selectors';
import {selectShowFilters} from '../App/selectors';
import {createStructuredSelector} from 'reselect';
import * as actions from './actions';
import {connect} from 'react-redux';
import Filters from '../../components/Filters';
import {selectCustomerContacts} from '../App/selectors';
import {loadCustomerContacts} from '../App/actions';
import DeleteQuoteDialog from './DeleteQuoteDialog';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import homeSaga from './sagas';
import homeReducer from './reducer';
import { compose } from '@reduxjs/toolkit';
//import { RESTART_ON_REMOUNT } from '../../utils/constants';

export class Quotes extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleQuotesGridPageChange = this.handleQuotesGridPageChange.bind(this);
        this.openDeleteQuoteConfirmationDialog = this.openDeleteQuoteConfirmationDialog.bind(this);
        this.closeDeleteQuoteConfirmationDialog = this.closeDeleteQuoteConfirmationDialog.bind(this);

        this.state = {
            showFilterClearButton: false,
            openDialog: false,
            quoteToDelete: 0,
            quoteToDeleteAddress: ''
        }

        this.filterInfo={};
    }

    componentDidMount() {
        this.props.loadQuotes({startIndex: 0, pageSize: 10});
        this.props.loadCustomerContacts();
        //ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.send({ hitType: "pageview", page: "/quotes" });
    }

    executeSearch = (filterInfo) => {
        this.filterInfo = filterInfo;
        this.props.loadQuotes({
            dateCreated: filterInfo.dateCreated,
            filterText: filterInfo.filterText,
            createdBy: filterInfo.createdBy,
            startIndex: 0,
            pageSize: 10,
        });
    }

    clearSearch = ()=>{
        this.filterInfo = {};
        this.props.loadQuotes({startIndex: 0, pageSize: 10});
    }

    getCustomerContactsFilterData() {
        const modifiedCustContacts = [];

        for (let i = 0; i < this.props.customerContacts.length; i++) {
        modifiedCustContacts.push({
            id: this.props.customerContacts[i].id,
            text: this.props.customerContacts[i].name
        });
        }
        return modifiedCustContacts;
    }

    handleQuotesGridPageChange(startIndex, pageSize, gridName, currentPage) {
        this.props.loadQuotes({
            startIndex: startIndex,
            pageSize: pageSize,
            dateCreated: this.filterInfo.dateCreated,
            filterText: this.filterInfo.filterText,
            createdBy: this.filterInfo.createdBy,
            currentPage: currentPage
        });
    }

    openDeleteQuoteConfirmationDialog(quote) {
        const address = quote.address.line1;
        this.setState({
            quoteToDeleteId: quote.id,
            quoteToDeleteAddress: address,
            openDialog: true
        });
    }

    closeDeleteQuoteConfirmationDialog() {
        this.setState({openDialog: false});
    }

    render() {
        return (
        <React.Fragment>
            <Filters dropdownCreatedByDataSource={this.getCustomerContactsFilterData()}
                executeSearchAction = {this.executeSearch}
                filterClearedAction = {this.clearSearch}
                showFilterProp={this.props.showFilterProp}/>
            <GridQuotesDashboard
                totalItemsCount={this.props.totalQuotesCount}
                datasource={this.props.quotes}
                showPager={true}
                currentPage={this.props.currentPage}
                onPageChange={this.handleQuotesGridPageChange}
                openDeleteQuoteDialog={this.openDeleteQuoteConfirmationDialog}
                isLoading={this.props.isLoading}
                showTitle = {false}/>
            <DeleteQuoteDialog isShown={this.state.openDialog}
                handleCancel={this.closeDeleteQuoteConfirmationDialog}
                quoteId={this.state.quoteToDeleteId}
                quoteAddress={this.state.quoteToDeleteAddress}/>
        </React.Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    quotes: selectQuotes(),
    totalQuotesCount: selectTotalQuotesCount(),
    customerContacts: selectCustomerContacts(),
    showFilterProp: selectShowFilters(),
    isLoading: selectIsLoading(),
    currentPage: selectCurrentPage()
})
const mapDispatchToProps = ({
    ...actions,
    loadCustomerContacts: loadCustomerContacts
})
// Wrap the component to inject dispatch and state into it
//export default connect(mapStateToProps, mapDispatchToProps)(Quotes);
var withSaga = injectSaga({ key: 'quotes', saga: homeSaga/*, mode: RESTART_ON_REMOUNT*/ });
var withReducer = injectReducer({ key: 'quotes', reducer: homeReducer });

// Wrap the component to inject dispatch and state into it
export default compose(
  withSaga,
  withReducer,
  connect(mapStateToProps, mapDispatchToProps)
)(Quotes);