import React from 'react';
import Popover from '@mui/material/Popover';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import request from '../../utils/request';

export default class AddressSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            popoverOpen: false,
            dataSource: [],
            textValue: '',
            textNotFound: 'No addresses were found. Please change your search criteria.',
            popoverAnchorEl: null
        };

        this.inputRef = React.createRef();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleOnFocus = this.handleOnFocus.bind(this);
        this.handleMenuItemSelected = this.handleMenuItemSelected.bind(this);
        this.handleRequestClosePopover = this.handleRequestClosePopover.bind(this);
        this.timeout = null;
    }

    doneTyping(text) {
        const self = this;
        //const dropDownValue = this.state.filterByDropDownItemSelected;
        const searchURL = this.props.endPointUrl;
        const completeURL = searchURL + '?keyword=' + encodeURIComponent(text) + (this.props.extraQueryString || '');// + '&searchBy=' + dropDownValue
        // need to be not auth request
        if (this.props.makeAuthRequest) {
            request
                .getAuth(completeURL)
                .then((result) => {
                    self.setState({ dataSource: result, popoverOpen: true, isloading: false });
                    self.textBox.focus();
                })
                .catch(e => {
                    self.setState({ dataSource: [{ buildingName: this.state.textNotFound }], popoverOpen: true, isloading: false });
                    self.textBox.focus();
                });
        }
        else {
            // Minumim 3 chars
            if (text.length >= 3) {
                request
                    .get(completeURL)
                    .then((result) => {
                        self.setState({ dataSource: result, popoverOpen: true, isloading: false });
                        self.textBox.focus();
                    })
                    .catch(e => {
                        self.setState({ dataSource: [{ buildingName: this.state.textNotFound }], popoverOpen: true, isloading: false });
                        self.textBox.focus();
                    });
            }
        }
    }

    handleInputChange(e) {
        if (this.props.onUpdateInput) {
            this.props.onUpdateInput(e.target.value);
        }

        this.setState({ popoverAnchorEl: e.target, textValue: e.target.value, isloading: true });
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            this.doneTyping(this.state.textValue);
        }, 500);
    }

    handleOnFocus(e) {
        if (this.skipFocus == true) {
            this.skipFocus = false;
            return;
        }
        // PE7 1RE
        const self = this;
        if (this.state.dataSource.length > 0) {
            // self.setState({ dataSource: this.state.dataSource, popoverOpen: true, isloading: false, popoverAnchorEl: e.target });
            // self.textBox.focus();
            if (this.state.dataSource[0].buildingName != this.state.textNotFound) {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    // this.setState({ popoverOpen: true });
                    console.log('handleOnFocus ', this.textBox)
                    self.setState({ dataSource: this.state.dataSource, popoverOpen: true, isloading: false, popoverAnchorEl: this.textBox });
                }, 300);
            }
        }
    }
    handleMenuItemSelected(itemIndex) {
        if (this.props.onMenuItemSelected) {
            var address = this.state.dataSource[itemIndex];

            // initialy added for 560 but seems gbg always return same descrpition as street name. So 688 was submited. 
            // 560. Exclude street descriptions from GB data results.
            // sometime gbg returns street desc as street name. so if desc and name are the same then clear street name
            /*var street = address.thoroughfare;
            var streetDesc = address.streetDesc;
            if(street && streetDesc){
                let s = street.replace(/ /g, ''); // remove all spaces
                let sd = streetDesc.replace(/ /g, ''); // remove all spaces
                if(sd.toLowerCase().indexOf(s.toLowerCase(), 0) !== -1){
                    street = "";
                }
            }

            address.thoroughfare = street;*/

            this.props.onMenuItemSelected(address);
            // this.textBox.focus();

            // if(address.isValid===true){
            //     this.props.onMenuItemSelected(address);
            // }
            // else{
            //     this.textBox.focus();
            // }
        }

        this.setState({ popoverOpen: false, popoverAnchorEl: null });
        this.skipFocus = true;
    }

    handleRequestClosePopover() {
        this.setState({ popoverOpen: false });
        this.skipFocus = true;
    }

    renderPopoverChildren() {
        return (
            <Menu
                PaperProps={{
                    style: {
                      maxHeight: 450,
                      width: this.textBox != undefined ? this.textBox.offsetWidth : 0,
                      overflowX:'auto'
                    },
                  }}
                disableAutoFocus={true}
                anchorEl={this.state.popoverAnchorEl}
                onClose={this.handleRequestClosePopover}
                open={this.state.popoverOpen}>
                {this.state.dataSource.map((item, index) => {
                    let newText = this.props.formatMenuItem(item).trim();
                    let test = newText.slice(-2);
                    if (test === '.,') {
                        newText = newText.substring(0, newText.length - 1);
                    }
                    return <MenuItem className="search-results" key={index} onClick={() => this.handleMenuItemSelected(index)}>{newText}</MenuItem>
                }
                )}
            </Menu>
        );
    }

    static defaultProps = {
        containerClassName: "content-block-form align-center",
        internalDivClassName: "form-control-60",
        placeholder: 'Enter your postcode and select a matching address.',
        inputClassName: 'form-control-grey',
        label: 'Postcode search',
        isRequired: true,
        makeAuthRequest: false
    }

    render() {
        return (
            <div className={this.props.containerClassName}>
                <div className={`entry entry-search form-masked-label ${this.props.internalDivClassName}`}>
                    {this.state.isloading === true ? <div className="loader"></div> : null}
                    <input id="filter-postcode" type="search"
                        placeholder={this.props.placeholder}
                        className={this.props.inputClassName}
                        onChange={this.handleInputChange}
                        onFocus={this.handleOnFocus}
                        ref={input => this.textBox = input}
                        value={this.state.textValue}
                        required={this.props.isRequired}
                    />
                    <label htmlFor="filter-postcode">{this.props.label}</label>
                    {this.props.extraInfo}
                    {/* <Popover open={this.state.popoverOpen}
                        anchorEl={this.textBox}//{this.state.popoverAnchorEl}
                        //useLayerForClickAway={false}
                        onClose={this.handleRequestClosePopover}
                        style={style}> */}
                    {this.renderPopoverChildren()}
                    {/* </Popover> */}
                </div>
            </div>
        );
    }
}
