import keys from 'lodash/keys';
import moment from 'moment';

export function calculateSLADate(selectedPackageIds, allPackages) {
    if(allPackages && keys(allPackages).length == 0)
        return moment();

    var packages = [];
    keys(selectedPackageIds).forEach(p => {
        packages.push(allPackages[p]);
    });

    return calculateSLADatePackages(packages);
}

export function calculateSLADatePackages(packages){
    var maxSla = -1;
    packages.forEach(pack => {
        if(pack.packageSLA > maxSla)
        {
            maxSla = pack.packageSLA;
        }
    });

    var now = moment();
    var slaDate = moment();
    while (maxSla > 0)
    {
        slaDate = slaDate.add(1, 'day');
        now.add(1, 'day');
        if(now.isoWeekday() == 6 || now.isoWeekday() == 7)
        {
            continue;
        }

        maxSla--;
    }

    return slaDate;
}
