
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Trash = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path d="M3.5 3.5h16v20h-16zM7.5.5h8v3h-8zM1 3.5h22M7.5 7v12M11.5 7v12M15.5 7v12"/>
      </g>
  </SvgIconEx>
);
Trash.displayName = 'Trash';
Trash.muiName = 'SvgIcon';

export default Trash;