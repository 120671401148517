import React from 'react';
import NewOrderProductsResidential from './NewOrderProductsResidential';
import NewOrderProductsDiscount from './NewOrderProductsDiscount';
import AddressSearch from '../../components/AddressSearch/AddressSearch';
import config from '../../utils/config';

export default class NewOrderProducts extends React.PureComponent {
    handleTabChanged(tabName) {
        if (this.props.changeTab) {
            this.props.changeTab(tabName);
        }
    }

    handlePackageSelected = (slectedPackage) => {
        this.props.selectPackage(slectedPackage.id, true);
    }

    formatMenuItem(item) {
        return item.name;
    }

    render() {
        return (

            <div className="content-block">
                <div className="content-block-title"> {/* Add className "fixed" when users scroll down and this reaches the top of the screen */}
                    <h2>Products</h2>
                    <ul className="content-grid-toggle"> {/* Clicking these options toggles visibility of product groups */}
                        <li className={this.props.activeTab == 'res' ? "text-link active" : "text-link"} onClick={() => this.handleTabChanged('res')}>Residential</li> {/* Add className 'active' when clicked to show L21-L23 */}
                        <li className={this.props.activeTab == 'com' ? "color-purple active" : 'color-purple'} onClick={() => this.handleTabChanged('com')}>Commercial</li> {/* Add className 'active' when clicked to show L26-L28 */}
                        {(this.props.hideDiscountPackages === "true") ?
                            null :
                            <li className={this.props.activeTab == 'dis' ? "color-pink active" : 'color-pink'} onClick={() => this.handleTabChanged('dis')}>Discount Packages</li>
                        }{/* Add className 'active' when clicked to show L31-L33 */}
                    </ul>
                </div>
                <div className="content-block-form no-padding">
                    {/* Products Search */}
                    <AddressSearch containerClassName='filters-form' internalDivClassName='form-control-icon' isRequired={false}
                        placeholder='Search products by name' inputClassName='form-control-white' label='Product Search'
                        onMenuItemSelected={this.handlePackageSelected}
                        formatMenuItem={this.formatMenuItem}
                        endPointUrl={`${config.webApiUrl}/package/getFilteredPackages`} extraQueryString={this.props.activeTab == 'res' ? '&isResidential=true' : '&isResidential=false'} makeAuthRequest={true} />
                    {/*<div className="filters-form" >
                        <div className="entry entry-search form-masked-label form-control-icon">
                            <input id="product-search" type="search"
                            placeholder="Search {'residential products', 'commerical products' or 'discount packages'} by name"
                             className="form-control-white"/>
                            <label htmlFor="product-search">Product Search</label>
                        </div>
                    </div>*/}

                    {/* If showing residentail products: */}
                    {this.props.activeTab == 'res' ? <NewOrderProductsResidential /> : null}

                    {/* If showing commercial products */}
                    {this.props.activeTab == 'com' ? <NewOrderProductsResidential /> : null}

                    {/* If showing discount pacakges */}
                    {this.props.activeTab == 'dis' ? <NewOrderProductsDiscount /> : null}

                    <p className="products-disclaimer">* Products advertised as POA (Price on Application) are not free and have a chargeable fee which is to be confirmed. In order to obtain confirmation of the price please continue with your purchase and a customer service representative will contact you in order to confirm the fee. At this time, you will have the option of cancelling the purchase of the respective product, before your order is processed.</p>
                </div>
            </div>
        );
    }
}
