import React from 'react';
import {Link} from 'react-router-dom';
import Info from '../../svg-icons/info';
import {blue100, purple100} from "../../assets/less/base/_variables.js";

// it is used in quotes grid
let  PackagesColumnTemplate = (props) => {
    var datacontext = props.datacontext;
    if(datacontext == undefined){
        return null;
    }

     var residentialStyle ='commercial';
     if(datacontext.isResidential == true){
         residentialStyle = 'residential';
     }

    var products = datacontext.products.map((item, index)=>{
        return <li key={index}>
                    <strong className={`color-${residentialStyle}`}>{item.name}</strong><br/>
                    <span>(£{item.amount - item.discountAmount} + VAT)</span>
                </li>
    })

    return (
         <div className="cell-wrapper">
            <div className="text-product">{datacontext.products.length}</div>
            <div className="popover-icon">
                <div className="popover popover-products" >
                    <ul>
                        {products}
                    </ul>
                     <Link to={`/quote/${props.row.id}`} className="button button-small button-green">Add More Products</Link>
                </div>
                <Info className={`icon icon-attention icon-attention-${residentialStyle}`}/>
            </div>
        </div>
)}

export default PackagesColumnTemplate;
