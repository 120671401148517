import PropTypes from 'prop-types';
import React from 'react';
import {
  Grid,
  Columns,
  Column
} from '../Grid'

import {
  ExpDateCellTemplate,
  NavigationColumnTemplate,
  AddressColumnTemplate,
  PackagesColumnTemplate
} from './index';
import { formatPrice } from '../../utils/format';
import GridTitle from './GridTitle';
import Pager from '../Pager';

export default class GridQuotesDashboard extends React.PureComponent {
    static defaultProps = {
        showTitle: true
    };

  render() {
    var pager;

    if (this.props.showPager === true) {
      pager =
        <Pager onChangePage={this.props.onPageChange}
               totalItemsCount={this.props.totalItemsCount}
               currentPage={this.props.currentPage}
               pageSize={this.props.pageSize}
               gridName='orders'/>;
    }


    return (
      <section id="quotes" className="content-block content-wrapper">
        {this.props.showTitle ? <GridTitle {...this.props} /> : null}
        <Grid
          isLoading={this.props.isLoading}
          datasource={this.props.datasource}
          columnsData={[
            {
              header: 'exp.',
              //className: 'cell-60',
              style: {width: 60},
              dataField: 'expireDate',
              dataCellTemplate: (props) => (<ExpDateCellTemplate {...props}/>)
            },
            {
              dataField: 'address',
              header: 'Address',
              style: {width: '*'},
              dataCellTemplate: AddressColumnTemplate
            },
            {
              dataField: 'packages',
              header: 'Products',
              className: 'hide-mobile',
              style: {width: 100, textAlign: 'right', paddingLeft: 45},
              dataCellTemplate: PackagesColumnTemplate
            },
            {
              dataField: 'quoteValue',
              header: 'Price (Inc Vat)',
              className: 'hide-mobile',
              style: {width: 120, textAlign: 'right'},
              dataCellTemplate: (props) => (
                <div className="text-price">{formatPrice(props.row.orderDto.orderValue)}</div>)
            },
            {
              style: {width: 180, textAlign: 'center'},
              className: 'hide-tablet',
              dataCellTemplate: (props) => (
                <NavigationColumnTemplate {...props} openDeleteQuoteDialog={this.props.openDeleteQuoteDialog}/>)
            }
          ]}>
        </Grid>
        {pager}
      </section>
    );
  }
}

GridQuotesDashboard.propTypes = {
  totalItemsCount: PropTypes.number,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func,
  initialPage: PropTypes.number,
  showPager: PropTypes.bool,
  openDeleteQuoteDialog: PropTypes.func,
  showTitle: PropTypes.bool,
};
