
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class SvgIconEx extends Component {
  static defaultProps = {
      viewBox: '0 0 24 24',
      color: 'none',
      //fill: 'none',
      //stroke: '#53565A'
  };

  render(){
  const {
      children,
      //style,
      viewBox,
      color,
      style,
      stroke,
      fill,
      ...other
    } = this.props;

    const mergedStyles = Object.assign({
      //height: 20,
      //width: 20,
      color,
      stroke,
      fill
    }, style);


     return (
      <svg {...other} viewBox={viewBox} style={mergedStyles}>
          {children}
      </svg>
    );
  }
}