import * as ActionTypes from './constants';
import { combineReducers } from 'redux';
import { create } from '../Queries/queryGridReducer';
import { createUploadReducer } from '../../components/UploadFile/reducer';
import { produce } from "immer";

const initialState = {

    order: {},
    //queries:[],
    /*queryGrid:*/
};

const initialStateUI = {
    activeQueryTab: 'open',
    alerts: [],
    selectedAlerts: {},
    orderChaseButtonState: {},
    orderCancelButtonState: {},
    productChaseButtonState: {},
    productCancelButtonState: {},
    isReportDialogOpen: false,
    reports: [],
    isSaving: false,
    showRaiseQueryButton: true
};

export function uiReducer(state = initialStateUI, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case ActionTypes.ORDER_DETAILS_RAISE_QUERY:
                draft.showRaiseQueryButton = false;
                break;
            case ActionTypes.ORDER_DETAILS_SUBMIT_RAISE_QUERY_SUCCESS:
            case ActionTypes.ORDER_DETAILS_RAISE_QUERY_CANCELLED:
                draft.showRaiseQueryButton = true;
                break;
            case ActionTypes.ORDER_DETAILS_CHANGE_QUERY_TAB:
                draft.activeQueryTab = action.tabName;
                break;
            case ActionTypes.LOAD_ORDER_DETAILS:
                draft.isLoading = true;
                break;

            case ActionTypes.LOAD_ORDER_DETAILS_SUCCESS:
            case ActionTypes.LOAD_ORDER_DETAILS_ERROR:
                draft.isLoading = false;
                break;
            case ActionTypes.ORDER_DETAILS_SET_ALERT_DATASOURCE:
                draft.alerts = action.alerts;
                draft.selectedAlerts = {};
                break;

            case ActionTypes.ORDER_DETAILS_SELECT_ALERT:
                if (action.value) {
                    var id = action.packageId;
                    draft.selectedAlerts[id] = { value: true };
                    return;
                }

                // check this
                console.log(draft.selectedAlerts)
                delete draft.selectedAlerts[action.packageId];
                break;

            case ActionTypes.ORDER_CHASE:
                draft.orderChaseButtonState[action.info.orderId] = { 'chasing': true, 'showChasedLabel': false, 'chased': false };
                break;
            case ActionTypes.ORDER_CHASE_SUCCESS:
                draft.orderChaseButtonState[action.orderId] = { 'chasing': false, 'showChasedLabel': true, 'chased': false };
                break;
            case ActionTypes.ORDER_CHASE_FAIL:
                draft.orderChaseButtonState[action.orderId] = { 'chasing': false, 'showChasedLabel': false, 'chased': false };
                break;
            case ActionTypes.ORDER_CHASE_HIDE_LABEL:
                draft.orderChaseButtonState[action.orderId] = { 'chasing': false, 'showChasedLabel': false, 'chased': true };
                break;

            case ActionTypes.PACKAGE_CHASE:
                draft.productChaseButtonState[action.item.packageId] = { 'chasing': true, 'showChasedLabel': false, 'chased': false };
                break;
            case ActionTypes.PACKAGE_CHASE_SUCCESS:
                draft.productChaseButtonState[action.packageId] = { 'chasing': false, 'showChasedLabel': true, 'chased': false };
                break;
            case ActionTypes.PACKAGE_CHASE_FAIL:
                draft.productChaseButtonState[action.packageId] = { 'chasing': false, 'showChasedLabel': false, 'chased': false };
                break;
            case ActionTypes.PACKAGE_CHASE_HIDE_LABEL:
                draft.productChaseButtonState[action.packageId] = { 'chasing': false, 'showChasedLabel': false, 'chased': true };
                break;
            case ActionTypes.PACKAGE_CANCEL:
                draft.productCancelButtonState[action.item.packageId] = { 'canceling': true, 'showCancelLabel': false };
                break;
            case ActionTypes.PACKAGE_CANCEL_SUCCESS:
                draft.productCancelButtonState[action.packageId] = { 'canceling': false, 'showCancelLabel': true };
                break;
            case ActionTypes.PACKAGE_CANCEL_FAIL:
                draft.productCancelButtonState[action.packageId] = { 'canceling': false, 'showCancelLabel': false };
                break;
            case ActionTypes.ORDER_CANCEL:
                draft.orderCancelButtonState[action.item.orderId] = { 'canceling': true, 'showCancelLabel': false };
                break;
            case ActionTypes.ORDER_CANCEL_SUCCESS:
                draft.orderCancelButtonState[action.orderId] = { 'canceling': false, 'showCancelLabel': true };
                break;
            case ActionTypes.ORDER_CANCEL_FAIL:
                draft.orderCancelButtonState[action.orderId] = { 'canceling': false, 'showCancelLabel': false };
                break;


            case ActionTypes.LOAD_PACKAGE_REPORTS_SUCCESS:
                draft.isReportDialogOpen = true;
                draft.reports = action.reports;
                break;

            case ActionTypes.CLOSE_PACKAGE_REPORT_DIALOG:
                draft.isReportDialogOpen = false;
                break;
            case ActionTypes.ORDER_DETAILS_ADD_SELECTED_PACKAGES_TO_ORDER:
                draft.isSaving = true;
                break;
            case ActionTypes.ORDER_DETAILS_ADD_SELECTED_PACKAGES_TO_ORDER_SUCCESS:
                draft.isSaving = false;
                break;
        }
    });
}

export function orderReducer(state = initialState, action) {
    if (action.uploadChannel != undefined && action.uploadChannel != ActionTypes.OrderDetailsUploadChannel) {
        return state;
    }
    return produce(state, (draft) => {
        switch (action.type) {
            case ActionTypes.LOAD_ORDER_DETAILS_SUCCESS:
                draft.order = action.order;
                break;

            case ActionTypes.ORDER_CANCEL_SUCCESS:
                draft.order.packages.forEach(item => {
                    if (item.status != 3 && item.status != 4 && item.status != 7) {
                        item.status = 6;
                        item.statusString = 'Awaiting Response';
                    }
                });
                break;
        }
    });
}

export default combineReducers({
    ui: uiReducer,
    order: orderReducer,
    queryGrid: create(ActionTypes.OpenQueryGridName), // pass grid name as argument also in that reducer is where datasource of query grid is set
    queryGridClosed: create(ActionTypes.ClosedQueryGridName),
    upload: createUploadReducer(action => {
        if (action.uploadChannel != undefined && action.uploadChannel != ActionTypes.OrderDetailsUploadChannel) {
            return false;
        }

        return true;
    })
})
