import { createSelector } from 'reselect';
const selectLogin = () => (state) => {
  return state.login||{};
}
const selectErrorMgs = () => createSelector(
  selectLogin(),
  (homeState) => homeState.showErrorMsg
);

const selectShowForgottenPswdInitalForm = () => createSelector(
  selectLogin(),
  (homeState) => homeState.showForgottenPswdInitalForm
);

const selectShowForgottenPswdError = () => createSelector(
  selectLogin(),
  (homeState) => homeState.showForgottenPswdError
);

const selectActiveView = () => createSelector(
  selectLogin(),
  (homeState) => homeState.activeView
);

const selectShowNotification = () => createSelector(
  selectLogin(),
  (homeState) => homeState.showNotification
);
const selectNotificationMsg = () => createSelector(
  selectLogin(),
  (homeState) => homeState.notificationMsg
);
export {
  selectErrorMgs,
  selectShowForgottenPswdInitalForm,
  selectShowForgottenPswdError,
  selectActiveView,
  selectShowNotification,
  selectNotificationMsg
}