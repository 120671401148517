import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';

export default class LogoutItem extends React.Component { // eslint-disable-line react/prefer-stateless-function

  handleLogout = (event)=>{
    event.preventDefault();
    if(this.props.logout){
      this.props.logout();
    }
    // Action tracking with GA
    ReactGA.event({
      category: 'User Actions',
      action: 'User logout',
    });
  }

  render(){
    return (
        <li className ='navbar-logout'>
            <Link to="/login" onClick={this.handleLogout} className="color-red">Logout</Link>
        </li>
     );
   }
}

LogoutItem.propTypes ={
    logout: PropTypes.func.isRequired
}
