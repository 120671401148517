
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Clear = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path d="M.5.5l23 23M23.5.5l-23 23"/>
      </g>
  </SvgIconEx>
);
Clear.displayName = 'Clear';
Clear.muiName = 'SvgIcon';

export default Clear;