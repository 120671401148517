import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { formatPrice } from '../../utils/format';
import { selectOrderData, selectShowError } from './selectors';
import { Link, useParams } from 'react-router-dom';
import { selectCustomerContacts } from '../App/selectors'
import Attachment from '../../svg-icons/attachment';
import config from '../../utils/config';
import keys from 'lodash/keys';
import DownloadLink from '../../components/DownloadLink';
import { useInjectReducer } from 'utils/injectReducer';
import detailsReducer from './reducer';
const createdOrderSelector = selectOrderData();
const customerContactsSelector = selectCustomerContacts();
const showErrorSelector = selectShowError();

export default function OrderConfirmationInternal(props) {
    useInjectReducer({ key: 'order-confirmation', reducer: detailsReducer });
    const createdOrder = useSelector(createdOrderSelector);
    const customerContacts = useSelector(customerContactsSelector);
    const showError = useSelector(showErrorSelector);
    
    function renderCustomerProp(prop) {
        const contact = customerContacts.filter(c => {
            return c.id == createdOrder.contactPersonId;
        })[0];

        if (contact == undefined) {
            return '';
        }
        return contact[prop];
    }

    function renderUploadedPlans() {
        return createdOrder.attachments.map((a, index) => {
            //var url = `${config.webApiUrl}/file/download?filePath=${a.fileLocation}`;
            var url = `${config.webApiUrl}/file/download?filePath=`;
            return (
                <li key={index}>
                    <DownloadLink url={url} file={a.fileLocation} className="button button-small button-text-icon button-white color-teal" showFileInDialog={a.isNotDownloadable === true}>
                        <Attachment className="icon icon-attachment" role="img" />
                        <div className="file-label">{a.name}</div>
                    </DownloadLink>
                </li>
            );

        });
    }

    function renderPriceLines(priceLines) {
        return priceLines.map((price, j) => {
            //return <span style={{display:'block'}} key={j}>{pl.defaultPrice}</span>
            return <span style={{ display: 'block' }} key={j}>{formatPrice(price.defaultPrice)}{price.vatRate != 0 ? ' + ' + formatPrice(price.vatRate * price.defaultPrice / 100) + '(VAT)' : null}</span>;
        });
    }

    function renderProducts() {
        return createdOrder.packages.map((p, i) => {
            var priceLines = p.priceLines || [];

            return (
                <tr key={i + 10000}>
                    <td>
                        <div className="cell-wrapper">
                            <div className="text-heading">{p.name}</div>
                        </div>
                    </td>
                    <td style={{ padding: '2px 0px' }}>
                        <div>
                            {renderPriceLines(priceLines)}
                        </div>
                    </td>
                    <td className="cell-100 align-right">
                        <div className="text-price">{formatPrice(p.amount)}</div>
                    </td>
                </tr>
            );
        });
    }

    function renderDiscountProducts() {
        return createdOrder.packages.map(p => {
            if (p.isDiscountApplied != true) {
                return null;
            }

            return (
                <tr key={p.id}>
                    <td colSpan="2" className="label align-right">Package Discount on {p.name}</td>
                    <td className="cell-100 align-right"><div className="text-price">{formatPrice(p.discountAmount)}</div></td>
                </tr>
            );
        });
    }

    function renderCharges() {
        if (createdOrder.priceDetails.charges == undefined) {
            return null;
        }

        var charges = createdOrder.priceDetails.charges;
        var chargesUI = keys(charges).map((itemId, index) => {
            var item = charges[itemId];
            return (
                <tr key={index}>
                    <td colSpan="2" className="label align-right">{item.discountType}</td>
                    <td className="cell-100 align-right"><div className="text-price">{formatPrice(item.chargeAmount)}</div></td>
                </tr>);
        });

        return chargesUI;
    }

    function renderError() {
        return (
            <div className="content summary-content" role="main">
                <div className="order-summary">
                    <div className="order-summary-title">
                        <h1>Thank you for your order.</h1>
                        <p className="h1-description" style={{ padding: 40 }}>This order has been alredy confirmed.Please check your <Link to="/">dashboard</Link> for latest Order status.</p>
                    </div>
                </div>
            </div>
        );
    }

    function print() {
        window.print();
    }

    if (showError) {
        return renderError();
    }

    if (createdOrder == undefined || createdOrder.length == 0) {
        return null;
    }

    const slaDate = moment(createdOrder.slaDate);// calculateSLADate(props.selectedPackageIds, props.allPackages);
    var price = createdOrder.priceDetails;

    return (
        <div className="content summary-content" role="main">
            <div className="order-summary">
                <div className="order-summary-title">
                    {props.isQuote ?
                        <React.Fragment>
                            <h1>Please see your quote details below.</h1>
                            <p className="h1-description" style={{ margin: '20px 0px 10px 0px' }}>Your quote number is <span className="status-label reference-label">{props.quoteId}</span></p>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <h1>Thank you for your order.</h1>
                            <p className="h1-description">Your order number is <span className="status-label reference-label">{props.orderId}</span></p>
                        </React.Fragment>
                    }
                </div>
                {props.isQuote ?
                    null //dont print completion date on quotes
                    :
                    <div className="order-summary-date">
                        <strong>Estimated Completion</strong>
                        <span className="date-badge">
                            <span className="month">{slaDate.format('MMM')}</span>
                            <span className="day">{slaDate.date()}</span>
                        </span>
                    </div>
                }
                {/* <!-- Customer Information --> */}
                <div className="order-summary-block">
                    <div className="order-summary-block-title">
                        <h2>Customer Information</h2>
                    </div>
                    <table className="order-summary-grid">
                        <tbody>
                            <tr>
                                <td>
                                    <span className="label">Contact Person</span>
                                    <span className="value">{renderCustomerProp("name")}</span>
                                </td>
                                <td>
                                    <span className="label">Customer Reference</span>
                                    <span className="value">{createdOrder.customerRef}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="label">E-mail Address</span>
                                    <span className="value">{renderCustomerProp("email")}</span>
                                </td>
                                <td>
                                    <span className="label">Phone Number</span>
                                    <span className="value">{renderCustomerProp("phone")}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* <!-- Property Information --> */}
                <div className="order-summary-block">
                    <div className="order-summary-block-title">
                        <h2>Property Information</h2>
                    </div>
                    <div className="order-summary-block-row">
                        <table className="order-summary-grid">
                            <tbody>
                                <tr>
                                    <td>
                                        <span className="label">Property Address</span>
                                        <span className="value">
                                            {createdOrder.organisation}{createdOrder.organisation == null || createdOrder.organisation.length == 0 ? null : <br />}
                                            {createdOrder.flatNo}{createdOrder.flatNo == null || createdOrder.flatNo.length == 0 ? null : <br />}
                                            {createdOrder.houseNo}{createdOrder.houseNo == null || createdOrder.houseNo.length == 0 ? null : <br />}
                                            {createdOrder.houseName}{createdOrder.houseName == null || createdOrder.houseName.length == 0 ? null : <br />}
                                            {createdOrder.street}{createdOrder.street == null || createdOrder.street.length == 0 ? null : <br />}
                                            {createdOrder.locality}{createdOrder.locality == null || createdOrder.locality.length == 0 ? null : <br />}
                                            {createdOrder.city}{createdOrder.city == null || createdOrder.city.length == 0 ? null : <br />}
                                            {createdOrder.postalCode}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="order-summary-grid">
                            <tbody>
                                <tr>
                                    <td>
                                        <span className="label">Developer Name</span>
                                        <span className="value">{createdOrder.developerName}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="label">Site Name</span>
                                        <span className="value">{createdOrder.siteName}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="label">Additional information</span>
                                        <span className="value wrap-text">{createdOrder.additionalInfo}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="label">Uploaded Plan(s)</span>
                                        <ul className="file-upload-list">
                                            {renderUploadedPlans()}
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* <!-- Products Ordered --> */}
                <div className="order-summary-block">
                    <div className="order-summary-block-title">
                        <h2>Products ordered</h2>
                    </div>
                    <table className="content-grid products-grid discount-grid">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th></th>
                                <th className="cell-100 align-right">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderProducts()}
                        </tbody>
                        <tfoot>
                            <tr className="total">
                                <td colSpan="2" className="label align-right">Total Exc. VAT</td>
                                <td className="cell-100 align-right"><div className="text-price">{formatPrice(price.totalNoVat)}</div></td>
                            </tr>
                            {renderDiscountProducts()}
                            {renderCharges()}
                            <tr className="total">
                                <td colSpan="2" className="label align-right">Subtotal</td>
                                <td className="cell-100 align-right"><div className="text-price">{formatPrice(price.subTotal)}</div></td>
                            </tr>
                            <tr>
                                <td colSpan="2" className="label align-right">VAT {price.vatRate}%</td>
                                <td className="cell-100 align-right"><div className="text-price">{formatPrice(price.totalVat)}</div></td>
                            </tr>
                            <tr className="total">
                                <td colSpan="2" className="label align-right">Total Inc. VAT</td>
                                <td className="cell-100 align-right"><div className="text-price">{formatPrice(price.totalPrice)}</div></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div className="order-summary-actions">
                    <button onClick={print} type="button" className="button button-regular button-blue">Print this page</button>
                    <Link style={{ marginRight: 10 }} to="/create-order" className="button button-regular button-green">Create new order</Link>
                    <Link to="/" className="button button-regular button-green">Overview</Link>
                </div>
            </div>
        </div>
    )

}