import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    selectActiveCategoryTab,
    selectRecommendedDatasource,
    selectIsRecommendedLoading,
    selectAlerts,
    selectSelectedAlerts
} from './selectors';
import GridProductContainer from './gridProductContainer';
import AlertTriangle from '../../svg-icons/alertTriangle';
import Info from '../../svg-icons/info';
import { blue100, stsRed } from "../../assets/less/base/_variables.js";
import Modal from '../../components/Modal/index';
import { selectPackage } from './actions';

class RecommendedPackages extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showAlertInfoModal: false,
            infoMsg: '',
            loading: true,
            selectedAlerts: { "22": { "value": true }, "51": { "value": true }, "62": { "value": true }, "1214": { "value": true } }
        };
    }
    componentDidMount() {
    }
    // UNSAFE_componentWillUpdate(nextProps, nextState) {
    //     // http://localhost:5000/copy-order/25245844
    //     if (List.isList(nextProps.recommendedDatasource)) {
    //         let packages = nextProps.recommendedDatasource;
    //         let itemsCount = nextProps.recommendedDatasource.length;
    //         let selectedItems = nextProps.selectedAlerts;
    //         // let selectedItems = this.state.selectedAlerts;
    //         if (itemsCount > 0 && Object.keys(selectedItems).length > 0) {
    //             for (const key of Object.keys(selectedItems)) {
    //                 console.log(+key);
    //                 let found = packages.findIndex(x => +x.id === +key);
    //                 if (found > 0) {
    //                     this.props.selectPackage(+key, true);
    //                 }
    //             }
    //         }
    //     }
    // }
    showAlertInfo(msg) {
        this.setState({ infoMsg: msg, showAlertInfoModal: true });
    }

    closeModal(event) {
        this.setState({ showAlertInfoModal: !event });
    }
    render() {
        var style = 'residential';
        if (this.props.activeCategoryTab === 'com') {
            style = 'commercial'
        }
        var stroke = blue100;// '#009FE3';
        var strokeRed = stsRed;// '#009FE3';
        var classEx = 'residential';
        var alertsList = [];
        if (this.props.selectAlertsOriginal !== undefined
            && this.props.selectAlertsOriginal['originalAlerts'] !== undefined
            && this.props.selectAlertsOriginal['originalAlerts'] !== null) {
            alertsList = this.props.selectAlertsOriginal['originalAlerts'];
        }
        alertsList = alertsList.map((item, i) => {
            return (
                <div key={i} className="alerts-grid">
                    <div className="alerts-icon">
                        <AlertTriangle
                            className={"icon icon-attention alert-icon-size icon-alert-triangle-warning"}
                            stroke={strokeRed} fill={'#e90027'} />

                    </div>
                    <div className="alerts-label">{item.alertType}</div>
                    <div className="alert-icon-info popover-icon hide-mobile" onClick={() => {
                        this.showAlertInfo(item.alertDescription)
                    }}>
                        <Info className={"icon icon-attention icon-attention-" + classEx}
                            stroke={stroke} /> {/* Show L15-L17 when mouse over this */}
                    </div>
                </div>);

        });
        return (
            <div className="product-alert residential-products">
                <div>
                    <h3>Recommended Products</h3>

                    <div className="alerts-grid-order"> {alertsList}
                        <Modal
                            showModal={this.state.showAlertInfoModal}
                            msg={this.state.infoMsg}
                            classNameCustom="alert-info-modal"
                            handleClose={(e) => {
                                this.closeModal(e)
                            }}
                        />
                    </div>
                    <em> These products are recommended for the property you selected.</em>
                    <GridProductContainer name='gridRecommended'
                        productStyle={style}
                        datasource={this.props.recommendedDatasource}
                        isLoading={this.props.isLoading} />
                </div>
            </div>
        );
    }
}
const mapStateToProps = createStructuredSelector({
    activeCategoryTab: selectActiveCategoryTab(),
    recommendedDatasource: selectRecommendedDatasource(),
    isLoading: selectIsRecommendedLoading(),
    selectAlertsOriginal: selectAlerts(),
    selectedAlerts: selectSelectedAlerts()
});
const mapDispatchToProps = {
    selectPackage
}
// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(RecommendedPackages);
