import { takeLatest, call, put, get, select, fork, cancel } from 'redux-saga/effects';
import { storeCustomerOptions } from '../Login/actions';
import config from 'utils/config';
import request from 'utils/request';
import {
    loadJobTitlesSuccess,
    loadJobTitlesFail,
    onBoardSuccess,
    onBoardFail
} from './actions';
import { LOAD_JOB_TITLES, ON_BOARD_USER } from './constants';

export function* onBoardSaga() {
    yield takeLatest(ON_BOARD_USER, postOnBoardUser);
}

export function* postOnBoardUser(action) {
    const requestURL = config.webApiIdentityUrl + "/user/onboard";
    try {
        const newUser = yield call(request.post, requestURL, action.userInfo);
        const jwt = yield call(request.post, `${config.webApiLoginUrl}`, { userName: action.userInfo.userName, password: action.userInfo.password });
        yield put(onBoardSuccess(jwt));
        //yield put(registerUserSucceed(newUser));
        newUser.CustomerType = 'NonAccount'; // it is credit card customer so just pass this to app
        yield put(storeCustomerOptions(newUser));
        action.navigate("/");
    }
    catch (error) {
        yield put(onBoardFail(error));
    }
}

export function* loadJobTitlesSaga() {
    yield takeLatest(LOAD_JOB_TITLES, loadJobTitlesRequest);
}

export function* loadJobTitlesRequest(action) {
    const requestURL = config.webApiUrl + "/registration/jobtitles";
    try {
        const titles = yield call(request.get, requestURL);
        yield put(loadJobTitlesSuccess(titles));
    }
    catch (error) {
        yield put(loadJobTitlesFail(error));
    }
}

export default function* rootSaga() {
    // Non-blocking fork effects
    // https://redux-saga.js.org/docs/advanced/RootSaga
    // rootSaga will terminate on the first error in any individual child effect or saga and crash your whole app! 
    // this is not so bad but we have to appropriate error handling
    yield fork(onBoardSaga);
    yield fork(loadJobTitlesSaga);
}