import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

export default function MenuItem(props) { // eslint-disable-line react/prefer-stateless-function
    return (
        <li>
            <NavLink to={props.toUrl}
                className={({ isActive, isPending }) => {
                    return isActive ? "active text-link" : isPending ? "pending text-link" : "text-link";
                }}
                >{props.label}
                {
                    props.badge != undefined && props.badge != 0
                        ? <span className="badge hide-mobile">{props.badge}</span>
                        : null
                }
            </NavLink>
        </li>
    );
}

MenuItem.propTypes = {
    label: PropTypes.string.isRequired,
    toUrl: PropTypes.string.isRequired,
    badge: PropTypes.number, // small number indicates open orders, queries ...
}