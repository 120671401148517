//import { fromJS, List, Map } from 'immutable';
import {produce} from "immer";
import {
    SHOW_QUERY_REPLAY,
    HIDE_QUERY_REPLAY,
    LOAD_QUERY_DETAILS,
    CHANGE_REPLY_TEXT_IN_QUERY,
    CUSTOMER_REPLY_TO_QUERY,
    DELETE_QUERY_FILE, QUERY_DETAILS_SHOW_EARLIER_MSGS, SET_QUERY_GRID_DATA_SOURCE
} from './constants';
import { ORDER_DETAILS_RAISE_QUERY, ORDER_DETAILS_RAISE_QUERY_CANCELLED, ORDER_DETAILS_SUBMIT_RAISE_QUERY_SUCCESS } from '../OrderDetails/constants';
import { UploadActions } from '../../components/UploadFile/actions';

const initialState = {
    queryGridNavigationColumnState: {} // this serves as dictionary for each query row
};

const queryDetailsInitialState = () => {
    return {
        state: 'open',
        attachments: [],
        text: '',
        showLatestMsgs: false,
        isNew: false,
        needCustomerReply: true
    }
}

export function createQueryDetailsReducer(gridName) {
    return function queryDetails(state = initialState, action) {
        if (gridName != action.gridName) {
            if (action.uploadChannel == undefined || action.uploadChannel != gridName)
                return state;
        }

        return produce(state, (draft) => {
            switch (action.type) {
                case SET_QUERY_GRID_DATA_SOURCE:
                    draft.queryGridNavigationColumnState = {};
                    break;

                case ORDER_DETAILS_RAISE_QUERY:
                    draft.queryGridNavigationColumnState[-1] = queryDetailsInitialState();
                    draft.queryGridNavigationColumnState[-1].state = 'open';
                    draft.queryGridNavigationColumnState[-1].isNew = true;
                    break;

                case ORDER_DETAILS_RAISE_QUERY_CANCELLED:
                    draft.queryGridNavigationColumnState[-1] = queryDetailsInitialState();
                    break;

                case LOAD_QUERY_DETAILS:
                    draft.queryGridNavigationColumnState[action.queryId] = queryDetailsInitialState();
                    draft.queryGridNavigationColumnState[action.queryId].state = 'busy';
                    break;

                case CHANGE_REPLY_TEXT_IN_QUERY:
                    draft.queryGridNavigationColumnState[action.queryId].text = action.text;
                    break;

                 case CUSTOMER_REPLY_TO_QUERY:
                     draft.queryGridNavigationColumnState[action.queryDto.queryId].state = 'busy';
                     draft.queryGridNavigationColumnState[action.queryDto.queryId].needCustomerReply = false;
                     break;

                case SHOW_QUERY_REPLAY:
                    draft.queryGridNavigationColumnState[action.queryId].state = 'open';
                    break;

                case HIDE_QUERY_REPLAY:
                    draft.queryGridNavigationColumnState[action.queryId].state = 'close';
                    draft.queryGridNavigationColumnState[action.queryId].attachments = [];
                    draft.queryGridNavigationColumnState[action.queryId].text = '';
                    break;

                case ORDER_DETAILS_SUBMIT_RAISE_QUERY_SUCCESS:
                    draft.queryGridNavigationColumnState[-1].state = 'close';
                    break;

                case UploadActions.UPLOAD_REQUEST:
                    var file = {
                        fileName: action.file.name,
                        progress: 0
                    };

                    draft.queryGridNavigationColumnState[action.objectId].attachments.push(file);
                    break;

                case UploadActions.UPLOAD_PROGRESS:
                    var uploadedFiles = state.queryGridNavigationColumnState[action.objectId].attachments;
                    var index = uploadedFiles.findIndex(item => { return item.fileName == action.fileName });
                    var fileProgress = action.progress == 1 ? 0.99 : action.progress;

                    draft.queryGridNavigationColumnState[action.objectId].attachments[index].progress = fileProgress;
                    break;

                case UploadActions.UPLOAD_SUCCESS:
                    var uploadedFiles = state.queryGridNavigationColumnState[action.objectId].attachments;
                    var index = uploadedFiles.findIndex(item => { return item.fileName == action.fileName });

                    draft.queryGridNavigationColumnState[action.objectId].attachments[index].progress = 1;
                    draft.queryGridNavigationColumnState[action.objectId].attachments[index].path = action.responsePath;
                    break;

                case UploadActions.UPLOAD_FAILURE:
                    var uploadedFiles = state.queryGridNavigationColumnState[action.objectId].attachments;
                    var index = uploadedFiles.findIndex(item => { return item.fileName == action.fileName });

                    draft.queryGridNavigationColumnState[action.objectId].attachments[index].failed = true;
                    draft.queryGridNavigationColumnState[action.objectId].attachments[index].errMsg = action.err.message;
                    draft.queryGridNavigationColumnState[action.objectId].attachments[index].progress = 0;
                    break;

                case DELETE_QUERY_FILE:
                    draft.queryGridNavigationColumnState[action.queryId].attachments.splice(action.index, 1);
                    break;

                case QUERY_DETAILS_SHOW_EARLIER_MSGS:
                    draft.queryGridNavigationColumnState[action.queryId].showLatestMsgs = true;
                    break;
            }
        })
    }
}