import React from 'react';

class AdditionalInfoWarningRowTemplate extends React.Component{
    render(){

        if(this.props.dataContext == undefined) return null;
        var datacontext = this.props.dataContext;
        
        return(
            <div>Please select valid address in order to see these extra questions.</div>
        );
    }
};

export default AdditionalInfoWarningRowTemplate;