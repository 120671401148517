import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import { selectErrorMgs, selectShowForgottenPswdInitalForm, selectShowForgottenPswdError, selectActiveView, selectShowNotification, selectNotificationMsg } from './selectors';
import { selectLoading } from '../App/selectors';
import { remindMe, changeView, initPage, loginUser } from './actions';
import { changeIsLoginPageVisible } from '../App/actions';
import LoginForm from './login';
import ForgottenPassword from './forgottenPswd';
import Notification from './notifications';
import { useNavigate } from 'react-router-dom';
import rootSaga from './sagas';
import loginReducer from './reducer';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import Form from '../../components/Form';
//import { RESTART_ON_REMOUNT } from '../../utils/constants';

const showErrorMsgSelector = selectErrorMgs();
const isLoadingSelector = selectLoading();
const showForgottenPswdInitalFormSelector = selectShowForgottenPswdInitalForm();
const showForgottenPswdErrorSelector = selectShowForgottenPswdError();
const activeViewSelector = selectActiveView();
const showNotificationSelector = selectShowNotification();
const notificationMsgSelector = selectNotificationMsg();

export default function Login(props) {
    useInjectReducer({ key: 'login', reducer: loginReducer });
    useInjectSaga({ key: 'login', saga: rootSaga/*, mode: RESTART_ON_REMOUNT*/ });
    let navigate = useNavigate();
    const showErrorMsg = useSelector(showErrorMsgSelector);
    const isLoading = useSelector(isLoadingSelector);
    const showForgottenPswdInitalForm = useSelector(showForgottenPswdInitalFormSelector);
    const showForgottenPswdError = useSelector(showForgottenPswdErrorSelector);
    const activeView = useSelector(activeViewSelector);
    const showNotification = useSelector(showNotificationSelector);
    const notificationMsg = useSelector(notificationMsgSelector);

    const dispatch = useDispatch();
    const loginUserAction = useCallback((userName, password, returnUrl, navigate) => dispatch(loginUser(userName, password, returnUrl, navigate)), [dispatch]);
    const changeViewAction = useCallback((view) => dispatch(changeView(view)), [dispatch]);
    const remindMeAction = useCallback((userName, email) => dispatch(remindMe(userName, email)), [dispatch]);
    const initPageAction = useCallback(() => dispatch(initPage()), [dispatch]);
    const changeIsLoginPageVisibleAction = useCallback((value) => dispatch(changeIsLoginPageVisible(value)), [dispatch]);

    useEffect(() => {
        changeIsLoginPageVisibleAction(true);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        initPageAction();
    }, []);
    !function generateTwitterWindow(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0], p = /^http:/.test(d.location) ? 'http' : 'https';
        if (!d.getElementById(id)) {
            js = d.createElement(s); js.id = id; js.src = p + "://platform.twitter.com/widgets.js";
            fjs.parentNode.insertBefore(js, fjs);
        }
    }(document, "script", "twitter-wjs");


    if (activeView == 'login') {
        return (
            <React.Fragment>
                {showNotification == true ? <Notification message={notificationMsg} /> : null}
                <LoginForm
                    isLoading={isLoading}
                    showErrorMsg={showErrorMsg}
                    navigate={navigate}
                    //returnUrl = {location.query.returnUrl}
                    loginUserAction={loginUserAction}
                    changeViewAction={changeViewAction} />
                <Form className="twitter-feed-form">               
                    <div className="card" style={{ position: "relative", left: "480px", bottom: "440px", width: "350px" }} >
                        <div className="widget-header">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-twitter-x" viewBox="0 0 16 16">
                                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425
                                5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                            </svg>
                            <h1>Get the scoop on X</h1>
                        </div>

                        {/*Widget with profile timeline*/}
                        <div className="widget">
                            <a className="twitter-timeline" href="https://twitter.com/STSearches"
                                data-chrome="nofooter noborders" data-tweet-limit="1" data-aria-polite="assertive">Tweets by @STSearches
                            </a>
                            <script>
                            generateTwitterWindow();
                            </script>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        );
    }

    return <ForgottenPassword
        changeViewAction={changeViewAction}
        remindMeAction={remindMeAction}
        isLoading={isLoading}
        showForgottenPswdInitalForm={showForgottenPswdInitalForm}
        showForgottenPswdError={showForgottenPswdError} />;
}
