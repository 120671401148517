import { createSelector } from 'reselect';
const selectHome = () => (state) => {
  return state.home || {};
}

const selectData = () => createSelector(
  selectHome(),
  (data) => {
    return data.data || {};
  }
);

const selectOrders = () => createSelector(
  selectData(),
  (data) => data.orders
);

const selectOrdersCount = () => createSelector(
  selectData(),
  (data) => data.ordersCount
);

const selectQuotesCount = () => createSelector(
  selectData(),
  (data) => data.quotesCount
);

const selectOrderGridRowState = () => createSelector(
  selectData(),
  (data) => data.orderGridRowState
);

const selectOrderIdChasing = () => createSelector(
  selectData(),
  (data) => data.orderIdChasing
);

const selectOrderIdChasingMessage = () => createSelector(
  selectData(),
  (data) => data.orderIdChasingMessage
);

const selectTotalOrdersCount = () => createSelector(
  selectData(),
  (data) => data.totalOrdersCount
);

const selectQuotes = () => createSelector(
  selectData(),
  (data) => data.quotes
);

const selectTotalQuotesCount = () => createSelector(
  selectData(),
  (data) => data.totalQuotesCount
);

const selectGridData = () => createSelector(
  selectHome(),
  (homeState) => homeState.queryGrid || {}
);

const selectQueryGridDataSource = () => createSelector(
  selectGridData(),
  (data) => {
    return data.dataSource.items;
  }
);

const selectQueriesCount = () => createSelector(
  selectGridData(),
  (data) => data.dataSource.totalQueriesCount
);

const selectGridUI = () => createSelector(
  selectGridData(),
  (ui) => ui.ui
)
const selectQueryGridNavigationColumnState = () => createSelector(
  selectGridUI(),
  (ui) => ui.queryGridNavigationColumnState
)

const selectHomeUi = () => createSelector(
  selectHome(),
  (home) => home.ui
);

const selectIsLoading = () => createSelector(
  selectHomeUi(),
  (ui) => ui.isLoading
);

const selectFilterCreatedBy = () => createSelector(
  selectData(),
  (data) => data.filterValues.createdBy
);

const selectFilterDateCreated = () => createSelector(
  selectData(),
  (data) => data.filterValues.dateCreated
);

const selectFilterDateCreatedPickerValue = () => createSelector(
  selectData(),
  (data) => data.filterValues.dateCreatedPickerValue
);

const selectFilterAutoCompleteTextEnteredFilterBy = () => createSelector(
  selectData(),
  (data) => data.filterValues.autoCompleteTextEntered.filterBy
);

const selectFilterAutoCompleteTextEnteredFilterText = () => createSelector(
  selectData(),
  (data) => data.filterValues.autoCompleteTextEntered.filterText
);

const selectFilterAutoCompleteTextEntering = () => createSelector(
  selectData(),
  (data) => data.filterValues.autoCompleteTextEntering
);

const selectGlobal = () => (state) => state.global;

const selectCurrentUser = () => createSelector(
  selectGlobal(),
  (globalState) => globalState.currentUser
);

const selectUserOptions = () => createSelector(
  selectCurrentUser(),
  (currentUser) => currentUser.userOptions
);
const selectCustomerPreferenceDto = () => createSelector(
  selectUserOptions(),
  (options) => options.customerPreferenceDto
)

const selectIsTemplateNewOrderCreationPref = () => createSelector(
  selectCustomerPreferenceDto(),
  (currentUser) => {
    return currentUser.newOrderCreation == 'template';
  }
);


export {
  selectOrders,
  selectQuotes,
  selectCustomerPreferenceDto,
  selectOrdersCount,
  selectQuotesCount,
  selectQueryGridDataSource,
  selectQueriesCount,
  selectQueryGridNavigationColumnState,
  selectIsLoading,
  selectFilterCreatedBy,
  selectFilterDateCreated,
  selectFilterDateCreatedPickerValue,
  selectFilterAutoCompleteTextEnteredFilterBy,
  selectFilterAutoCompleteTextEnteredFilterText,
  selectFilterAutoCompleteTextEntering,
  selectOrderIdChasing,
  selectOrderIdChasingMessage,
  selectOrderGridRowState,
  selectIsTemplateNewOrderCreationPref
}
