import * as ActionTypes from './constants';

export function loadOrder(orderId) {
  return {
    type: ActionTypes.LOAD_ORDER_DETAILS,
    orderId
  };
}

/**
 * Dispatched when the repositories are loaded by the request saga
 *
 * @param  {object} order Data for the order
 *
 */
export function orderLoaded(order) {
  return {
    type: ActionTypes.LOAD_ORDER_DETAILS_SUCCESS,
    order
  };
}

export function orderLoadingError(error) {
  return {
    type: ActionTypes.LOAD_ORDER_DETAILS_ERROR,
    error,
  };
}

export function changeQueryTab(tabName){
  return{
    type: ActionTypes.ORDER_DETAILS_CHANGE_QUERY_TAB,
    tabName
  }
}

export function setAlertDatasource(alerts){
    return{
      type: ActionTypes.ORDER_DETAILS_SET_ALERT_DATASOURCE,
      alerts
    }
}

export function selectAlert(packageId, value){
  return{
    type: ActionTypes.ORDER_DETAILS_SELECT_ALERT,
    packageId, value
  }
}

export function addSelectedPackagesToOrder(payload){
  return{
    type: ActionTypes.ORDER_DETAILS_ADD_SELECTED_PACKAGES_TO_ORDER,
    payload
  }
}

export function addSelectedPackagesToOrderSuccess(payload, extraAmount){
  return{
    type: ActionTypes.ORDER_DETAILS_ADD_SELECTED_PACKAGES_TO_ORDER_SUCCESS,
    payload, extraAmount
  }
}


export function chaseOrder(info) {
  return {
    type: ActionTypes.ORDER_CHASE,
    info
  }
}

export function chaseOrderSuccess(orderId) {
  return {
    type: ActionTypes.ORDER_CHASE_SUCCESS,
    orderId
  }
}

export function chaseOrderFail(orderId) {
  return {
    type: ActionTypes.ORDER_CHASE_FAIL,
    orderId
  }
}

export function chaseOrderHideLabel(orderId) {
  return {
    type: ActionTypes.ORDER_CHASE_HIDE_LABEL,
    orderId
  }
}


export function chaseProduct(item) {
  return {
    type: ActionTypes.PACKAGE_CHASE,
    item
  }
}
export function chasePackageSuccess(packageId) {
  return {
    type: ActionTypes.PACKAGE_CHASE_SUCCESS,
    packageId
  }
}

export function chasePackageFail(packageId) {
  return {
    type: ActionTypes.PACKAGE_CHASE_FAIL,
    packageId
  }
}

export function cancelProduct(item) {
  return {
    type: ActionTypes.PACKAGE_CANCEL,
    item
  }
}

export function cancelPackageSuccess(packageId) {
  return {
    type: ActionTypes.PACKAGE_CANCEL_SUCCESS,
    packageId
  }
}

export function cancelPackageFail(packageId) {
  return {
    type: ActionTypes.PACKAGE_CANCEL_FAIL,
    packageId
  }
}


export function cancelOrder(item) {
  return {
    type: ActionTypes.ORDER_CANCEL,
    item
  }
}

export function cancelOrderSuccess(orderId) {
  return {
    type: ActionTypes.ORDER_CANCEL_SUCCESS,
    orderId
  }
}

export function cancelOrderFail(orderId) {
  return {
    type: ActionTypes.ORDER_CANCEL_FAIL,
    orderId
  }
}


export function chasePackageHideLabel(packageId) {
  return {
    type: ActionTypes.PACKAGE_CHASE_HIDE_LABEL,
    packageId
  }
}

export function loadPackageReports(orderId, packageId) {
  return {
    type: ActionTypes.LOAD_PACKAGE_REPORTS,
    orderId,
    packageId
  }
}

export function loadPackageReportsSuccess(reports) {
  return {
    type: ActionTypes.LOAD_PACKAGE_REPORTS_SUCCESS,
    reports
  };
}

export function loadPackageReportsError(error) {
  return {
    type: ActionTypes.LOAD_PACKAGE_REPORTS_FAIL,
    error
  };
}

export function closeReportDialog(){
  return {
    type: ActionTypes.CLOSE_PACKAGE_REPORT_DIALOG
  }
}

export function raiseQuery(orderId, gridName, rowTemplateKey){
  return {
    type: ActionTypes.ORDER_DETAILS_RAISE_QUERY,
    orderId,
    gridName,
    rowTemplateKey
  }
}

// sends query to server to create new query. 
export function submitQueryRequest(gridName, queryDto){
  return {
    type: ActionTypes.ORDER_DETAILS_SUBMIT_RAISE_QUERY,
    gridName,
    queryDto
  }
}
export function cancelQuery(gridName, queryDto){
  return {
    type: ActionTypes.ORDER_DETAILS_RAISE_QUERY_CANCELLED,
    gridName,
    queryDto
  }
}


export function submitQuerySucceed(gridName, queryDto){
  return {
    type: ActionTypes.ORDER_DETAILS_SUBMIT_RAISE_QUERY_SUCCESS,
    gridName,
    queryDto
  }
}

export function submitQueryFail(gridName, queryDto){
  return {
    type: ActionTypes.ORDER_DETAILS_SUBMIT_RAISE_QUERY_FAIL,
    gridName,
    queryDto
  }
}
