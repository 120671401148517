import {
    LOAD_USER_CONTACTS,
    LOAD_USER_CONTACTS_SUCCESS,
    LOAD_USER_CONTACTS_FAIL,
    LOAD_PRIMARY_CONTACT,
    LOAD_PRIMARY_CONTACT_SUCCESS,
    LOAD_PRIMARY_CONTACT_FAIL,
    LOAD_CUSTOMER_ADDRESS,
    LOAD_CUSTOMER_ADDRESS_SUCCESS,
    LOAD_CUSTOMER_ADDRESS_FAIL,
    LOAD_PREFERENCE_SETTINGS,
    LOAD_PREFERENCE_SETTINGS_SUCCESS,
    LOAD_PREFERENCE_SETTINGS_FAIL,
    UPDATE_PREFERENCE_SETTINGS,
    UPDATE_PREFERENCE_SETTINGS_SUCCESS,
    UPDATE_PREFERENCE_SETTINGS_FAIL,
    ADD_NEW_CUSTOMER_CONTACT,
    ADD_NEW_CUSTOMER_CONTACT_SUCCESS,
    ADD_NEW_CUSTOMER_CONTACT_FAIL,
    UPDATE_CUSTOMER_CONTACT,
    UPDATE_CUSTOMER_CONTACT_SUCCESS,
    UPDATE_CUSTOMER_CONTACT_FAIL,
    UPDATE_CUSTOMER_ADDRESS,
    UPDATE_CUSTOMER_ADDRESS_SUCCESS,
    UPDATE_CUSTOMER_ADDRESS_FAIL,
    LOAD_JOB_TITLES,
    LOAD_JOB_TITLES_SUCCESS,
    LOAD_JOB_TITLES_ERROR,
    LOAD_CUSTOMER_NAME,
    LOAD_CUSTOMER_NAME_SUCCESS,
    LOAD_CUSTOMER_NAME_FAIL
} from './constants';

export function loadContacts() {
    return {
        type: LOAD_USER_CONTACTS
    };
}

export function loadContactsSuccess(contacts) {
    return {
        type: LOAD_USER_CONTACTS_SUCCESS,
        contacts
    }
}

export function loadContactsFail(error) {
    return {
        type: LOAD_USER_CONTACTS_FAIL,
        error
    }
}

export function loadPrimaryContact() {
    return {
        type: LOAD_PRIMARY_CONTACT
    }
}

export function loadPrimaryContactSuccess(primaryContact) {
    return {
        type: LOAD_PRIMARY_CONTACT_SUCCESS,
        primaryContact
    }
}

export function loadPrimaryContactFail(error) {
    return {
        type: LOAD_PRIMARY_CONTACT_FAIL,
        error
    }
}

export function loadCustomerAddress() {
    return {
        type: LOAD_CUSTOMER_ADDRESS
    }
}

export function loadCustomerAddressSuccess(customerAddress) {
    return {
        type: LOAD_CUSTOMER_ADDRESS_SUCCESS,
        customerAddress
    }
}

export function loadCustomerAddressFail(error) {
    return {
        type: LOAD_CUSTOMER_ADDRESS_FAIL,
        error
    }
}

export function updateCustomerAddress(customerAddress) {
    return {
        type: UPDATE_CUSTOMER_ADDRESS,
        customerAddress
    }
}

export function updateCustomerAddressSuccess(customerAddress) {
    return {
        type: UPDATE_CUSTOMER_ADDRESS_SUCCESS,
        customerAddress
    }
}

export function updateCustomerAddressFail(error) {
    return {
        type: UPDATE_CUSTOMER_ADDRESS_FAIL,
        error
    }
}

export function loadPreferenceSettings() {
    return {
        type: LOAD_PREFERENCE_SETTINGS
    }
}

export function loadPreferenceSettingsSuccess(preferenceSettings) {
    return {
        type: LOAD_PREFERENCE_SETTINGS_SUCCESS,
        preferenceSettings
    }
}

export function loadPreferenceSettingsFail(error) {
    return {
        type: LOAD_PREFERENCE_SETTINGS_FAIL,
        error
    }
}

export function updatePreferenceSettings(preferenceSettings, navigate) {
    return {
        type: UPDATE_PREFERENCE_SETTINGS,
        preferenceSettings,
        navigate
    }
}

export function updatePreferenceSettingsSuccess(preferenceSettings) {
    return {
        type: UPDATE_PREFERENCE_SETTINGS_SUCCESS,
        preferenceSettings
    }
}

export function updatePreferenceSettingsFail(error) {
    return {
        type: UPDATE_PREFERENCE_SETTINGS_FAIL,
        error
    }
}

export function updateCustomerContact(customerContact) {
    return {
        type: UPDATE_CUSTOMER_CONTACT,
        customerContact
    }
}

export function updateContactSuccess(updatedContact) {
    return {
        type: UPDATE_CUSTOMER_CONTACT_SUCCESS,
        updatedContact
    }
}

export function updateContactFail(error) {
    return {
        type: UPDATE_CUSTOMER_CONTACT_FAIL,
        error
    }
}

export function addNewCustomerContact(customerContact) {
  return {
    type: ADD_NEW_CUSTOMER_CONTACT,
    customerContact
  }
}

export function addNewCustomerContactSuccess(createdContact) {
  return {
    type: ADD_NEW_CUSTOMER_CONTACT_SUCCESS,
    createdContact
  }
}

export function addNewCustomerContactFail(error) {
  return {
    type: ADD_NEW_CUSTOMER_CONTACT_FAIL,
    error
  }
}

export function loadJobTitles() {
    return {
        type: LOAD_JOB_TITLES
    };
}

export function loadJobTitlesSuccess(titles) {
    return {
        type: LOAD_JOB_TITLES_SUCCESS,
        titles
    };
}

export function loadJobTitlesFail(error) {
    return {
        type: LOAD_JOB_TITLES_ERROR,
        error
    };
}

export function loadCustomerName() {
    return {
        type: LOAD_CUSTOMER_NAME
    }
}

export function loadCustomerNameSuccess(userInfo) {
    return {
        type: LOAD_CUSTOMER_NAME_SUCCESS,
        userInfo
    }
}

export function loadCustomerNameFail(error) {
    return {
        type: LOAD_CUSTOMER_NAME_FAIL,
        error
    }
}