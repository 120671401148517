import { takeLatest, call, put, select, fork, cancel } from 'redux-saga/effects';
import { LOAD_QUOTES, DELETE_QUOTE } from './constants';
import { loadQuotesSuccess, removeDeletedQuote } from './actions';
import { showAppError } from 'containers/App/actions';
import config from 'utils/config';
import request  from 'utils/request';

//
// API call to load dashboard data
//
export function* loadQuotesRequest(action) {

  let startIndex = action.values.startIndex || 0;
  let pageSize = action.values.pageSize || 10;
  const customerContactId = action.values.createdBy || null;
  const dateOrderCreated = action.values.dateCreated || null;
  const filterText = action.values.filterText || null;

  const requestURL = `${config.webApiUrl}/quote?startIndex=${startIndex}&pageSize=${pageSize}&customerContactId=${customerContactId}&dateCreated=${dateOrderCreated}&filterText=${filterText}`;
  
  try {
    // Call our request helper (see 'utils/request')
    const repos = yield call(request.getAuth, requestURL);
    yield put(loadQuotesSuccess(repos));
  } 
  catch (err) {
    yield put(showAppError(err));
  }
}

export function* deleteQuoteRequest(action) {

  let quoteId = action.payload;

  const requestURL = `${config.webApiUrl}/quote?quoteId=${quoteId}`;
  
  try {
    // Call our request helper (see 'utils/request')
    yield call(request.deleteAuth, requestURL);
    yield put(removeDeletedQuote(quoteId));
  } 
  catch (err) {
    yield put(showAppError(err));
  }
}

export function* loadQuotesSaga() {
  yield takeLatest(LOAD_QUOTES, loadQuotesRequest);
}

export function* deleteQuoteSaga() {
  yield takeLatest(DELETE_QUOTE, deleteQuoteRequest);
}

// Bootstrap sagas
export default function* rootSaga() {
  // Non-blocking fork effects
  // https://redux-saga.js.org/docs/advanced/RootSaga
  // rootSaga will terminate on the first error in any individual child effect or saga and crash your whole app! 
  // this is not so bad but we have to appropriate error handling
  yield fork(loadQuotesSaga);
  yield fork(deleteQuoteSaga);
}