import React from 'react';
import { Link } from 'react-router-dom';

let  QueryDetailsCloseColumnTemplate = (props) => {
    var datacontext = props.datacontext;
    if(datacontext == undefined){
        return null;
    }

    return (
    <div>
        <strong>{datacontext.address.line1}</strong>
        <div className="text-user">{props.row.queryName}</div>
        <span className="status-label reference-label show-tablet">{props.row.customerRef}</span>
    </div>);
};

export default QueryDetailsCloseColumnTemplate;