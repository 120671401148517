import React, { Component } from 'react'

const URL = require('url-parse')

export default class SafeURL extends Component {
  isSafe(dangerousURL, text) {
    const url = URL(dangerousURL, {})
    if (url.protocol === 'http:') return true;
    if (url.protocol === 'https:') return true;
    return false;
  }

  render() {
    const dangerousURL = this.props.dangerousURL;
    const safeURL = this.isSafe(dangerousURL) ? dangerousURL : null;
    return <a href={safeURL} className={this.props.className} target={this.props.target}>{this.props.text}</a>;
  }
}

// ReactDOM.render(
//   <SafeURL dangerousURL=" javascript: alert(1)" text="Click me!" />,
//   document.getElementById('root')
// )