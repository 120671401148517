
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Settings = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path d="M20.254 13.5H23.5v-3h-3.247c-.21-.867-.55-2.18-1.003-2.922l2.296-2.295-2.83-2.83L16.42 4.75c-.74-.452-2.053-.793-2.92-1.002V.5h-3v3.248c-.867.21-2.18.55-2.92 1.002L5.28 2.454l-2.83 2.83L4.75 7.578c-.452.74-.794 2.053-1.004 2.92H.5v3h3.246c.21.867.552 2.18 1.004 2.92L2.453 18.72l2.83 2.828 2.295-2.297c.74.453 2.055.794 2.922 1.004V23.5h3v-3.246c.867-.21 2.18-.552 2.92-1.004l2.298 2.296 2.828-2.828-2.296-2.297c.452-.74.794-2.053 1.004-2.92z"/><circle cx="12" cy="12" r="4.5"/>
      </g>
  </SvgIconEx>
);
Settings.displayName = 'Settings';
Settings.muiName = 'SvgIcon';

export default Settings;