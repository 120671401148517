
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let AlertError = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path  d="M16.78 15.29c0-.607.15-1.204.435-1.74l1.463-2.746c.297-.48.146-1.112-.337-1.408-.48-.297-1.11-.146-1.408.337l-1.858 2.926 1.104-6.025c.12-.553-.232-1.098-.786-1.218-.554-.118-1.098.233-1.217.786l-1.15 5.09v-6.49c0-.564-.46-1.023-1.024-1.023-.565 0-1.023.46-1.023 1.024v6.492l-1.15-5.34c-.12-.555-.666-.906-1.22-.787-.552.118-.904.663-.785 1.216l1.104 7.64-2.04-1.782c-.418-.382-1.066-.353-1.447.065-.384.416-.355 1.064.062 1.446l3.59 4.78c.58.607 1.384.952 2.225.952h2.39c2.43 0 3.074-1.895 3.074-4.2z"/>
          <path  d="M7.244 23.5L.5 16.784V7.33L7.244.5h9.454L23.5 7.33v9.454L16.698 23.5z"/>
      </g>
  </SvgIconEx>
);
AlertError.displayName = 'AlertError';
AlertError.muiName = 'SvgIcon';

export default AlertError;