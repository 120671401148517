import React from 'react';
import moment from 'moment';
//uiState
//order
//handleChase

export default function ChaseButton(props) {
    // orderIdChasing, orderIdChasingMessage
    var order = props.order;
    var rowState = props.uiState || {};
    var date = moment(order.dueDate);
    var now = moment();
    var dueInDays = moment.duration(date - now).days() + 1;

    var chaseContent = null;
    if (rowState.chasing) {
        chaseContent = <div className='loader'></div>;
    }
    else if (rowState.showChasedLabel == true) {
        chaseContent = <div className="status-label color-teal">Chased</div>;
    }
    else if (rowState.chased || order.isChased) {
        chaseContent = null;
    }
    else if (order.status == "Completed" || order.status == "Cancelled") {
        chaseContent = null;
    }
    else if (dueInDays <= 0 && order.status != "Closed") {
        chaseContent = <button onClick={() => { props.handleChase(order) }} type="button" className="button button-small button-teal">Chase</button>
    }

    return chaseContent;
};