import { createSelector } from 'reselect';

const selectHome = () => (state) => state.queries || {};

//
// queries.queryGrid
const selectGridData = () => createSelector(
  selectHome(),
  (homeState) => homeState.queryGrid
);

//
// queries.queryGrid.dataSource.items
const selectGridDataSource = () => createSelector(
  selectGridData(),
  (data) => data.dataSource.items
);

//
// queries.queryGrid.totalQueriesCount
const selectGridDataSourceTotalCount = () => createSelector(
  selectGridData(),
  (data) => data.dataSource.totalQueriesCount
);

//
// queries.queryGrid.ui.queryGridNavigationColumnState
const selectQueryGridNavigationColumnState = () => createSelector(
  selectGridData(),
  (ui) => ui.ui.queryGridNavigationColumnState
)

//
// queries.queryGridClosed
const selectGridDataClosed = () => createSelector(
  selectHome(),
  (homeState) => homeState.queryGridClosed
);

//
// queries.queryGridClosed.dataSource.items
const selectGridClosedDataSource = () => createSelector(
  selectGridDataClosed(),
  (data) => data.dataSource.items
);

// queries.queryGrid.totalQueriesCount
const selectGridClosedDataSourceTotalCount = () => createSelector(
  selectGridDataClosed(),
  (data) => data.dataSource.totalQueriesCount
);

//
// queries.queryGridClosed.ui.queryGridNavigationColumnState
const selectQueryGridClosedNavigationColumnState = () => createSelector(
  selectGridDataClosed(),
  (ui) => ui.ui.queryGridNavigationColumnState
)

//
// queries.pageUi.activeTab
const selectActiveTab = () => createSelector(
  selectHome(),
  (homeState) => homeState.pageUi.activeTab
);

//
// queries.pageUi.activeTab
const selectQuieryFilters = () => createSelector(
  selectHome(),
  (homeState) => homeState.pageUi.filterInfo
);

//
// queries.pageUi.isLoading
const selectIsLoading = () => createSelector(
  selectHome(),
  (data) => data.pageUi.isLoading
);

const selectCurrentPage = () => createSelector(
  selectHome(),
  (ui) => ui.pageUi.currentPage
);
export {
  selectActiveTab,
  selectGridDataSource,
  selectQueryGridNavigationColumnState,
  selectQueryGridClosedNavigationColumnState,
  selectGridDataSourceTotalCount,
  selectGridClosedDataSource,
  selectGridClosedDataSourceTotalCount,
  selectIsLoading,
  selectQuieryFilters,
  selectCurrentPage
}