import React from 'react';
import ExpandableSection from '../../components/ExpandableSection';
import {createStructuredSelector} from 'reselect';
import {selectContactPerson, selectCustomerReference} from './selectors';
import {selectContactPersonAction, selectCustomerReferenceAction} from './actions';
import {connect} from 'react-redux';
import {selectCustomerContacts} from '../App/selectors';
import TextField from '../../components/TextField';

class NewOrderCustomer extends React.PureComponent {
  static defaultProps = {
    isExpandable: true
  }

  constructor(props) {
    super(props);
    this.state = {
      customerReference: ''
    };
  }

  componentDidMount() {
    this.setState({customerReference: this.props.custReference});
  }

  componentWillReceiveProps(nextProps) {
    this.setState({customerReference: nextProps.custReference});
  }

  handleTextChanged(value, textBoxName) {
    this.setState({customerReference: value});
  }

  handleLostFocus = (e, value) => {
    if (this.props.selectCustomerReference) {
      this.props.selectCustomerReference(value);
    }
  }

  handleCustomerContactChange = (event) => {
    if (this.props.selectContactPerson) {
      var contactId = event.target.value;
      var item = this.props.customerContacts.filter((contact) => {
        return contact.id == contactId
      })[0];

      this.props.selectContactPerson(item);
    }
  }

  renderContacts() {
    return this.props.customerContacts.map((item, index) => {
      return <option key={index} value={item.id}>{item.name}</option>
    });
  }

  renderEmailAndPhone() {

    var customer = this.props.customerContacts.filter(item => {
      return item.id == this.props.contactPerson.id;
    })[0];

    if (customer == undefined) return;
    return <div className="entry form-control-nolabel form-control-60">
      <span className="form-control-description">E-mail Address: <strong>{customer.email}</strong></span>
      <span className="form-control-description">Phone Number: <strong>{customer.phone}</strong></span>
    </div>
  }

  render() {

    return (
      <ExpandableSection title='CUSTOMER INFORMATION' isExpandable={this.props.isExpandable}>
        <fieldset className="content-block-form">
          <div className="entry-block">
            <div className="entry form-control-40">
              <label htmlFor="filter-contact-person">Contact Person <strong className="label-required">*</strong>
              </label>
              <div className="form-dropdown">
                <select id="contact-person"
                        value={this.props.contactPerson.id}
                        className="form-control-grey"
                        onChange={this.handleCustomerContactChange}>
                  {this.renderContacts()}
                </select>

              </div>
            </div>
            {/* Show this only after person is selected */}
            {this.props.contactPerson.id != -1 && this.props.contactPerson.id != undefined ?
              this.renderEmailAndPhone()
              : null}
          </div>

          <TextField className="entry form-control-40" placeholder="" label='Customer Reference'
                     inputClassName='form-control-grey' isRequired={true}
                     value={this.state.customerReference}
                     onChange={(event, value) => this.handleTextChanged(value, 'customerReference')}
                     onBlur={this.handleLostFocus}
                     maxLength="50"
                     maxCharacterWarningMsg='Customer Reference is limited '/>
        </fieldset>
      </ExpandableSection>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  customerContacts: selectCustomerContacts(),
  contactPerson: selectContactPerson(),
  custReference: selectCustomerReference()
})
const mapDispatchToProps = {
  selectContactPerson: selectContactPersonAction,
  selectCustomerReference: selectCustomerReferenceAction
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(NewOrderCustomer);
