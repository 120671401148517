import { takeLatest, call, put, select, fork, cancel, takeEvery } from 'redux-saga/effects';
import { LOGIN_USER, REMIND_ME, INIT_LOGIN_PAGE } from './constants';
import { loginUserSucceed, loginUserFail, remindMeError, remindMeSuccess, storeCustomerOptions, showNotificationOnLogin } from './actions';
import { showAppError } from '../App/actions';
import config from 'utils/config';
import request from 'utils/request';
/**
 * Github repos request/response handler
 */
export function* postLoginUser(action) {
  // Select username from store
  //const username = yield select(selectUsername());
  //const requestURL = `https://api.github.com/users/${username}/repos?type=all&sort=updated`;
  const requestURL = `${config.webApiLoginUrl}`;
  const requestURL2 = config.webApiIdentityUrl + "/user/loguserprofile";
  const requestCustomerURL = `${config.webApiUrl}`;
  const returnUrl = action.returnUrl || '/';
  try {
    // Call our request helper (see 'utils/request')
    const jwt = yield call(request.post, requestURL, { userName: action.userName, password: action.password });
    // save token
    yield put(loginUserSucceed(jwt));
    // get customer preferences
    const userOptions = yield call(request.getAuth, `${requestCustomerURL}/customer/GetCurrentCustomer`);
    yield put(storeCustomerOptions(userOptions));
    
    // log profile
    let userProfile = {
      Mobile: yield detectMobile(),
      Browser: yield detectBrowser(),
      Dimensions:
      {
        Width: window.innerWidth,
        Height: window.innerHeight,
      },
      UserName: '',
      UserId: 0
    }
    userProfile.UserName = userOptions.customerPreferenceDto.customerName;
    userProfile.UserId = userOptions.customerPreferenceDto.customerId;
    const profile = yield call(request.postAuth, requestURL2, userProfile);
    action.navigate(returnUrl); // go to home. In future redirect to return Url
  }
  catch (err) {
    console.error(err);
    if (err.msg.indexOf('suspended') !== -1) {
      yield put(showAppError(err));
      yield put(loginUserFail(err));
    } else {
      yield put(loginUserFail(err));
    }
  }
}

export function* postLoginUserSaga(){
  yield takeLatest(LOGIN_USER, postLoginUser);
}
/**
 * Call forgotten password request/response handler
 */
export function* postRemindForgottenPswd(action) {
  const requestURL = `${config.webApiIdentityUrl}/user/recover`;
  try {
    // Call our request helper (see 'utils/request')
    yield call(request.post, requestURL, { userName: action.userName, email: action.email });
    yield put(remindMeSuccess());
  }
  catch (err) {
    yield put(remindMeError(err));
  }
}

/**
 * Call forgotten password request/response handler
 */
export function* getNotificationMsg() {
  const requestURL = `${config.webApiUrl}/app/getNotification`;
  try {
    // Call our request helper (see 'utils/request')
    const msg = yield call(request.get, requestURL);
    yield put(showNotificationOnLogin(msg));
  }
  catch (err) {
    yield put(showAppError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* checkForNotificationSaga() {
  yield takeLatest(INIT_LOGIN_PAGE, getNotificationMsg);
}

export function* postRemindForgottenPswdSaga() {
  yield takeLatest(REMIND_ME, postRemindForgottenPswd);
}

function* detectMobile() {
  let isMobile = window.matchMedia || window.msMatchMedia;
  if (isMobile) {
    let match_mobile = isMobile("(pointer:coarse)");
    return match_mobile.matches;
  }
  return false;
}
function* detectBrowser() {

  var objappVersion = navigator.appVersion;
  var browserAgent = navigator.userAgent;
  var browserName = navigator.appName;
  var browserVersion = '' + parseFloat(navigator.appVersion);
  var browserMajorVersion = parseInt(navigator.appVersion, 10);
  var Offset, OffsetVersion, ix;

  // For Chrome 
  if ((OffsetVersion = browserAgent.indexOf("Chrome")) != -1) {
    browserName = "Chrome";
    browserVersion = browserAgent.substring(OffsetVersion + 7);
  }

  // For Microsoft internet explorer 
  else if ((OffsetVersion = browserAgent.indexOf("MSIE")) != -1) {
    browserName = "Microsoft Internet Explorer";
    browserVersion = browserAgent.substring(OffsetVersion + 5);
  }

  // For Firefox
  else if ((OffsetVersion = browserAgent.indexOf("Firefox")) != -1) {
    browserName = "Firefox";
  }

  // For Safari
  else if ((OffsetVersion = browserAgent.indexOf("Safari")) != -1) {
    browserName = "Safari";
    browserVersion = browserAgent.substring(OffsetVersion + 7);
    if ((OffsetVersion = browserAgent.indexOf("Version")) != -1)
      browserVersion = browserAgent.substring(OffsetVersion + 8);
  }

  // For other browser "name/version" is at the end of userAgent 
  else if ((Offset = browserAgent.lastIndexOf(' ') + 1) <
    (OffsetVersion = browserAgent.lastIndexOf('/'))) {
    browserName = browserAgent.substring(Offset, OffsetVersion);
    browserVersion = browserAgent.substring(OffsetVersion + 1);
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }

  // Trimming the fullVersion string at 
  // semicolon/space if present 
  if ((ix = browserVersion.indexOf(";")) != -1)
    browserVersion = browserVersion.substring(0, ix);
  if ((ix = browserVersion.indexOf(" ")) != -1)
    browserVersion = browserVersion.substring(0, ix);


  browserMajorVersion = parseInt('' + browserVersion, 10);
  if (isNaN(browserMajorVersion)) {
    browserVersion = '' + parseFloat(navigator.appVersion);
    browserMajorVersion = parseInt(navigator.appVersion, 10);
  }
  return (
    {
      'NameOfBrowser': browserName,
      'FullVersion': browserVersion,
      'MajorVersion': browserMajorVersion,
      'NavigatorAppName': navigator.appName,
      'NavigatorUserAgent': navigator.userAgent
    }
  );
}
// Bootstrap sagas
export default function* rootSaga() {
    yield fork(postLoginUserSaga);
    yield fork(postRemindForgottenPswdSaga);
    yield fork(checkForNotificationSaga);
}