import React from 'react';

class QueriesTitle extends React.PureComponent{

    handleTabChanged = (tabName) =>{
        if(this.props.tabChanged){
            this.props.tabChanged(tabName);
        }
    }

    handleRaiseQuery = () => {
        if(this.props.raiseQueryAction){
            this.props.raiseQueryAction();
        }
    }

    render(){
        
        return(
            <div className="content-block-title">
                <h2>Queries</h2>
                {this.props.isRaiseButtonVisibile ? <button onClick={this.handleRaiseQuery} className="button button-small button-blue hide-mobile">Raise Query</button> : null}
                <ul className="content-grid-toggle">
                    <li className={`text-link ${this.props.activeTab == 'open' ? 'active' : null}`} onClick={()=>this.handleTabChanged('open')} >Open ({this.props.openQueryCount})</li> 
                    <li className={`text-link ${this.props.activeTab != 'open' ? 'active' : null}`} onClick={()=>this.handleTabChanged('closed')}>Closed ({this.props.closedQueryCount})</li> 
                </ul>
            </div>
            );
    }
}

export default QueriesTitle;