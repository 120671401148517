import NewOrderBasket from './newOrderBasket';
import NewOrderProducts from './newOrderProducts';
import { selectActiveCategoryTab, selectIsLoading } from './selectors';
import { selectOrderGridReference } from '../OrderDetails/selectors';
import Form from '../../components/Form';
import PageTitle from './pageTitle';
import BackArrow from '../../svg-icons/backArrow';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import { initCreatePageState, loadPackagesInfo, loadOrderPackages ,
    changeProductCategoryTab, setAddressForAditionalProducts, selectPackage} from './actions';
import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import rootSaga from './sagas';
import reducer from './reducer';
import { useParams } from 'react-router-dom';
import { useInjectReducer } from 'utils/injectReducer';
//import { RESTART_ON_REMOUNT } from '../../utils/constants';

const activeTabSelector= selectActiveCategoryTab();
const isLoadingSelector= selectIsLoading();
const gridRefSelector= selectOrderGridReference();

export default function AddProductToOrder(props) {

    useInjectSaga({ key: 'create-order', saga: rootSaga/*, mode: RESTART_ON_REMOUNT*/ });
    useInjectReducer({ key: 'create-order', reducer: reducer });
    let activeTab = useSelector(activeTabSelector);
    let isLoading = useSelector(isLoadingSelector);
    let gridRef = useSelector(gridRefSelector);

    const dispatch = useDispatch();
    const initCreatePageStateAction = useCallback((orderId) => dispatch(initCreatePageState(orderId)), [dispatch]);
    const loadOrderPackagesAction = useCallback((orderId) => dispatch(loadOrderPackages(orderId)), [dispatch]);
    const setAddressForAditionalProductsAction = useCallback((gridRef) => dispatch(setAddressForAditionalProducts(gridRef)), [dispatch]);
    const loadPackagesInfoAction = useCallback(() => dispatch(loadPackagesInfo()), [dispatch]);
    const changeProductCategoryTabAction = useCallback((tabName) => dispatch(changeProductCategoryTab(tabName)), [dispatch]);
    const selectPackageAction = useCallback((packageId, value, gridName, termsRequired) => dispatch(selectPackage(packageId, value, gridName, termsRequired)), [dispatch]);

    let { orderId } = useParams();
    useEffect(() => {
        initCreatePageStateAction();
        loadPackagesInfoAction();
        setAddressForAditionalProductsAction(gridRef);
        loadOrderPackagesAction(orderId);
    }, []);

    return (
        <div>
            <PageTitle>
                <div className="details-header">
                    <Link to={`/order/${orderId}`} className="button button-small button-text-icon button-transparent color-blue back-arrow">
                        <BackArrow className="icon icon-arrow-left" role="img" />
                        Back to order #{orderId}
                    </Link>
                    <div className="details-title">
                        <h1>Add Products</h1>
                    </div>
                </div>
            </PageTitle>
            <Form className="content-wrapper details-wrapper">
                <section id="sectionWidth" className="details-main">
                    {isLoading ?
                        <Loading />
                        :
                        <NewOrderProducts changeTab={changeProductCategoryTabAction}
                            activeTab={activeTab} selectPackage={selectPackageAction} />
                    }
                </section>
                <NewOrderBasket showAddToOrder={true} showSubmit={false} showQuote={false} orderId={orderId} />
            </Form>
        </div>
    );
}