import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pager from '../Pager';
import { Grid } from '../Grid'

import {
    NavigationColumnTemplate,
    QueryDetailsCloseColumnTemplate,
    QueryDetailsConversationTemplate
} from './index';

export default class GridQueriesClosed extends React.Component {
    constructor(props) {
        super(props);
        this.rowTemplateResolver = this.rowTemplateResolver.bind(this);
    }

    rowTemplateResolver(rowData) {
        var cellState = this.props.queryGridNavigationColumnState[rowData.queryId];
        if (cellState && cellState.state == 'open') {
            return 'row-open';
        }

        return '';
    }

    render() {
        var pager;

        if (this.props.showPager === true) {
            pager =
                <Pager onChangePage={this.props.onPageChange}
                    totalItemsCount={this.props.totalItemsCount}
                    currentPage={this.props.currentPage}
                    pageSize={this.props.pageSize}
                    gridName='queries' />;
        }

        return (
            <section id="queries" className="content-block content-wrapper">
                <Grid
                    tableClassName='content-grid content-grid-compact'
                    datasource={this.props.datasource}
                    isLoading={this.props.isLoading}
                    columnsData={[

                        {
                            header: 'Details',
                            dataField: 'queryDetails',
                            style: { width: '*' },
                            dataCellTemplate: QueryDetailsCloseColumnTemplate
                        },
                        {
                            header: 'YOUR REF.',
                            dataField: 'customerRef',
                            style: { width: 160 },
                        },
                        {
                            header: 'Query ref',
                            style: { width: 100 },
                            dataField: 'queryId',
                        },
                        {
                            header: 'Order Ref',
                            dataField: 'orderId',
                            style: { width: 100 },
                            dataCellTemplate: (props) => (<Link className="button button-small button-grey color-blue" to={"/order/" + props.datacontext}>{props.datacontext}</Link>)
                        },
                        {
                            header: 'Raised',
                            style: { width: 100 },
                            dataField: 'date',
                            dataCellTemplate: (props) => {
                                var date = moment(props.datacontext);
                                var dateString = date.format('DD/MM/YYYY');
                                var timeString = date.format('hh:mm A');
                                return <div>
                                    {dateString}
                                    <div className="text-user">{timeString}</div>
                                </div>
                            }
                        },
                        {
                            header: 'Completed',
                            style: { width: 100 },
                            dataField: 'lastStatusChangeDateTime',
                            dataCellTemplate: (props) => {
                                var date = moment(props.datacontext);
                                var dateString = '';
                                if (date.isValid()) {
                                    dateString = date.format('DD/MM/YYYY');
                                }

                                //var timeString = date.format('hh:mm A');
                                return <div>
                                    {dateString}
                                    {/*<div className="text-user">{timeString}</div>*/}
                                </div>
                            }
                        },
                        {
                            header: '',
                            style: { width: 90, textAlign: 'center' },
                            dataCellTemplate: (props) => (<NavigationColumnTemplate {...props}
                                gridName={this.props.gridName}
                                queryGridNavigationColumnState={this.props.queryGridNavigationColumnState}
                                showQueryReplyRow={this.props.showQueryReplyRow}
                                hideQueryReplyRow={this.props.hideQueryReplyRow} />)
                        }
                    ]}
                    // if data contains key as prop value then the template will be applied
                    rowTemplates={[
                        {
                            key: 'queryDetails', rowClassName: 'query-row', template: (props) => (<QueryDetailsConversationTemplate {...props}
                                gridName={this.props.gridName}
                                queryGridNavigationColumnState={this.props.queryGridNavigationColumnState}
                                addAttachmentToQuery={this.props.addAttachmentToQuery}
                                customerReplyToQuery={this.props.customerReplyToQuery}
                                customerReopenQuery={this.props.customerReopenQuery}
                                changeReplyText={this.props.changeReplyText}
                                deleteQueryFile={this.props.deleteQueryFile}
                                showEarlierMsgs={this.props.showEarlierMsgs} />)
                        }
                    ]}
                    // use this prop if you want to set class to <tr>
                    rowClassNameResolver={this.rowTemplateResolver}>
                </Grid>
                {pager}
            </section>
        );
    }
}
