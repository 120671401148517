import React from 'react';
//uiState
//order
//handleChase

let  ProductChaseButton = (props) => {
    // orderIdChasing, orderIdChasingMessage
    var product = props.product || {};
    var rowState =props.uiState[product.id] || {};
    var chaseContent=null;
    if(rowState.chasing){
        chaseContent=<div className='loader'></div>;
    }
    else if(rowState.showChasedLabel == true){
        chaseContent = <div className="status-label color-teal">Chased</div>;
    }
    else if(rowState.chased || product.isChased){
        chaseContent=null;
    }
    else if(product.status != 1 || product.status != 2){
        chaseContent = <button onClick={() => { props.handleChase(product) }} type="button" className="button button-small button-teal">Chase</button>
    }
  
    return chaseContent;
};

export default ProductChaseButton;