import React from 'react';
import Cell from './Cell';

export default class Row extends React.PureComponent {
    render() {
        var cells = this.props.columns.map((column, index) => {
            var datacontext = {};
            if (column.dataField) {
                datacontext = this.props.row[column.dataField];
            }

            return <Cell datacontext={datacontext} column={column} row={this.props.row} key={index} />
        });

        return (
            <tr className={this.props.className}>
                {cells}
            </tr>
        );
    }
}