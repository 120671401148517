import React, { useState, useCallback, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../../components/Form';
import TextField from '../../components/TextField';
import PasswordField from '../../components/PasswordField';
import { selectLoading } from '../App/selectors';
import { registerUser } from './actions';
import ReCAPTCHA from "react-google-recaptcha";
import { selectErrorMgs, selectErrorMsgText } from './selectors';
import AlertMsg from './alertMsg';
import config from '../../utils/config';
import { useNavigate } from 'react-router-dom';
import homeSaga from './sagas';
import homeReducer from './reducer';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { changeIsLoginPageVisible } from '../App/actions';
//import { RESTART_ON_REMOUNT } from '../../utils/constants';

const loadingSelector = selectLoading();
const errorMsgSelector = selectErrorMgs();
const errorMsgTextSelector = selectErrorMsgText();

export default function Register(props) {

    useInjectReducer({ key: 'register', reducer: homeReducer });
    useInjectSaga({ key: 'register', saga: homeSaga/*, mode: RESTART_ON_REMOUNT*/ });
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const registerUserAction = useCallback((customerName, userName, password, navigate) => dispatch(registerUser(customerName, userName, password, navigate)), [dispatch]);
    const changeIsLoginPageVisibleAction = useCallback((value) => dispatch(changeIsLoginPageVisible(value)), [dispatch]);

    const isLoading = useSelector(loadingSelector);
    const showErrorMsg = useSelector(errorMsgSelector);
    const errorMsg = useSelector(errorMsgTextSelector);

    const [recaptchaErrorMsg, setRecaptchaErrorMsg] = useState(false);
    const [recaptchaMsg, setRecaptchaMsg] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [readterms, setReadterms] = useState(false);
    const [captchaVerified, setCaptchaVerified] = useState(false);

    useEffect(() => {
        changeIsLoginPageVisibleAction(true);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    function handleCustomerNameChanged(event, value) {
        setCustomerName(value);
    }

    function handleUserNameChanged(event, value) {
        setUserName(value);
    }

    function handlePasswordChanged(event, value) {
        setPassword(value);
    }

    function validateFields() {
        if (captchaVerified !== true) {
            return true;
        }
        if (customerName.length <= 0) {
            return true;
        }
        if (userName.length <= 0) {
            return true;
        }

        if (password.length <= 0) {
            return true;
        }
        if (readterms !== true) {
            return true;
        }

        return false;
    }

    function handleRegister(event) {
        registerUserAction(customerName, userName, password, navigate);
    }

    function expiredCallback() {
        setCaptchaVerified(false);
    }

    function verifyCaptchaCallback(response) {
        const body = { token: response };
        const requestURL = config.webApiIdentityUrl + '/user/recaptcha';
        fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(function (res) { return res.json() })
            .then(response => {
                if (response.success === true) {
                    setCaptchaVerified(true);
                    setRecaptchaErrorMsg(false);
                }
                else {
                    setRecaptchaMsg('You did not pass the Recaptcha! Please try again!');
                    setRecaptchaErrorMsg(true);
                }
            })
            .catch(error => console.error('Error:', error));
    }

    function handleReadTermsChanged(event) {
        setReadterms(event.target.checked);
    }

    return (
        <Form id="register-form" className="user-form register-form">
            <div className="user-form-title">
                <h1>Customer Registration</h1>
            </div>

            <TextField placeholder="Enter your customer name"
                label='Customer Name'
                inputClassName='form-control-grey'
                value={customerName}
                isRequired={true}
                description="Please enter the name of a firm. If you're not acting on behalf of a firm, please enter your name."
                onChange={handleCustomerNameChanged} />

            <TextField placeholder=""
                label='Username'
                inputClassName='form-control-grey'
                value={userName}
                isRequired={true}
                pattern=".{6,12}"
                title="Minimum 6 and maximum 12 characters long."
                description="Please enter your username, with at least 6 but no more than 12 characters."
                onChange={handleUserNameChanged} />

            <PasswordField
                label='Password'
                inputClassName='form-control-grey'
                pattern=".{8}"
                title="Minimum 8 characters long."
                value={password}
                isRequired={true}
                description="Please enter the password you wish to use (click the eye icon to see what you’re writing). Password needs to be at least 8 characters. Don' t worry if you forget it later, we can recover it for you."
                onChange={handlePasswordChanged} />

            <div className="entry entry-break">

                <ReCAPTCHA sitekey={config.captchaKey}
                    onChange={verifyCaptchaCallback}
                    onExpired={expiredCallback} />
                {recaptchaErrorMsg ? <AlertMsg msg={recaptchaMsg} /> : null}
            </div>

            {showErrorMsg ? <AlertMsg msg={errorMsg} /> : null}

            <div className="actions actions-checkbox">
                <label htmlFor="terms" className="form-checkbox">
                    <input id="terms" type="checkbox" required checked={readterms}
                        onChange={handleReadTermsChanged} />
                    <span className="indicator"></span>
                    <span className="label">Please read the <a href="https://weborders.severntrentsearches.com/Terms.htm"
                        className="text-link">terms and conditions</a> and check the box to confirm you have read and accepted them. <strong
                            className="label-required">*</strong></span>
                </label>
                {isLoading == true ?
                    <div className="alert alert-loading alert-success">
                        <div className="loader"></div>
                        <p>Hello <strong>{customerName}</strong>, we're processing your registration...</p>
                    </div>
                    :
                    <button onClick={handleRegister}
                        disabled={validateFields()}
                        type="submit"
                        className="button button-large button-green">
                        Register</button>
                }
            </div>
        </Form>
    );
}


// const mapStateToProps = createStructuredSelector({
//     showErrorMsg: selectErrorMgs(),
//     errorMsg: selectErrorMsgText(),
//     isLoading: selectLoading()
// })

// const mapDispatchToProps = ({
//     registerUser
// })

// var withSaga = injectSaga({ key: 'register', saga: homeSaga });
// var withReducer = injectReducer({ key: 'register', reducer: homeReducer });

// // Wrap the component to inject dispatch and state into it
// export default compose(
//     withSaga,
//     withReducer,
//     connect(mapStateToProps, mapDispatchToProps)
// )(Register);
