import {
    LOAD_JOB_TITLES,
    LOAD_JOB_TITLES_SUCCESS,
    LOAD_JOB_TITLES_ERROR,
    ON_BOARD_USER,
    ON_BOARD_SUCCESS,
    ON_BOARD_FAIL,
    LOAD_CUSTOMER_NAME,
    LOAD_CUSTOMER_NAME_SUCCESS,
    LOAD_CUSTOMER_NAME_FAIL
} from './constants';

export function loadJobTitles() {
    return {
        type: LOAD_JOB_TITLES
    };
}

export function loadJobTitlesSuccess(titles) {
    return {
        type: LOAD_JOB_TITLES_SUCCESS,
        titles
    };
}

export function loadJobTitlesFail(error) {
    return {
        type: LOAD_JOB_TITLES_ERROR,
        error
    };
}

export function onBoardUser(userInfo, navigate) {
    return {
        type: ON_BOARD_USER,
        userInfo,
        navigate
    };
}

export function onBoardSuccess(jwt) {
    return {
      type: ON_BOARD_SUCCESS,
      jwt
    };
}

export function onBoardFail(error) {
    return {
        type: ON_BOARD_FAIL,
        error
    };
}

export function loadCustomerName() {
    return {
        type: LOAD_CUSTOMER_NAME
    }
}

export function loadCustomerNameSuccess(userInfo) {
    return {
        type: LOAD_CUSTOMER_NAME_SUCCESS,
        userInfo
    }
}

export function loadCustomerNameFail(error) {
    return {
        type: LOAD_CUSTOMER_NAME_FAIL,
        error
    }
}
