import React from 'react';
import Footer from '../../components/Footer';

export default class Content extends React.Component {
    render() {
        //var myHeight = document.documentElement.clientHeight - 120 - 50; // 120 is footer, 50 is header
        var className = "";
        if (this.props.isLoginPageVisible == true)
            className += ' onboard-content';

        return (
            // scroll is now responsibility of content div
            <div className={'content'} style={{height: 'calc(100% - 50px)', overflow: 'auto' }}>
                {/* keeps footer stick in bottom */}
                <div style={{ minHeight: 'calc(100% - 122px)'}} className={className}> 
                    {this.props.children}
                </div>

                <Footer isUserLogged={this.props.isUserLogged} customerName={this.props.customerName} />
            </div>
        );
    }
}