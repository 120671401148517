
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Pdf = (props) => (
  <SvgIconEx  {...props} viewBox="0 0 43 56">
      <g fill="none" fillRule="evenodd">
          <path d="M30.485 0H1.463C.655 0 0 .655 0 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L31.107.257C30.942.093 30.718 0 30.485 0" fill="#E9E9E0"/>
          <path fill="#D9D7CA" d="M31 .151V12h11.849L31 .151"/>
          <path d="M13.014 33.324c-.348 0-.682-.113-.967-.326-1.041-.782-1.181-1.651-1.115-2.243.182-1.627 2.195-3.332 5.985-5.068 1.504-3.296 2.935-7.357 3.788-10.75-.998-2.173-1.967-4.99-1.261-6.644.248-.578.558-1.023 1.134-1.215.229-.076.804-.172 1.016-.172.504 0 .947.649 1.261 1.049.295.377.964 1.174-.373 6.803 1.348 2.783 3.257 5.619 5.088 7.561 1.311-.237 2.439-.358 3.358-.358 1.566 0 2.515.366 2.902 1.117.32.622.188 1.349-.391 2.16-.557.779-1.324 1.191-2.22 1.191-1.216 0-2.632-.768-4.211-2.285-2.838.593-6.151 1.651-8.829 2.822-.836 1.774-1.637 3.203-2.383 4.251-1.023 1.437-1.907 2.107-2.782 2.107zm2.662-5.126c-2.138 1.201-3.009 2.188-3.071 2.744-.01.092-.037.334.431.692.149-.047 1.019-.444 2.64-3.436zm13.637-4.442c.815.627 1.014.944 1.547.944.234 0 .902-.01 1.21-.441.149-.209.207-.343.229-.414-.122-.066-.285-.197-1.175-.197-.504 0-1.139.021-1.811.108zm-7.47-6.582c-.716 2.474-1.66 5.145-2.674 7.564 2.09-.811 4.362-1.519 6.496-2.02-1.35-1.568-2.699-3.526-3.822-5.544zm-.607-8.462c-.098.033-1.33 1.757.096 3.216.949-2.115-.053-3.23-.096-3.216zM41.537 56H1.463C.655 56 0 55.345 0 54.537V39h43v15.537c0 .808-.655 1.463-1.463 1.463" fill="#CC4B4C"/>
          <path d="M10.885 53H9.244V42.924h2.899c.428 0 .852.068 1.271.205.419.137.795.342 1.128.615.333.274.602.604.807.991.205.388.307.823.307 1.306 0 .511-.087.973-.259 1.388-.174.415-.416.763-.725 1.046-.31.282-.684.501-1.121.656-.438.155-.921.232-1.449.232h-1.217V53zm0-8.832v3.992h1.504c.2 0 .398-.034.594-.102.196-.069.376-.18.54-.335.165-.156.296-.371.397-.65.1-.278.15-.622.15-1.032 0-.164-.023-.353-.068-.567-.046-.214-.139-.419-.28-.616-.142-.196-.34-.36-.595-.492-.255-.132-.593-.198-1.012-.198h-1.23zM25.719 47.682c0 .829-.089 1.538-.267 2.126-.178.588-.403 1.08-.677 1.476-.273.397-.581.709-.922.937-.342.227-.673.398-.992.512-.319.115-.61.187-.875.219-.264.032-.46.048-.588.048h-3.814V42.924h3.035c.848 0 1.593.135 2.236.403.642.269 1.175.627 1.599 1.073.424.447.74.955.95 1.525.21.569.315 1.155.315 1.757zm-4.867 4.115c1.111 0 1.914-.356 2.406-1.067.492-.71.738-1.741.738-3.089 0-.419-.05-.834-.15-1.244-.101-.411-.294-.782-.581-1.115-.287-.333-.677-.601-1.169-.806-.492-.205-1.131-.308-1.914-.308h-.957v7.629h1.627zM29.766 44.168v3.172h4.211v1.121h-4.211V53h-1.668V42.924H34.4v1.244h-4.634" fill="#FFF"/>
      </g>
  </SvgIconEx>
);
Pdf.displayName = 'Pdf';
Pdf.muiName = 'SvgIcon';

export default Pdf;