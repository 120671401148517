import React from 'react';
import { Link } from 'react-router-dom';
import QueryArchive from '../../svg-icons/query-archive';

export default class PageTitle extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    render(){
        var openClassName="active";
        var closeClassName="";

        if(this.props.activeTab == 'close'){
            openClassName= "";
            closeClassName= "active";
        }
        return (
                <div className="page-title"> {/*<!-- Add className "fixed" when users scroll down and this reaches the top of the screen -->*/}
                    <div className="content-wrapper">
                        <h1 className="hide-mobile">{this.props.activeTab == 'open' ?
                            'Open Orders'
                            : 'Closed Orders'}</h1>
                        <div className="page-title-actions hide-mobile">
                            <Link to= '/create-order' className="button button-small button-green hide-mobile">Create New Order</Link>
                        </div>
                        <ul className="page-title-menu">
                            <li className={openClassName}>
                                <Link onClick={()=>this.props.switchOpenClose('open')}  className="text-link">
                                    <span className="count hide-mobile">{this.props.totalOpenOrdersCount}</span> {/*<!-- Don't show if total = 0 -->*/}
                                    <span className="text">Open Orders</span>
                                </Link>
                            </li>
                            <li className={closeClassName}>
                                <Link  onClick={()=>this.props.switchOpenClose('close')} className="text-link">
                                    <QueryArchive className="icon icon-tab-query hide-mobile"/>
                                    <span className="text">Closed Orders</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div> );
    };
}