import React from 'react';

export default class AutoComplete extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource:[],
            textValue:''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.timeout = null;
    }
    doneTyping(text) {
        if (this.props.onUpdateInput) {
            this.props.onUpdateInput(text);
        }
    }

    handleInputChange(e) {
        this.setState({ textValue: e.target.value });
        clearTimeout(this.timeout);
        this.timeout=setTimeout(()=>{
            this.doneTyping(this.state.textValue);
        }, 500);
    }

    // use as API for external call
    setInitalText(text){
        this.setState({textValue: text});
    }

    render() {
        return (

            <div className='entry entry-search form-masked-label form-control-icon'>
                <input id="filter-postcode" type="search"
                    name="your-reference"
                    placeholder={this.props.placeholder}
                    className="form-control-white"
                    data-filter="search"
                    data-demo="filter"
                    onChange={this.handleInputChange}
                    ref={input=>this.textBox = input}
                    value={this.state.textValue}
                />
                <label htmlFor="filter-postcode" data-filter="search">{this.props.hintText}</label>
            </div>
        );
    }
}