/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const DEFAULT_LOCALE = 'en';
export const APP_ERROR = 'atlas/App/APP_ERROR';
export const APP_USER_LOGOUT = 'atlas/App/APP_USER_LOGOUT';
export const APP_MENU_NOTIFICATIONS_TOGGLE = 'atlas/app/APP_MENU_NOTIFICATIONS_TOGGLE';
export const APP_MENU_NOTIFICATIONS_REMOVE_ITEM ='atlas/app/APP_MENU_NOTIFICATIONS_REMOVE_ITEM';
export const APP_MENU_NOTIFICATIONS_REMOVE_ITEM_SUCCESS ='atlas/app/APP_MENU_NOTIFICATIONS_REMOVE_ITEM_SUCCESS';
export const APP_MENU_NOTIFICATIONS_REMOVE_ALL_ITEMS ='atlas/app/APP_MENU_NOTIFICATIONS_REMOVE_ALL_ITEMS';
export const APP_MENU_NOTIFICATIONS_REMOVE_ALL_ITEMS_SUCCESS ='atlas/app/APP_MENU_NOTIFICATIONS_REMOVE_ALL_ITEMS_SUCCESS';
export const HIDE_APP_SNACK_BAR = 'atlas/app/HIDE_APP_SNACK_BAR';

export const LOAD_CUSTOMER_CONTACTS = 'atlas/app/LOAD_CUSTOMER_CONTACTS';
export const LOAD_CUSTOMER_CONTACTS_SUCCESS = 'atlas/app/LOAD_CUSTOMER_CONTACTS_SUCCESS';
export const LOAD_CUSTOMER_NOTIFICATIONS_SUCCESS = 'atlas/app/LOAD_CUSTOMER_NOTIFICATIONS_SUCCESS';
export const HIDE_FILTER_ICON = 'atlas/app/HIDE_FILTER_ICON';
export const SHOW_FILTERS = 'atlas/app/SHOW_FILTERS';
export const IS_LOGIN_PAGE_VISIBLE = 'atlas/app/IS_LOGIN_PAGE_VISIBLE';