
import Modal from '../../components/Modal/index'
import Broken from '../../svg-icons/Broken';
import { initCreatePageState, loadPackagesInfo, loadOrderIdentity } from './actions';
import CreateOrderInternal from './index-internal';
import { loadCustomerContacts } from '../App/actions';

import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import rootSaga from './sagas-cancell-order';
import { useParams, useSearchParams } from 'react-router-dom';
//import { RESTART_ON_REMOUNT } from '../../utils/constants';

export default function CancelledOrder(props) {

    useInjectSaga({ key: 'cancelled-order', saga: rootSaga/*, mode: RESTART_ON_REMOUNT*/ });

    const dispatch = useDispatch();
    //const initCreatePageStateAction = useCallback((orderId) => dispatch(initCreatePageState(orderId)), [dispatch]);
    const loadOrderIdentityAction = useCallback((orderId) => dispatch(loadOrderIdentity(orderId)), [dispatch]);
    //const loadCustomerContactsAction = useCallback(() => dispatch(loadCustomerContacts()), [dispatch]);
    //const loadPackagesInfoAction = useCallback(() => dispatch(loadPackagesInfo()), [dispatch]);

    let { orderId } = useParams();
    let [searchParams] = useSearchParams();
    let isusercancelled = searchParams.get('isusercancelled');
    useEffect(() => {
        //initCreatePageStateAction();
        //loadPackagesInfoAction();
        //loadCustomerContactsAction();
        loadOrderIdentityAction(orderId);
    }, []);

    if (orderId) {
        var msg = "We were unable to take your payment. Please check your details and try again. If you continue to have a problem with your payment. Please contact Severn Trent Searches on 0115 9713550 and our customer service advisors will be able to assist you.";
        if (isusercancelled == "true") {
            msg = "Payment has been cancelled. Please make your changes and submit order again.";
        }

        return (
            <div>
                <Modal showModal={true} icon={<Broken className="icon icon-broken inline-flex" />} msg={msg} />
                <CreateOrderInternal /> {/* orderId={this.props.params.id}/>*/}
            </div>
        );
    }

    return null;
}