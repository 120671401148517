import { createQueryDetailsReducer } from '../Queries/queryDetailsReducer';
import { combineReducers } from 'redux';
import { SHOW_QUERY_REPLAY, HIDE_QUERY_REPLAY } from '../Queries/constants';
import {produce} from "immer";

export function queryDetailsData(state = {}, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case SHOW_QUERY_REPLAY:
                draft.data = action.data;
                break;

            case HIDE_QUERY_REPLAY:
                draft.data.queryDetails.splice(0, 0, action.queryDto);
                break;
        }
    });
}

export default combineReducers({
    queryDetailsUI: createQueryDetailsReducer("query-detail-cont"),
    queryDetailsData: queryDetailsData
})