export const LOAD_JOB_TITLES = 'atlas/OnBoard/LOAD_JOB_TITLES'
export const LOAD_JOB_TITLES_SUCCESS = 'atlas/OnBoard/LOAD_JOB_TITLES_SUCCESS';
export const LOAD_JOB_TITLES_ERROR = 'atlas/OnBoard/LOAD_JOB_TITLES_ERROR';

export const ON_BOARD_USER = 'atlas/OnBoard/ON_BOARD_USER';
export const ON_BOARD_SUCCESS = 'atlas/OnBoard/ON_BOARD_SUCCESS';
export const ON_BOARD_FAIL = 'atlas/OnBoard/ON_BOARD_FAIL';

export const LOAD_CUSTOMER_NAME = 'atlas/OnBoard/LOAD_CUSTOMER_NAME';
export const LOAD_CUSTOMER_NAME_SUCCESS = 'atlas/OnBoard/LOAD_CUSTOMER_NAME_SUCCESS';
export const LOAD_CUSTOMER_NAME_FAIL = 'atlas/OnBoard/LOAD_CUSTOMER_NAME_FAIL';
