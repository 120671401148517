
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let AlertSuccess = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path d="M17 8.5l-7.5 7L7 13"/><circle cx="12" cy="12" r="11.5"/>
      </g>
  </SvgIconEx>
);
AlertSuccess.displayName = 'AlertSuccess';
AlertSuccess.muiName = 'SvgIcon';

export default AlertSuccess;