import React from 'react';
import AlertError from '../../svg-icons/alertError';

export default class AlertMsg extends React.Component {
    static defaultProps={
        msg: 'Username and/or password incorrect. Please try again or call us on 0115 9713550 for assistance.'
    }

    render(){
        return(
            <div className="alert alert-block alert-error">
                <AlertError className="icon icon-alert-error" />
                <div className="alert-content">
                    <p>{this.props.msg}</p>
                </div>
            </div>
        );
    }
}
