import React from 'react';

export default class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showModal: false };

        this.handleCloseMenu = this.handleCloseMenu.bind(this);
    }

    componentDidMount() {
        // console.log(this.props);
        if (this.props.showModal !== undefined) {
            this.setState({ showModal: this.props.showModal });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showModal !== undefined && this.props.showModal !== prevProps.showModal) {
            this.setState({ showModal: this.props.showModal });
        }
    }

    handleCloseMenu(e) {
        this.setState({ showModal: !this.state.showModal });
        if (this.props.handleClose !== undefined) {
            this.props.handleClose(true);
        }
    };

    render() {
        const show = this.state.showModal ? 'block' : 'none';
        const style = { display: show };
        return (
            <div className={"modal-custom " + this.props.classNameCustom} style={style}>
                <div className="modal-content-custom">
                    <span onClick={(e) => this.handleCloseMenu(e)} className="close-custom">&times;</span>
                    <div className="modal-msg-area">
                        <h1>{this.props.title}</h1>
                        <p>{this.props.icon} {this.props.msg}</p>
                    </div>
                </div>
            </div>
        );
    }
}
