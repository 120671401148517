
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Download = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
            <path d="M22.5 17.718v1.913c0 1.585-1.285 2.87-2.87 2.87H3.37C1.783 22.5.5 21.215.5 19.63V17.72M11.5 17.608V.5M18.5 10.608l-7 7-7-7"/>
      </g>
  </SvgIconEx>
);
Download.displayName = 'Download';
Download.muiName = 'SvgIcon';

export default Download;