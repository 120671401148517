import React from 'react';
import { guid } from '../../utils/format';
import { showAppError } from '../App/actions';
import AlertWarning from '../../svg-icons/alertWarning';
import * as actions from './actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    selectSelectedPackages, selectAllPackages, selectCustomerReference, selectSelectedPackagesInOrder,
    selectContactPerson, selectIsCreating, selectHowIsAddressEntered,
    selectDiscountPackageCore, selectVatRate, selectLocalAuthoritySearchPackage,
    selectBasketOpenClassName, selectMapSaveDiscardAction, selectShowAlertForSave, selectIsBusy, selectWarningPackage,
    selectValidationMsgs
} from './selectors';

import AsideWithCart from '../../components/Aside/asideWithCart';
import BasketContent from './basket/content';
import keys from 'lodash/keys';
import { processRegularPackages, mapStateToPropsOS, createOrder, createQuote, createOrderFromQuote } from './newOrderSubmition';

class NewOrderBasket extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleSaveQuote = this.handleSaveQuote.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAddPackages = this.handleAddPackages.bind(this);
        this.state =
        {
            msg: ''
        };
    }

    handleSaveQuote() {
        if (this.validateForm() === false) {
            return;
        }
        var order = createQuote(this.props);
        this.props.saveQuote({ order: order, quoteId: this.props.quoteId });
    }

    validateForm = () => {
        if (this.props.contactPerson.id < 0 ||
            this.props.customerReference.length < 1 ||
            this.props.howIsAddressEntered == 'none') {
            return false;
        }

        if (this.props.howIsAddressEntered == 'edit') {
            this.props.addValidationMessage('editAddress', 'Please save property address!');
            document.getElementById("address-details").scrollIntoView();
            this.props.setAddressSaveTooltip(true);
            return false;
        }

        const packages = this.props.selectedPackageIds;
        for (const key in packages) {
            if (packages.hasOwnProperty(key)) {
                if (packages[key].termsRequired !== undefined
                    && packages[key].termsRequired === true
                    && packages[key].termsAndConditions !== undefined
                    && packages[key].termsAndConditions === false) {
                    this.props.addValidationMessage('terms', 'You have to agree with "Terms and conditions first"!');
                    return false;
                }
            }
        }

        if (this.props.mapSaveDiscardAction === false && this.props.showAlertForSave === true) {
            this.props.addValidationMessage('saveMap', 'Please complete the Property Map section to place the order!');
            document.getElementById("picframe").scrollIntoView();
            return false;
        }

        return true;
    };

    handleSubmit() {
        if (this.validateForm() === false) {
            return;
        }

        var order;
        if (this.props.quoteId == undefined || this.props.quoteId == 0) {
            order = createOrder(this.props);
        }
        else {
            order = createOrderFromQuote(this.props.quoteId, this.props);
        }

        this.props.submitOrder(order);
    }

    handleAddPackages() {
        var packages = processRegularPackages(this.props.selectedPackageIds, this.props.allPackages);
        this.props.addPackagesToOrder(this.props.orderId, packages);
    }

    getPackageCount() {
        var count = keys(this.props.selectedPackageIds).length;// + 1;
        return count;
    }

    render() {
        return (
            <AsideWithCart isOpen={this.props.openClassName} hideBasketOnMobile={this.props.hideBasketOnMobile}>
                <div className="details-aside-title">
                    <h4>Basket ({this.getPackageCount()})</h4>
                </div>
                <BasketContent selectedPackageIds={this.props.selectedPackageIds}
                    selectedPackagesInOrder={this.props.selectedPackagesInOrder}
                    selectedDiscountPackageCore={this.props.selectedDiscountPackageCore}
                    allPackages={this.props.allPackages}
                    deletePackage={this.props.selectPackage}
                    deleteCoreDiscountPackage={this.props.selectDiscountPackage}
                    deleteEnquiryPackage={this.props.selectTmIntegrationPackage}
                    vatRate={this.props.vatRate}
                    handleSaveQuote={this.handleSaveQuote}
                    handleSubmit={this.handleSubmit}
                    validateForm={this.validateForm}
                    handleAddPackages={this.handleAddPackages}
                    isCreating={this.props.isCreating}
                    showSubmit={this.props.showSubmit}
                    showAddToOrder={this.props.showAddToOrder}
                    showQuote={this.props.showQuote}
                    isBusy={this.props.isBusy}
                    localAuthoritySearchPackageId={this.props.localAuthoritySearchPackageId}
                    warningPackages={this.props.warningPackages} />
                {
                    Object.keys(this.props.validationMsgs).map((key) => {
                        let item = this.props.validationMsgs[key];
                        return (
                            <div className="basket-msg" key={guid()}>
                                <div className="w20">
                                    <AlertWarning className="icon icon-alert-warning basket-alert-icon-size" />
                                </div>
                                <div className="w80">
                                    {item}
                                </div>
                            </div>
                        );
                    })
                }
            </AsideWithCart>
        );
    }
}
const mapStateToProps = (state, props) => {
    return createStructuredSelector({
        vatRate: selectVatRate(),
        selectedPackageIds: selectSelectedPackages(),
        selectedDiscountPackageCore: selectDiscountPackageCore(),
        allPackages: selectAllPackages(),
        customerReference: selectCustomerReference(),
        contactPerson: selectContactPerson(),
        isCreating: selectIsCreating(),
        quoteId: () => {
            return props.quoteId
        },
        howIsAddressEntered: selectHowIsAddressEntered(),
        openClassName: selectBasketOpenClassName(),
        selectedPackagesInOrder: selectSelectedPackagesInOrder(),
        localAuthoritySearchPackageId: selectLocalAuthoritySearchPackage(),
        mapSaveDiscardAction: selectMapSaveDiscardAction(),
        showAlertForSave: selectShowAlertForSave(),
        isBusy: selectIsBusy(),
        warningPackages: selectWarningPackage(),
        validationMsgs: selectValidationMsgs(),
        ...mapStateToPropsOS
    })
}

const mapDispatchToProps = ({
    ...actions, showAppError
})

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(NewOrderBasket);
