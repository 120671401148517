import { take, call, put, select, fork, takeLatest } from 'redux-saga/effects';
import {
  LOAD_PAGED_ORDERS,
  LOAD_ORDER_STATUSES,
  LOAD_TOTAL_OPEN_ORDERS_COUNT
} from './constants';
import { ordersLoaded, 
  ordersLoadingError,
  orderStatusesLoaded,
  totalOpenOrdersCountLoaded
} from './actions';
import {
  chaseOrderSaga
} from '../OrderDetails/sagas';
import { showAppError } from 'containers/App/actions';
import config from 'utils/config';
import request from 'utils/request';

export function* loadPagedOrdersRequest(action) {
  // Select username from store
  //const username = yield select(selectUsername());
    let startIndex = 0;
    let pageSize = 10;
    const customerContactId = action.values.customerContactId || null;
    const dateOrderCreated = action.values.dateCreated || null;
    const filterText = action.values.filterText || null;
    const orderStatus = action.values.orderStatus || 0;
    let loadOpenOrders = action.values.loadOpenOrders;
    if (loadOpenOrders == null){
      loadOpenOrders = true;
    }
    const dateCompleted = action.values.dateCompleted || null;

  if (action.values) {
    startIndex = action.values.startIndex;
    pageSize = action.values.pageSize;
  }
  // Grid to update. Undefined for all grids
  const requestURL = `${config.webApiUrl}/order?startIndex=${startIndex}&pageSize=${pageSize}&customerContactId=${customerContactId}&dateCreated=${dateOrderCreated}&filterText=${filterText}&orderStatus=${orderStatus}&openOrders=${loadOpenOrders}&dateCompleted=${dateCompleted}`;
  
  try {
    // Call our request helper (see 'utils/request')
    const repos = yield call(request.getAuth, requestURL);
    yield put(ordersLoaded(repos));
  }
  catch (err) {
    console.debug('............ error in load dashboard saga ..................');
    console.debug(err);
    yield put(ordersLoadingError(err));
    yield put(showAppError(err));
  }
}

export function* loadTotalOpenOrdersCountRequest(action) {
  const requestURL = `${config.webApiUrl}/order/openOrdersCount`;
  try {
    const repos = yield call(request.getAuth, requestURL);
    yield put(totalOpenOrdersCountLoaded(repos));
  }
  catch (err) {
    console.error(err);
  }
}

export function* loadOrderStatusesRequest(action) {
  let loadOpenOrders = action.values.loadOpenOrders;
  if (loadOpenOrders == null){
      loadOpenOrders = true;
  }
  const requestURL = `${config.webApiUrl}/order/orderStatuses?openOrders=${loadOpenOrders}`;
  try {
    // Call our request helper (see 'utils/request')
    const repos = yield call(request.getAuth, requestURL);
    yield put(orderStatusesLoaded(repos));
  }
  catch (err) {
    console.error(err);
  }
}

export function* loadPagedOrdersSaga() {
  yield takeLatest(LOAD_PAGED_ORDERS, loadPagedOrdersRequest);
}

export function* loadOrderStatusesSaga() {
  yield takeLatest(LOAD_ORDER_STATUSES, loadOrderStatusesRequest);
}

export function* loadTotalOpenOrdersCountSaga() {
  yield takeLatest(LOAD_TOTAL_OPEN_ORDERS_COUNT, loadTotalOpenOrdersCountRequest);
}

// Bootstrap sagas
export default function* rootSaga() {
  // Non-blocking fork effects
  // https://redux-saga.js.org/docs/advanced/RootSaga
  // rootSaga will terminate on the first error in any individual child effect or saga and crash your whole app! 
  // this is not so bad but we have to appropriate error handling
  yield fork(loadPagedOrdersSaga);
  yield fork(loadOrderStatusesSaga);
  yield fork(loadTotalOpenOrdersCountSaga);
  yield fork(chaseOrderSaga);
}