import {
  LOAD_ORDER_CONFIRMATION,
  LOAD_ORDER_CONFIRMATION_SUCCESS,
  LOAD_ORDER_CONFIRMATION_ERROR,
  LOAD_QUOTE_CONFIRMATION
} from './constants';

export function loadOrderConfirmation(orderId) {
  return {
    type: LOAD_ORDER_CONFIRMATION,
    orderId
  };
}

export function loadOrderConfirmationLoaded(order) {
  return {
    type: LOAD_ORDER_CONFIRMATION_SUCCESS,
    order
  };
}


export function loadOrderConfirmationError(error) {
  return {
    type: LOAD_ORDER_CONFIRMATION_ERROR,
    error,
  }
}

//
// quote confirmation
export function loadQuoteConfirmation(quoteId) {
  return {
    type: LOAD_QUOTE_CONFIRMATION,
    quoteId
  };
}

// export function loadQuoteConfirmationLoaded(quote) {
//   return {
//     type: LOAD_ORDER_CONFIRMATION_SUCCESS,
//     quote
//   };
// }


// export function loadQuoteConfirmationError(error) {
//   return {
//     type: LOAD_ORDER_CONFIRMATION_ERROR,
//     error,
//   }
// }