import { LOGIN_USER, LOGIN_USER_ERROR, REMIND_ME_SUCCESS, REMIND_ME_ERROR, CHANGE_VIEW, INIT_LOGIN_PAGE, LOGIN_PAGE_SHOW_NOTIFICATION } from './constants';

const initialState = {
  showErrorMsg: false,
  showForgottenPswdInitalForm: true,
  showForgottenPswdError: false,
  activeView: 'login',
  showNotification: false,
  notificationMsg: ''
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        showErrorMsg: false
      }

    case LOGIN_USER_ERROR:
      return {
        ...state,
        showErrorMsg: true
      }

    case REMIND_ME_SUCCESS:
      return {
        ...state,
        showForgottenPswdError: false,
        showForgottenPswdInitalForm: false
      }

    case REMIND_ME_ERROR:
      return {
        ...state,
        showForgottenPswdError: true,
        showForgottenPswdInitalForm: true
      }

    case CHANGE_VIEW:
      var newState = state;
      if (action.view == 'reset') {
        newState = {
          ...state,
          showForgottenPswdError: false,
          showForgottenPswdInitalForm: true
        }
      }

      newState={
        ...state,
        activeView:action.view
      }
      
      return newState;

    case INIT_LOGIN_PAGE:
      return {
        ...state,
        showErrorMsg: false,
        showForgottenPswdInitalForm: true,
        showForgottenPswdError: false
      }

    case LOGIN_PAGE_SHOW_NOTIFICATION:
      return{
        ...state,
        showNotification: action.payload.showMessage,
        notificationMsg: action.payload.message
      }
      default:
        return state;
  }
}