
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Utility = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path d="M3.561 22.56c-.586.587-1.537.587-2.121 0-.586-.585-.586-1.534 0-2.12l6.57-6.572 2.121 2.121-6.57 6.571z"/>
          <path strokeLinecap="round" d="M6.951 12.807l4.24 4.242"/>
          <path d="M19.75 4.25L9.062 14.938M20.934 5.433L18.459 2.96 22 .5 23.5 2zM11.89 9.812L8.145 6.068c.616-1.456.334-3.203-.854-4.39C6.104.493 4.434.21 2.977.825L5 2.934V5H2.846L.781 3.02c-.616 1.458-.333 3.166.854 4.352 1.186 1.188 2.933 1.451 4.388.835l3.746 3.738M11.89 14.055l3.747 3.748c-.616 1.456-.334 3.202.854 4.389 1.187 1.186 2.933 1.468 4.39.853l-2.034-2.11v-2h2L23 20.923c.616-1.456.333-3.202-.854-4.388-1.187-1.188-2.933-1.471-4.389-.854l-3.745-3.747"/>
      </g>
  </SvgIconEx>
);
Utility.displayName = 'Utility';
Utility.muiName = 'SvgIcon';

export default Utility;