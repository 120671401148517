import { take, call, put, select, fork, takeLatest } from 'redux-saga/effects';
import { NEW_ORDER_LOAD_IDENTITY } from './constants';
import { loadQuoteDetailsSuccess, getAlertsFromLandmark } from './actions';
import { showAppError } from 'containers/App/actions';
import config from 'utils/config';
import request from 'utils/request';

import { uploadInit } from '../../components/UploadFile/actions';
//
// Load order identity saga. It is used when customer cancells order payment and we need to return him back to order page
//
export function* loadOrderIdentitySaga() {
  yield takeLatest(NEW_ORDER_LOAD_IDENTITY, loadOrderIdentityRequest);
}

export function* loadOrderIdentityRequest(action) {
  var orderId = action.orderId || 0;
  var requestURL = `${config.webApiUrl}/order/getOrderIdentity/${orderId}`;
  var requestType = request.getAuth;

  try {
    const orderData = yield call(requestType, requestURL);
    yield put(loadQuoteDetailsSuccess(orderData));
    yield put(uploadInit(orderData.attachments, 'create-order-upload-channel'));
    yield put(getAlertsFromLandmark());
  }
  catch (err) {
    yield put(showAppError(err));
  }
}

// Bootstrap sagas
export default function* rootSaga() {
  yield fork(loadOrderIdentitySaga);
}
