import React, { useCallback, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useSelector, useDispatch } from 'react-redux';
import { selectData, selectUI } from './selectors';
import { useParams } from 'react-router-dom';
import { uploadRequest } from '../../components/UploadFile/actions';
import {
    loadQuery,
    customerReplyToQuery,
    changeReplyText,
    deleteQueryFile,
    showEarlierMsgs
} from '../Queries/actions';
import QueryDetailsConversationTemplate from '../../components/GridQueries/QueryDetailsConversationTemplate';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import rootSaga from '../Queries/sagas';
import detailsReducer from './reducer';
//import { RESTART_ON_REMOUNT } from '../../utils/constants';

const gridName = "query-detail-cont";
const dataSelector = selectData();
const uiSelector = selectUI();

export default function QueryDetails(props) {
    const { id } = useParams();
    useInjectReducer({ key: 'queryDetails', reducer: detailsReducer });
    useInjectSaga({ key: 'queryDetails', saga: rootSaga/*, mode: RESTART_ON_REMOUNT*/ });

    const dispatch = useDispatch();
    const loadQueryAction = useCallback((rowTemplateKey, gridName, queryId) => dispatch(loadQuery(rowTemplateKey, gridName, queryId)), [dispatch]);
    const addAttachmentToQueryAction = useCallback((file, objectId, attachmentType, uploadChannel, options) => dispatch(uploadRequest(file, objectId, attachmentType, uploadChannel, options)), [dispatch]);
    const customerReplyToQueryAction = useCallback((gridName, queryDto) => dispatch(customerReplyToQuery(gridName, queryDto)), [dispatch]);
    const changeReplyTextAction = useCallback((gridName, queryId, text) => dispatch(changeReplyText(gridName, queryId, text)), [dispatch]);
    const deleteQueryFileAction = useCallback((gridName, queryId, index) => dispatch(deleteQueryFile(gridName, queryId, index)), [dispatch]);
    const showEarlierMsgsAction = useCallback((gridName, queryId) => dispatch(showEarlierMsgs(gridName, queryId)), [dispatch]);

    useEffect(() => {
        loadQueryAction("", gridName, id);
        ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });
    }, []);

    const queryData = useSelector(dataSelector);
    const queryUI = useSelector(uiSelector);
    if (queryData == undefined) {// && this.props.queries.count() == 0){
        return null;
    }

    return (<div style={{ marginTop: 50 }}>
        <QueryDetailsConversationTemplate
            gridName={gridName}
            dataContext={queryData}
            queryGridNavigationColumnState={queryUI}
            addAttachmentToQuery={addAttachmentToQueryAction}
            customerReplyToQuery={customerReplyToQueryAction}
            changeReplyText={changeReplyTextAction}
            showEarlierMsgs={showEarlierMsgsAction}
            deleteQueryFile={deleteQueryFileAction} />
    </div>);

}