import { UploadActions } from './actions';
import {produce} from "immer";
import { NEW_ORDER_INIT_STATE } from '../../containers/CreateOrder/constants'
const initialState = {
    uploadedFiles: [], // keep this as immutable List
}

export function createUploadReducer(shouldProcessCallback) {
    if (shouldProcessCallback == undefined) {
        throw Error("must define shouldProcessCallback.");
    }

    return function uploadReducer(state = initialState, action) {
        if (shouldProcessCallback(action) === false) {
            return state;
        }
        return produce(state, (draft) => {
            switch (action.type) {
                case UploadActions.UPLOAD_INIT:
                case NEW_ORDER_INIT_STATE:
                    var uploadedFiles = [];
                    if (action.attachments) {
                        action.attachments.forEach((att) => {
                            var file = {
                                fileName: att.name,
                                path: att.fileLocation,
                                progress: 1,
                                isNotDownloadable: att.isNotDownloadable || false
                            };

                            uploadedFiles.splice(0, 0, file);
                        });
                    }

                    draft.uploadedFiles = uploadedFiles;
                    break;

                case UploadActions.UPLOAD_REQUEST:
                    var file = {
                        fileName: action.file.name,
                        progress: 0
                    };

                    draft.uploadedFiles.splice(state.uploadedFiles.length, 0, file);
                    break;

                case UploadActions.UPLOAD_PROGRESS:
                    let index = state.uploadedFiles.findIndex(item => { return item.fileName == action.fileName });

                    // dont set progress to 100% until we receive confirmation from server
                    var fileProgress = action.progress == 1 ? 0.99 : action.progress;
                    draft.uploadedFiles[index].progress = fileProgress;
                    break;

                case UploadActions.UPLOAD_SUCCESS:
                    let index1 = state.uploadedFiles.findIndex(item => { return item.fileName == action.fileName });
                    draft.uploadedFiles[index1].progress = 1;
                    draft.uploadedFiles[index1].path = action.responsePath;
                    draft.uploadedFiles[index1].isNotDownloadable = action.fileOptions.isNotDownloadable;
                    break;

                case UploadActions.UPLOAD_FAILURE:
                case UploadActions.UPLOAD_DELETE_FAILURE:
                    var index2 = state.uploadedFiles.findIndex(item => { return item.fileName == action.fileName });
                    draft.uploadedFiles[index2].failed = true;
                    draft.uploadedFiles[index2].errMsg = action.err.message;
                    draft.uploadedFiles[index2].progress = 0;
                    break;

                case UploadActions.UPLOAD_DELETE_SUCCESS:
                    let index3 = state.uploadedFiles.findIndex(item => { return item.fileName == action.fileName });
                    draft.uploadedFiles.splice(index3, 1);
                    break;
            }
        });
    }
}