import PropTypes from 'prop-types';
import React from 'react';
import GridTitle from './GridTitle';
//import GridOrdersDashboard from './GridOrdersDashboard';
import Pager from '../Pager';
import {
  Grid,
  Columns,
  Column
} from '../Grid'

import {
  ExpDateCellTemplate,
  NavigationColumnTemplate,
  AddressColumnTemplate,
  ProductColumnTemplate,
  StatusColumnTemplate
} from './index';

export default class GridOrdersDashboard extends React.PureComponent {

  static defaultProps = {
    showTitle: true
  };

  render() {
    var pager;

    if (this.props.showPager === true) {
      pager =
        <Pager onChangePage={this.props.onPageChange}
               totalItemsCount={this.props.totalItemsCount}
               currentPage={this.props.currentPage}
               pageSize={this.props.pageSize}
               gridName='orders'/>;
    }

    return (
      <section id="orders" className="content-block content-wrapper">
        {this.props.showTitle
          ? <GridTitle titleText='Open Orders' itemsCount={this.props.itemsCount}
                       pageItemsCount={this.props.datasource.length}/>
          : null}
        {/*<GridOrdersDashboard {...this.props}/>*/}

        <Grid
          isLoading={this.props.isLoading}
          datasource={ this.props.datasource }
          columnsData={[
            {
              header: 'due',
              //className: 'cell-60',
              style: {width: 60},
              dataField: 'dueDate',
              dataCellTemplate: (props) => (<ExpDateCellTemplate {...props}/>)
            },
            {
              header: 'Address',
              dataField: 'address',
              style: {width: '*'},
              dataCellTemplate: AddressColumnTemplate
            },
            {
              header: 'Your Ref.',
              dataField: 'customerRef',
              className: 'hide-mobile',
              style: {width: 160},
              //dataCellTemplate: AddressColumnTemplate
            },
            {
              header: 'Status',
              dataField: 'status',
              className: 'hide-mobile',
              style: {width: 120},
              dataCellTemplate: StatusColumnTemplate
            },
            {
              header: 'Products',
              dataField: 'products',
              className: 'hide-mobile',
              style: {width: 100, textAlign: 'right'},
              dataCellTemplate: ProductColumnTemplate
              //className: 'cell-100 align-right'
            },
            {
              dataField: 'price',
              header: 'Price (Inc VAT)',
              className: 'hide-mobile',
              style: {width: 120, textAlign: 'right'},
              dataCellTemplate: (props) => (<div className="text-price">{props.datacontext}</div>)
              //className: 'cell-120 align-right'
            },
            {
              //className: 'cell-140',
              style: {width: 220},
              className: 'align-right row-actions hide-tablet',
              dataCellTemplate: (props) => (<NavigationColumnTemplate {...props}
                                                                      handleChase={this.props.handleChase }
                                                                      orderGridRowState={this.props.orderGridRowState}/>)
            }
          ]}>
        </Grid>
        {pager}
      </section>
    );
  }
}

GridOrdersDashboard.propTypes = {
  totalItemsCount: PropTypes.number,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func,
  currentPage: PropTypes.number,
  showPager: PropTypes.bool,
  showTitle: PropTypes.bool
};
