import {produce} from "immer";
import { LOAD_JOB_TITLES_SUCCESS, LOAD_CUSTOMER_NAME_SUCCESS, ON_BOARD_USER, ON_BOARD_SUCCESS, ON_BOARD_FAIL } from './constants';

const initialState = {
    showErrorMsg: false,
    errorMsg: '',
    isLoading: false,
    userInfo: {},
    titles: []
};

export default function onBoardReducer(state = initialState, action) {
    return produce(state, (draft) => {
        switch (action.type) {

            case LOAD_JOB_TITLES_SUCCESS:
                draft.titles = action.titles;
                break;
            case LOAD_CUSTOMER_NAME_SUCCESS:
                draft.userInfo = action.userInfo;
                break;
            case ON_BOARD_USER:
                draft.isLoading = true
                draft.showErrorMsg = false
                draft.errorMsg = '';
                break;
            case ON_BOARD_SUCCESS:
                draft.isLoading = false;
                draft.showErrorMsg = false;
                draft.errorMsg = '';
                break;
            case ON_BOARD_FAIL:
                draft.isLoading = false;
                draft.showErrorMsg = true;
                draft.errorMsg = action.error.msg;
                break;
        }
    });
}
