import { initCreatePageState, loadExistingOrderDetails } from './actions';
import CreateOrderInternal from './index-internal';
import { loadCustomerContacts } from '../App/actions';
import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import rootSaga from './sagas-copy-order';
import { useParams } from 'react-router-dom';
//import { RESTART_ON_REMOUNT } from '../../utils/constants';

export default function CopyOrder(props) {

    useInjectSaga({ key: 'copy-order', saga: rootSaga/*, mode: RESTART_ON_REMOUNT*/ });

    const dispatch = useDispatch();
    //const initCreatePageStateAction = useCallback(() => dispatch(initCreatePageState()), [dispatch]);
    //const loadCustomerContactsAction = useCallback(() => dispatch(loadCustomerContacts()), [dispatch]);
    const loadExistingOrderDetailsAction = useCallback((orderId) => dispatch(loadExistingOrderDetails(orderId)), [dispatch]);

    let { orderId } = useParams();
    useEffect(() => {
        //initCreatePageStateAction();
        // loadPackagesInfo is called by load existing order saga
        loadExistingOrderDetailsAction(orderId);
        //loadCustomerContactsAction();
    }, []);

    return <CreateOrderInternal />;
}