import React from 'react';
import BackArrow from '../../svg-icons/backArrow';
import Mobile from '../../svg-icons/mobile';
import ExpDateCellTemplate from '../../components/GridCommon/ExpDateCellTemplate';
import {Link} from 'react-router-dom';
import ChasedMessage from '../../components/ChaseMessage';
import ChaseButton from '../../components/ChaseMessage/chaseButton';
import CancelButton from '../../components/CancelButton';

class PageTitle extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {toggleMobileMenu: false};
  }

  handleMobileMenu = () => {
    const state = this.state.toggleMobileMenu;
    this.setState({toggleMobileMenu: !state});
  }

  handleRaiseQuery = () => {
    if (this.props.raiseQueryAction) {
      this.props.raiseQueryAction();
    }
  }

  handleCancelOrder = () => {
    this.props.cancelOrderAction({
      orderId: this.props.order.orderId,
      dueDate: this.props.order.dueDate
    });
  }

  _renderAddMorePackages(status) {
    if (this.props.isAccountCustomer != true) {
      return null;
    }

    if (status == 'Completed') {
      return null;
    }

    return <li className="show-mobile">
      <Link to={`/add-products/${this.props.order.orderId}`}
            className="button button-small button-white color-green">Add More Products</Link>
    </li>
  }

  shouldShowChaseCancel(status) {
    if (status == 'Completed' || status == 'Cancelled' || status == undefined) {
      return false;
    }

    return true;
  }

  render() {
    /*
     Received,
     InProgress,
     Referred,
     Completed,
     Cancelled,
     NotStarted,
     AutomatedProcess*/
    var status = this.props.order.status;

    var statusClassEx = 'received'; //{'received', 'in-progress' or 'completed'}
    if (status == 'InProgress') {
      statusClassEx = 'in-progress';
    }
    else if (status == 'Completed') {
      statusClassEx = 'completed';
    }

    return (
      <div
        className="page-title"> {/* Add className "fixed" when users scroll down and this reaches the top of the screen */}
        <div className="content-wrapper">
          <div className="details-header with-badge">
            < ExpDateCellTemplate datacontext={this.props.order.dueDate} orderStatus={status}/>
            <Link to='/orders'
                  className="button button-small button-text-icon button-transparent color-blue back-arrow">
              <BackArrow className="icon icon-arrow-left"/>
              Back to orders
            </Link>
            <div className="details-title">
              <h1>Order {this.props.order.orderId}</h1>
              <span className={`status-label ${statusClassEx}`}>{status}</span> {/*show-mobile*/}
            </div>
          </div>
          {/* Button to show order actions on mobile */}
          <div onClick={this.handleMobileMenu}
               className="button button-icon button-icon-small button-grey show-mobile"> {/* When clicked toggle className 'open' to L34 */}
            <Mobile className="icon icon-mobile" role="img"/>
          </div>
          {/* Order Actions */}
          <ul className={this.state.toggleMobileMenu ? "page-title-actions open" : "page-title-actions"}>
            <li>
              <Link to={`/copy-order/${this.props.order.orderId}`} className="button button-small button-blue">Copy Order</Link>
            </li>

            { /* If order can be chased: */}
            {this.shouldShowChaseCancel(status)
              ? <li className="hide-mobile">
                <ChaseButton order={this.props.order} handleChase={this.props.handleChase}
                             uiState={this.props.orderChaseButtonState}/>
              </li> : null
            }

            {/* If order can be cancelled: >*/}
            {/*status == 'Completed' ? null : <li className="hide-mobile"><button onClick={this.handleCancelOrder} className="button button-small button-red">Cancel Order</button></li>*/}
            {this.shouldShowChaseCancel(status)
              ? <li className="hide-mobile">
                <CancelButton item={this.props.order} itemId={this.props.order.orderId}
                              uiState={this.props.orderCancelButtonState} handleCancel={ this.handleCancelOrder }
                              className="button button-small button-red" text="Cancel Order"/>
              </li> : null}

            {this._renderAddMorePackages(status)}

            <li className="show-mobile">
              <button onClick={this.handleRaiseQuery} className="button button-small button-white color-blue">Raise
                Query
              </button>
            </li>
            <li className="show-mobile">
              <button onClick={this.handleMobileMenu} type="button"
                      className="button button-small button-transparent color-black">Close
              </button>
              {/* When clicked remove className 'open' from L34 */}
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default PageTitle;
