import React from 'react';
import ProductChaseButton from '../../../components/ChaseMessage/productChaseButton';
import moment from 'moment';
import CancelButton from '../../../components/CancelButton';

 class ActionColumnTemplate extends React.Component{

    getProduct(){
        var product = this.props.row;

        if(product == undefined){
            return null;
        }

        return product;
    }

    handleReportClick = ()=>{
        var product = this.getProduct();
        if(product == undefined){
            return;
        }

        this.props.handleLoadReports(product.id);
    }

    shouldShowChase(product){
        var date = moment(product.dueDate);
        var now = moment();
        var dueInDays = moment.duration(date - now).days();
        if(product.status != 3 && product.status != 4 && dueInDays < 0 && product.isCancelRequested == false){
            return true;
        }

        return false;
    }

    render(){
        var product = this.getProduct();
        if(product == undefined){
            return null;
        }

        var statusClassEx = 'received'; //{'received', 'in-progress' or 'completed'}
        if(product.status == 2){
            statusClassEx= 'in-progress';
        }
        else if(product.status == 4){
            statusClassEx= 'completed';
        }

        return (
            <div className="cell-wrapper">
                {/* If product is completed, show L54 */}
                {product.status == 4 ? <button type="button" onClick={this.handleReportClick} className="button button-small button-blue">Report</button> : null}

                { /* If product can be chased, show L56 */}
                {this.shouldShowChase(product) ? <ProductChaseButton product = {product} uiState = {this.props.productChaseButtonState} handleChase ={ this.props.handleProductChase } /> : null}

                {/* If product can be cancelled, show L58 */}
                {/*product.status == 1 ? <button type="button" className="button button-small button-white color-red hide-mobile">Cancel</button> : null*/}
                {product.status == 1 ? <CancelButton item = {product} itemId = {product.id} uiState = {this.props.productCancelButtonState} handleCancel ={ this.props.handleProductCancel } /> : null}

            </div>
        );
    }
};

export default ActionColumnTemplate;
