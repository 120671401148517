import React from 'react';
import BackArrow from '../../svg-icons/backArrow';

export default class ExpandableSection extends React.Component {
    static defaultProps ={
       isExpandable: false,
        isMap:false
    };

    constructor(props){
        super(props);
        this.state={open: true}
    }

    toggleExpand = (e)=> {
        if(this.props.isExpandable == true){
            this.setState({open: !this.state.open});
        }
    }

    render(){
        var classEx = '';
        if(this.props.isExpandable == true){
            classEx = 'with-arrow';
        }

        var className = this.props.className != ('' || undefined) ? this.props.className : 'content-block';
        var titleContent = this.props.titleContent || <h2>{this.props.title}</h2>;

        var rotate={
            transform: 'rotate(90deg)'
        };
        //{/*<div className={`${className} ${this.state.open ? '': 'collapsed'}`} style={this.props.rootStyle} > */}
        return (

            <div className={className} style={this.props.rootStyle} >
                <div className={`content-block-title ${classEx}`} onClick = {this.toggleExpand}>
                    {titleContent}
                    {this.props.isExpandable ? <BackArrow className="icon icon-arrow-down" style={this.state.open ? null : rotate} /> : null}
                </div>
                {this.props.isMap?<div className={this.state.open?'':'display-none'}>{this.props.children}</div>:
                this.state.open ? this.props.children : null}
            </div>
        );
    }
}
