import { createSelector } from 'reselect';
const selectHome = () => (state) => state.orders || {};
const selectData = () => createSelector(
  selectHome(),
  (ordersState) => ordersState.data || {}
);

const selectUI = () => createSelector(
  selectHome(),
  (ordersState) => ordersState.ui
);

const selectOrders = () => createSelector(
  selectData(),
  (data) => data.orders
);

const selectTotalOrdersCount = () => createSelector(
  selectData(),
  (data) => data.totalOrdersCount
);

const selectFilterInfo = () => createSelector(
  selectData(),
  (data) => data.filterInfo
);

const selectTotalOpenOrdersCount = () => createSelector(
  selectData(),
  (data) => data.totalOpenOrdersCount
);

const selectOrderStatuses = () => createSelector(
  selectData(),
  (data) => data.orderStatuses
);

const selectActiveTab = () => createSelector(
  selectHome(),
  (ui) => ui.ui.activeTab
);
const selectIsLoading = () => createSelector(
  selectHome(),
  (ui) => ui.ui.isLoading
);

const selectOrderGridRowState = () => createSelector(
  selectUI(),
  (ui) => ui.orderGridRowState
);
const selectCurrentPage = () => createSelector(
  selectUI(),
  (ui) => ui.currentPage
);
export {
  selectOrders,
  selectTotalOrdersCount,
  selectOrderStatuses,
  selectActiveTab,
  selectTotalOpenOrdersCount,
  selectOrderGridRowState,
  selectIsLoading,
  selectFilterInfo,
  selectCurrentPage
}
