import React from 'react';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    toaster: {
        background: 'red',
        color: 'black',
        width: '100%'
    },
    toasterBody: {
        background: 'red',
        textAlign: 'center',
        maxWidth: '90%',
        margin: '0 auto'
    }
};
/*
* severity coul be error, warning, info, success
*
*/
export default function CustomizedSnackbar(props) {
    const { open, message, handleClose, severity } = props;
    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={open}
            //message={message}
            autoHideDuration={4000}
            onClose={handleClose}
        //style={styles.toaster}

        >
            <Alert onClose={handleClose} severity={severity || 'error'} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>)
}

