
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Attention = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
             <path strokeLinecap="round" d="M22.498 12.31c.105 6.075-4.923 11.086-10.998 11.192C5.426 23.606.607 18.768.502 12.692.396 6.62 5.426 1.606 11.5 1.502c6.074-.106 10.893 4.734 10.998 10.808zM11.5 14V7"/>
             <path d="M12 17.5c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5z"/>
      </g>
  </SvgIconEx>
);
Attention.displayName = 'Attention';
Attention.muiName = 'SvgIcon';

export default Attention;