import { createSelector } from 'reselect';

const selectHome = () => (state) => state.queryDetails || { queryDetailsData: {}, queryDetailsUI: {} };

//
// queries.queryGrid
const selectData = () => createSelector(
  selectHome(),
  (homeState) => homeState.queryDetailsData.data
);

//
// queries.queryGrid.dataSource.items
const selectUI = () => createSelector(
  selectHome(),
  (homeState) => homeState.queryDetailsUI.queryGridNavigationColumnState
);
export {
  selectData,
  selectUI
}