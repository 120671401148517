import React from 'react';
import Form from '../../components/Form';
import { Link } from 'react-router-dom';
import AllertWarning from '../../svg-icons/alertWarning';
import AlertSuccess from '../../svg-icons/alertSuccess';
import Loader from '../../components/Loading';
import TextField from '../../components/TextField';

export default class ForgottenPassword extends React.PureComponent{
    constructor(props){
        super(props);
        this.state={
            userName: '',
            email:''
        }
        this.invalidUser='';
        this.invalidEmail='';
    }
    handleChangeView =()=>{
        this.props.changeViewAction('login');
    }

    handleEmailChanged=(e, value)=>{
        this.setState({email: value});
    }

    handleUserNameChanged=(e, value)=>{
        this.setState({userName: value});
    }
    
    handleRemindMe=()=>{
        if(this.state.userName == '')
            return;

        if(this.state.email == '')
            return;  

        this.invalidEmail = this.state.email;
        this.invalidUser = this.state.userName;
        this.props.remindMeAction(this.state.userName, this.state.email);
    }

    _renderInvalidCombination(){
        return (
            <div className="alert alert-block alert-warning">
                <AllertWarning className="icon icon-alert-warning" role="img"/>
                <div className="alert-content">
                    <p>Sorry, but we can't find the following combination:</p>
                    <ul>
                        <li>Username: <strong>{this.invalidUser}</strong>
                        </li>
                        <li>Email address: <strong>{this.invalidEmail}</strong>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    _renderSuccessMsg(){
        return (
            <div>
                <div className="alert alert-block alert-success">
                    <AlertSuccess className="icon icon-alert-success" role="img"/>
                    <div className="alert-content">
                        <p>Password reminder sent to: <strong>{this.state.email}</strong>.</p>
                    </div>
                </div>
                <div className="actions">
                    <Link style= {{cursor: 'pointer'}} onClick={this.handleChangeView} className="text-button text-link">Sign In</Link>
                </div>
            </div>
        );
    }

    _renderInitialState(){
        return (
            <div>
                <TextField placeholder="Enter your username" label='Username' inputClassName='form-control-grey'
                        value={this.state.userName} onChange={this.handleUserNameChanged} isRequired={true}/>
                  
                <TextField placeholder="Enter your registered email address" label='Email Address' inputClassName='form-control-grey'
                        value={this.state.email} onChange={this.handleEmailChanged} isRequired={true} type="email"/>
                 
                {this.props.isLoading ? <Loader />
                :
                <div className="actions">
                    <button onClick={this.handleRemindMe} type="submit" className="button button-large button-green">Remind Me</button>
                    <Link style= {{cursor: 'pointer'}} onClick={this.handleChangeView} className="text-button text-link">Sign In</Link>
                </div>
                }
            </div>
        );
    }

    render(){
        return(<Form className="user-form login-form">
                    <div className="user-form-title">
                        <h1>Password Reminder</h1>
                        <p className="h1-description">Enter your username and a registered email address to receive a reminder.</p>
                    </div>
                    {this.props.showForgottenPswdError ? this._renderInvalidCombination() : null}
                    {
                        this.props.showForgottenPswdInitalForm ?
                            this._renderInitialState()
                            :
                            this._renderSuccessMsg()
                    }
                   
                </Form>
            );
    }
}