/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const CHANGE_ACTIVE_PRODUCT_CATEGORY_TAB = 'atlas/CreateOrder/CHANGE_ACTIVE_PRODUCT_CATEGORY_TAB';
export const CHANGE_ACTIVE_PRODUCT_CLASSIFICATION_TAB = 'atlas/CreateOrder/CHANGE_ACTIVE_PRODUCT_CLASSIFICATION_TAB';
export const CHANGE_ACTIVE_LA_LETTER_TAB = 'atlas/CreateOrder/CHANGE_ACTIVE_LA_LETTER_TAB';
export const LOAD_PACKAGE_SALE_INFO = 'atlas/CreateOrder/LOAD_PACKAGE_SALE_INFO';
export const LOAD_PACKAGE_SALE_INFO_SUCCESS = 'atlas/CreateOrder/LOAD_PACKAGE_SALE_INFO_SUCCESS';
export const LOAD_PACKAGE_SALE_INFO_ERROR = 'atlas/CreateOrder/LOAD_PACKAGE_SALE_INFO_ERROR';
export const SET_FAVORITE_PACKAGES_AFTER_ERROR = 'atlas/CreateOrder/SET_FAVORITE_PACKAGES_AFTER_ERROR';

export const SHOW_PACKAGE_ADDITIONAL_INFO_ADDRESS_WARNING = 'atlas/CreateOrder/SHOW_PACKAGE_ADDITIONAL_INFO_ADDRESS_WARNING';
export const SHOW_PACKAGE_ADDITIONAL_INFO = 'atlas/CreateOrder/SHOW_PACKAGE_ADDITIONAL_INFO';
export const HIDE_PACKAGE_ADDITIONAL_INFO = 'atlas/CreateOrder/HIDE_PACKAGE_ADDITIONAL_INFO';
export const PACKAGE_ADDITIONAL_INFO_SUCCESS = 'atlas/CreateOrder/PACKAGE_ADDITIONAL_INFO_SUCCESS';
export const PACKAGE_ADDITIONAL_INFO_ERROR = 'atlas/CreateOrder/PACKAGE_ADDITIONAL_INFO_ERROR';
export const SELECT_PACKAGE = 'atlas/CreateOrder/SELECT_PACKAGE';
export const SELECT_PACKAGE_CON29_OPTIONAL_ENQUIRY = 'atlas/CreateOrder/SELECT_PACKAGE_CON29_OPTIONAL_ENQUIRY';
export const SET_PACKAGE_PRODUCT_ADDITIONAL_TEXT = 'atlas/CreateOrder/SET_PACKAGE_PRODUCT_ADDITIONAL_TEXT';
export const SET_PACKAGE_PRODUCT_AGREE_CHECKBOX = 'atlas/CreateOrder/SET_PACKAGE_PRODUCT_AGREE_CHECKBOX';

export const SELECT_DISCOUNT_PACKAGE = 'atlas/CreateOrder/SELECT_DISCOUNT_PACKAGE';
export const TOGGLE_FAVORITE_PACKAGE = 'atlas/CreateOrder/TOGGLE_FAVORITE_PACKAGE';
export const SELECT_ALL_FAVORITE_PACKAGE = 'atlas/CreateOrder/SELECT_ALL_FAVORITE_PACKAGE';

export const ADD_PACKAGES_INIT_STATE = 'atlas/CreateOrder/ADD_PACKAGES_INIT_STATE';
export const ADD_PACKAGES_REQUEST = 'atlas/CreateOrder/ADD_PACKAGES_REQUEST';
export const ADD_PACKAGES_SUCCESS = 'atlas/CreateOrder/ADD_PACKAGES_SUCCESS';
export const ADD_PACKAGES_ERROR = 'atlas/CreateOrder/ADD_PACKAGES_ERROR';

export const DELETE_MAP = 'atlas/CreateOrder/DELETE_MAP';
export const CLEAR_GEOMETRIES = 'atlas/CreateOrder/CLEAR_GEOMETRIES';

export const NEW_ORDER_INIT_STATE = 'atlas/CreateOrder/NEW_ORDER_INIT_STATE';
export const NEW_ORDER_LOAD_OERDER_PACKAGES = 'atlas/CreateOrder/NEW_ORDER_LOAD_OERDER_PACKAGES';
export const SHOW_ADDRESS_SAVE_TOOLTIP = 'atlas/CreateOrder/SHOW_ADDRESS_SAVE_TOOLTIP';
export const NEW_ORDER_ADDRESS_SELECTED = 'atlas/CreateOrder/NEW_ORDER_ADDRESS_SELECTED';
export const NEW_ORDER_ADDRESS_VALIDATION_CHANGED = 'atlas/CreateOrder/NEW_ORDER_ADDRESS_VALIDATION_CHANGED';
export const NEW_ORDER_SELECT_CONTACT_PERSON = 'atlas/CreateOrder/NEW_ORDER_SELECT_CONTACT_PERSON';
export const NEW_ORDER_SELECT_CUSTOMER_REFERENCE = 'atlas/CreateOrder/NEW_ORDER_SELECT_CUSTOMER_REFERENCE';
export const NEW_ORDER_SELECT_DEVELOPER_NAME = 'atlas/CreateOrder/NEW_ORDER_SELECT_DEVELOPER_NAME';
export const NEW_ORDER_SELECT_SITE_NAME = 'atlas/CreateOrder/NEW_ORDER_SELECT_SITE_NAME';
export const NEW_ORDER_SELECT_ADDITIONAL_INFO = 'atlas/CreateOrder/NEW_ORDER_SELECT_ADDITIONAL_INFO';
export const NEW_ORDER_SUBMIT = 'atlas/CreateOrder/NEW_ORDER_SUBMIT';
export const NEW_ORDER_SUBMIT_SUCCESS = 'atlas/CreateOrder/NEW_ORDER_SUBMIT_SUCCESS';
export const NEW_ORDER_SUBMIT_FAILED = 'atlas/CreateOrder/NEW_ORDER_SUBMIT_FAILED';
export const NEW_ORDER_SAVE_QUOTE = 'atlas/CreateOrder/NEW_ORDER_SAVE_QUOTE';
export const NEW_ORDER_SAVE_QUOTE_SUCCESS = 'atlas/CreateOrder/NEW_ORDER_SAVE_QUOTE_SUCCESS';
export const NEW_ORDER_SAVE_QUOTE_ERROR = 'atlas/CreateOrder/NEW_ORDER_SAVE_QUOTE_ERROR';
export const NEW_ORDER_WIZARD_STEP = 'atlas/CreateOrder/NEW_ORDER_WIZARD_STEP';
export const NEW_ORDER_DISCOUNT_PACKAGE_OPEN = 'atlas/CreateOrder/NEW_ORDER_DISCOUNT_PACKAGE_OPEN';
export const NEW_ORDER_DISCOUNT_PACKAGE_CLOSE = 'atlas/CreateOrder/NEW_ORDER_DISCOUNT_PACKAGE_CLOSE';
export const NEW_ORDER_DISCOUNT_PACKAGE_LOADED = 'atlas/CreateOrder/NEW_ORDER_DISCOUNT_PACKAGE_LOADED';
export const NEW_ORDER_DISCOUNT_PACKAGES_ADD_SELECTED = 'atlas/CreateOrder/NEW_ORDER_DISCOUNT_PACKAGES_ADD_SELECTED';
export const NEW_ORDER_DISCOUNT_PACKAGES_DELETE = 'atlas/CreateOrder/NEW_ORDER_DISCOUNT_PACKAGES_DELETE';
export const NEW_ORDER_SHOW_BASKET_ON_MOBILE = 'atlas/CreateOrder/NEW_ORDER_SHOW_BASKET_ON_MOBILE';
export const NEW_ORDER_HIDE_BASKET_ON_MOBILE = 'atlas/CreateOrder/NEW_ORDER_HIDE_BASKET_ON_MOBILE';
export const NEW_ORDER_TOGGLE_MAP_SAVE_BUTTON = 'atlas/CreateOrder/NEW_ORDER_TOGGLE_MAP_SAVE_BUTTON';
export const NEW_ORDER_SITE_GEOMETRY_CHANGED = 'atlas/CreateOrder/NEW_ORDER_SITE_GEOMETRY_CHANGED';
export const NEW_ORDER_SET_ALERTS_LOADING = 'atlas/CreateOrder/NEW_ORDER_SET_ALERTS_LOADING';
export const SET_RECOMMENDED_LOCAL_AUTHORITY_PACKAGES = 'atlas/Order/SET_RECOMMENDED_LOCAL_AUTHORITY_PACKAGES';
export const SET_RECOMMENDED_ALERTS_PACKAGES = 'atlas/Order/SET_RECOMMENDED_ALERTS_PACKAGES';


export const SET_ALERTS_PACKAGES_FOR_ORDER = 'atlas/Order/SET_ALERTS_PACKAGES_FOR_ORDER';
export const NEW_ORDER_LOAD_IDENTITY = 'atlas/CreateOrder/NEW_ORDER_LOAD_IDENTITY';
export const QUOTE_DETAILS_LOAD_QUOTE = 'atlas/QuoteDetails/QUOTE_DETAILS_LOAD_QUOTE';
export const QUOTE_DETAILS_LOAD_QUOTE_SUCCESS = 'atlas/QuoteDetails/QUOTE_DETAILS_LOAD_QUOTE_SUCCESS';
export const LOAD_EXISTING_ORDER_FOR_COPY = 'atlas/OrderDetails/LOAD_EXISTING_ORDER_FOR_COPY';
export const NEW_ORDER_GET_ALERTS = 'atlas/CreateOrder/NEW_ORDER_GET_ALERTS';
export const RESET_LOADING_ALERTS = 'atlas/CreateOrder/RESET_LOADING_ALERTS';
export const NEW_GEOMETRIES = 'atlas/CreateOrder/NEW_GEOMETRIES';
export const SET_ADDRESS_FOR_MORE_PRODUCTS = 'atlas/CreateOrder/SET_ADDRESS_FOR_MORE_PRODUCTS';
export const MAP_USER_ACTION = 'atlas/CreateOrder/MAP_USER_ACTION';
export const ADD_VALIDATION_MESSAGE = 'atlas/CreateOrder/ADD_VALIDATION_MESSAGE';
export const REMOVE_VALIDATION_MESSAGE = 'atlas/CreateOrder/REMOVE_VALIDATION_MESSAGE';
export const IS_BUSY = 'atlas/CreateOrder/IS_BUSY';
export const SWITCH_TO_SUGGESTED_PACKAGE = 'atlas/CreateOrder/SWITCH_TO_SUGGESTED_PACKAGE';
export const CLOSE_SWITCH_PRODUCT_DIALOG = 'atlas/CreateOrder/CLOSE_SWITCH_PRODUCT_DIALOG';