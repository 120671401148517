import React from 'react';
import { Grid } from '../../components/Grid';
import ProductColumnTemplate from '../../components/GridProducts/ProductColumnTemplate';
import DocumentColumnTemplate from '../../components/GridProducts/DocumentColumnTemplate';
import { formatPrice } from '../../utils/format';
import { selectAlerts, selectPackagesInOrder, selectSelectedAlerts, selectIsSaving, selectIsAnyWaterPackageSelected } from './selectors';
import { selectIsAccountCustomer } from '../App/selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectAlert, addSelectedPackagesToOrder } from './actions';
import keys from 'lodash/keys';
import Loading from '../../components/Loading';
import isEmpty from 'lodash/isEmpty'
class Alerts extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    processRegularPackages() {
        var packages = keys(this.props.selectedAlerts).map((item) => {
            return {
                id: item,
            };
        });

        return packages;
    }

    handleAddSelectedPackages = () => {
        let payload = { orderId: this.props.orderId, packages: this.processRegularPackages() };
        if (this.props.status !== "Completed") {
            this.props.addSelectedPackagesAction(payload);
        } else {            
            console.log('rerouting here');
            console.log(this.props.selectedAlerts);
            // TODO
            //browserHistory.push(`/copy-order/${this.props.orderId}`, { state: payload });
        }
    }

    renderGrid() {
        return (
            <Grid
                tableClassName='content-grid products-grid'
                showEmptyContent={false}
                datasource={this.props.alertDatasource}
                columnsData={[
                    /*{
                        header: 'star',
                        className: 'favorites-cell',
                        style: {width: 40},
                        //dataField: 'queryId',
                        dataCellTemplate: (props)=>(<StarColumnTemplate {...props} favoritePackages={this.props.favoritePackages} toggleFavorite={this.props.toggleFavoriteAction} />)
                    },*/
                    {
                        header: 'name',
                        style: { width: '*', textAlign: 'left' },
                        dataCellTemplate: (props) => (<ProductColumnTemplate {...props} gridName={this.props.name}
                            activeCategoryTab={this.props.activeCategoryTab}
                            selectPackageAction={this.props.selectAlertAction}
                            selectedPackages={this.props.selectedAlerts}
                            discountPackages={{}} 
                            selectedPackagesInOrder={this.props.selectedPackagesInOrder}
                            isAnyWaterPackageSelected={this.props.isAnyWaterPackageSelected}/>)
                    },
                    {
                        header: 'docs',
                        dataField: 'packageDocuments',
                        style: { width: 320, textAlign: 'left' },
                        className: 'show-desktop',
                        dataCellTemplate: (props) => (<DocumentColumnTemplate {...props} />)
                    },
                    {
                        header: 'days',
                        dataField: 'packageSLA',
                        style: { width: 50, textAlign: 'right' },
                        className: 'hide-mobile',
                        dataCellTemplate: (props) => (<div className="text-product"> {props.datacontext == undefined ? 'NaN' : props.datacontext}</div>)
                    },
                    {
                        header: 'price',
                        dataField: 'priceWithVAT',
                        style: { width: 100, textAlign: 'right' },
                        dataCellTemplate: (props) => (<div className="text-price">{formatPrice(props.datacontext)}</div>)
                    }
                ]}>
            </Grid>);
    }

    render() {

        return (
            <div className="product-alert">
                <h3>Product Alert</h3>
                <em>Some of the recommended products for this property were not selected.</em>
                {this.renderGrid()}
                {this.props.isSaving
                    ? <Loading />
                    :
                    this.props.isAccountCustomer ? <button disabled={isEmpty(this.props.selectedAlerts)} onClick={this.handleAddSelectedPackages} className="button button-small button-green">
                        {this.props.status === 'Completed'
                            ? 'Add selected to a new order' :
                            'Add selected to this order'
                        }
                    </button> : null}
            </div>
        );
    }
}
const mapStateToProps = createStructuredSelector({
    selectedPackagesInOrder: selectPackagesInOrder(),
    isAnyWaterPackageSelected: selectIsAnyWaterPackageSelected(),
    alertDatasource: selectAlerts(),
    selectedAlerts: selectSelectedAlerts(),
    isSaving: selectIsSaving(),
    isAccountCustomer: selectIsAccountCustomer()
});

const mapDispatchToState = {
    selectAlertAction: selectAlert,
    addSelectedPackagesAction: addSelectedPackagesToOrder
}

export default connect(mapStateToProps, mapDispatchToState)(Alerts);
