export const LOAD_PAGED_ORDERS = 'atlas/Order/LOAD_PAGED_ORDERS';
export const LOAD_ORDERS_SUCCESS = 'atlas/Order/LOAD_ORDERS_SUCCESS';
export const LOAD_ORDERS_ERROR = 'atlas/Order/LOAD_ORDERS_ERROR';
export const LOAD_ORDER_STATUSES = 'atlas/Order/LOAD_ORDER_STATUSES';
export const LOAD_ORDER_STATUSES_SUCCESS = 'atlas/Order/LOAD_ORDER_STATUSES_SUCCESS';
export const LOAD_TOTAL_OPEN_ORDERS_COUNT = 'atlas/Order/LOAD_TOTAL_OPEN_ORDERS_COUNT';
export const LOAD_TOTAL_OPEN_ORDERS_COUNT_SUCCESS = 'atlas/Order/LOAD_TOTAL_OPEN_ORDERS_COUNT_SUCCESS';

export const ORDER_SWITCH_OPEN_CLOSE = 'atlas/Order/ORDER_SWITCH_OPEN_CLOSE';
export const ORDER_STORE_FILTER_INFO = 'atlas/Order/ORDER_STORE_FILTER_INFO';
