import { takeLatest, call, put, select, fork, cancel } from 'redux-saga/effects';
import { LOAD_DASHBOARD, SEARCH_DASHBOARD } from './constants';
import { dashboardLoaded, dashboardLoadingError } from './actions';
import { showAppError } from 'containers/App/actions';
import config from 'utils/config';
import request from 'utils/request';
import { chaseOrderSaga } from '../OrderDetails/sagas';
import { deleteQuoteSaga } from '../Quotes/sagas'
//import { replyToQueryRequestSaga } from '../Queries/sagas';
//import { uploadRequestWatcherSaga } from '../../components/UploadFile/sagas';
import { setQueryDataSource } from '../Queries/actions';

//
// API call to load dashboard data
//
export function* loadDashboardRequest(action) {
    const requestURL = `${config.webApiUrl}/dashboard`;
    try {
        // Call our request helper (see 'utils/request')
        const repos = yield call(request.getAuth, requestURL);
        yield put(dashboardLoaded(repos));
        yield put(setQueryDataSource('dashboardQueryGrid', { queries: repos.queries, queriesCount: repos.queriesCount }));
    }
    catch (err) {
        console.error(err);
        yield put(dashboardLoadingError(err));
        yield put(showAppError(err));
    }
}

export function* searchDashboardRequest(action) {
    const customerContactId = action.values.createdBy || null;
    const dateOrderCreated = action.values.dateCreated || null;
    const filterText = action.values.filterText || null;
    const requestURL = `${config.webApiUrl}/dashboard/search?customerContactId=${customerContactId}&dateCreated=${dateOrderCreated}&filterText=${filterText}`;
    try {
        // Call our request helper (see 'utils/request')
        const repos = yield call(request.getAuth, requestURL);
        yield put(dashboardLoaded(repos));
        yield put(setQueryDataSource('dashboardQueryGrid', { queries: repos.queries, queriesCount: repos.queriesCount }));
    }
    catch (err) {
        yield put(dashboardLoadingError(err));
        yield put(showAppError(err));
    }
}
export function* loadDashboardWatcherSaga() {
    yield takeLatest(LOAD_DASHBOARD, loadDashboardRequest);
}
export function* searchDashboardWatcherSaga() {
    yield takeLatest(SEARCH_DASHBOARD, searchDashboardRequest);
}

// Bootstrap sagas
export default function* rootSaga() {
    // Non-blocking fork effects
    // https://redux-saga.js.org/docs/advanced/RootSaga
    // rootSaga will terminate on the first error in any individual child effect or saga and crash your whole app! 
    // this is not so bad but we have to appropriate error handling
    yield fork(loadDashboardWatcherSaga);
    yield fork(searchDashboardWatcherSaga);
    //yield fork(uploadRequestWatcherSaga);
    //yield fork(replyToQueryRequestSaga);
    yield fork(deleteQuoteSaga);
    yield fork(chaseOrderSaga);
}

