/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
//import { FormattedMessage } from 'react-intl';
import ReactGA from 'react-ga4';
import messages from './messages';


export default class NotFound extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  componentDidMount(){
      ReactGA.send({ hitType: "pageview", page: "/*" });
  }
  render() {
    return (
      <h1 style={{textAlign:'center'}}>
       {/* <FormattedMessage {...messages.header} />*/}
       Page not found
      </h1>
    );
  }
}
