import {produce} from "immer";
import { QUERY_STORE_FILTER_INFO, QUERY_SWITCH_OPEN_CLOSE, SET_QUERY_GRID_DATA_SOURCE, LOAD_QUERY_PAGED_LIST_REQUEST } from './constants'
import { combineReducers } from 'redux';
import { create } from './queryGridReducer';

const initialState = {
    activeTab: 'open',
    filterInfo: {},
    isLoading: true,
    currentPage: 1
};

export function queriesReducer(state = initialState, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case QUERY_SWITCH_OPEN_CLOSE:
                draft.activeTab = action.activeTab;;
                break;
            case QUERY_STORE_FILTER_INFO:
                draft.filterInfo = action.filterInfo;;
                break;
            case SET_QUERY_GRID_DATA_SOURCE:
                draft.isLoading = false;
                break;
            case LOAD_QUERY_PAGED_LIST_REQUEST:
                draft.isLoading = true;
                draft.currentPage = action.values.currentPage;
                break;
        }
    });
}

export default combineReducers({
    queryGrid: create('queriesPageGrid'),
    queryGridClosed: create('queriesPageGridClosed'),
    pageUi: queriesReducer
})