
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Specialist = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path d="M16 12a4 4 0 1 1-4-4 4 4 0 0 1 4 4z"/>
          <circle cx="8.1" cy="2.87" r="2.34"/>
          <circle cx="15.58" cy="2.87" r="2.34"/>
          <circle cx="21.16" cy="8.54" r="2.34"/>
          <circle cx="21.16" cy="15.63" r="2.34"/>
          <circle cx="15.58" cy="21.13" r="2.34"/>
          <circle cx="8.1" cy="21.13" r="2.34"/>
          <circle cx="2.84" cy="15.63" r="2.34"/>
          <circle cx="2.84" cy="8.54" r="2.34"/>
          <path strokeDasharray="2 .5" d="M8.97 5.16l1.45 3.45M13.31 15.56L14.75 19M14.89 5.03l-1.44 3.44M10.35 15.49L8.9 18.93M4.94 14.41l3.45-1.42M15.54 10.69L19 9.27M8.46 10.69L5.01 9.27M18.93 14.82l-3.46-1.41"/>
      </g>
  </SvgIconEx>
);
Specialist.displayName = 'Specialist';
Specialist.muiName = 'SvgIcon';

export default Specialist;