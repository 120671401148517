import React from 'react';
import { Grid } from '../../../components/Grid';
import { connect } from 'react-redux';

import * as queryActions from '../../Queries/actions';
import { uploadRequest } from '../../../components/UploadFile/actions';
import { createStructuredSelector } from 'reselect';
import { selectQueryGridDataSource, selectQueryGridNavigationColumnState, selectIsQueryLoading } from '../selectors';
import { OpenQueryGridName } from '../constants';

import {
    ExpDateCellTemplate,
    NavigationColumnTemplate,
    QueryDetailsConversationTemplate
} from '../../../components/GridQueries';

import { submitQueryRequest, cancelQuery } from '../actions';

export class GridQueriesInOrderPage extends React.Component {
    constructor(props) {
        super(props);
        this.rowTemplateResolver = this.rowTemplateResolver.bind(this);
    }

    rowTemplateResolver(rowData) {
        var cellState = this.props.queryGridNavigationColumnState[rowData.queryId];
        if (cellState && cellState.state == 'open') {
            return 'row-open';
        }

        return '';
    }

    render() {
        return (
            <div className="content-block-grid">
                <Grid
                    gridName={OpenQueryGridName}
                    isLoading={this.props.isLoading}
                    datasource={this.props.queries}
                    columnsData={[
                        {
                            header: 'date',
                            style: { width: 60 },
                            dataField: 'dueDate',
                            dataCellTemplate: (props) => (<ExpDateCellTemplate {...props} objType="query" />)
                        },
                        {
                            header: 'Query',
                            dataField: 'queryName',
                            style: { width: '*' },
                            dataCellTemplate: (props) => (<span>{props.datacontext}</span>)
                        },
                        {
                            header: 'Status',
                            //dataField: 'status',
                            style: { width: 90, textAlign: 'center' },
                            dataCellTemplate: (props) => (<NavigationColumnTemplate {...props}
                                gridName={OpenQueryGridName}
                                queryGridNavigationColumnState={this.props.queryGridNavigationColumnState}
                                showQueryReplyRow={this.props.showQueryReplyRow}
                                hideQueryReplyRow={this.props.hideQueryReplyRow} />)
                        }
                    ]}
                    // if data contains key as prop value then the template will be applied
                    rowTemplates={[
                        {
                            key: 'queryDetails', rowClassName: 'query-row', template: (props) => (<QueryDetailsConversationTemplate {...props}
                                gridName={OpenQueryGridName}
                                queryGridNavigationColumnState={this.props.queryGridNavigationColumnState}
                                addAttachmentToQuery={this.props.addAttachmentToQuery}
                                customerReplyToQuery={this.props.customerReplyToQuery}
                                changeReplyText={this.props.changeReplyText}
                                deleteQueryFile={this.props.deleteQueryFile}
                                submitQueryRequest={this.props.submitQueryRequest}
                                cancelQueryAction={this.props.cancelQueryAction}
                                showEarlierMsgs={this.props.showEarlierMsgs} />)
                        }

                    ]}
                    // use this prop if you want to set class to <tr>
                    rowClassNameResolver={this.rowTemplateResolver}>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    isLoading: selectIsQueryLoading(),
    queries: selectQueryGridDataSource(),
    queryGridNavigationColumnState: selectQueryGridNavigationColumnState()
})

const mapDispatchToProps = ({
    showQueryReplyRow: queryActions.loadQuery,
    hideQueryReplyRow: queryActions.hideQueryReplyRow,
    addAttachmentToQuery: uploadRequest,
    customerReplyToQuery: queryActions.customerReplyToQuery,
    changeReplyText: queryActions.changeReplyText,
    deleteQueryFile: queryActions.deleteQueryFile,
    showEarlierMsgs: queryActions.showEarlierMsgs,
    submitQueryRequest: submitQueryRequest,
    cancelQueryAction: cancelQuery
})

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(GridQueriesInOrderPage);
