import React from 'react';
import Cell from './Cell';
import isFunction from 'lodash/isFunction';

export default class RowWithTemplate extends React.PureComponent {
    render(){
        var templateMetadata = this.props.rowTemplateObj;
        var content = templateMetadata.template;
        if(isFunction(templateMetadata.template)){
            content = templateMetadata.template({dataContext: this.props.row});//{ datacontext: this.props.datacontext, row: this.props.row });
        }

        return (
            <tr className={templateMetadata.rowClassName}>
                <td colSpan={this.props.columns.length}>
                    {content}
                </td>
            </tr>
        );
    }
}