import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Rows from '../Grid/Rows';
import DownloadLink from '../../components/DownloadLink';
import config from '../../utils/config';
import ChaseButton from '../ChaseMessage/chaseButton';

let NavigationColumnTemplate = (props) => {

    // orderIdChasing, orderIdChasingMessage
    var rowState = {};
    if(props.orderGridRowState != undefined){
        rowState = props.orderGridRowState[props.row.orderId] || {};
    }

    /*var date = moment(props.row.dueDate);
    var now = moment();
    var dueInDays = moment.duration(date - now).days() + 1;

    var chaseContent=null;
    if(rowState.chasing){
        chaseContent=<div className='loader'></div>;
    }
    else if(rowState.showChasedLabel == true){
        chaseContent = <div className="status-label color-teal">Chased</div>;
    }
    else if(rowState.chased || props.row.isChased){
        chaseContent=null;
    }
    else if(props.row.status == "Completed" || props.row.status == "Cancelled"){
      chaseContent=null;
    }
    else if(dueInDays <= 0 && props.row.status != "Closed"){
        chaseContent = <button onClick={() => { props.handleChase(props.row) }} type="button" className="button button-small button-teal">Chase 1</button>
    }*/

    var receiptContent=null;
    //var receiptUrl = `${config.webApiUrl}/file/download?filePath=${props.row.receiptFileName}`;
    var receiptUrl = `${config.webApiUrl}/file/download?filePath=`;
    if(props.row.status == "Completed"){
        receiptContent= <DownloadLink url = {receiptUrl} file={props.row.receiptFileName} className="button button-small button-blue">
                            Receipt
                        </DownloadLink>
    }

    return (
        <div className="cell-wrapper">
            <ChaseButton order={props.row} uiState={rowState} handleChase={props.handleChase}/>
            {/*chaseContent*/}
            {receiptContent}
            <Link to={"/order/" + props.row['orderId']} className="button button-small button-grey">Details</Link>
        </div>
    );
}

export default NavigationColumnTemplate;
