import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import globalReducer from '../app/containers/App/reducer';
import loginReducer from '../app/containers/Login/reducer';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware()
const middleware = [sagaMiddleware]


var store = configureStore({
  reducer: {
    global: globalReducer,
    //login: loginReducer
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middleware),
});

// Then run the saga
store.runSaga = sagaMiddleware.run;//(appSaga);
store.injectedReducers = {}; // Reducer registry
store.injectedSagas = {}; // Saga registry

export default store;