/* Colors				==================================*/
	
	/* Warm Colors ***/

		/* Grey Tints ***/
		export const grey100= 			'#53565A';
		export const grey80= 			'#757876';
		export const grey60= 			'#989A9C';
		export const grey40= 			'#BABBBD';
		export const grey20= 			'#DDDDDE';

		/* Purple Tints ***/
		export const purple100= 		'#87189D';
		export const purple80= 			'#9F46B1';
		export const purple60= 			'#B774C4';
		export const purple40= 			'#CFA3D8';
		export const purple20= 			'#E7D1EB';

		/* Pink Tints ***/
		export const pink100= 			'#E6007E';
		export const pink80= 			'#EB3398';
		export const pink60= 			'#F066B2';
		export const pink40= 			'#F599CB';
		export const pink20= 			'#FACCE5';

		/* Orange Tints ***/
		export const orange100= 		'#FFB614';
		export const orange80= 			'#FFC543';
		export const orange60= 			'#FFD372';
		export const orange40= 			'#FFE2A1';
		export const orange20= 			'#FFF0D0';

	/* Cool Colors ***/

		/* Blue Tints ***/
		export const blue100=			'#009FE3';
		export const blue80= 			'#33B2E9';
		export const blue60= 			'#66C5EE';
		export const blue40= 			'#99D9F4';
		export const blue20= 			'#CCECF9';

		/* Teal Tints ***/
		export const teal100= 			'#00B398';
		export const teal80= 			'#33C2AD';
		export const teal60= 			'#66D1C1';
		export const teal40= 			'#99E1D6';
		export const teal20= 			'#CCF0EA';

		/* Green Tints ***/
		export const green100= 			'#009639';
		export const green80= 			'#33AB61';
		export const green60= 			'#66C088';
		export const green40= 			'#99D5B0';
		export const green20= 			'#CCEAD7';

		/* Lime Tints ***/
		export const lime100= 			'#CEDC00';
		export const lime80= 			'#D8E333';
		export const lime60= 			'#E2EA66';
		export const lime40= 			'#EBF199';
		export const lime20= 			'#F5F8CC';

	/* Other Colors ***/
		export const black=				'#19191B';
		export const white=				'#FFFFFF';
        export const grey=              '#F5F5F5';
		export const stsBlue= 			'#001587';
		export const stsRed= 			'#E90027';
		export const alertRed= 			'#E83553';
		export const alertRedPale= 		'#FDCFD7';